import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useUserType } from '../common/hooks/useUserType'
import { UserType } from '../models/graphql'
import { AssistantDashboard } from '../views/assistant/AssistantDashboard'
import { AssistantProfile } from '../views/assistant/AssistantProfile'

import { AssistantDoctors } from '../views/assistant/AssistantDoctors'
import { DoctorPatient } from '../views/doctor/DoctorPatients/components/DoctorPatient'
import { DoctorPatients } from '../views/doctor/DoctorPatients/DoctorPatients'
import { RedirectHandler } from './RedirectHandler'

const AssistantRoutes: React.FC = () => {
  const userType = useUserType()

  if (userType !== UserType.Assistant) {
    return <Redirect to={`/${userType}/home`} />
  }

  return (
    <Switch>
      <Route path="/assistant/home">
        <AssistantDashboard />
      </Route>
      <Route
        path={`/assistant/patients/:patientId/:treatmentId/:appointmentId/:action`}
      >
        <DoctorPatient />
      </Route>
      <Route
        path={`/assistant/patients/:patientId/:treatmentId/:appointmentId`}
      >
        <DoctorPatient />
      </Route>
      <Route path={`/assistant/patients/:patientId/:treatmentId`}>
        <DoctorPatient />
      </Route>
      <Route path={`/assistant/patients/:patientId`}>
        <DoctorPatient />
      </Route>
      <Route path={`/assistant/patients`}>
        <DoctorPatients />
      </Route>
      <Route path="/assistant/profile">
        <AssistantProfile />
      </Route>
      <Route path={`/assistant/doctors`}>
        <AssistantDoctors />
      </Route>
      <Route>
        <RedirectHandler />
      </Route>
    </Switch>
  )
}

export { AssistantRoutes }

import { gql } from '@apollo/client'
import { FRAGMENT_PATIENT_CHORE } from './fragments'

export const GET_PATIENT_PROFILE = gql`
  query getPatientProfile($patientId: String!) {
    getPatientProfile(patientId: $patientId) {
      ...patientChore
      gender
      bloodType
      takenMedicines
      medicineIntolerance
      emergencyContacts {
        name
        phone
      }
      phone
      address
      birthDate
      emailSettings
      weight
      height
    }
  }
  ${FRAGMENT_PATIENT_CHORE}
`

export const UPDATE_PATIENT_PROFILE = gql`
  mutation updatePatientProfile(
    $updatePatientInput: EditAblePatientData!
    $patientId: String!
  ) {
    updatePatientProfile(
      updatePatientInput: $updatePatientInput
      patientId: $patientId
    ) {
      id
    }
  }
`

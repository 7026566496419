import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { EmptyState } from '../../../../../common/components/EmptyState'
import { Loading } from '../../../../../common/components/Loading'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useSurveySchemaForResults } from '../../hooks/useSurveySchemaForResults'
import { SurveyResult } from './SurveyResult'

type RouteParams = {
  surveySchemaId: string
}

export const GetSurveyResult: React.FC = () => {
  const { t } = useTranslation()

  const { surveySchemaId } = useParams<RouteParams>()
  const { loading, surveySchema, refetch } = useSurveySchemaForResults({
    variables: { surveySchemaId },
  })

  useEffect(() => refetch(), [refetch])

  return (
    <>
      {loading && <Loading />}
      {!surveySchema && !loading && (
        <Box
          flexGrow={1}
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <EmptyState
            title={t('survey:surveySchemaNotFoundTitle')}
            description={t('survey:surveySchemaNotFoundMessage', {
              surveySchemaId,
            })}
          />
        </Box>
      )}
      {surveySchema && !loading && <SurveyResult surveySchema={surveySchema} />}
    </>
  )
}

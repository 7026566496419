import { Theme } from '@mui/material/styles/createTheme'
import React, { useState } from 'react'
import {
  Box,
  Link,
  Typography,
  useMediaQuery,
  Stack,
  TypographyProps,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { PrivacyTip as PrivacyTipIcon } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { AvatarForIcon } from './AvatarForIcon'
import { CookiePermissions } from '../../../components/CookieComponent/CookiePermissions'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.common.white,
  },
}))

interface Props extends TypographyProps {
  isFooterOpen?: boolean
}

export const Law: React.FC<Props> = ({
  isFooterOpen = false,
  ...typoProps
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const mdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  const [isCustomizationOpen, setCustomizationOpen] = useState(false)

  return (
    <>
      <Stack direction="row" gap={2}>
        {isFooterOpen && mdUp && (
          <AvatarForIcon>
            <PrivacyTipIcon fontSize="inherit" />
          </AvatarForIcon>
        )}

        <Stack
          gap={isFooterOpen ? 1 : 2}
          mt={isFooterOpen ? 2.5 : 0}
          direction={isFooterOpen ? 'column' : 'row'}
          alignItems={isFooterOpen ? 'flex-start' : 'center'}
        >
          {isFooterOpen && (
            <Typography variant="h6">{t('footer:law.title')}</Typography>
          )}

          <Link
            className={classes.link}
            component={RouterLink}
            to="/privacy-statement"
            underline="hover"
          >
            <Typography {...typoProps}>
              {t('footer:menu.privacyStatement')}
            </Typography>
          </Link>

          {!isFooterOpen && <Box sx={{ borderRight: 'solid', height: 16 }} />}

          <Link className={classes.link}>
            <Box
              onClick={() => setCustomizationOpen(true)}
              sx={{
                cursor: 'pointer',
              }}
            >
              <Typography {...typoProps}>{t('cookie:link')}</Typography>
            </Box>
          </Link>
        </Stack>
      </Stack>

      {isCustomizationOpen && (
        <CookiePermissions
          open={isCustomizationOpen}
          setOpen={setCustomizationOpen}
        />
      )}
    </>
  )
}

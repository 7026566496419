import { Box, IconButton, Typography } from '@mui/material'
import React, { FC, useState } from 'react'
import { useColorPalette } from '../../../../common/hooks/helper/useColor'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { WarningOutlined } from '@mui/icons-material'
import { CloseIcon } from '../../../../common/icons/CloseIcon'

export const TreatmentEESZTWarning: FC = () => {
  const colorPalette = useColorPalette()
  const { t } = useTranslation()
  const [isWarningMessageCanceled, setIsWarningMessageCanceled] =
    useState(false)

  return isWarningMessageCanceled ? null : (
    <Box
      bgcolor={colorPalette.warning.light}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 1,
        gap: 3,
        mx: 2,
        px: 2,
        py: 0.5,
      }}
    >
      <>
        <WarningOutlined color="warning" />
        <Typography variant="h6">{t('treatment:EESZTLoginNeeded')}</Typography>
      </>
      <IconButton onClick={() => setIsWarningMessageCanceled(true)} edge="end">
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  )
}

import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  upsertSurveyQuestionAnswer,
  upsertSurveyQuestionAnswerVariables,
} from '../../../../models/graphql'
import { UPSERT_SURVEY_QUESTION_ANSWER } from '../operations/patientSurveyOperations'

type Options = MutationHookOptions<
  upsertSurveyQuestionAnswer,
  upsertSurveyQuestionAnswerVariables
>

type Return = MutationTuple<
  upsertSurveyQuestionAnswer,
  upsertSurveyQuestionAnswerVariables
>

export const usePatientSurveyAnswerUpsert = (options: Options = {}): Return => {
  return useMutation<
    upsertSurveyQuestionAnswer,
    upsertSurveyQuestionAnswerVariables
  >(UPSERT_SURVEY_QUESTION_ANSWER, options)
}

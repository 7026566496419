import { useMutation } from '@apollo/client'
import { REMOVE_SURVEY_FROM_TREATMENT_SCHEMA } from '../operations/treatmentSchemaOperations'
import {
  removeSurveySchemaFromTreatmentSchema as RemoveSurveySchemaFromTreatmentSchema,
  removeSurveySchemaFromTreatmentSchemaVariables as RemoveSurveySchemaFromTreatmentSchemaVariables,
} from '../../../../models/graphql'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'

type Options = MutationHookOptions<
  RemoveSurveySchemaFromTreatmentSchema,
  RemoveSurveySchemaFromTreatmentSchemaVariables
>

type Return = MutationTuple<
  RemoveSurveySchemaFromTreatmentSchema,
  RemoveSurveySchemaFromTreatmentSchemaVariables
>

export const useRemoveSurveySchemaFromTreatmentSchema = (
  options: Options = {}
): Return => {
  return useMutation<
    RemoveSurveySchemaFromTreatmentSchema,
    RemoveSurveySchemaFromTreatmentSchemaVariables
  >(REMOVE_SURVEY_FROM_TREATMENT_SCHEMA, {
    refetchQueries: ['getTreatmentSchema'],
    ...options,
  })
}

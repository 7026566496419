import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import React, { useState } from 'react'
import { useInitiatePasswordReset } from '../../hooks/useInitiatePasswordReset'
import { useMe } from '../../hooks/useMe'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { SaveButton } from '../SaveButton'

const PasswordReset: React.FC = () => {
  const { t } = useTranslation()
  const [completed, setCompleted] = useState(false)
  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })
  const email = me?.email

  const [startPasswordReset, { loading }] = useInitiatePasswordReset({
    onCompleted: () => setCompleted(true),
  })

  return (
    <>
      <SaveButton
        variant="outlined"
        size="medium"
        isSaving={loading}
        disabled={completed}
        onClick={() => email && startPasswordReset({ variables: { email } })}
        text={t('auth:sendPasswordResetToken')}
        inProgressText={t('auth:sendingPasswordResetToken')}
        completedText={t('auth:passwordResetTokenSent')}
      />
      <Dialog open={completed}>
        <DialogTitle>{t('auth:sendPasswordResetToken')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('auth:passwordResetEmailInstructions', { email })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setCompleted(false)}
          >
            {t(`common:close`)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { PasswordReset }

import React from 'react'
import { QuestionInSection } from '../EditSurvey/editSurvey.types'
import { QuestionTitle } from '../FillQuestion/QuestionTitle'
import { QuestionResultByType } from './QuestionResultByType'
import { aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList as QuestionResults } from '../../../../../models/graphql'

interface Props {
  question: QuestionInSection
  questionOrder: number
  questionResult: QuestionResults
}

export const QuestionResult: React.FC<Props> = ({
  question,
  questionOrder,
  questionResult,
}) => {
  return (
    <>
      <QuestionTitle question={question} questionOrder={questionOrder} />
      <QuestionResultByType questionResult={questionResult} />
    </>
  )
}

import React, { FC } from 'react'
import { Box, TextField, TextFieldProps, Typography } from '@mui/material'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { Language } from '../../../models/graphql'
import { useFormContext } from 'react-hook-form'

type Props = {
  title: string
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any
  required?: boolean
} & TextFieldProps

export const MultiLangTextField: FC<Props> = ({
  title,
  name,
  required,
  ...props
}) => {
  const { t } = useTranslation()

  const sortedLanguages = Object.keys(Language).sort((language) =>
    language === 'hu' ? -1 : 0
  )

  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      {sortedLanguages.map((lang) => {
        const fieldError = errors ? errors?.[name]?.[lang] : null

        return (
          <Box my={1} key={lang}>
            <Typography variant="subtitle2">{`${title} - ${t(
              `common:languages.${lang}`
            )}`}</Typography>
            <TextField
              {...props}
              {...register(`${name}.${lang}`, {
                required:
                  !!required && (t('messages:warnings.required') as string),
              })}
              error={!!fieldError}
              helperText={fieldError ? fieldError.message : ''}
            />
          </Box>
        )
      })}
    </>
  )
}

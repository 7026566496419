// from https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react/44661948
export const downloadFile = (
  content: string,
  type: string,
  filename: string
): void => {
  const element = document.createElement('a')
  const file = new Blob([content], {
    type: type,
  })
  element.href = URL.createObjectURL(file)
  element.download = filename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

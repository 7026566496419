import { gql } from '@apollo/client'

export const FRAGMENT_MESSAGE = gql`
  fragment message on Message {
    id
    message
    createdAt
    readAt
    recipientUserId
    senderUserId
  }
`

export const GET_PARTNERS = gql`
  query getPartners {
    getPartners {
      id
      user {
        userType
        id
        doctorProfile {
          id
          firstName
          lastName
          professions {
            id
            code
            name
          }
          title
          phone
        }
        patientProfile {
          id
          firstName
          lastName
          title
          tajNumber
          phone
          birthDate
        }
        assistantProfile {
          id
          firstName
          lastName
          title
          registrationNumber
          phone
        }
        email
      }
      newMessages {
        ...message
      }
      lastMessageAt
    }
  }
  ${FRAGMENT_MESSAGE}
`

export const GET_PARTNER_MESSAGES = gql`
  query getPartnerMessages(
    $partnerUserId: String!
    $paginationInput: PaginationInput
  ) {
    getPartnerMessages(
      partnerUserId: $partnerUserId
      paginationInput: $paginationInput
    ) {
      ...message
    }
  }
  ${FRAGMENT_MESSAGE}
`
export const SEND_MESSAGE = gql`
  mutation sendMessage($message: String!, $recipientUserId: String!) {
    sendMessage(message: $message, recipientUserId: $recipientUserId) {
      ...message
    }
  }
  ${FRAGMENT_MESSAGE}
`

export const MARK_MESSAGES_AS_READ = gql`
  mutation markMessagesAsRead($senderUserId: String) {
    markMessagesAsRead(senderUserId: $senderUserId) {
      id
      readAt
    }
  }
`

export const NEW_MESSAGE = gql`
  subscription newMessage {
    newMessage {
      ...message
    }
  }
  ${FRAGMENT_MESSAGE}
`

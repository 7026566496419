import { appointmentChore as Appointment } from '../../models/graphql'
import { useSelectedLanguage } from './useSelectedLanguage'

type LocationReturnType = {
  mapLink?: string | undefined | null
  address?: string
  roomName?: string
  roomDescription?: string
  institutionName?: string
}

export const useAppointmentLocation = (
  appointment?: Pick<Appointment, 'institution' | 'room'>
): LocationReturnType => {
  const selectedLanguage = useSelectedLanguage()

  const mapLink =
    appointment?.room?.mapLink || appointment?.institution?.mapLink || ''

  const addresses = []
  if (appointment?.institution?.concatenatedAddress) {
    addresses.push(appointment?.institution?.concatenatedAddress)
  }
  if (appointment?.room?.address) {
    addresses.push(appointment?.room?.address[selectedLanguage])
  }
  const address = addresses.join(', ')
  const roomName = appointment?.room?.name[selectedLanguage]
  const roomDescription =
    appointment?.room?.description?.[selectedLanguage] || undefined

  const institutionName = appointment?.institution?.name || undefined

  return { mapLink, address, roomName, institutionName, roomDescription }
}

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  SurveyQuestionEnum,
  SurveyQuestionFilterInput,
} from '../../../../../models/graphql'
import {
  EXISTING_QUESTION_KEY_PREFIX,
  NEW_QUESTION_KEY_PREFIX,
} from '../../utils/survey.util'
import { ReturnData as SurveyQuestionListReturn } from '../../hooks/useSurveyQuestions'
import { Waypoint } from 'react-waypoint'
import {
  getExistingQuestionsWithoutUsedQuestions,
  QUESTION_LIST_PAGE_SIZE,
} from '../../utils/question.util'
import { Loading } from '../../../../../common/components/Loading'
import { QuestionIcon } from '../Questions/QuestionIcon'
import { Question } from '../Questions/questions.types'
import { SurveyFillTime } from '../SurveyDetails/SurveyFillTime'
import { SurveyQuestionNumber } from '../SurveyDetails/SurveyQuestionNumber'
import { Section } from './editSurvey.types'

interface Props {
  orderedSections: Section[]
  newQuestionTypes: string[]
  numberOfQuestionsInSurvey: number
  surveyQuestionListData: SurveyQuestionListReturn
  filterInputState: [
    SurveyQuestionFilterInput,
    React.Dispatch<React.SetStateAction<SurveyQuestionFilterInput>>
  ]
  addNewSection: () => void
}

export const QuestionStore: React.FC<Props> = ({
  orderedSections,
  newQuestionTypes,
  numberOfQuestionsInSurvey,
  surveyQuestionListData,
  filterInputState,
  addNewSection,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const {
    surveyQuestions,
    loading,
    fetchingMore,
    fetchMoreQuestions,
    hasMoreQuestions,
  } = surveyQuestionListData

  const filteredQuestions = useMemo(
    () =>
      getExistingQuestionsWithoutUsedQuestions(
        surveyQuestions,
        orderedSections
      ),
    [surveyQuestions, orderedSections]
  )

  const [filterInput, setFilterInput] = filterInputState

  const onQuestionEnter = useCallback(() => {
    const offset = filteredQuestions.length

    fetchMoreQuestions({
      variables: {
        filterInput,
        paginationInput: {
          offset,
          limit: QUESTION_LIST_PAGE_SIZE,
        },
      },
    })
  }, [filteredQuestions, fetchMoreQuestions, filterInput])

  const QuestionListComponentOrNull = useMemo<JSX.Element[] | null>(() => {
    if (loading && !filteredQuestions.length) {
      return null
    }
    return filteredQuestions.map((question: Question, idx) => (
      <React.Fragment key={'wp+' + question.id}>
        <Draggable
          key={`${EXISTING_QUESTION_KEY_PREFIX}:${question.id}`}
          draggableId={`${EXISTING_QUESTION_KEY_PREFIX}:${question.id}`}
          index={idx + newQuestionTypes.length}
        >
          {(provided) => (
            <Paper
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              elevation={0}
              sx={{ p: 2 }}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <QuestionIcon questionType={question.type} fontSize="large" />
                <Typography variant="h6">{question.title}</Typography>
              </Stack>
            </Paper>
          )}
        </Draggable>

        {idx === filteredQuestions.length - 3 && hasMoreQuestions && (
          <Waypoint onEnter={onQuestionEnter} />
        )}
      </React.Fragment>
    ))
  }, [
    filteredQuestions,
    hasMoreQuestions,
    loading,
    onQuestionEnter,
    newQuestionTypes.length,
  ])

  return (
    <Card
      sx={{
        width: '450px',
        height: 'fit-content',
        position: 'sticky',
        top: 0,
        overflow: 'auto',
      }}
    >
      <CardContent sx={{ padding: 3 }}>
        <Stack gap={2}>
          <SurveyQuestionNumber numberOfQuestions={numberOfQuestionsInSurvey} />
          <SurveyFillTime numberOfQuestions={numberOfQuestionsInSurvey} />

          <Button onClick={addNewSection}>
            {t('survey:editor.addNewSection')}
          </Button>
          <Card
            elevation={0}
            sx={{ backgroundColor: colorPalette.grey[200], p: 2, pt: 1.5 }}
          >
            <Typography variant="body2" mb={1.5}>
              {t('survey:editor.newQuestion')}
            </Typography>
            <Stack gap={1}>
              {newQuestionTypes.map((type: string, i: number) => (
                <Draggable
                  key={`${NEW_QUESTION_KEY_PREFIX}:${type}`}
                  draggableId={`${NEW_QUESTION_KEY_PREFIX}:${type}`}
                  index={i}
                >
                  {(provided) => (
                    <Paper
                      elevation={0}
                      sx={{ p: 2 }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      data-cy={`QuestionStore-Paper-${type}`}
                    >
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <QuestionIcon
                          questionType={type as SurveyQuestionEnum}
                          fontSize="large"
                        />
                        <Typography variant="h6">
                          {t(`survey:question.type.${type}`)}
                        </Typography>
                      </Stack>
                    </Paper>
                  )}
                </Draggable>
              ))}
            </Stack>
          </Card>

          <Card
            elevation={0}
            sx={{ backgroundColor: colorPalette.grey[200], p: 2, pt: 1.5 }}
          >
            <Typography variant="body2" mb={1.5}>
              {t('survey:editor.questions')}
            </Typography>
            <Typography variant="italic">
              {t('survey:editor.chooseFromQuestions')}
            </Typography>
            <Stack direction="column" my={1}>
              {Object.keys(SurveyQuestionEnum).map(
                (type: string, i: number) => (
                  <Stack key={i} direction="row" alignItems="center" mr={3}>
                    <Checkbox
                      checked={
                        filterInput.questionType?.includes(
                          type as SurveyQuestionEnum
                        ) || false
                      }
                      onClick={() =>
                        setFilterInput((prevInput) => {
                          const prevTypes = prevInput.questionType || []
                          return {
                            ...prevInput,
                            questionType: prevTypes.includes(
                              type as SurveyQuestionEnum
                            )
                              ? prevTypes.filter((t) => t !== type)
                              : [...prevTypes, type as SurveyQuestionEnum],
                          }
                        })
                      }
                    />
                    <Typography variant="body1">
                      {t(`survey:question.type.${type}`)}
                    </Typography>
                  </Stack>
                )
              )}
            </Stack>

            <TextField
              size="small"
              variant="outlined"
              placeholder={t('survey:editor.searchQuestions')}
              fullWidth
              onChange={(e) =>
                setFilterInput((prevInput) => ({
                  ...prevInput,
                  searchTerm: e.target.value,
                }))
              }
              sx={{ backgroundColor: colorPalette.background.default }}
            />

            <Stack gap={1} mt={3}>
              {QuestionListComponentOrNull}
              {fetchingMore && (
                <Box display="flex" alignItems="center">
                  <Loading />
                </Box>
              )}
            </Stack>
          </Card>
        </Stack>
      </CardContent>
    </Card>
  )
}

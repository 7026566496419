import { ApolloQueryResult, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import {
  getDoctorPatientTreatments,
  getDoctorPatientTreatments_getPatientProfile_treatments,
  getDoctorPatientTreatmentsVariables,
} from '../../../../models/graphql'
import { GET_DOCTOR_PATIENT_TREATMENTS } from '../../../../operations/doctorPatientOperations'

type Props = {
  skip?: boolean
  patientId?: string
}

type ReturnData = {
  treatments: getDoctorPatientTreatments_getPatientProfile_treatments[]
  loading: boolean
  refetch: (
    variables?: Partial<getDoctorPatientTreatmentsVariables> | undefined
  ) => Promise<ApolloQueryResult<getDoctorPatientTreatments>>
}

type RouteParams = {
  patientId?: string
}

export const useDoctorPatientTreatmentsData = ({
  skip,
  patientId: overridePatientId,
}: Props): ReturnData => {
  const { patientId: currentPatientId } = useParams<RouteParams>()
  const patientId = overridePatientId || currentPatientId || ''

  const { data, loading, refetch } = useQuery<
    getDoctorPatientTreatments,
    getDoctorPatientTreatmentsVariables
  >(GET_DOCTOR_PATIENT_TREATMENTS, {
    variables: { patientId },
    skip: !patientId || skip,
    displayName: 'Patient treatments list',
    fetchPolicy: 'network-only',
  })

  const treatments = data?.getPatientProfile.treatments || []

  return {
    treatments,
    loading,
    refetch,
  }
}

import { QueryHookOptions, useQuery } from '@apollo/client'
import {
  getSurveySchemaById,
  getSurveySchemaByIdVariables,
  getSurveySchemaById_getSurveySchemaById,
} from '../../../../models/graphql'

import { GET_SURVEY_SCHEMA_BY_ID } from '../operations/surveyOperations'

type Options = QueryHookOptions<
  getSurveySchemaById,
  getSurveySchemaByIdVariables
>

type Return = {
  surveySchema?: getSurveySchemaById_getSurveySchemaById
  loading: boolean
  refetch: () => void
}
export const useSurveySchemaData = (options: Options = {}): Return => {
  const { data, loading, refetch } = useQuery<
    getSurveySchemaById,
    getSurveySchemaByIdVariables
  >(GET_SURVEY_SCHEMA_BY_ID, options)

  const surveySchema = data?.getSurveySchemaById

  return {
    loading,
    surveySchema,
    refetch,
  }
}

import { ArrowForward, Delete } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { LocalSchedule } from '../treatmentSchemaSchedule.types'
import {
  FormValues,
  LocalDependency,
  MinMax,
  TimeUnit,
} from './dependencyEditor.types'
import {
  getScheduleById,
  getTimeUnitFromDistanceString,
  getValueFromDistanceString,
  mapFormValueToLocalDependency,
} from './dependencyUtils'

interface Props {
  dependency: LocalDependency
  schedules: LocalSchedule[]
  isUnsavedChangesInForm: boolean
  isEditable: boolean
  submitInList: (d: LocalDependency) => boolean
  deleteDependency: (dependencyId: string) => void
  setDependencyDirty: (id: string, isDirty: boolean) => void
  validateDependency: (dependencyToValidate: LocalDependency) => boolean
}

export const DependencyEditorListItem = React.memo<Props>(
  ({
    dependency,
    schedules,
    isUnsavedChangesInForm,
    isEditable,
    submitInList,
    deleteDependency,
    setDependencyDirty,
    validateDependency,
  }) => {
    const {
      control,
      watch,
      getValues,
      handleSubmit,
      register,
      setError,
      reset,
      formState: { isDirty, errors },
    } = useForm<FormValues>({
      mode: 'all',
      defaultValues: {
        start: getScheduleById(schedules, dependency.startId),
        end: getScheduleById(schedules, dependency.endId),
        timeConstraintNeeded: !!dependency.distance,
        unit: getTimeUnitFromDistanceString(dependency.distance),
        distanceValue: getValueFromDistanceString(dependency.distance),
        minMaxConstraint:
          !dependency.constraint ||
          dependency.constraint === MinMax.NotSpecified
            ? MinMax.MIN
            : dependency.constraint,
      },
    })

    const { timeConstraintNeeded } = watch()

    useEffect(() => {
      setDependencyDirty(dependency.id, isDirty)
    }, [dependency.id, isDirty, setDependencyDirty])

    /** Checks for local errors, if everything is valid, updates the dependency in the parent list and checks for other dependency related errors */
    const onSubmit = useMemo(
      () =>
        handleSubmit((formValues) => {
          let error = false
          if (formValues.start?.id === formValues.end?.id) {
            error = true
            setError('end', { type: 'validate' })
            setError('start', { type: 'validate' })
          }
          if (!error) {
            const submitSucceeded = submitInList(
              mapFormValueToLocalDependency(formValues, dependency.id)
            )
            // Note: resetting the form to make isDirty false
            if (submitSucceeded) {
              reset(formValues)
            }
          }
        }),
      [dependency.id, handleSubmit, reset, setError, submitInList]
    )

    const selectedLanguage = useSelectedLanguage()
    const { t } = useTranslation()

    // TODO maybe separate component
    /** Renders the schedule selectors, the start and end of a dependency */
    const renderScheduleSelector = useCallback(
      (name: 'start' | 'end') => (
        <Controller
          control={control}
          name={name}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Autocomplete<LocalSchedule>
                disabled={!isEditable}
                options={schedules}
                size="small"
                sx={{ flex: 1 }}
                value={value}
                onChange={(_, newValue) => {
                  onChange(newValue)
                  onSubmit()
                }}
                getOptionLabel={(option) =>
                  option.doctorTitle
                    ? option.doctorTitle[selectedLanguage] || ''
                    : ''
                }
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderOption={(renderProps, option: LocalSchedule) => (
                  <li {...renderProps} key={option.id}>
                    {option.doctorTitle
                      ? option.doctorTitle[selectedLanguage] || ''
                      : ''}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    variant="outlined"
                    placeholder={t('protocol:dependency.chooseSchedule')}
                  />
                )}
              />
            )
          }}
        />
      ),
      [control, isEditable, onSubmit, schedules, selectedLanguage, t]
    )

    const getBorderLeftStyleOfListItem = (): string => {
      // In case if there is dependency error should not show orange warning.
      if (!!dependency.error) {
        return ''
      }
      if (isDirty) {
        return `3px solid ${isUnsavedChangesInForm ? 'red' : 'orange'}`
      }
      return '3px solid transparent'
    }

    return (
      <Box
        border={!!dependency.error ? 1 : 0}
        borderColor={'red'}
        borderRadius={isDirty && !dependency.error ? 0 : 3}
        borderLeft={getBorderLeftStyleOfListItem}
        p={1}
        marginBottom={1}
      >
        {!!dependency.error && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="errorText">{t(dependency.error)}</Typography>
          </Box>
        )}
        <Box display="flex" alignItems="center" flex={1} marginBottom={2}>
          {renderScheduleSelector('start')}
          <ArrowForward fontSize="large" />
          {renderScheduleSelector('end')}
          {isEditable && (
            <IconButton onClick={() => deleteDependency(dependency.id)}>
              <Delete />
            </IconButton>
          )}
        </Box>
        <Collapse in={timeConstraintNeeded} timeout={200}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Controller
              control={control}
              name="minMaxConstraint"
              render={({ field: { value, onChange } }) => (
                <Select
                  disabled={!isEditable}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                    // This field is depends from another listitem value,
                    // so validate after its changed
                    validateDependency(
                      mapFormValueToLocalDependency(getValues(), dependency.id)
                    )
                  }}
                  size="small"
                >
                  <MenuItem value={MinMax.MIN}>
                    {t(`protocol:dependency.minConstraint`)}
                  </MenuItem>
                  <MenuItem value={MinMax.MAX}>
                    {t(`protocol:dependency.maxConstraint`)}
                  </MenuItem>
                </Select>
              )}
            />
            <Box marginX={1}>
              <TextField
                {...register('distanceValue', {
                  min: 1,
                  validate: {
                    required: (value) => {
                      if (!value && getValues('timeConstraintNeeded')) {
                        return 'required if timeConstraintNeeded is true'
                      }
                      return true
                    },
                  },
                })}
                size="small"
                variant="outlined"
                type="number"
                disabled={!isEditable}
                InputProps={{ inputProps: { min: 1 } }}
                error={!!errors.distanceValue}
              />
            </Box>
            <Controller
              control={control}
              name="unit"
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  size="small"
                  disabled={!isEditable}
                >
                  <MenuItem value={TimeUnit.H}>
                    {t(`common:timingInterval.h`)}
                  </MenuItem>
                  <MenuItem value={TimeUnit.D}>
                    {t(`common:timingInterval.d`)}
                  </MenuItem>
                  <MenuItem value={TimeUnit.W}>
                    {t(`common:timingInterval.w`)}
                  </MenuItem>
                </Select>
              )}
            />
            {isDirty && (
              <Button
                variant="text"
                color={isUnsavedChangesInForm ? 'error' : 'primary'}
                onClick={onSubmit}
              >
                {t(`common:save`)}
              </Button>
            )}
          </Box>
        </Collapse>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={'center'}
        >
          <Controller
            control={control}
            name="timeConstraintNeeded"
            render={({ field: { value, onChange } }) => (
              <Checkbox
                disabled={!isEditable}
                checked={value}
                onChange={(_, newValue) => {
                  onChange(newValue)
                  onSubmit()
                }}
              />
            )}
          />
          <Typography variant="body2">
            {t('protocol:dependency.timeConstraintNeeded')}
          </Typography>
        </Box>
      </Box>
    )
  }
)

import { useMutation } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BetmenList } from '../../../common/components/BetmenList/BetmenList'
import { BetmenListActions } from '../../../common/components/BetmenList/BetmenListActions'
import { BetmenListBody } from '../../../common/components/BetmenList/BetmenListBody'
import { BetmenListFilter } from '../../../common/components/BetmenList/BetmenListFilter'
import { BetmenListHeader } from '../../../common/components/BetmenList/BetmenListHeader'
import { BetmenListHeaderCell } from '../../../common/components/BetmenList/BetmenListHeaderCell'
import { BetmenListItemCard } from '../../../common/components/BetmenList/BetmenListItemCard'
import { BetmenListItemCardCell } from '../../../common/components/BetmenList/BetmenListItemCardCell'
import { AddButton } from '../../../common/components/AddButton/AddButton'
import { BetmenPage } from '../../../common/components/BetmenPage'
import {
  ConfirmDialog,
  ConfirmDialogValue,
} from '../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useAppointmentTypes } from '../../../common/hooks/useAppointmentTypes'
import { useSelectedLanguage } from '../../../common/hooks/useSelectedLanguage'
import {
  deleteAppointmentInfoVariables,
  listAppointmentInfos_listAppointmentInfos,
} from '../../../models/graphql'
import { DELETE_APPOINTMENT_INFO } from '../../../operations/appointmentOperations'
import { AppointmentAdminModal } from '../components/AppointmentAdminModal'
import { useUserType } from '../../../common/hooks/useUserType'

type RouteParams = {
  appointmentId?: string
}

const AppointmentAdmin: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { appointmentId } = useParams<RouteParams>()
  const userType = useUserType()

  const [appointmentAdminModalOpen, setAppointmentAdminModalOpen] =
    useState(false)

  const [confirmDeleteAppointmentInfo, setConfirmDeleteAppointmentInfo] =
    useState<ConfirmDialogValue>(false)

  const [searchTerm, setSearchTerm] = useState<string | null>(null)

  const [deleteAppointmentInfoId, setDeleteAppointmentInfoId] = useState<
    string | null
  >()

  const selectedLanguage = useSelectedLanguage()

  const [selectedAppointmentInfo, setSelectedAppointmentInfo] = useState<
    listAppointmentInfos_listAppointmentInfos | null | undefined
  >()

  const { appointmentTypes, loading } = useAppointmentTypes()

  const [deleteAppointmentInfo] = useMutation<deleteAppointmentInfoVariables>(
    DELETE_APPOINTMENT_INFO,
    {
      refetchQueries: ['listAppointmentInfos'],
    }
  )

  useEffect(() => {
    const selected = appointmentTypes?.find(
      (appointmentInfo) => appointmentInfo.id === appointmentId
    )
    setSelectedAppointmentInfo(selected)
  }, [appointmentId, appointmentTypes])

  // TODO - filter on backend
  const filteredAppointmentInfos = useMemo(() => {
    if (!searchTerm) {
      return appointmentTypes
    }
    return appointmentTypes.filter(
      (appointmentInfo) =>
        appointmentInfo.doctorTitle.en.includes(searchTerm) ||
        appointmentInfo.doctorTitle.hu.includes(searchTerm) ||
        appointmentInfo.patientTitle.en.includes(searchTerm) ||
        appointmentInfo.patientTitle.hu.includes(searchTerm)
    )
  }, [appointmentTypes, searchTerm])

  return (
    <>
      <BetmenPage
        title={t('menu:doctor.appointmentAdmin')}
        withoutPadding
        headerRightContent={
          <AddButton
            handleAdd={() => setAppointmentAdminModalOpen(true)}
            buttonText={t(`protocol:addNewSchedule`)}
          />
        }
      >
        <BetmenList
          dataSource={filteredAppointmentInfos}
          loading={loading}
          gridColumnSizes={[6, 5, 1]}
          filters={
            <BetmenListFilter
              searchPlaceholder={t('doctor:searchAppointment')}
              handleSearch={setSearchTerm}
            />
          }
          header={
            <BetmenListHeader>
              <BetmenListHeaderCell>
                {`${t('appointment:admin.AppointmentName')} (${t(
                  'common:doctor'
                )})`}
              </BetmenListHeaderCell>

              <BetmenListHeaderCell>
                {`${t('appointment:admin.AppointmentName')} (${t(
                  'common:patient'
                )})`}
              </BetmenListHeaderCell>

              <BetmenListHeaderCell actions>
                {t('common:actions')}
              </BetmenListHeaderCell>
            </BetmenListHeader>
          }
        >
          <BetmenListBody>
            {filteredAppointmentInfos.map((appointment) => (
              <BetmenListItemCard key={'wp+' + appointment.id}>
                <BetmenListItemCardCell>
                  {appointment.doctorTitle?.[selectedLanguage]}
                </BetmenListItemCardCell>

                <BetmenListItemCardCell>
                  {appointment.patientTitle?.[selectedLanguage]}
                </BetmenListItemCardCell>

                <BetmenListActions
                  edit={() =>
                    history.push(
                      `/${userType?.toLowerCase()}/appointments/${
                        appointment.id
                      }`
                    )
                  }
                  remove={() => {
                    setDeleteAppointmentInfoId(appointment.id)
                    setConfirmDeleteAppointmentInfo(true)
                  }}
                />
              </BetmenListItemCard>
            ))}
          </BetmenListBody>
        </BetmenList>
      </BetmenPage>
      <AppointmentAdminModal
        isOpen={appointmentAdminModalOpen || !!selectedAppointmentInfo}
        setIsOpen={setAppointmentAdminModalOpen}
        selectedAppointmentInfo={selectedAppointmentInfo}
        onClose={() => history.push(`/${userType?.toLowerCase()}/appointments`)}
      />
      <ConfirmDialog
        isAlertingDialog
        valueState={[
          confirmDeleteAppointmentInfo,
          setConfirmDeleteAppointmentInfo,
        ]}
        text={t(`appointment:admin.confirmDeleteAppointmentInfo`)}
        onAccept={() => {
          deleteAppointmentInfo({
            variables: {
              appointmentInfoInput: {
                appointmentInfoId: deleteAppointmentInfoId,
              },
            },
          })
        }}
      />
    </>
  )
}

export { AppointmentAdmin }

import { Box, TextField } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { QuestionInSection } from '../EditSurvey/editSurvey.types'

interface Props {
  question: QuestionInSection
  answerIndex: number
  isPreview: boolean
  readOnly?: boolean
}

export const FillSimpleTextQuestion: React.FC<Props> = ({
  question,
  answerIndex,
  isPreview,
  readOnly,
}) => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const pathToAnswer = `answers.${answerIndex}.simpleTextQuestionAnswer.text`
  const errorToAnswer = get(errors, pathToAnswer)

  return (
    <Box>
      <TextField
        {...register(pathToAnswer, {
          required:
            isPreview || !question.isRequired
              ? false
              : (t('messages:warnings.required') as string),
        })}
        fullWidth
        variant="outlined"
        minRows={2}
        multiline
        error={!!errorToAnswer}
        helperText={errorToAnswer?.message}
        sx={{ mt: 1 }}
        disabled={readOnly}
      />
    </Box>
  )
}

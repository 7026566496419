import {
  GridProps,
  GridSize,
  IconButton,
  IconButtonProps,
  Popover,
  Stack,
  Tooltip,
} from '@mui/material'
import React, { FC, useState } from 'react'
import { BetmenListItemCardCell } from './BetmenListItemCardCell'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Edit as EditIcon,
  RemoveRedEye as ViewIcon,
  Share as ShareIcon,
  Clear as ClearIcon,
} from '@mui/icons-material'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { isFunction } from 'lodash'

const More: FC = ({ children }) => {
  const [actionsMenuOpenAnchor, setActionsMenuAnchor] =
    useState<HTMLButtonElement | null>(null)

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          setActionsMenuAnchor(e.currentTarget)
        }}
        data-cy={'BetmenListActions-IconButton-more'}
      >
        <MoreVertIcon fontSize="large" />
      </IconButton>
      {actionsMenuOpenAnchor && (
        <Popover
          onClick={(e) => e.stopPropagation()}
          open={!!actionsMenuOpenAnchor}
          anchorEl={actionsMenuOpenAnchor}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClose={(e: any) => {
            e.stopPropagation()
            setActionsMenuAnchor(null)
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {children}
        </Popover>
      )}
    </>
  )
}

interface GeneralActionButtonProps extends IconButtonProps {
  tooltipKey: string
  click: () => void
  icon: React.ReactElement
}

const GeneralActionButton: FC<GeneralActionButtonProps> = ({
  tooltipKey,
  click,
  icon,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t(tooltipKey) as string}>
      <IconButton
        color="primary"
        onClick={(e) => {
          e.stopPropagation()
          click()
        }}
        size="large"
        {...props}
      >
        {icon}
      </IconButton>
    </Tooltip>
  )
}

interface ActionButtonProps {
  click: () => void
}

const Edit: FC<ActionButtonProps> = ({ click }) => {
  return (
    <GeneralActionButton
      tooltipKey="common:edit"
      click={click}
      icon={<EditIcon />}
      data-cy={'BetmenListEditButton'}
    />
  )
}

const Delete: FC<ActionButtonProps> = ({ click }) => {
  return (
    <GeneralActionButton
      tooltipKey="common:delete"
      click={click}
      icon={<ClearIcon />}
      color="error"
      data-cy={'BetmenListDeleteButton'}
    />
  )
}

const View: FC<ActionButtonProps> = ({ click }) => {
  return (
    <GeneralActionButton
      tooltipKey="common:view"
      click={click}
      icon={<ViewIcon />}
      data-cy={'BetmenListViewButton'}
    />
  )
}

const Share: FC<ActionButtonProps> = ({ click }) => {
  return (
    <GeneralActionButton
      tooltipKey="common:share"
      click={click}
      icon={<ShareIcon />}
      data-cy={'BetmenListShareButton'}
    />
  )
}

interface BetmenListActionsProps extends GridProps {
  gridSize?: GridSize
  more?: React.ReactNode
  edit?: () => void
  remove?: () => void
  view?: () => void
  share?: () => void
}

export const BetmenListActions: FC<BetmenListActionsProps> = ({
  children,
  gridSize,
  more,
  view,
  edit,
  remove,
  share,
  ...gridProps
}) => {
  return (
    <BetmenListItemCardCell {...gridProps} gridSize={gridSize}>
      <Stack direction="row" justifyContent="flex-end">
        {children}
        {isFunction(view) && <View click={view} />}
        {isFunction(edit) && <Edit click={edit} />}
        {isFunction(share) && <Share click={share} />}
        {isFunction(remove) && <Delete click={remove} />}
        {more && <More>{more}</More>}
      </Stack>
    </BetmenListItemCardCell>
  )
}

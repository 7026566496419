import { useQuery } from '@apollo/client'
import { getDoctorOwnAssistants } from '../../../../models/graphql'
import { GET_DOCTOR_OWN_ASSISTANTS } from '../operations/doctorOwnAssistantsOperations'

type Return = {
  data?: getDoctorOwnAssistants
  loading: boolean
  refetch: () => void
}
export const useDoctorOwnAssistants = (): Return => {
  const { data, loading, refetch } = useQuery<getDoctorOwnAssistants>(
    GET_DOCTOR_OWN_ASSISTANTS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    }
  )

  return {
    loading,
    data,
    refetch,
  }
}

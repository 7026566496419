import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PageWithScrollableCard } from '../common/layout/PageWithScrollableCard'

const PrivacyStatement: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box display="flex" height="100%">
      <PageWithScrollableCard
        pageTitle={t('footer:menu.privacyStatement')}
        markdownTextInCard={t('common:privacyStatement')}
      />
    </Box>
  )
}

export { PrivacyStatement }

import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { getTreatmentSchema_getTreatmentSchema_schedules_dependencies } from '../../../../../models/graphql'
import { LocalSchedule, LocalSurvey } from '../treatmentSchemaSchedule.types'
import { Color, Week } from './scheduleCalendar.types'

export const useCalendarColors = (): Color => {
  const colorPalette = useColorPalette()
  return {
    event: colorPalette.info.dark,
    optimalDuration: colorPalette.warning.dark,
    error: colorPalette.secondary.light,
    dependency: colorPalette.grey[200],
    tooltip: colorPalette.grey[200],
  }
}

/**
 * This function checks whether we have to draw
 * a dependency line in the calendar on a given day.
 *
 * (owns the protocol)
 * @param dayFromStart The day that we want to observe
 * @param localSchedules The schedules that are displayed in the calendar
 * @returns true if there is a schedule after (or on) the dayFromStart
 * which has a dependency before (or on) the dayFromStart
 */
function calculateIsDependency(
  dayFromStart: number,
  localSchedules: Array<LocalSchedule | LocalSurvey>
): boolean {
  const schedulesBeforeDay = localSchedules.filter(
    (schedule: LocalSchedule | LocalSurvey) =>
      Number(schedule.customDays) <= dayFromStart
  )
  const schedulesAfterDay = localSchedules.filter(
    (schedule: LocalSchedule | LocalSurvey) =>
      Number(schedule.customDays) >= dayFromStart
  )

  return !!schedulesAfterDay.find(
    (schedule: LocalSchedule | LocalSurvey) =>
      schedule.__typename === 'PlainTreatmentElement' &&
      !!schedule.dependencies.find(
        (
          dependency: getTreatmentSchema_getTreatmentSchema_schedules_dependencies
        ) =>
          !!schedulesBeforeDay.find(
            (beforeSchedule: LocalSchedule | LocalSurvey) =>
              beforeSchedule.id === dependency.fromId
          )
      )
  )
}

const MIN_WEEK_SIZE = 12

export function generateWeeks(
  optimalDuration: number,
  localSchedules: Array<LocalSchedule | LocalSurvey>
): Week[] {
  const dayOfLastSchedule = Math.max(
    ...localSchedules.map(
      (schedule: LocalSchedule | LocalSurvey) => schedule.customDays ?? 0
    )
  )
  const weekSize = Math.max(
    MIN_WEEK_SIZE,
    Math.ceil(dayOfLastSchedule / 7),
    Math.ceil(optimalDuration / 7)
  )

  return Array.from(new Array(weekSize).keys()).map((numOfWeek: number) => {
    return Array.from(new Array(7).keys()).map((numOfDay: number) => {
      const dayFromStart = numOfWeek * 7 + numOfDay
      return {
        dayFromStart: dayFromStart + 1,
        schedules: localSchedules.filter(
          (schedule: LocalSchedule | LocalSurvey) =>
            schedule.customDays === dayFromStart // TODO itt =< - et kellene vizsgalni, de meg nincs kitalalva hozza a megjelenites
        ),
        isOptimalDuration:
          optimalDuration !== 0 && optimalDuration === dayFromStart + 1,
        isDependency: calculateIsDependency(dayFromStart, localSchedules),
        error: localSchedules.find(
          (schedule: LocalSchedule | LocalSurvey) =>
            schedule.customDays === dayFromStart && schedule.dayError
        )?.dayError,
      }
    })
  })
}

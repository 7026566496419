import { gql } from '@apollo/client'
import { FRAGMENT_BNO_CODE_CHORE } from './fragments'

export const GET_BNO_CODES = gql`
  query bnoCodes($searchTerm: String) {
    bnoCodes(searchTerm: $searchTerm) {
      ...BnoCodeChore
    }
  }
  ${FRAGMENT_BNO_CODE_CHORE}
`

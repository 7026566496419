import React, { FC } from 'react'
import { BoxWithLabel } from '../../../common/components/BoxWithLabel'
import { TextField, Autocomplete } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useSelectedLanguage } from '../../../common/hooks/useSelectedLanguage'
import { roomChore } from '../../../models/graphql'

type Props = {
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  rooms: roomChore[]
}

export const RoomSelector: FC<Props> = (props) => {
  const { name, label, required, disabled = false, rooms } = props
  const { t } = useTranslation()
  const selectedLanguage = useSelectedLanguage()

  const { control } = useFormContext()

  return (
    <BoxWithLabel label={label}>
      <Controller
        control={control}
        name={name}
        defaultValue={null}
        rules={{
          required,
          validate: (value) => (required ? value?.length > 0 : true),
        }}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <Autocomplete
            options={rooms}
            getOptionLabel={(room) => room.name[selectedLanguage]}
            isOptionEqualToValue={(room) => room.id === value?.id}
            disabled={disabled}
            value={value ?? null}
            onChange={(_, newValue) => {
              onChange(newValue)
            }}
            onBlur={onBlur}
            fullWidth
            data-cy="AddAppointmentModal-TextField-selectedRoom"
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                placeholder={t('appointment:doctor.chooseRoomLater')}
                variant="outlined"
              />
            )}
          />
        )}
      />
    </BoxWithLabel>
  )
}

import { useQuery } from '@apollo/client'

import { GET_DOCTOR_OWN_PATIENTS } from '../../operations/doctorProfileOperations'
import { GET_ASSISTANT_PATIENTS } from '../../operations/assistantProfileOperations'
import {
  getAssistantPatients,
  getDoctorOwnPatients,
  getDoctorOwnPatientsVariables,
  getAssistantPatients_getAssistantProfile_ownPatients,
  getDoctorOwnPatients_getDoctorProfile_ownPatients,
  UserType,
} from '../../models/graphql'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { useUserProfile } from './useUserProfile'
import { useUserType } from './useUserType'

type ReturnData = {
  patientList:
    | getDoctorOwnPatients_getDoctorProfile_ownPatients[]
    | getAssistantPatients_getAssistantProfile_ownPatients[]
  loading: boolean
  refetch: () => void
}

type Props = {
  skip?: boolean
  showOnlyActivePatients?: boolean
}

export const useDoctorPatientsData = ({
  skip,
  showOnlyActivePatients,
}: Props): ReturnData => {
  const userType = useUserType()
  const profile = useUserProfile()
  const isAssistant = userType === UserType.Assistant
  const doctorProfileId = profile?.id

  const doctorResults = useQuery<
    getDoctorOwnPatients,
    getDoctorOwnPatientsVariables
  >(GET_DOCTOR_OWN_PATIENTS, {
    skip: skip || isAssistant || !doctorProfileId,
    variables: {
      doctorProfileId: doctorProfileId || '',
      isActivePatient: showOnlyActivePatients,
    },
    fetchPolicy: 'network-only',
  })

  const assistantResults = useQuery<getAssistantPatients>(
    GET_ASSISTANT_PATIENTS,
    { skip: skip || !isAssistant }
  )

  const patientList = useMemo(() => {
    const rawPatientList = isAssistant
      ? assistantResults.data?.getAssistantProfile?.ownPatients || []
      : doctorResults.data?.getDoctorProfile.ownPatients || []
    const sortedPatientList = rawPatientList
      .slice()
      .sort((a, b) => (dayjs(b.createdAt).isAfter(a.createdAt) ? 1 : -1))
    return sortedPatientList
  }, [isAssistant, assistantResults.data, doctorResults.data])

  const loading = isAssistant ? assistantResults.loading : doctorResults.loading
  const refetch = isAssistant ? assistantResults.refetch : doctorResults.refetch

  return {
    patientList,
    loading,
    refetch,
  }
}

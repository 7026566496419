import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  generateFileDocuments,
  generateFileDocumentsVariables,
} from '../../models/graphql'
import { DOWLOAD_DOCUMENT } from '../../operations/documentOperations'

type Options = MutationHookOptions<
  generateFileDocuments,
  generateFileDocumentsVariables
>

type Return = MutationTuple<
  generateFileDocuments,
  generateFileDocumentsVariables
>

export const useDocumentDownload = (options: Options = {}): Return => {
  return useMutation<generateFileDocuments, generateFileDocumentsVariables>(
    DOWLOAD_DOCUMENT,
    options
  )
}

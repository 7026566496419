import { ApolloError, MutationFunction, useMutation } from '@apollo/client'
import {
  EesztMobileTokenLogin,
  EesztMobileTokenLoginVariables,
} from '../../../models/graphql'
import { EESZT_MOBILE_TOKEN_LOGIN } from '../../../operations/eesztOperation'

type ReturnTypes = {
  loading: boolean
  error: ApolloError | undefined
  eesztMobileTokenLogin: MutationFunction<
    EesztMobileTokenLogin,
    EesztMobileTokenLoginVariables
  >
}

/**
 * Hook to handle the EESZT login with mobile token functionality.
 * The EESZTMobileToken application generates the token for this authentication
 */
export const useEesztMobileTokenLogin = (): ReturnTypes => {
  const [eesztMobileTokenLogin, { loading, error }] = useMutation<
    EesztMobileTokenLogin,
    EesztMobileTokenLoginVariables
  >(EESZT_MOBILE_TOKEN_LOGIN, { refetchQueries: ['hasEESZTToken'] })

  return {
    loading,
    error,
    eesztMobileTokenLogin,
  }
}

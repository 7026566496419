import { gql } from '@apollo/client'

export const GET_INVITED_DOCTORS_BY_ADMIN = gql`
  query getInvitedDoctorsByAdmin {
    getInvitedDoctorsByAdmin {
      id
      email
      lastLogin
      createdAt
      doctorProfile {
        title
        firstName
        lastName
      }
    }
  }
`

export const DELETE_INVITE = gql`
  mutation deleteInvitation($deleteUserId: String!) {
    deleteInvitation(deleteUserId: $deleteUserId)
  }
`

import React from 'react'
import { aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_ScaleQuestionResult as ScaleQuestionResults } from '../../../../../models/graphql'
import { QuestionResultChart } from './QuestionResultChart'

interface Props {
  questionResult: ScaleQuestionResults
}

export const ScaleQuestionResult: React.FC<Props> = ({ questionResult }) => {
  const {
    scaleAspectResults,
    scaleOptionResultsWithoutScaleAspect,
    totalAnswersWithoutScaleAspect,
  } = questionResult

  if (
    !!scaleOptionResultsWithoutScaleAspect.length &&
    (!!totalAnswersWithoutScaleAspect || totalAnswersWithoutScaleAspect === 0)
  ) {
    const data = scaleOptionResultsWithoutScaleAspect.map((optionResult) => ({
      name: `${optionResult.value}${optionResult.label ? ': ' : ''}${
        optionResult.label
      }`,
      amount: optionResult.total,
    }))

    return (
      <QuestionResultChart
        data={data}
        numberOfAnswers={totalAnswersWithoutScaleAspect}
      />
    )
  }

  return (
    <>
      {scaleAspectResults.map((aspect, i) => {
        const { totalAnswers } = aspect
        const data = aspect.scaleOptionResults.map((optionResult) => ({
          name: `${optionResult.value}${optionResult.label ? ': ' : ''}${
            optionResult.label
          }`,
          amount: optionResult.total,
        }))

        return (
          <QuestionResultChart
            key={i}
            data={data}
            numberOfAnswers={totalAnswers}
            chartLabel={aspect.name}
          />
        )
      })}
    </>
  )
}

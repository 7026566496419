import {
  Paper,
  Table,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from '@mui/material'
import { isFunction } from 'lodash'
import React, {
  ChangeEventHandler,
  PropsWithChildren,
  ReactElement,
  useState,
} from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { Loading } from '../Loading'
import { DataTableBody } from './DataTableBody'
import { DataTableHead } from './DataTableHead'
import { DataTableToolbar } from './DataTableToolbar'
import {
  DataTableColDef,
  DataTableFreeAction,
  DataTableOrder,
  DataTableRowAction,
  DataTableRowData,
} from './interfaces'
import { TableSizeHelper } from './TabSizeHelper'
import { getComparator } from './utils'
import { TableSearch } from './TableSearch'

type Props<T> = {
  title?: string
  isLoading?: boolean
  paging?: boolean
  pageSizeOptions?: number[]
  enableSort?: boolean
  defaultSort?: DataTableOrder
  defaultSortBy?: string
  columns: DataTableColDef<T>[]
  data: T[]
  freeActions?: DataTableFreeAction[]
  rowActions?: DataTableRowAction<T>[]
  showRowDetails?: boolean
  hideColumns?: {
    [width: string]: string[]
  }
  hasMoreRows?: boolean
  fetchMoreRows?: (offset: number) => void
  searchTerm?: string
  handleSearch?: (searchTerm: string) => void
  maxHeight?: string
  disableOrder?: boolean
}

const ResponsiveTable = <T extends DataTableRowData>(
  props: PropsWithChildren<Props<T>>
): ReactElement => {
  const {
    children,
    title,
    columns = [],
    data = [],
    hideColumns = {},
    paging = false,
    pageSizeOptions = [20, 50, 100],
    freeActions,
    rowActions,
    isLoading,
    enableSort = true,
    defaultSort = 'asc',
    defaultSortBy,
    showRowDetails = false,
    hasMoreRows,
    fetchMoreRows,
    searchTerm,
    handleSearch,
    maxHeight,
    disableOrder,
  } = props
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOptions[0])
  const [order, setOrder] = useState<DataTableOrder>(defaultSort)
  const [orderBy, setOrderBy] = useState<DataTableColDef<T> | null>(
    columns.find((item) => item.field === defaultSortBy) ?? columns[0] ?? null
  )
  const [currentSize, setCurrentSize] = useState(0)

  const hiddenColumnNames = hideColumns[currentSize] || []

  const visibleColumns = columns.filter(
    (item) => !hiddenColumnNames.includes(item.field)
  )

  const hiddenColumns = columns.filter((item) =>
    hiddenColumnNames.includes(item.field)
  )

  const rows = disableOrder
    ? data
    : data.slice().sort(getComparator(order, orderBy))

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSort = (col: DataTableColDef<T>) => {
    const isAsc = orderBy?.field === col.field && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(col)
  }

  return (
    <Paper>
      {(title || freeActions?.length) && (
        <DataTableToolbar title={title} actions={freeActions} />
      )}
      {isFunction(handleSearch) && (
        <TableSearch handleSearch={handleSearch} searchTerm={searchTerm} />
      )}
      <TableContainer sx={{ maxHeight: maxHeight || '80vh' }}>
        {isLoading && <Loading />}
        <TableSizeHelper
          breakpoints={Object.keys(hideColumns)}
          currentSize={currentSize}
          setCurrentSize={setCurrentSize}
        />
        <Table stickyHeader>
          <DataTableHead<T>
            visibleColumns={visibleColumns}
            hiddenColumns={hiddenColumns}
            order={order}
            orderBy={orderBy}
            onSort={enableSort ? handleSort : undefined}
            actions={rowActions}
            showRowDetails={showRowDetails}
          />
          <DataTableBody<T>
            columns={columns}
            visibleColumns={visibleColumns}
            hiddenColumns={hiddenColumns}
            rows={rows}
            actions={rowActions}
            showRowDetails={showRowDetails}
            hasMoreRows={hasMoreRows}
            fetchMoreRows={fetchMoreRows}
          />
          {paging && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={pageSizeOptions}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t('table:pagination.labelRowsPerPage')}
                  labelDisplayedRows={(info) =>
                    t('table:pagination.labelDisplayedRows', info)
                  }
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {children}
    </Paper>
  )
}

export { ResponsiveTable }

import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  removeAssistantFromDoctor,
  removeAssistantFromDoctorVariables,
} from '../../../../models/graphql'
import { REMOVE_ASSISTANT_FROM_DOCTOR } from '../operations/doctorOwnAssistantsOperations'

type Options = MutationHookOptions<
  removeAssistantFromDoctor,
  removeAssistantFromDoctorVariables
>

type Return = MutationTuple<
  removeAssistantFromDoctor,
  removeAssistantFromDoctorVariables
>

export const useDoctorOwnAssistantDelete = (options: Options = {}): Return => {
  return useMutation<
    removeAssistantFromDoctor,
    removeAssistantFromDoctorVariables
  >(REMOVE_ASSISTANT_FROM_DOCTOR, options)
}

import React from 'react'
import { Box } from '@mui/material'
import { useUserType } from '../../../../common/hooks/useUserType'
import { PatientDetails } from './PatientDetails'
import { PatientEeszt } from './PatientEeszt'
import { PatientDoctors } from '../../../patient/PatientDoctors/PatientDoctors'
import { useHistory } from 'react-router'
import { useEesztTokenState } from '../../../../common/hooks/eeszt/useEesztTokenState'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { EmptyState } from '../../../../common/components/EmptyState'
import { useParams } from 'react-router-dom'
import {
  getDoctorPatientProfile_getPatientProfile,
  UserType,
} from '../../../../models/graphql'
import { TreatmentSelector } from '../../Treatments/components/TreatmentSelector'
import { GetTreatmentPanelData } from '../../Treatments/components/GetTreatmentPanelData'

type Props = {
  activeTabIndex: number
  patientProfile: getDoctorPatientProfile_getPatientProfile
}

type RouteParams = {
  patientId: string
  treatmentId: string
}

export const PatientPanel: React.FC<Props> = ({
  activeTabIndex,
  patientProfile,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { patientId, treatmentId } = useParams<RouteParams>()
  const userType = useUserType()

  const isEesztTokenValid = useEesztTokenState()

  const loginToEesztMessage = (
    <Box
      maxWidth={1280}
      height="100%"
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      {userType === UserType.Doctor && (
        <EmptyState
          title={t('eeszt:notLoggedInTitle')}
          description={t('eeszt:notLoggedInDoctorMessage')}
        />
      )}
      {userType === UserType.Assistant && (
        <EmptyState
          title={t('eeszt:underConstruction')}
          description={t('eeszt:notAvailableForAssistants')}
        />
      )}
    </Box>
  )

  if (activeTabIndex === 0) {
    return (
      <Box overflow="auto" height="100%">
        {!treatmentId && patientProfile && (
          <TreatmentSelector patientId={patientId} />
        )}
        {treatmentId && <GetTreatmentPanelData />}
      </Box>
    )
  } else if (activeTabIndex === 1) {
    return (
      <Box overflow="auto">
        <Box width="100%" maxWidth={600}>
          <PatientDetails
            isEesztTokenValid={isEesztTokenValid}
            patientProfile={patientProfile}
            isDeletable={patientProfile.isDeletableForDoctor}
          />
        </Box>
      </Box>
    )
  } else if (activeTabIndex === 2) {
    return (
      <Box overflow="auto" height="100%">
        {isEesztTokenValid && (
          <Box width="100%" maxWidth={1280} p={2}>
            <PatientEeszt patientId={patientProfile.id} />
          </Box>
        )}
        {!isEesztTokenValid && <>{loginToEesztMessage}</>}
      </Box>
    )
  } else if (activeTabIndex === 3) {
    return (
      <Box overflow="auto">
        <Box width="100%" maxWidth={400}>
          <PatientDoctors patientId={patientProfile.id} />
        </Box>
      </Box>
    )
  } else if (activeTabIndex === 4) {
    history.push(`/messages/${patientProfile.user?.id}`)
    return <></>
  }
  return <></>
}

import { useMutation } from '@apollo/client'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import {
  removeSchemaDependency,
  removeSchemaDependencyVariables,
} from '../../../../models/graphql'
import { REMOVE_SCHEMA_DEPENDENCY } from '../operations/treatmentSchemaOperations'

type Options = MutationHookOptions<
  removeSchemaDependency,
  removeSchemaDependencyVariables
>

type Return = MutationTuple<
  removeSchemaDependency,
  removeSchemaDependencyVariables
>

export const useSchemaDependencyRemove = (options: Options = {}): Return => {
  return useMutation<removeSchemaDependency, removeSchemaDependencyVariables>(
    REMOVE_SCHEMA_DEPENDENCY,
    {
      ...options,
    }
  )
}

import { Person as PersonIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNotifications } from '../../../../common/providers/NotificationProvider'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { findAssistantProfile_findAssistantProfile_AssistantProfile as AssistantProfile } from '../../../../models/graphql'
import { ValidPhonePattern } from '../../../../utils/isValidPhone'
import { useAddAssistantToDoctor } from '../hooks/useAddAssistantToDoctor'
import { useFindAssistantProfile } from '../hooks/useFindAssistantProfile'
import { useInviteAssistant } from '../hooks/useInviteAssistant'

interface newAssistantForm {
  email: string
  registrationNumber: string
  title: string
  firstName: string
  lastName: string
  gender: string
  phone: string
}

interface InviteAssistantModal {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  ownAssistantRegistrationNumbers: string[]
  onComplete?: () => void
}

const InviteAssistantModal: React.FC<InviteAssistantModal> = ({
  isOpen,
  setIsOpen,
  ownAssistantRegistrationNumbers,
  onComplete,
}) => {
  const { t } = useTranslation()
  const { setSuccessToast, setCustomErrorToast } = useNotifications()

  const [isAlreadyAssigned, setIsAlreadyAssigned] = useState<boolean>(false)
  const [existingAssistantProfile, setExistingAssistantProfile] =
    useState<AssistantProfile | null>(null)
  const [invalidFieldValues, setInvalidFieldValues] = useState<{
    [key: string]: string[]
  }>({ email: [], registrationNumber: [] })

  const {
    watch,
    register,
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors, isValid },
  } = useForm<newAssistantForm>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      registrationNumber: '',
      firstName: '',
      lastName: '',
      gender: '',
      phone: '',
    },
  })

  const required = {
    value: true,
    message: t('messages:warnings.required'),
  }

  const closeModal = () => {
    setIsOpen(false)
    reset()
    setExistingAssistantProfile(null)
  }

  const [inviteAssistantQuery, { loading: isInvitingAssistant }] =
    useInviteAssistant({
      onCompleted: () => {
        setSuccessToast('common:invite.sent', { useFullKey: true })
        closeModal()
        onComplete && onComplete()
      },
      onError: (error) => {
        const errorMessages: string[] = []
        const graphQlError = error.graphQLErrors[0]
        if (graphQlError) {
          const detail =
            (graphQlError.extensions?.exception?.meta as { target: string })
              ?.target || ''
          const fieldsToCheck: (keyof newAssistantForm)[] = [
            'email',
            'registrationNumber',
          ]
          fieldsToCheck.forEach((field) => {
            const isFieldDuplicate = !!~detail.indexOf(field)
            if (isFieldDuplicate) {
              setInvalidFieldValues({
                ...invalidFieldValues,
                [field]: [...invalidFieldValues[field], watch(field)],
              })
              trigger(field)
              errorMessages.push(t('common:invite.valueExists', { field }))
            }
          })
        }
        setCustomErrorToast(errorMessages.join(', ') || t('common:serverError'))
      },
    })

  const [addAssistantToDoctorMutation, { loading: isAddingAssistantToDoctor }] =
    useAddAssistantToDoctor({
      onCompleted: () => {
        setSuccessToast('common:invite.assistant.assigned', {
          useFullKey: true,
        })
        closeModal()
        onComplete && onComplete()
      },
    })

  const [findAssistantProfileQuery] = useFindAssistantProfile({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (
        data.findAssistantProfile.__typename === 'AssistantProfileNotFoundError'
      ) {
        return
      }
      setExistingAssistantProfile(data.findAssistantProfile)
    },
  })

  const canSave = !isAlreadyAssigned && (!!existingAssistantProfile || isValid)
  const isSaving = isAddingAssistantToDoctor || isInvitingAssistant

  const onSubmit = handleSubmit((createAssistantArgs) =>
    inviteAssistantQuery({ variables: { createAssistantArgs } })
  )

  const handleSave = () => {
    if (existingAssistantProfile) {
      addAssistantToDoctorMutation({
        variables: {
          assistantId: existingAssistantProfile.id,
        },
      })
    }
  }

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <form onSubmit={onSubmit}>
        <DialogTitle>
          {t('common:invite.assistant.assignAssistantTitle')}
        </DialogTitle>

        <DialogContent sx={{ width: { sm: 400 } }}>
          <Box mb={3}>
            <DialogContentText>
              {t('common:invite.doctor.panelText')}
            </DialogContentText>
          </Box>

          <Box my={1}>
            <Typography variant="subtitle2">
              {t('common:invite.assistant.registrationNumber')}
            </Typography>
            <Controller
              name="registrationNumber"
              control={control}
              rules={{
                required,
                maxLength: 6,
                min: {
                  value: 0,
                  message: t('messages:warnings.negativeRegistrationNumber'),
                },
                pattern: /[0-9]*/,
                validate: (value) =>
                  !invalidFieldValues.registrationNumber.includes(value),
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  fullWidth
                  error={!!errors.registrationNumber}
                  helperText={errors.registrationNumber?.message}
                  variant="outlined"
                  onChange={(event) => {
                    field.onChange(event)
                    setExistingAssistantProfile(null)

                    const registrationNumber = event.target.value
                    const isValidRegistrationNumber =
                      registrationNumber.length > 0

                    if (isValidRegistrationNumber) {
                      setIsAlreadyAssigned(
                        ownAssistantRegistrationNumbers.includes(
                          registrationNumber
                        )
                      )
                      findAssistantProfileQuery({
                        variables: {
                          registrationNumber,
                        },
                      })
                    } else {
                      setIsAlreadyAssigned(false)
                    }
                  }}
                />
              )}
            />
          </Box>

          <Collapse in={!!existingAssistantProfile}>
            <Box mt={2} ml={0.5}>
              <Typography variant="body1" color="textSecondary">
                {t(
                  isAlreadyAssigned
                    ? 'common:invite.assistant.isAlreadyAssigned'
                    : 'common:invite.assistant.profileFound'
                )}
              </Typography>

              <Box mt={1} display="flex" alignItems="center">
                <Box px={1} fontSize={32} display="flex">
                  <PersonIcon fontSize="inherit" />
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {t(
                      'common:formattedNameFull',
                      existingAssistantProfile || ''
                    )}
                  </Typography>
                  <Typography variant="body2">
                    {existingAssistantProfile?.user?.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Collapse>

          <Collapse in={!existingAssistantProfile}>
            <Box my={1}>
              <Typography variant="subtitle2">
                {t('patients:gender')}
              </Typography>
              <FormControl error={!!errors.gender}>
                <Controller
                  name="gender"
                  control={control}
                  rules={{ required }}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Box display="flex">
                        <FormControlLabel
                          value="f"
                          control={<Radio />}
                          label={t('patients:genders.f')}
                        />
                        <FormControlLabel
                          value="m"
                          control={<Radio />}
                          label={t('patients:genders.m')}
                        />
                      </Box>
                    </RadioGroup>
                  )}
                />
                <FormHelperText>{errors.gender?.message}</FormHelperText>
              </FormControl>
            </Box>

            <Box my={1}>
              <Typography variant="subtitle2">
                {t('profile:doctor.title')}
              </Typography>

              <TextField
                {...register('title')}
                error={!!errors.title}
                helperText={errors.title?.message}
                fullWidth
                variant="outlined"
              />
            </Box>

            <Box my={1}>
              <Typography variant="subtitle2">
                {t('patients:lastName')}
              </Typography>
              <TextField
                {...register('lastName', { required })}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                fullWidth
                variant="outlined"
              />
            </Box>

            <Box my={1}>
              <Typography variant="subtitle2">
                {t('patients:firstName')}
              </Typography>

              <TextField
                {...register('firstName', { required })}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                fullWidth
                variant="outlined"
              />
            </Box>

            <Box my={1}>
              <Typography variant="subtitle2">{t('common:email')}</Typography>
              <TextField
                {...register('email', {
                  required,
                  validate: (value) =>
                    !invalidFieldValues.email.includes(value),
                })}
                type="email"
                error={!!errors.email}
                helperText={errors.email?.message}
                fullWidth
                variant="outlined"
              />
            </Box>

            <Box my={1}>
              <Typography variant="subtitle2">{t('common:phone')}</Typography>
              <TextField
                {...register('phone', {
                  pattern: {
                    value: ValidPhonePattern,
                    message: 'messages:warnings.notPhone',
                  },
                })}
                type="phone"
                fullWidth
                placeholder="+36 1 234 5678 #4433"
                error={!!errors.phone}
                helperText={
                  errors.phone?.message ? t(errors.phone?.message) : undefined
                }
                variant="outlined"
              />
            </Box>
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Button disabled={isSaving} variant="outlined" onClick={closeModal}>
            {t('common:cancel')}
          </Button>

          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSaving || !canSave}
            onClick={existingAssistantProfile ? handleSave : undefined}
          >
            {t(
              existingAssistantProfile
                ? 'common:invite.assistant.assignAssistant'
                : 'common:invite.send'
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export { InviteAssistantModal }

import { action } from 'easy-peasy'
import { AuthModel } from './auth.interface'

const authModel: AuthModel = {
  isFirstLogin: undefined,
  setIsFirstLogin: action((state, payload) => {
    state.isFirstLogin = payload
  }),
}

export { authModel }

import React from 'react'
import { Box, Typography } from '@mui/material'
import { Alert } from '@mui/material'

import { Loading } from '../../../../../common/components/Loading'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'

const Info: React.FC = ({ children }) => {
  return (
    <Box maxWidth={355} mt={2}>
      <Alert elevation={0} variant="filled" severity="info">
        <Typography variant="body2">{children}</Typography>
      </Alert>
    </Box>
  )
}

type Props = {
  isActiveEesztBooking: boolean
  editingDisabled: boolean
  isBookingToEeszt: boolean
  loadingEesztSlots: boolean
  hasEesztSlots: boolean
  canQueryEeszt: boolean
  isEesztTokenValid: boolean
  isSentTreatment: boolean
  failedTransactionMessage?: string
}

const AddAppointmentModalInfo: React.FC<Props> = (props) => {
  const {
    isActiveEesztBooking,
    editingDisabled,
    isBookingToEeszt,
    loadingEesztSlots,
    hasEesztSlots,
    canQueryEeszt,
    isEesztTokenValid,
    isSentTreatment,
    failedTransactionMessage,
  } = props
  const { t } = useTranslation()

  const displayPendingEesztBookingMessage =
    process.env.REACT_APP_EESZT_BOOKING_ENABLED === 'true' &&
    !isActiveEesztBooking &&
    editingDisabled

  const displayBookingToBetmenMessage =
    !isActiveEesztBooking && !isBookingToEeszt

  const displayBookingToEesztMessage =
    process.env.REACT_APP_EESZT_BOOKING_ENABLED === 'true' &&
    !isActiveEesztBooking &&
    !editingDisabled &&
    isBookingToEeszt

  const displayEesztDataLoadingSpinner =
    process.env.REACT_APP_EESZT_BOOKING_ENABLED === 'true' &&
    displayBookingToEesztMessage &&
    loadingEesztSlots

  const displatFailedTransactionMessage =
    process.env.REACT_APP_EESZT_BOOKING_ENABLED === 'true' &&
    displayBookingToEesztMessage &&
    failedTransactionMessage

  const dispplayLoginToEesztToBookAlert =
    process.env.REACT_APP_EESZT_BOOKING_ENABLED === 'true' &&
    !isActiveEesztBooking &&
    canQueryEeszt &&
    !isEesztTokenValid

  const dispplayLoginToEesztToDeleteAlert =
    process.env.REACT_APP_EESZT_BOOKING_ENABLED === 'true' &&
    isActiveEesztBooking &&
    !isEesztTokenValid

  const dispplaySendTreatmentToBookEesztAlert =
    process.env.REACT_APP_EESZT_BOOKING_ENABLED === 'true' &&
    !isActiveEesztBooking &&
    canQueryEeszt &&
    !isSentTreatment

  return (
    <Box mt={2}>
      <Typography variant="subtitle2">
        {t('appointment:bookingType')}
      </Typography>

      {isActiveEesztBooking && (
        <Typography variant="body2">
          {t('appointment:bookedToEeszt')}
        </Typography>
      )}

      {displayPendingEesztBookingMessage && (
        <Typography>{t('appointment:pendingEesztBooking')}</Typography>
      )}

      {displayBookingToBetmenMessage && (
        <Typography variant="body2">
          {t('appointment:bookingToBetmen')}
        </Typography>
      )}

      {displatFailedTransactionMessage && (
        <Box mt={2}>
          <Typography variant="subtitle2">
            {t('appointment:eesztMessage')}
          </Typography>
          <Typography variant="body2">{failedTransactionMessage}</Typography>
        </Box>
      )}

      {displayBookingToEesztMessage && (
        <Typography variant="body2">
          {t('appointment:bookingToEeszt')}
          {loadingEesztSlots && <> - {t('appointment:loadingEesztSlots')}</>}
          {!loadingEesztSlots && !hasEesztSlots && (
            <> - {t('appointment:noEesztSlotsAvailable')}</>
          )}
        </Typography>
      )}

      {displayEesztDataLoadingSpinner && (
        <Box mt={3}>
          <Loading size={24} />
        </Box>
      )}

      {dispplayLoginToEesztToDeleteAlert && (
        <Info>{t('appointment:loginToEesztToDelete')}</Info>
      )}

      {dispplaySendTreatmentToBookEesztAlert && (
        <Info>{t('appointment:eesztBookingAvailableToSentTreatments')}</Info>
      )}

      {dispplayLoginToEesztToBookAlert && (
        <Info>{t('appointment:bookingLoginToEeszt')}</Info>
      )}
    </Box>
  )
}

export { AddAppointmentModalInfo }

import { getLink } from '../../../apollo-links'
import { apolloClient } from '../../../ApolloProvider'
import { store } from '../../../store'

enum LoginErrorMessage {
  Unauthorized = 'Unauthorized',
  UserLockedOut = 'UserLockedOut',
}

enum LoginErrorTranslateKey {
  wrongCredentials = 'messages:warnings.wrongCredentials',
  lockedAccount = 'messages:warnings.lockedAccount',
  serverError = 'messages:warnings.serverError',
}

export const storeDataAfterLogin = (
  jwtToken: string,
  isFirstLogin: boolean
): void => {
  localStorage.setItem('token', jwtToken)
  apolloClient.setLink(getLink())
  store.dispatch.auth.setIsFirstLogin(isFirstLogin)
}

export const getLoginErrorTranslateKey = (
  errorMessage?: string
): LoginErrorTranslateKey => {
  switch (errorMessage) {
    case LoginErrorMessage.Unauthorized:
      return LoginErrorTranslateKey.wrongCredentials
    case LoginErrorMessage.UserLockedOut:
      return LoginErrorTranslateKey.lockedAccount
    default:
      return LoginErrorTranslateKey.serverError
  }
}

import { gql } from '@apollo/client'

export const INITIATE_PASSWORD_RESET = gql`
  mutation initiatePasswordReset($email: String!) {
    initiatePasswordReset(email: $email)
  }
`

export const SET_NEW_PASSWORD = gql`
  mutation setNewPassword($token: String!, $password: String!) {
    setNewPassword(token: $token, password: $password)
  }
`

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      jwtToken
      isFirstLogin
    }
  }
`

export const LOGIN_WITH_TWO_FACTOR_TOKEN = gql`
  mutation LoginWithTwoFactorToken(
    $loginWithTwoFactorTokenInput: LoginWithTwoFactorTokenInput!
  ) {
    loginWithTwoFactorToken(
      loginWithTwoFactorTokenInput: $loginWithTwoFactorTokenInput
    ) {
      jwtToken
    }
  }
`

export const GET_ME = gql`
  query Me {
    me {
      id
      email
      userType
      language
      isAdmin
      isLockedOut
      assistantDoctorIds
      institutionPermissions {
        institutionId
      }
      profile {
        id
        title
        firstName
        lastName
        phone
        ... on DoctorProfile {
          institutionToDoctor {
            id
            phone
            workingHours
            facebookId
            assistant
            institution {
              id
              name
            }
          }
          professions {
            id
            code
            name
          }
        }
      }
    }
  }
`

export const CHANGE_LANGUAGE = gql`
  mutation ChangeLanguage($language: String!) {
    changeLanguage(language: $language) {
      id
      language
    }
  }
`

import { Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { isValidHttpUrl } from '../../../../../common/components/Reference/referenceUtil'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { Reference } from '../../../TreatmentSchema/components/TreatmentSchemaDetailsEdit/treatmentSchemaDetailsEdit.types'

interface Props {
  reference: string
}

export const ShowTreatmentReference: React.FC<Props> = ({ reference }) => {
  const { t } = useTranslation()
  const referenceArray = reference.includes('[') ? JSON.parse(reference) : []

  return (
    <Stack gap={1}>
      <Typography variant="subtitle1">
        {t('treatment:protocol.url')}:
      </Typography>
      {referenceArray.map((ref: Reference, index: number) => (
        <Stack direction="row" key={index} alignItems="center" gap={1} ml={2}>
          {isValidHttpUrl(ref.url) && (
            <>
              <Typography variant="subtitle1">{index + 1}.</Typography>
              <Typography variant="subtitle1">{ref.name}: </Typography>
              <Link href={ref.url} target="_blank" rel="noreferrer">
                <Typography variant="subtitle1">{ref.url}</Typography>
              </Link>
            </>
          )}
        </Stack>
      ))}
    </Stack>
  )
}

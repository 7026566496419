import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, ButtonProps } from '@mui/material'
import { CancelOutlined as CancelOutlinedIcon } from '@mui/icons-material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  ConfirmDialog,
  ConfirmDialogValue,
} from '../../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'
import { useTreatmentSchemaRemove } from '../../hooks/useTreatmentSchemaRemove'
import { useStoreActions } from '../../../../../store/store.hooks'
import { isFunction } from 'lodash'
import { useUserType } from '../../../../../common/hooks/useUserType'

interface Props extends ButtonProps {
  treatmentSchemaId: string
  refetchTreatments?: () => void
}

export const TreatmentSchemaRemoveButton: FC<Props> = ({
  treatmentSchemaId,
  refetchTreatments,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userType = useUserType()
  const [confirmDeleteSchema, setConfirmDeleteSchema] =
    useState<ConfirmDialogValue>(false)
  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const [removeTreatmentSchema] = useTreatmentSchemaRemove({
    onCompleted: () => {
      setToast({
        text: t('notification:successRemoveProtocol'),
        type: 'success',
      })
      history.push(`/${userType?.toLowerCase()}/treatment-options`)
      isFunction(refetchTreatments) && refetchTreatments()
    },
    onError: (error) => {
      setToast({
        text: t(error.message),
        type: 'error',
      })
    },
  })

  return (
    <>
      <Button
        color="error"
        variant="text"
        startIcon={<CancelOutlinedIcon />}
        onClick={() => setConfirmDeleteSchema(true)}
        {...buttonProps}
      >
        {t('protocol:deleteProtocol')}
      </Button>

      <ConfirmDialog
        isAlertingDialog
        valueState={[confirmDeleteSchema, setConfirmDeleteSchema]}
        text={t(`protocol:confirmSchemaRemoval`)}
        onAccept={() => {
          removeTreatmentSchema({
            variables: {
              treatmentSchemaId,
            },
          })
        }}
      />
    </>
  )
}

import React from 'react'
import { Box, Typography } from '@mui/material'

interface Props {
  title: string
  color: string
}

export const ViewSurveyDetailsLabel: React.FC<Props> = ({ title, color }) => {
  return (
    <Box
      sx={{
        backgroundColor: color,
        py: 0.5,
        px: 3,
        maxWidth: { xs: 'fit-content', sm: 'inherit' },
      }}
    >
      <Typography variant="subtitle1">{title}</Typography>
    </Box>
  )
}

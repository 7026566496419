import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { Button, ButtonProps } from '@mui/material'
import { Done as DoneIcon } from '@mui/icons-material'
import { Loading } from '../Loading'

type Props = {
  disabled?: boolean
  isSaving?: boolean
  text?: string
  inProgressText?: string
  completedText?: string
  completedIcon?: JSX.Element
} & ButtonProps

export const SaveButton: FC<Props> = ({
  disabled,
  isSaving,
  onClick,
  text,
  inProgressText,
  completedText,
  completedIcon,
  ...props
}) => {
  const { t } = useTranslation()

  const [saveCompleted, setSaveCompleted] = useState(false)
  const [saveStarted, setSaveStarted] = useState(false)
  const timeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (isSaving) {
      setSaveStarted(true)
    } else {
      if (saveStarted) {
        setSaveCompleted(true)
        setSaveStarted(false)
        timeout.current = setTimeout(() => setSaveCompleted(false), 2000)
      }
    }
  }, [isSaving, setSaveCompleted, saveCompleted, saveStarted])

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  const startIcon = isSaving ? (
    <Loading size={12} color="inherit" />
  ) : saveCompleted ? (
    completedIcon || <DoneIcon />
  ) : undefined

  return (
    <Button
      data-cy="SaveButton"
      disabled={disabled || isSaving || !!saveCompleted}
      onClick={onClick}
      startIcon={startIcon}
      {...props}
    >
      {isSaving
        ? inProgressText || t('common:saving')
        : saveCompleted
        ? completedText || t('common:saved')
        : text || t('common:save')}
    </Button>
  )
}

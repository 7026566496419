/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Paper,
  TextField,
  Collapse,
  Typography,
  useTheme,
  Autocomplete,
} from '@mui/material'
import {
  Close as CloseIcon,
  AddCircle as AddCircleIcon,
} from '@mui/icons-material'
import { useMutation, gql } from '@apollo/client'
import { useSelectedLanguage } from '../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { useUserProfile } from '../../../common/hooks/useUserProfile'

import { UPDATE_DOCTOR_PROFILE } from '../../../operations/doctorProfileOperations'
import {
  updateDoctorProfile,
  updateDoctorProfileVariables,
  InstitutionInputData,
  getDoctorProfile_getDoctorProfile_professions,
  updateDoctorProfile_updateDoctorProfile,
} from '../../../models/graphql'
import { BoxWithLabel } from '../../../common/components/BoxWithLabel'
import { InputStatusAdornment } from '../../../common/components/InputStatusAdornment'
import { useStoreActions, useStoreState } from '../../../store/store.hooks'
import { ScrollablePanel } from '../../../common/components/ScrollablePanel'
import { ValidPhonePattern } from '../../../utils/isValidPhone'
import { SaveButton } from '../../../common/components/SaveButton'
import { MessageModal } from './components/MessageModal'
import { useInstitutionList } from '../../../common/hooks/useInstitutionList'
import { PasswordReset } from '../../../common/components/PasswordReset'
import { useDebounce } from '../../../common/hooks/helper/useDebounce'
import { ProfessionSelector } from '../../../common/components/selectors/ProfessionSelector'

interface DoctorFormData {
  phone?: string | null
  title?: string | null
  institutions?: InstitutionInputData[] | null
  professions: getDoctorProfile_getDoctorProfile_professions[] | null
}

const DoctorProfile: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const profile = useUserProfile()
  const doctorProfileId = profile?.id

  const isFirstLogin = useStoreState((state) => state.auth.isFirstLogin)
  const setIsFirstLogin = useStoreActions(
    (actions) => actions.auth.setIsFirstLogin
  )
  const [isOpen, toggleIsOpen] = useState(!!isFirstLogin)

  const [inputValue, setInputValue] = useState('')
  const nameSearchTerm = useDebounce(inputValue, 200)

  const selectedLanguage = useSelectedLanguage()
  const intlCollator = new Intl.Collator(selectedLanguage)

  const methods = useForm<DoctorFormData>({
    mode: 'onBlur',
    defaultValues: {
      phone: profile?.phone ?? '',
      title: profile?.title ?? '',
      professions:
        profile?.__typename === 'DoctorProfile' ? profile.professions : [],
      institutions:
        profile?.__typename === 'DoctorProfile'
          ? profile.institutionToDoctor.map((institutionToDoctor) => {
              return {
                phone: institutionToDoctor.phone,
                facebookId: institutionToDoctor.facebookId,
                institution: institutionToDoctor.institution || [],
                assistant: institutionToDoctor.assistant,
                workingHours: institutionToDoctor.workingHours || [],
              }
            })
          : [],
    },
  })
  const {
    watch,
    register,
    reset,
    formState: { isDirty, isValid, dirtyFields, errors },
    handleSubmit,
    control,
    setValue,
    trigger,
  } = methods

  const watchAllFields = watch()
  const [savedFields, setSavedFields] = useState<typeof dirtyFields>()

  useEffect(() => {
    trigger()
  }, [trigger, isValid])

  const resetFormWithDoctorProfile = (
    doctorProfile: updateDoctorProfile_updateDoctorProfile
  ) => {
    reset({
      phone: doctorProfile.phone,
      title: doctorProfile.title,
      institutions: (doctorProfile.institutionToDoctor || []).map(
        (institutionToDoctor) => ({
          phone: institutionToDoctor.phone,
          facebookId: institutionToDoctor.facebookId,
          institution: institutionToDoctor.institution || [],
          assistant: institutionToDoctor.assistant,
          workingHours: institutionToDoctor.workingHours || [],
        })
      ),
      professions: doctorProfile?.professions,
    })
  }

  const [updateDoctorProfileMutation, { loading: isUpdatingDoctorProfile }] =
    useMutation<updateDoctorProfile, updateDoctorProfileVariables>(
      UPDATE_DOCTOR_PROFILE,
      {
        refetchQueries: ['listInstitutions'],
        onCompleted: (data) => {
          setSavedFields(dirtyFields)
          resetFormWithDoctorProfile(data.updateDoctorProfile)
        },
        update: (cache, { data }) => {
          if (!data?.updateDoctorProfile) {
            return
          }
          const { updateDoctorProfile } = data
          cache.writeFragment({
            id: cache.identify({
              __typename: 'DoctorProfile',
              id: updateDoctorProfile.id,
            }),
            fragment: gql`
              fragment DoctorProfileUpdateChore on DoctorProfile {
                title
                phone
                professions {
                  id
                  code
                  name
                }
                hasEESZTToken
                institutionToDoctor {
                  id
                  assistant
                  facebookId
                  phone
                  workingHours
                  institution {
                    id
                    mapLink
                    name
                  }
                }
              }
            `,
            data: updateDoctorProfile,
          })
        },
      }
    )

  const {
    loading: isLoadingAllInstitutions,
    institutions,
    refetch,
  } = useInstitutionList({
    notifyOnNetworkStatusChange: true,
    variables: { nameSearchTerm },
  })

  useEffect(() => {
    refetch()
  }, [refetch, nameSearchTerm])

  // We need the next line to avoid the following warning:
  // The value provided to Autocomplete is invalid.
  // None of the options match with `{"id":"","name":""}.
  const emptyInstitution = nameSearchTerm ? [] : [{ id: '', name: '' }]

  const institutionList =
    [
      ...institutions
        .filter(
          (institution) =>
            !watchAllFields.institutions ||
            watchAllFields.institutions.every(
              (existingInstitution) =>
                existingInstitution.institution?.id !== institution.id
            )
        )
        .sort((firstInst, secondInst) =>
          intlCollator.compare(firstInst.name, secondInst.name)
        ),
      ...emptyInstitution,
    ] || []

  const deleteOneInstitution = (institutionId: string) => {
    const filteredInstitutions = watchAllFields.institutions?.filter(
      (institution) => institution.institution.id !== institutionId
    )

    setValue('institutions', filteredInstitutions || [], { shouldDirty: true })
  }

  const submitForm = (formFields: DoctorFormData) => {
    if (!doctorProfileId) {
      return
    }
    setIsFirstLogin(false)
    return updateDoctorProfileMutation({
      variables: {
        doctorProfileId,
        updateDoctorInput: {
          phone: formFields.phone,
          title: formFields.title,
          professionIds: formFields.professions?.map((prof) => prof.id),
          // removing empty (invalid) institutions and working hours
          institutions: formFields.institutions
            ? formFields.institutions
                .filter((institution) => institution.institution?.id)
                .map((institutionData) => ({
                  ...institutionData,
                  institution: {
                    id: institutionData.institution.id,
                    name: institutionData.institution.name,
                  },
                  workingHours: institutionData.workingHours
                    ? institutionData.workingHours.filter(
                        (workingHour) => workingHour?.length > 0
                      )
                    : [],
                }))
            : [],
        },
      },
    })
  }

  const isLastInstitutionNew = !!watchAllFields.institutions?.some(
    (institution) => !institution.institution?.id
  )

  const hasInstitution = !!watchAllFields.institutions?.length

  const hasAnyProfession = !!watchAllFields.professions?.length

  const savingDisabled =
    !isDirty ||
    !isValid ||
    !hasInstitution ||
    isLastInstitutionNew ||
    !hasAnyProfession

  useEffect(() => {
    setIsFirstLogin(false)
  }, [setIsFirstLogin])

  return (
    <FormProvider {...methods}>
      <ScrollablePanel
        title={`${t('profile:doctor.profile')} - 
      ${t('common:formattedNameFull', {
        title: profile?.title ?? '',
        firstName: profile?.firstName ?? '',
        lastName: profile?.lastName ?? '',
      })}`}
        headerRightContent={
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <PasswordReset />
            </Box>
            <Box>
              <SaveButton
                disabled={savingDisabled}
                isSaving={isUpdatingDoctorProfile}
                onClick={handleSubmit(submitForm)}
              />
            </Box>
          </Box>
        }
      >
        <Box width={400} maxWidth="100%">
          <form>
            <BoxWithLabel label={t(`profile:doctor.title`)}>
              <TextField
                {...register('title')}
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputStatusAdornment
                      modified={dirtyFields?.title}
                      saved={savedFields?.title}
                    />
                  ),
                }}
              />
            </BoxWithLabel>

            <BoxWithLabel label={t(`profile:doctor.phone`)}>
              <TextField
                {...register('phone', {
                  pattern: {
                    value: ValidPhonePattern,
                    message: 'messages:warnings.notPhone',
                  },
                })}
                size="small"
                fullWidth
                placeholder="+36 1 234 5678 #4433"
                error={!!errors.phone}
                helperText={
                  errors.phone?.message ? t(errors.phone?.message) : undefined
                }
                InputProps={{
                  endAdornment: (
                    <InputStatusAdornment
                      error={!!errors.phone}
                      modified={dirtyFields?.phone}
                      saved={savedFields?.phone}
                      onErrorClick={() => {
                        reset()
                      }}
                    />
                  ),
                }}
              />
            </BoxWithLabel>

            <ProfessionSelector
              name="professions"
              required
              hasError={!!errors.professions || !hasAnyProfession}
              label={t('profile:doctor.professions')}
            />

            <BoxWithLabel label={t(`profile:doctor.institutions`)}>
              {(watchAllFields.institutions || []).map((institution, i) => {
                const institutionErrors = (errors as any).institutions?.[i]
                const dirtyInstitutionFields = (dirtyFields as any)
                  .institutions?.[i]
                const savedInstitutionFields = (savedFields as any)
                  ?.institutions?.[i]
                const isNewInstitution = !institution.institution?.id

                return (
                  <Paper key={i}>
                    <Box mx={1} mb={2}>
                      <Box mt={1} display="flex" alignItems="center">
                        <BoxWithLabel label={t('institution:name')}>
                          <Controller
                            control={control}
                            name={`institutions.${i}.institution`}
                            defaultValue={undefined}
                            render={({ field: { onChange, onBlur, name } }) => {
                              const value = watch(
                                `institutions.${i}.institution`
                              )
                              return (
                                <Autocomplete
                                  options={
                                    isLoadingAllInstitutions
                                      ? []
                                      : isNewInstitution
                                      ? institutionList
                                      : [value]
                                  }
                                  getOptionLabel={(option) =>
                                    option?.name || ''
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                  disabled={
                                    !isNewInstitution || isUpdatingDoctorProfile
                                  }
                                  loadingText={t('common:loading')}
                                  noOptionsText={t('common:noOptions')}
                                  value={value || null}
                                  onChange={(e, newValue) =>
                                    onChange(newValue || '')
                                  }
                                  filterOptions={(x) => x}
                                  onInputChange={(_, newInputValue) => {
                                    setInputValue(newInputValue)
                                  }}
                                  onBlur={onBlur}
                                  onOpen={() => {
                                    setInputValue('')
                                    if (nameSearchTerm !== '') {
                                      refetch({
                                        nameSearchTerm: '',
                                      })
                                    }
                                  }}
                                  loading={isLoadingAllInstitutions}
                                  renderOption={(props, option, index) => {
                                    if (option.id === '') {
                                      return null
                                    }
                                    return (
                                      <li {...props} key={option.id ?? index}>
                                        {option.name}
                                      </li>
                                    )
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={t('common:search')}
                                      error={!institution.institution}
                                      helperText={
                                        !institution.institution
                                          ? t('messages:warnings.required')
                                          : undefined
                                      }
                                      variant="outlined"
                                      name={name}
                                    />
                                  )}
                                />
                              )
                            }}
                          />
                        </BoxWithLabel>
                        {!isNewInstitution && (
                          <Box ml={1} mt={2}>
                            <Button
                              variant="outlined"
                              size="medium"
                              onClick={() => {
                                deleteOneInstitution(institution.institution.id)
                              }}
                            >
                              <CloseIcon />
                            </Button>
                          </Box>
                        )}
                      </Box>

                      <Collapse in={!isNewInstitution}>
                        <BoxWithLabel label={t('profile:doctor.phone')}>
                          <TextField
                            {...register(`institutions.${i}.phone`, {
                              pattern: {
                                value: ValidPhonePattern,
                                message: 'messages:warnings.notPhone',
                              },
                            })}
                            size="small"
                            fullWidth
                            helperText={
                              institutionErrors?.phone?.message
                                ? t(institutionErrors?.phone?.message)
                                : undefined
                            }
                            placeholder="+36 1 234 5678 #4433"
                            error={!!institutionErrors?.phone}
                            InputProps={{
                              endAdornment: (
                                <InputStatusAdornment
                                  error={!!institutionErrors?.phone}
                                  modified={dirtyInstitutionFields?.phone}
                                  saved={savedInstitutionFields?.phone}
                                  onErrorClick={() => {
                                    reset()
                                  }}
                                />
                              ),
                            }}
                          />
                        </BoxWithLabel>

                        <BoxWithLabel label={t('institution:facebookId')}>
                          <TextField
                            {...register(`institutions.${i}.facebookId`)}
                            size="small"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputStatusAdornment
                                  modified={dirtyInstitutionFields?.facebookId}
                                  saved={savedInstitutionFields?.facebookId}
                                />
                              ),
                            }}
                          />
                        </BoxWithLabel>

                        <BoxWithLabel label={t(`institution:assistantName`)}>
                          <TextField
                            {...register(`institutions.${i}.assistant.name`)}
                            size="small"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputStatusAdornment
                                  modified={
                                    dirtyInstitutionFields?.assistant?.name
                                  }
                                  saved={
                                    savedInstitutionFields?.assistant?.name
                                  }
                                />
                              ),
                            }}
                          />
                        </BoxWithLabel>
                        <BoxWithLabel label={t(`institution:assistantPhone`)}>
                          <TextField
                            {...register(`institutions.${i}.assistant.phone`, {
                              pattern: {
                                value: ValidPhonePattern,
                                message: 'messages:warnings.notPhone',
                              },
                            })}
                            size="small"
                            fullWidth
                            placeholder="+36 1 234 5678 #4433"
                            helperText={
                              institutionErrors?.assistant?.phone?.message
                                ? t(
                                    institutionErrors?.assistant?.phone?.message
                                  )
                                : undefined
                            }
                            error={!!institutionErrors?.assistant?.phone}
                            InputProps={{
                              endAdornment: (
                                <InputStatusAdornment
                                  error={!!institutionErrors?.assistant?.phone}
                                  modified={
                                    dirtyInstitutionFields?.assistant?.phone
                                  }
                                  saved={
                                    savedInstitutionFields?.assistant?.phone
                                  }
                                  onErrorClick={() => {
                                    reset()
                                  }}
                                />
                              ),
                            }}
                          />
                        </BoxWithLabel>
                        <BoxWithLabel label={t(`institution:assistantEmail`)}>
                          <TextField
                            {...register(`institutions.${i}.assistant.email`, {
                              pattern:
                                /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
                            })}
                            size="small"
                            fullWidth
                            error={!!institutionErrors?.assistant?.email}
                            InputProps={{
                              endAdornment: (
                                <InputStatusAdornment
                                  error={!!institutionErrors?.assistant?.email}
                                  modified={
                                    dirtyInstitutionFields?.assistant?.email
                                  }
                                  saved={
                                    savedInstitutionFields?.assistant?.email
                                  }
                                  onErrorClick={() => {
                                    reset()
                                  }}
                                />
                              ),
                            }}
                          />
                        </BoxWithLabel>

                        <BoxWithLabel label={t(`institution:workingHours`)}>
                          <TextField
                            {...register(`institutions.${i}.workingHours.0`)}
                            size="small"
                            multiline
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputStatusAdornment
                                  modified={
                                    dirtyInstitutionFields?.workingHours?.[0]
                                  }
                                  saved={
                                    savedInstitutionFields?.workingHours?.[0]
                                  }
                                />
                              ),
                            }}
                          />
                        </BoxWithLabel>
                      </Collapse>
                    </Box>
                  </Paper>
                )
              })}

              <Button
                variant="outlined"
                size="medium"
                style={{
                  borderColor: !hasInstitution
                    ? theme.palette.error.main
                    : undefined,
                }}
                startIcon={<AddCircleIcon />}
                disabled={isLoadingAllInstitutions || isLastInstitutionNew}
                onClick={() => {
                  const newInstitutionIdx = watchAllFields.institutions
                    ? watchAllFields.institutions.length
                    : 0
                  register(`institutions.${newInstitutionIdx}`)
                  setValue(`institutions.${newInstitutionIdx}`, {
                    institution: { id: '', name: '' },
                  })
                }}
              >
                {t(`institution:doctor.add`)}
              </Button>

              {!hasInstitution && (
                <Box ml={2}>
                  <Typography variant="caption" color="error">
                    {t('messages:warnings.required')}
                  </Typography>
                </Box>
              )}
            </BoxWithLabel>
          </form>
        </Box>
        <MessageModal
          isOpen={isOpen}
          toggleIsOpen={toggleIsOpen}
          testKey="DoctorProfile-Button-firstlogin"
          title={t('messages:notification.doctor.welcomeTitle')}
          message={t('messages:notification.doctor.welcomeMessage')}
        />
      </ScrollablePanel>
    </FormProvider>
  )
}

export { DoctorProfile }

import React, { useRef, useEffect, useLayoutEffect, useState } from 'react'
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  Link,
  useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  Send as SendIcon,
  ChevronLeft as ChevronLeftIcon,
  MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material'
import { useTranslation } from '../../common/hooks/helper/useTranslation'
import { Link as RouterLink } from 'react-router-dom'

import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { useUserType } from '../../common/hooks/useUserType'
import {
  getPartnerMessages,
  getPartnerMessagesVariables,
  getPartnerMessages_getPartnerMessages,
  sendMessage,
  sendMessageVariables,
  getPartners_getPartners,
  markMessagesAsRead,
  markMessagesAsReadVariables,
  UserType,
} from '../../models/graphql'
import {
  GET_PARTNERS,
  GET_PARTNER_MESSAGES,
  SEND_MESSAGE,
  MARK_MESSAGES_AS_READ,
} from '../../operations/messageOperations'

import { MESSAGES_LIMIT } from '../../config'
import { Avatar } from '../../common/components/Avatar'
import { MessagePartnerProfile } from './MessagePartnerProfile'
import { MessageBoardMessage } from './MessageBoardMessage'
import { getProfessionString } from '../../utils/professions'

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      background: `${theme.palette.primary.main}11`,
    },
  },
}))

type Props = {
  partner: getPartners_getPartners
  onBack?: () => void
}

const MessageBoardChat: React.FC<Props> = ({ partner, onBack }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const apolloClient = useApolloClient()
  const classes = useStyles()

  const [messages, setMessages] = useState<
    getPartnerMessages_getPartnerMessages[]
  >([])
  const [isMoreMessage, setIsMoreMessage] = useState<boolean>(true)
  const [isDraft, setIsDraft] = useState<boolean>(false)
  const [newMessageId, setNewMessageId] = useState<string | undefined>()

  const userType = useUserType()
  const isPatient = partner.user.userType === UserType.Patient
  const isDoctor = partner.user.userType === UserType.Doctor
  const isAssistant = partner.user.userType === UserType.Assistant
  const profile: MessagePartnerProfile | null = isDoctor
    ? partner.user.doctorProfile
    : isAssistant
    ? partner.user.assistantProfile
    : partner.user.patientProfile
  const newMessageField = useRef<HTMLInputElement>(null)

  const { data: messagesData, fetchMore: fetchMoreMessages } = useQuery<
    getPartnerMessages,
    getPartnerMessagesVariables
  >(GET_PARTNER_MESSAGES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      partnerUserId: partner.id,
      paginationInput: {
        offset: 0,
        limit: MESSAGES_LIMIT,
      },
    },
    onCompleted: (data) => {
      const isNewPartner =
        messages.length &&
        messages[0].senderUserId !== partner.user.id &&
        messages[0].recipientUserId !== partner.user.id
      const newMessagesCount =
        data.getPartnerMessages.length - (isNewPartner ? 0 : messages.length)
      setIsMoreMessage(newMessagesCount === MESSAGES_LIMIT)
    },
  })

  const [sendMessageMutation, { loading: isSendingMessage }] = useMutation<
    sendMessage,
    sendMessageVariables
  >(SEND_MESSAGE, {
    onCompleted: (data) => {
      const newMessage = data.sendMessage
      if (newMessageField.current) {
        newMessageField.current.value = ''
        setIsDraft(false)
      }

      const updatePartnerCache = () => {
        const cache = apolloClient.cache
        cache.modify({
          // check https://github.com/apollographql/apollo-client/issues/7577 for the reason of any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          id: cache.identify(partner as any),
          fields: {
            lastMessageAt() {
              return newMessage.createdAt
            },
          },
        })
      }
      updatePartnerCache()

      const updatePartnerMessagesCache = () => {
        apolloClient.writeQuery({
          query: GET_PARTNER_MESSAGES,
          variables: {
            partnerUserId: partner.id,
          },
          data: {
            getPartnerMessages: [
              newMessage,
              ...(messagesData ? messagesData.getPartnerMessages : []),
            ],
          },
        })
      }

      updatePartnerMessagesCache()
    },
  })

  const [markMessagesAsReadMutation, { loading: isMarkingMessagesAsRead }] =
    useMutation<markMessagesAsRead, markMessagesAsReadVariables>(
      MARK_MESSAGES_AS_READ,
      {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
          const getPartnersData = apolloClient.readQuery({
            query: GET_PARTNERS,
          })
          const partners = getPartnersData?.getPartners || []
          const senderPartner = partners.find(
            (getPartner: getPartners_getPartners) =>
              getPartner.user.id === partner.id
          )

          if (senderPartner) {
            const cache = apolloClient.cache
            cache.modify({
              // check https://github.com/apollographql/apollo-client/issues/7577 for the reason of any
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              id: cache.identify(senderPartner as any),
              fields: {
                newMessages() {
                  return []
                },
              },
            })
          }
        },
      }
    )

  useEffect(() => {
    if (messagesData) {
      if (!isMarkingMessagesAsRead) {
        markMessagesAsReadMutation({
          variables: {
            senderUserId: partner.id,
          },
        })
      }

      const incomingMessages = messagesData.getPartnerMessages
        .slice()
        .sort(
          (firstMessage, secondMessage) =>
            Number(new Date(firstMessage.createdAt)) -
            Number(new Date(secondMessage.createdAt))
        )

      const newMessages = incomingMessages.filter(
        (incomingMessage) =>
          !messages.find(
            (existingMessage) => incomingMessage.id === existingMessage.id
          )
      )

      setNewMessageId(
        newMessages.length ? newMessages[newMessages.length - 1].id : undefined
      )

      setMessages(incomingMessages)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesData])

  useLayoutEffect(() => {
    if (newMessageField.current) {
      newMessageField.current.scrollIntoView()
      newMessageField.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessageField.current, partner])

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {/* HEADER */}
      {profile && (
        <Box
          display="flex"
          alignItems="center"
          p={1}
          borderBottom={`1px solid ${theme.palette.grey[300]}`}
        >
          <Avatar
            firstName={profile.firstName}
            lastName={profile.lastName}
            size="small"
          />
          <Box display="flex" flexDirection="column">
            <Link
              underline="none"
              component={RouterLink}
              to={
                userType === UserType.Doctor
                  ? isDoctor
                    ? '#'
                    : `/doctor/patients/${profile.id}`
                  : `/patient/doctors/${profile.id}`
              }
            >
              <Typography variant="h6">
                {t('common:formattedNameFull', {
                  title: profile.title,
                  firstName: profile.firstName,
                  lastName: profile.lastName,
                })}
              </Typography>
            </Link>
            <Typography variant="subtitle2">
              {isPatient
                ? t('common:patient')
                : isAssistant
                ? t('common:assistant')
                : 'professions' in profile && profile.professions
                ? getProfessionString(profile?.professions, t)
                : ''}
            </Typography>
          </Box>
          <Box flexGrow={1} />

          <Box textAlign="right">
            {isPatient && (
              <>
                <Typography variant="caption" component="div">
                  {t('common:taj')}: {profile.tajNumber || ''}
                </Typography>
                <Typography variant="caption" component="div">
                  {t('common:birthdate')}:{' '}
                  {profile.birthDate
                    ? (t('common:intlDateFormattedLongMonth', {
                        date: profile.birthDate,
                      }) as string)
                    : ''}
                </Typography>
              </>
            )}
            {(isPatient || isAssistant) && (
              <>
                <Typography variant="caption" color="primary" component="div">
                  {partner.user.email}
                </Typography>
                <Typography variant="caption" color="primary" component="div">
                  {profile.phone}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}

      {/* MESSAGES */}
      <Box flexGrow={1} p={1} style={{ overflowY: 'auto' }}>
        {isMoreMessage && (
          <Box textAlign="center">
            <IconButton
              className={classes.button}
              onClick={() =>
                fetchMoreMessages({
                  variables: {
                    paginationInput: {
                      offset: messages.length,
                      limit: MESSAGES_LIMIT,
                    },
                  },
                })
              }
              size="large"
            >
              <MoreHorizIcon />
            </IconButton>
          </Box>
        )}
        <Grid container spacing={1} direction="column">
          {messages.map((message) => (
            <Grid item key={message.id}>
              <MessageBoardMessage
                message={message}
                partner={partner}
                shouldScrollIntoView={message.id === newMessageId}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* WRITE MESSAGE */}
      <Box p={1} borderTop={`1px solid ${theme.palette.grey[300]}`}>
        <Box>
          <TextField
            id="outlined-multiline-static"
            multiline
            minRows={3}
            variant="outlined"
            fullWidth
            placeholder={`${t('common:message')} ...`}
            inputRef={newMessageField}
            onChange={(e) => setIsDraft(!!e.target.value.length)}
          />
        </Box>
        <Box display="flex" mt={1}>
          <Button
            variant="outlined"
            startIcon={<ChevronLeftIcon />}
            onClick={() => onBack && onBack()}
          >
            {t('common:back')}
          </Button>
          <Box flexGrow={1} />
          <Button
            variant="contained"
            disabled={!isDraft || isSendingMessage}
            endIcon={<SendIcon />}
            onClick={() => {
              newMessageField.current &&
                sendMessageMutation({
                  variables: {
                    recipientUserId: partner.id,
                    message: newMessageField.current.value,
                  },
                })
            }}
          >
            {t('common:sendMessage')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export { MessageBoardChat }

import { QueryHookOptions, useQuery } from '@apollo/client'
import {
  GetIndividualSurveyResults,
  GetIndividualSurveyResultsVariables,
  GetIndividualSurveyResults_getIndividualSurveyResults_surveys,
} from '../../../../models/graphql'

import { GET_INDIVIDUAL_SURVEY_RESULTS } from '../operations/surveyOperations'

type Options = QueryHookOptions<
  GetIndividualSurveyResults,
  GetIndividualSurveyResultsVariables
>

type Return = {
  totalSurveyResults: number
  surveyResultsByPatient?: GetIndividualSurveyResults_getIndividualSurveyResults_surveys[]
  xlsxInBase64?: string
  csvInBase64?: string
  loading: boolean
  refetch: () => void
  fetchMore: (option: Options) => void
}
export const useSurveyResultByPatient = (options: Options = {}): Return => {
  const { data, loading, refetch, fetchMore } = useQuery<
    GetIndividualSurveyResults,
    GetIndividualSurveyResultsVariables
  >(GET_INDIVIDUAL_SURVEY_RESULTS, options)

  return {
    totalSurveyResults: data?.getIndividualSurveyResults.total ?? 0,
    surveyResultsByPatient: data?.getIndividualSurveyResults.surveys ?? [],
    csvInBase64: data?.getIndividualSurveyResults.csvInBase64,
    xlsxInBase64: data?.getIndividualSurveyResults.xlsxInBase64,
    loading,
    refetch,
    fetchMore,
  }
}

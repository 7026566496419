import AppsIcon from '@mui/icons-material/Apps'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { SurveyQuestionFileSize } from '../../../../../models/graphql'
import { getDefaultScaleData } from '../../utils/question.util'
import { EditQuestionByType } from '../EditQuestion/EditQuestionByType'
import { SurveyContext } from '../EditSurveyState'
import { QuestionIcon } from '../Questions/QuestionIcon'
import { QuestionForm } from '../Questions/questions.types'
import { QuestionInSection, Section } from './editSurvey.types'

interface Props {
  question: QuestionInSection
  indexOfQuestion: number
  dragHandleProps: DraggableProvidedDragHandleProps | undefined
  isDragging: boolean
  sections: Section[]
  sectionOrder: number
  updateQuestionInSections: (newQuestion: QuestionInSection) => void
  removeQuestionFromSections: (questionToRemove: QuestionInSection) => void
}

export const EditQuestionCard: React.FC<Props> = ({
  question,
  indexOfQuestion,
  dragHandleProps,
  isDragging,
  sections,
  sectionOrder,
  updateQuestionInSections,
  removeQuestionFromSections,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const [isOpen, setOpen] = useState<boolean>(question.isNew)
  const { surveySchemaId, isReadonly, updateQuestionFormMethods } =
    useContext(SurveyContext)

  const formMethods = useForm<QuestionForm>({
    defaultValues: {
      title: question.title || '',
      description: question.description || '',
      isRequired: question.isRequired ?? true,
      isSurveySpecific: question.isSurveySpecific,
      data: question.data || {
        multipleChoice: false,
        scaleData: getDefaultScaleData(),
        fileUploadData: {
          maxItemsCount: 1,
          maxFileSize: SurveyQuestionFileSize.FiveMB,
          validFileTypes: [],
        },
      },
      choices: question.choices || [{ name: '' }, { name: '' }],
      aspects: question.aspects || [{ name: '' }],
      isJumpToNextSectionAvailable: question.isJumpToNextSectionAvailable,
    },
    mode: 'onChange',
  })
  const {
    control,
    getValues,
    watch,
    formState: { isDirty },
  } = formMethods

  useEffect(() => {
    updateQuestionFormMethods(formMethods)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods.formState.isValid])

  // Subscription of the form is needed here instead of simple onChange event
  // because MUI dropdown not trigger 'change' event properly.
  useEffect(() => {
    const subscription = watch(() => {
      if (!isDirty) {
        return
      }

      return updateQuestionInSections({ ...question, ...getValues() })
    })
    return () => subscription.unsubscribe()
  }, [getValues, isDirty, question, updateQuestionInSections, watch])

  return (
    <FormProvider {...formMethods}>
      <Paper
        elevation={0}
        component="form"
        sx={{
          p: 2,
          border: `solid 1px ${colorPalette.grey[200]}`,
          bgcolor: isDragging ? colorPalette.grey[200] : '',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="body2" ml={1} mb={1}>
            {t('survey:editor.question.title', { index: indexOfQuestion })}
          </Typography>
          {!isReadonly && (
            <IconButton
              onClick={() => {
                removeQuestionFromSections(question)
                updateQuestionFormMethods(formMethods, true)
              }}
            >
              <DeleteIcon fontSize="large" />
            </IconButton>
          )}
        </Stack>
        <Stack direction="row" alignItems="flex-start">
          {!isReadonly && (
            <IconButton {...dragHandleProps}>
              <AppsIcon fontSize="large" />
            </IconButton>
          )}

          <Box pt={1} mx={1}>
            <QuestionIcon questionType={question.type} fontSize="large" />
          </Box>

          {isOpen ? (
            <EditQuestionByType
              questionType={question.type}
              sectionOrder={sectionOrder}
              sections={sections}
              isReadonly={isReadonly}
            />
          ) : (
            <Box pt={1} mx={1} flex={1}>
              <Typography>{question.title}</Typography>
            </Box>
          )}

          <IconButton onClick={() => setOpen(!isOpen)}>
            {isOpen ? (
              <ArrowDropUpOutlinedIcon fontSize="large" />
            ) : (
              <ArrowDropDownOutlinedIcon fontSize="large" />
            )}
          </IconButton>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Typography variant="subtitle1">
            {t('survey:editor.question.required')}
          </Typography>
          <Controller
            control={control}
            name="isRequired"
            render={({ field: { value, onChange } }) => (
              <Switch
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                disabled={isReadonly}
                data-cy="EditQuestionCard-Switch-isRequired"
              />
            )}
          />
        </Stack>
        {!surveySchemaId && question.isNew && (
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Typography variant="subtitle1">
              {t('survey:editor.question.isSurveySpecific')}
            </Typography>
            <Controller
              control={control}
              name="isSurveySpecific"
              render={({ field: { value, onChange } }) => (
                <Switch
                  checked={!value}
                  onChange={(e) => onChange(!e.target.checked)}
                  disabled={isReadonly}
                />
              )}
            />
          </Stack>
        )}
      </Paper>
    </FormProvider>
  )
}

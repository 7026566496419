import { Box, Checkbox, Grid, Radio, Stack, Typography } from '@mui/material'
import React from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { getSurveyQuestions_getSurveyQuestions_surveyQuestions_mcSurveyQuestionChoices as Choice } from '../../../../../models/graphql'
import { Question } from '../Questions/questions.types'

interface Props {
  question: Question
}

export const ViewMultipleChoiceQuestion: React.FC<Props> = ({ question }) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  return (
    <Box py={1} ml={5} gap={2}>
      <Typography variant="italic" color={colorPalette.grey[300]}>
        {question.description}
      </Typography>

      <Stack direction="row" alignItems="center">
        <Checkbox disabled={true} checked={!!question.data?.multipleChoice} />
        <Typography variant="body2">
          {t('survey:question.multipleChoice')}
        </Typography>
      </Stack>

      {question.choices?.map((choice: Choice, i: number) => (
        <Grid key={`choice_${i}`} container alignItems="center" gap={2}>
          <Grid item display="flex" justifyContent="center">
            <Radio disabled={true} />
          </Grid>
          <Grid item xs>
            <Typography variant="body1">{choice.name}</Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  )
}

import React, { FC, useCallback } from 'react'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { ConfirmDialog } from '../../../../common/components/dialogs/ConfirmDialog'
import { getTreatmentSchema_getTreatmentSchema } from '../../../../models/graphql'
import { useSchedulesRemove } from '../hooks/useSchedulesRemove'
import { useStoreActions } from '../../../../store/store.hooks'
import { useRemoveSurveySchemaFromTreatmentSchema } from '../hooks/useRemoveSurveySchemaFromTreatmentSchema'

interface Props {
  treatmentSchema: getTreatmentSchema_getTreatmentSchema
  scheduleIds: string[] | null
  surveyIds: string[] | null
  onClose: () => void
  onSuccess: () => void
}

export const RemoveScheduleModal: FC<Props> = ({
  treatmentSchema,
  scheduleIds,
  surveyIds,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation()

  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const onRemoveSuccess = useCallback(() => {
    setToast({
      text: t('appointment:removeSchedules.success', {
        count: (scheduleIds?.length || 0) + (surveyIds?.length || 0),
      }),
      type: 'success',
    })
    onSuccess()
  }, [onSuccess, scheduleIds, setToast, surveyIds, t])

  const [removeSchedules] = useSchedulesRemove(treatmentSchema, {
    onCompleted: onRemoveSuccess,
  })

  const [removeSurveys] = useRemoveSurveySchemaFromTreatmentSchema({
    onCompleted: onRemoveSuccess,
  })

  const handleRemove = useCallback(() => {
    if (!scheduleIds || !surveyIds) {
      return
    }
    removeSchedules({
      variables: { scheduleIds },
    })
    surveyIds.forEach((surveyId) =>
      removeSurveys({
        variables: {
          treatmentSchemaToSurveyId: surveyId,
        },
      })
    )
  }, [removeSchedules, removeSurveys, scheduleIds, surveyIds])

  return (
    <ConfirmDialog
      isAlertingDialog
      valueState={[!!scheduleIds, onClose]}
      title={t(`protocol:schedule.deleteMultipleDialogTitle`)}
      text={t(`protocol:schedule.deleteDialogText`)}
      dontClose
      onAccept={handleRemove}
      onCancel={onClose}
    />
  )
}

import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  updateSurveyQuestion,
  updateSurveyQuestionVariables,
} from '../../../../models/graphql'
import { UPDATE_SURVEY_QUESTION } from '../operations/questionOperations'

type Options = MutationHookOptions<
  updateSurveyQuestion,
  updateSurveyQuestionVariables
>

type Return = MutationTuple<updateSurveyQuestion, updateSurveyQuestionVariables>

export const useSurveyQuestionUpdate = (options: Options = {}): Return => {
  return useMutation<updateSurveyQuestion, updateSurveyQuestionVariables>(
    UPDATE_SURVEY_QUESTION,
    options
  )
}

import { QueryHookOptions, useQuery, NetworkStatus } from '@apollo/client'
import {
  getSurveySchemas_getSurveySchemas_surveySchemas as SurveySchema,
  getSurveySchemas,
  getSurveySchemasVariables,
} from '../../../../models/graphql'
import { GET_SURVEY_SCHEMAS } from '../operations/surveyOperations'
import { renameDuplicatedSurveyTitles } from '../utils/survey.util'

type Options = QueryHookOptions<getSurveySchemas, getSurveySchemasVariables>

type ReturnData = {
  loading: boolean
  surveySchemas: SurveySchema[]
  totalSchemas: number
  hasMoreSchemas: boolean
  fetchingMore: boolean
  refetch: () => void
  fetchMoreSchemas: (options: Options) => void
}

export const useSurveySchemas = (options: Options = {}): ReturnData => {
  const {
    data: surveyData,
    loading,
    refetch,
    fetchMore,
    networkStatus,
  } = useQuery<getSurveySchemas, getSurveySchemasVariables>(
    GET_SURVEY_SCHEMAS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      ...options,
    }
  )

  const totalSchemas = surveyData?.getSurveySchemas?.total || 0
  const surveySchemas: SurveySchema[] = renameDuplicatedSurveyTitles(
    surveyData?.getSurveySchemas.surveySchemas || []
  )
  const hasMoreSchemas = surveySchemas.length < totalSchemas

  return {
    loading,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    fetchMoreSchemas: fetchMore,
    refetch,
    surveySchemas,
    totalSchemas,
    hasMoreSchemas,
  }
}

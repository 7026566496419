import { useQuery } from '@apollo/client'

import { GET_PATIENT_APPOINTMENT } from '../../../../operations/appointmentOperations'
import {
  getPatientAppointment,
  getPatientAppointmentVariables,
  getPatientAppointment_getAppointment,
} from '../../../../models/graphql'

type ReturnData = {
  appointment?: getPatientAppointment_getAppointment
  refetch: () => void
  loading: boolean
}

type Props = {
  appointmentId?: string
  skip?: boolean
}

// receive appointments for all or single treatment (used by patients)
export const usePatientAppointment = ({
  skip,
  appointmentId = '',
}: Props = {}): ReturnData => {
  const { data, loading, refetch } = useQuery<
    getPatientAppointment,
    getPatientAppointmentVariables
  >(GET_PATIENT_APPOINTMENT, {
    skip: skip || !appointmentId,
    variables: {
      appointmentId,
    },
  })

  return {
    appointment: data?.getAppointment,
    loading,
    refetch,
  }
}

import { gql } from '@apollo/client'
import { FRAGMENT_ROOM_CHORE, FRAGMENT_INSTITUTION_CHORE } from './fragments'

export const LIST_INSTITUTIONS = gql`
  query listInstitutions(
    $ids: [ID!]
    $hasEESZTCertificate: Boolean
    $nameSearchTerm: String
    $paginationInput: PaginationInput
  ) {
    listInstitutions(
      ids: $ids
      hasEESZTCertificate: $hasEESZTCertificate
      nameSearchTerm: $nameSearchTerm
      paginationInput: $paginationInput
    ) {
      institutions {
        ...institutionChore
      }
      total
    }
  }
  ${FRAGMENT_INSTITUTION_CHORE}
`
export const CREATE_ROOM = gql`
  mutation createRoom($createRoomInput: CreateRoomInput!) {
    createRoom(createRoomInput: $createRoomInput) {
      ...roomChore
    }
  }
  ${FRAGMENT_ROOM_CHORE}
`

export const UPDATE_ROOM = gql`
  mutation updateRoom($updateRoomInput: UpdateRoomInput!) {
    updateRoom(updateRoomInput: $updateRoomInput) {
      ...roomChore
    }
  }
  ${FRAGMENT_ROOM_CHORE}
`

export const CREATE_INSTITUTION = gql`
  mutation createInstitution($institutionInput: InstitutionInput!) {
    createInstitution(institutionInput: $institutionInput) {
      ...institutionChore
    }
  }
  ${FRAGMENT_INSTITUTION_CHORE}
`

export const UPDATE_INSTITUTION = gql`
  mutation updateInstitution(
    $id: String!
    $institutionInput: InstitutionInput!
  ) {
    updateInstitution(id: $id, institutionInput: $institutionInput) {
      ...institutionChore
    }
  }
  ${FRAGMENT_INSTITUTION_CHORE}
`

export const ASSIGN_ADMINS_TO_INSTITUTION = gql`
  mutation assignAdminsToInstitution(
    $institutionId: String!
    $userIds: [String!]!
  ) {
    assignAdminsToInstitution(
      institutionId: $institutionId
      userIds: $userIds
    ) {
      id
    }
  }
`

export const GET_INSTITUTION_ADMIN_PROFILES = gql`
  query institutionAdminProfiles($institutionId: ID!) {
    institutionAdminProfiles(institutionId: $institutionId) {
      id
      title
      firstName
      lastName
      user {
        id
      }
    }
  }
`

export const STORE_CERTIFICATE_TO_INSTITUTION = gql`
  mutation uploadCertificate(
    $institutionId: String!
    $certificate: Upload!
    $passphrase: String!
  ) {
    uploadCertificate(
      institutionId: $institutionId
      certificate: $certificate
      passphrase: $passphrase
    )
  }
`
export const CHECK_INSTITUTION_HAS_CERTIFICATE = gql`
  query checkCertificate($institutionId: String!) {
    checkCertificate(institutionId: $institutionId)
  }
`

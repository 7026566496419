import {
  AddBoxOutlined,
  AddCircleOutline as AddCircleOutlineIcon,
  QuestionAnswerRounded,
  SaveRounded,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material'
import { Box, Button, Grid, Stack, TextField, Tooltip } from '@mui/material'
import React, { useRef } from 'react'
import { SaveButton } from '../../../../common/components/SaveButton'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { useTreatmentSchemaCreate } from '../../TreatmentSchema/hooks/useTreatmentSchemaCreate'
import {
  getTreatment_getTreatment,
  TreatmentStatus,
} from '../../../../models/graphql'
import { AddSurveyModalParams } from './Survey/AddSurveyModal'
import { AddAppointmentModalParams } from '../TreatmentPanel'

type Props = {
  treatment: getTreatment_getTreatment
  isOwnTreatment: boolean
  showArrows: boolean
  toggleArrowsVisibility: () => void
  setFilterValue: React.Dispatch<React.SetStateAction<string>>
  toggleAddAppointmentModal: React.Dispatch<
    React.SetStateAction<AddAppointmentModalParams>
  >
  toggleAddSurveyModal: React.Dispatch<
    React.SetStateAction<AddSurveyModalParams>
  >
  toggleAddProtocolModal: React.Dispatch<React.SetStateAction<boolean>>
}

const TreatmentPanelSubHeader: React.FC<Props> = ({
  treatment,
  isOwnTreatment,
  showArrows,
  toggleArrowsVisibility,
  setFilterValue,
  toggleAddAppointmentModal,
  toggleAddProtocolModal,
  toggleAddSurveyModal,
}) => {
  const { t } = useTranslation()

  const timeout = useRef<NodeJS.Timeout | null>(null)

  const isDeleted = treatment?.status === TreatmentStatus.Cancelled

  const [createTreatmentSchema, { loading: isCreateLoading }] =
    useTreatmentSchemaCreate({
      variables: {
        schema: {
          treatmentId: treatment.id,
          title: {
            en: t('treatment:protocol.newTitle', { lng: 'en' }),
            hu: t('treatment:protocol.newTitle', { lng: 'hu' }),
          },
          description: {
            en: t('treatment:protocol.noDescription', { lng: 'en' }),
            hu: t('treatment:protocol.noDescription', { lng: 'hu' }),
          },
        },
      },
    })

  const onSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // copy the value for the timeout
    const value = e.target.value

    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    timeout.current = setTimeout(() => {
      setFilterValue(value)
    }, 300)
  }

  return (
    <Box display="flex" justifyContent="flex-end" px={{ xs: 1, sm: 2 }} py={1}>
      <Box width={{ xs: '100%', md: 'calc(100% - 260px)' }}>
        {!isDeleted && isOwnTreatment && (
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Button
                size="small"
                color="primary"
                variant="contained"
                startIcon={<AddBoxOutlined />}
                onClick={() => toggleAddProtocolModal(true)}
              >
                {t('treatment:addNewProtocol')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="primary"
                variant="text"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => toggleAddAppointmentModal({ isOpen: true })}
                data-cy="AppointmentEmptyState-Button-addNewAppointment"
              >
                {t('treatment:addNewAppointment')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                color="primary"
                variant="text"
                startIcon={<QuestionAnswerRounded />}
                onClick={() => toggleAddSurveyModal({ isOpen: true })}
                data-cy="AppointmentEmptyState-Button-addNewSurvey"
              >
                {t('treatment:addNewSurvey')}
              </Button>
            </Grid>
            <Grid item>
              <SaveButton
                size="small"
                color="primary"
                variant="text"
                isSaving={isCreateLoading}
                startIcon={<SaveRounded />}
                onClick={() => createTreatmentSchema()}
                text={t('treatment:saveNewProtocol')}
              />
            </Grid>
          </Grid>
        )}
        <TextField
          sx={{ mt: 2, maxWidth: '480px' }}
          size="small"
          name="filter"
          variant="outlined"
          fullWidth
          id="treatmentSearchInput"
          placeholder={t('home:appointments.searchexample')}
          onChange={onSearchTermChange}
        />
        <Stack
          sx={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}
        >
          <Tooltip
            placement="top"
            title={t('treatment:showHideArrows') as string}
          >
            <Button
              size="large"
              color="primary"
              variant="text"
              startIcon={
                showArrows ? <VisibilityOutlined /> : <VisibilityOffOutlined />
              }
              onClick={toggleArrowsVisibility}
            ></Button>
          </Tooltip>
        </Stack>
      </Box>
    </Box>
  )
}

export { TreatmentPanelSubHeader }

import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  updateSurveySchemaStatus,
  updateSurveySchemaStatusVariables,
} from '../../../../models/graphql'
import { UPDATE_SURVEY_SCHEMA_STATUS } from '../operations/surveyOperations'

type Options = MutationHookOptions<
  updateSurveySchemaStatus,
  updateSurveySchemaStatusVariables
>

type Return = MutationTuple<
  updateSurveySchemaStatus,
  updateSurveySchemaStatusVariables
>

export const useSurveySchemaStatusUpdate = (options: Options = {}): Return => {
  return useMutation<
    updateSurveySchemaStatus,
    updateSurveySchemaStatusVariables
  >(UPDATE_SURVEY_SCHEMA_STATUS, options)
}

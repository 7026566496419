import React, { useState } from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { SelectedChip } from '../SelectedChip/SelectedChip'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import { FilterPopover } from './FilterPopover'
import { useListProfessions } from '../../hooks/useListProfessions'
import { useSelectedLanguage } from '../../hooks/useSelectedLanguage'
import { useColorPalette } from '../../hooks/helper/useColor'
import { listAllProfessions_listAllProfessions as Profession } from '../../../models/graphql'
import { ClearFiltersButton } from './ClearFiltersButton'

interface ProfessionFilterInput {
  professionIds?: string[] | null
}

interface Props {
  filterState: [
    ProfessionFilterInput,
    React.Dispatch<React.SetStateAction<ProfessionFilterInput>>
  ]
}

export const ProfessionFilter: React.FC<Props> = ({ filterState }) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  const [filterInput, setFilterInput] = filterState
  const [filterAnchor, setFilterAnchor] = useState<HTMLElement | null>(null)

  const { professions, loading } = useListProfessions()
  const selectedLanguage = useSelectedLanguage()
  const intlCollator = new Intl.Collator(selectedLanguage)

  const chosenProfessions =
    (filterInput.professionIds &&
      professions.filter((p) => filterInput.professionIds?.includes(p.id))) ||
    []

  const setProfessionFilter = (professions: Profession[]) =>
    setFilterInput((prevInput) => {
      return {
        ...prevInput,
        professionIds: professions.map((p) => p.id),
      }
    })

  return (
    <>
      <SelectedChip
        label={t('common:professions.title')}
        selected={!!filterInput.professionIds?.length}
        onClick={(e) => setFilterAnchor(e.currentTarget)}
        // we need this onDelete function to see the deleteIcon
        onDelete={(e) => setFilterAnchor(e.currentTarget)}
        deleteIcon={<KeyboardArrowDownIcon />}
      />

      {filterAnchor && (
        <FilterPopover
          filterAnchorState={[filterAnchor, setFilterAnchor]}
          open={!!filterAnchor}
        >
          {!loading && (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <ClearFiltersButton
                  onClear={() => setProfessionFilter([])}
                  disabled={!!!filterInput.professionIds?.length}
                />
                <Typography color={colorPalette.grey[300]} variant="body2">
                  {t('common:numberOfFilters', {
                    numberOfFilters: chosenProfessions.length,
                  })}
                </Typography>
              </Stack>
              <hr />
              <Autocomplete
                multiple
                loading={loading}
                loadingText={t('common:loading')}
                noOptionsText={t('common:noOptions')}
                options={professions
                  .slice()
                  .sort((a, b) => intlCollator.compare(a.name, b.name))
                  .sort((prof) => (prof.code === 'BTM1' ? -1 : 1))}
                disableClearable
                filterSelectedOptions
                getOptionLabel={(profession) => profession.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={chosenProfessions}
                onChange={(_, chosenProfessions) =>
                  setProfessionFilter(chosenProfessions)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('common:search')}
                    variant="outlined"
                  />
                )}
              />
            </>
          )}
        </FilterPopover>
      )}
    </>
  )
}

import React, { FC, MouseEventHandler } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { Button, Grid, Box } from '@mui/material'
import { MessageIcon } from '../../../../common/icons/MessageIcon'
import { PhoneIcon } from '../../../../common/icons/PhoneIcon'

interface Props {
  email: string
  phone?: string
  chatUserId?: string
}

/**
 * Contact section of the patient card
 */
export const Contact: FC<Props> = ({ email, phone, chatUserId }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleMessageClick: MouseEventHandler = (event) => {
    if (!chatUserId) {
      return
    }
    event.preventDefault()
    history.push(`/messages/${chatUserId}`)
  }

  return (
    <Box px={2}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={6}>
          <Button
            fullWidth={true}
            startIcon={<MessageIcon />}
            variant="outlined"
            size="large"
            disabled={!email?.length && !chatUserId?.length}
            onClick={handleMessageClick}
            href={email ? `mailto:${email}` : undefined}
          >
            {t('common:message')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth={true}
            startIcon={<PhoneIcon />}
            size="large"
            disabled={!phone?.length}
            href={`tel:${phone}`}
          >
            {t('common:phoneCall')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

import { useTranslation as _useTranslation } from 'react-i18next'

const DEBUG_KEY = 'betmen-debug'
let debug = !!sessionStorage.getItem(DEBUG_KEY)

// eslint-disable-next-line
export function useTranslation() {
  const { t } = _useTranslation()
  const toggleDebug = () => {
    debug = !debug
    sessionStorage.setItem(DEBUG_KEY, debug ? 'true' : '')
  }
  return {
    t: debug ? (key: string) => key : t,
    debug,
    toggleDebug,
  }
}

import { Grid, Stack } from '@mui/material'
import React, { FC } from 'react'
import { BetmenListHeaderCell } from './BetmenListHeaderCell'

export const BetmenListHeader: FC = ({ children }) => {
  return (
    <Stack sx={{ px: { xs: 3, md: 4 }, mb: 1 }}>
      <Grid container alignItems="center">
        {React.Children.map(children, (child, i) => {
          if (
            React.isValidElement(child) &&
            child.type === BetmenListHeaderCell
          ) {
            return React.cloneElement(child, { ...child.props, cellindex: i })
          }

          console.warn(
            'Invalid child component found in BetmenListHeader. Allowed: BetmenListHeaderCell',
            child
          )
          return child
        })}
      </Grid>
    </Stack>
  )
}

import React, { FC, useCallback, useMemo, useState } from 'react'
import { Card, CardContent, Stack } from '@mui/material'
import { useMe } from '../../../../common/hooks/useMe'
import { TreatmentSchemaActions } from './TreatmentSchemaActions'
import { TreatmentSchemaDetailsEdit } from './TreatmentSchemaDetailsEdit'
import { TreatmentSchemaDetailsRead } from './TreatmentSchemaDetailsRead'
import { TreatmentSchemaScheduleDraggableList } from './TreatmentSchemaScheduleDraggableList'
import { RemoveScheduleModal } from './RemoveScheduleModal'
import { ScrollablePanel } from '../../../../common/components/ScrollablePanel'
import {
  getTreatmentSchema_getTreatmentSchema_schedules as Schedule,
  getTreatmentSchema_getTreatmentSchema_surveySchemas as SurveySchema,
  getTreatmentSchema_getTreatmentSchema,
  getSurveySchemas_getSurveySchemas_surveySchemas as SurveySchemaSurvey,
} from '../../../../models/graphql'
import { FormProvider, useForm } from 'react-hook-form'
import { useTreatmentSchemaUpdate } from '../hooks/useTreatmentSchemaUpdate'
import { useScheduleAdd } from '../hooks/useScheduleAdd'
import { useTreatmentSchemaStatusUpdate } from '../hooks/useTreatmentSchemaStatusUpdate'
import { isTreatmentSchemaEditable } from '../../../../utils/treatmentSchemaHelper'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { useLocalTreatmentSchemaScheduleList } from '../hooks/useLocalTreatmentSchemaScheduleList'
import { ScheduleCalendar } from './ScheduleCalendar'
import { TreatmentSchemaDetailsFormValues } from './TreatmentSchemaDetailsEdit/treatmentSchemaDetailsEdit.types'
import { Box } from '@mui/system'
import { useSchedulesCreate } from '../hooks/useShcedulesCreate'
import { useStoreActions } from '../../../../store/store.hooks'
import omit from 'lodash/omit'
import { useAddSurveySchemaToTreatmentSchema } from '../hooks/useAddSurveySchemaToTreatmentSchema'

interface Props {
  treatmentSchema: getTreatmentSchema_getTreatmentSchema
  schedules: Schedule[]
  surveys: SurveySchema[]
  refetchTreatmentSchema: () => void
}

export const TreatmentSchemaPanel: FC<Props> = ({
  treatmentSchema,
  schedules,
  surveys,
  refetchTreatmentSchema,
}) => {
  const [removingScheduleIds, setRemovingScheduleIds] = useState<
    string[] | null
  >(null)
  const [removingSurveyIds, setRemovingSurveyIds] = useState<string[] | null>(
    null
  )
  const [selectedScheduleIds, setSelectedScheduleIds] = useState<string[]>([])
  const [selectedSurveyIds, setSelectedSurveyIds] = useState<string[]>([])

  const { t } = useTranslation()
  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })
  const userId = me?.id

  const isEditable = isTreatmentSchemaEditable(treatmentSchema, userId)

  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const onCreateSchedulesSuccess = useCallback(() => {
    setToast({
      text: t('appointment:cloneSchedules.success'),
      type: 'success',
    })
  }, [setToast, t])

  const onCreateSurveySuccess = useCallback(() => {
    setToast({
      text: t('notification:successInsertSurvey'),
      type: 'success',
    })
  }, [setToast, t])

  const [addSchedule] = useScheduleAdd(treatmentSchema)
  const [createSchedules] = useSchedulesCreate(treatmentSchema, {
    onCompleted: onCreateSchedulesSuccess,
  })
  const [addSurveySchemaToTreatmentSchema] =
    useAddSurveySchemaToTreatmentSchema({
      onCompleted: onCreateSurveySuccess,
    })

  const handleAddSurvey = useCallback(
    (surveySchema: SurveySchemaSurvey) => {
      addSurveySchemaToTreatmentSchema({
        variables: {
          treatmentSchemaId: treatmentSchema.id,
          surveySchemaId: surveySchema.id,
        },
      })
    },
    [addSurveySchemaToTreatmentSchema, treatmentSchema]
  )

  const formMethods = useForm<TreatmentSchemaDetailsFormValues>({
    mode: 'onChange',
    defaultValues: {
      title: treatmentSchema.title || null,
      description: treatmentSchema.description || null,
      url: treatmentSchema.url || '',
      snowmedCode: treatmentSchema.snowmedCode ?? '',
      bnoCodes: treatmentSchema.bnoCodes ?? [],
      professions: treatmentSchema.professions ?? [],
      optimalDuration: treatmentSchema.optimalDuration?.toString() ?? '',
    },
  })

  const newSchedules = useMemo(() => {
    return [...schedules, ...surveys].sort((a, b) => {
      if (a.customDays === b.customDays) {
        return 0
      }

      if (a.customDays === null) {
        return 1
      }
      if (b.customDays === null) {
        return -1
      }

      return a.customDays < b.customDays ? -1 : 1
    })
  }, [schedules, surveys])

  const {
    localSchedulesAndSurveys,
    hasAnyScheduleError,
    handleDayChanged,
    handleOnDragEnd,
    handleSurveyDayChanged,
  } = useLocalTreatmentSchemaScheduleList(
    newSchedules,
    Number(formMethods.watch('optimalDuration')) || null
  )

  const [updateTreatmentSchema] = useTreatmentSchemaUpdate({
    onCompleted: ({ updateTreatmentSchema: responseData }) => {
      formMethods.reset({
        title: responseData.title,
        description: responseData.description,
        url: responseData.url ?? '',
        snowmedCode: responseData.snowmedCode ?? '',
        bnoCodes: responseData.bnoCodes ?? [],
        professions: responseData.professions,
        optimalDuration: responseData.optimalDuration?.toString() ?? '',
      })
    },
  })

  const [updateTreatmentSchemaStatus] = useTreatmentSchemaStatusUpdate()

  const onSubmit = formMethods.handleSubmit(async (formValues) => {
    await updateTreatmentSchema({
      variables: {
        treatmentData: {
          id: treatmentSchema.id,
          title: omit(formValues.title, '__typename'),
          description: omit(formValues.description, '__typename'),
          url: formValues.url,
          snowmedCode: formValues.snowmedCode,
          bnoCodeIds: formValues.bnoCodes.map(({ id }) => id),
          professionIds: formValues.professions.map(({ id }) => id),
          optimalDuration: Number(formValues.optimalDuration) || null,
        },
      },
    })
  })

  const addNewSchedule = useCallback(
    async (appointmentInfoId: string) => {
      await addSchedule({
        variables: {
          treatmentSchemaId: treatmentSchema.id,
          appointmentInfoId,
        },
      })
    },
    [addSchedule, treatmentSchema.id]
  )

  const handleSaveAndPublish = async () => {
    await onSubmit()
    await updateTreatmentSchemaStatus({
      variables: {
        treatmentSchemaId: treatmentSchema.id,
        publish: true,
      },
    })
  }

  const handleRemove = useCallback(() => {
    setRemovingScheduleIds(selectedScheduleIds)
    setRemovingSurveyIds(selectedSurveyIds)
  }, [selectedScheduleIds, selectedSurveyIds])

  const handleRemoveModalClose = useCallback(() => {
    setRemovingScheduleIds(null)
    setRemovingSurveyIds(null)
  }, [])

  const onRemoveSuccess = useCallback(() => {
    setRemovingScheduleIds(null)
    setRemovingSurveyIds(null)
    setSelectedScheduleIds([])
    setSelectedSurveyIds([])
  }, [])

  const handleCloneMultiple = useCallback(() => {
    if (!!selectedScheduleIds.length) {
      const appointmentInfoIds = schedules
        .filter((schedule) => selectedScheduleIds.includes(schedule.id))
        .map((schedule) => schedule.appointmentInfo.id)

      createSchedules({
        variables: {
          treatmentSchemaId: treatmentSchema.id,
          appointmentInfoIds,
        },
      })
    }
    if (!!selectedSurveyIds) {
      treatmentSchema.surveySchemas
        .filter((surveySchema) => selectedSurveyIds.includes(surveySchema.id))
        .map((surveySchema) => surveySchema.surveySchema.id)
        .forEach((surveyId) =>
          addSurveySchemaToTreatmentSchema({
            variables: {
              treatmentSchemaId: treatmentSchema.id,
              surveySchemaId: surveyId,
            },
          })
        )
    }
  }, [
    addSurveySchemaToTreatmentSchema,
    createSchedules,
    schedules,
    selectedScheduleIds,
    selectedSurveyIds,
    treatmentSchema,
  ])

  const isScheduleListEditable = useMemo(
    () => treatmentSchema.disabled,
    [treatmentSchema.disabled]
  )

  return (
    <FormProvider {...formMethods}>
      <ScrollablePanel
        smoothScroll={true}
        title={
          isScheduleListEditable
            ? t('treatment:protocol.editor.title')
            : t('treatment:protocol.editor.title-readonly')
        }
        headerRightContent={
          <TreatmentSchemaActions
            treatmentSchema={treatmentSchema}
            hasAnyScheduleError={hasAnyScheduleError}
            onSaveAndPublish={handleSaveAndPublish}
          />
        }
      >
        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
          <Box sx={{ flex: 1, overflowX: 'scroll', padding: 1, margin: -1 }}>
            <Card sx={{ marginBottom: 2 }}>
              <CardContent sx={{ padding: 3 }}>
                {isEditable ? (
                  <TreatmentSchemaDetailsEdit
                    treatmentSchema={treatmentSchema}
                    onSubmit={onSubmit}
                  />
                ) : (
                  <TreatmentSchemaDetailsRead
                    treatmentSchema={treatmentSchema}
                  />
                )}
              </CardContent>
            </Card>
            <Card>
              <CardContent sx={{ padding: 3 }}>
                <TreatmentSchemaScheduleDraggableList
                  addNewSchedule={addNewSchedule}
                  addNewSurvey={handleAddSurvey}
                  schedulesAndSurveys={localSchedulesAndSurveys}
                  onRemove={handleRemove}
                  handleDayChanged={handleDayChanged}
                  handleSurveyDayChanged={handleSurveyDayChanged}
                  handleOnDragEnd={handleOnDragEnd}
                  treatmentSchemaId={treatmentSchema.id}
                  refetchTreatmentSchema={refetchTreatmentSchema}
                  isScheduleListEditable={isScheduleListEditable}
                  selectedItems={selectedScheduleIds}
                  setSelectedItems={setSelectedScheduleIds}
                  handleCloneMultiple={handleCloneMultiple}
                  selectedSurveys={selectedSurveyIds}
                  setSelectedSurveys={setSelectedSurveyIds}
                />
              </CardContent>
            </Card>
          </Box>

          <ScheduleCalendar
            optimalDuration={
              Number(formMethods.watch('optimalDuration')) || null
            }
            localSchedules={localSchedulesAndSurveys}
          />
        </Stack>

        <RemoveScheduleModal
          treatmentSchema={treatmentSchema}
          scheduleIds={removingScheduleIds}
          surveyIds={removingSurveyIds}
          onClose={handleRemoveModalClose}
          onSuccess={onRemoveSuccess}
        />
      </ScrollablePanel>
    </FormProvider>
  )
}

import { Stack } from '@mui/material'
import React, { useContext } from 'react'
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import { SurveyQuestionFilterInput } from '../../../../../models/graphql'
import { ReturnData as SurveyQuestionListReturn } from '../../hooks/useSurveyQuestions'
import { SurveyContext } from '../EditSurveyState'
import { QuestionInSection, Section } from './editSurvey.types'
import { EditSurveyForm } from './EditSurveyForm'
import { EditSurveyPatientDescription } from './EditSurveyPatientDescription'
import { QuestionStore } from './QuestionStore'
import { SurveySection } from './SurveySection'

interface Props {
  orderedSections: Section[]
  numberOfQuestionsInSurvey: number
  surveyQuestionListData: SurveyQuestionListReturn
  filterInputState: [
    SurveyQuestionFilterInput,
    React.Dispatch<React.SetStateAction<SurveyQuestionFilterInput>>
  ]
  newQuestionTypes: string[]
  setSections: React.Dispatch<React.SetStateAction<Section[]>>
  updateQuestionInSections: (
    newQuestion: QuestionInSection,
    sectionOrder: number
  ) => void
  removeQuestionFromSections: (
    questionToRemove: QuestionInSection,
    sectionOrder: number
  ) => void
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void
  setSectionName: (order: number, name: string) => void
}

export const EditSurvey: React.FC<Props> = ({
  orderedSections,
  numberOfQuestionsInSurvey,
  surveyQuestionListData,
  filterInputState,
  newQuestionTypes,
  setSections,
  updateQuestionInSections,
  removeQuestionFromSections,
  onDragEnd,
  setSectionName,
}) => {
  const { isReadonly } = useContext(SurveyContext)

  const addNewSection = () =>
    setSections((prevSections: Section[]) => [
      ...prevSections,
      {
        order: prevSections.length + 1,
        questions: [],
        name: '',
      },
    ])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
        <Droppable droppableId="survey-schema-list-id" isDropDisabled={true}>
          {(provided) => (
            <>
              <Stack
                spacing={2}
                flex={1}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <EditSurveyForm />
                <EditSurveyPatientDescription
                  numberOfQuestionsInSurvey={numberOfQuestionsInSurvey}
                />
                {orderedSections.map((section: Section, i: number) => (
                  <SurveySection
                    key={`section_${i}`}
                    section={section}
                    sections={orderedSections}
                    updateQuestionInSections={updateQuestionInSections}
                    removeQuestionFromSections={removeQuestionFromSections}
                    setSectionName={setSectionName}
                    deleteSection={(order) =>
                      setSections((sections) =>
                        sections
                          .filter((section) => section.order !== order)
                          .map((section) =>
                            order < section.order
                              ? { ...section, order: section.order - 1 }
                              : section
                          )
                      )
                    }
                  />
                ))}
                {provided.placeholder}
              </Stack>
              {!isReadonly && (
                <QuestionStore
                  orderedSections={orderedSections}
                  newQuestionTypes={newQuestionTypes}
                  numberOfQuestionsInSurvey={numberOfQuestionsInSurvey}
                  surveyQuestionListData={surveyQuestionListData}
                  filterInputState={filterInputState}
                  addNewSection={addNewSection}
                />
              )}
            </>
          )}
        </Droppable>
      </Stack>
    </DragDropContext>
  )
}

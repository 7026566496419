import makeStyles from '@mui/styles/makeStyles'

export const useStylesForEditableTitles = makeStyles((theme) => ({
  input: {
    color: theme.palette.common.black,
    fontSize: '20px',
    fontWeight: 700,
    padding: '10px 14px',
    '&::placeholder': {
      fontSize: '20px',
      fontWeight: 700,
      opacity: 0.87,
    },
  },
}))

export const useStylesForSurveyActionButtons = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.black,
      background: theme.palette.grey[200],
    },
  },
}))

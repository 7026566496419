import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import {
  EesztESZIGLogin,
  EesztESZIGLoginVariables,
} from '../../../models/graphql'
import { EESZT_ESZIG_LOGIN } from '../../../operations/eesztOperation'
import { useEesztTokenState } from './useEesztTokenState'

type UseEesztRedirectListener = {
  loading: boolean
  error: string | null
}

/**
 * The card reader redirects the user if the token request was successful.
 * On that page the hook, if it was called, tries to parse the token from the route params
 * and if it is successful, it sends the required data to the EESZT to get a valid SAML token.
 * Later with this SAML token, the user can load data from the EESZT
 * @returns A few variables that can help to give feedback to the user about the status of the process
 */
export const useEesztRedirectListener = (): UseEesztRedirectListener => {
  const searchParams = new URLSearchParams(useLocation().search)
  const idToken = searchParams.get('id_token')

  const [error, setError] = useState<string | null>(null)

  const isEesztTokenValid = useEesztTokenState()

  const [eesztEszigLogin, { loading }] = useMutation<
    EesztESZIGLogin,
    EesztESZIGLoginVariables
  >(EESZT_ESZIG_LOGIN, {
    onError: (error) => {
      setError(error.message)
    },
  })

  useEffect(() => {
    if (idToken && !loading && !isEesztTokenValid) {
      eesztEszigLogin({
        variables: {
          token: idToken,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken])

  return {
    loading,
    error,
  }
}

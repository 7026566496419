import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
} from '@latticejs/mui-recharts'
import BarChartIcon from '@mui/icons-material/BarChart'
import PieChartIcon from '@mui/icons-material/PieChart'
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'

enum ChartType {
  Bar = 'Bar',
  Pie = 'Pie',
}

interface Data {
  name: string
  amount: number
}

interface DataWithLabel extends Data {
  barLabelText: string
  percent: number
}

interface Props {
  numberOfAnswers: number
  data: Data[]
  chartLabel?: string
}

export const QuestionResultChart: React.FC<Props> = ({
  numberOfAnswers,
  data,
  chartLabel,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  const [chartType, setChartType] = useState<ChartType>(ChartType.Bar)

  const dataWithLabel: DataWithLabel[] = data.map((item) => {
    const percent = Math.round((item.amount / numberOfAnswers) * 100)
    return {
      ...item,
      percent,
      barLabelText: `${percent}%`,
    }
  })

  const colors = [
    colorPalette.primary.main,
    colorPalette.secondary.light,
    colorPalette.secondary.main,
    colorPalette.secondary.dark,
    colorPalette.success.main,
    colorPalette.warning.main,
  ]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box p={1}>
          <Typography variant="subtitle1">
            {t('survey:result.numberOfAnswers', {
              numberOfAnswers: payload[0].payload?.amount,
            })}
          </Typography>
        </Box>
      )
    }
    return null
  }

  return (
    <Stack direction="row" gap={2} my={1}>
      {!!chartLabel && (
        <Stack width={150}>
          <Typography variant="body1" sx={{ mt: 1 }}>
            {chartLabel}
          </Typography>
          <Typography variant="subtitle2">
            {t('survey:result.numberOfAnswers', {
              numberOfAnswers,
            })}
          </Typography>
        </Stack>
      )}
      <Stack>
        {!chartLabel && (
          <Typography variant="subtitle2" sx={{ my: 1 }}>
            {t('survey:result.numberOfAnswers', {
              numberOfAnswers,
            })}
          </Typography>
        )}

        {numberOfAnswers === 0 ? (
          <Typography variant="h6" textAlign="center" mt={chartLabel ? 2 : 0}>
            {t('survey:result.noChartData')}
          </Typography>
        ) : (
          <>
            {chartType === ChartType.Bar && (
              <Box my={1} height={220} width={800}>
                <ResponsiveContainer>
                  <BarChart data={dataWithLabel}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 100]} unit="%" />
                    <Tooltip content={<CustomTooltip />} />

                    <Bar
                      type="monotone"
                      dataKey="percent"
                      fill={colorPalette.primary.dark}
                      stackId="a"
                      barSize={30}
                      background={{ fill: colorPalette.grey[200] }}
                    >
                      <LabelList dataKey="barLabelText" position="top" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            )}
            {chartType === ChartType.Pie && (
              <PieChart width={800} height={220}>
                <Pie
                  data={dataWithLabel.filter((item) => item.percent !== 0)}
                  dataKey="percent"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={70}
                  label={(value: DataWithLabel) =>
                    `${value.name}: ${value.percent}% (${t(
                      'survey:result.numberOfAnswers',
                      {
                        numberOfAnswers: value.amount,
                      }
                    )})
                    `
                  }
                >
                  {data.map((_entry, index) => (
                    <Cell key={index} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
              </PieChart>
            )}
          </>
        )}
      </Stack>

      {numberOfAnswers !== 0 && (
        <Box>
          <ToggleButtonGroup
            value={chartType}
            exclusive
            onChange={(_e, newType) => setChartType(newType)}
          >
            <ToggleButton value={ChartType.Bar}>
              <BarChartIcon />
            </ToggleButton>
            <ToggleButton value={ChartType.Pie}>
              <PieChartIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
    </Stack>
  )
}

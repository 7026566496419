import React, { FC } from 'react'
import { Box, Link, Typography } from '@mui/material'
import { useSelectedLanguage } from '../../../../common/hooks/useSelectedLanguage'
import {
  getTreatmentSchema_getTreatmentSchema_institutions as Institution,
  getTreatmentSchema_getTreatmentSchema,
} from '../../../../models/graphql'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { getProfessionString } from '../../../../utils/professions'

interface Props {
  treatmentSchema: getTreatmentSchema_getTreatmentSchema
}

export const TreatmentSchemaDetailsRead: FC<Props> = (props) => {
  const { treatmentSchema } = props
  const { t } = useTranslation()
  const selectedLanguage = useSelectedLanguage()

  return (
    <Box p={{ xs: 2, md: 3 }}>
      {treatmentSchema?.url && (
        <Box mt={1}>
          <Typography variant="subtitle1">
            <Link href={treatmentSchema.url} target="_blank" rel="noreferrer">
              {treatmentSchema.url}
            </Link>
          </Typography>
        </Box>
      )}
      {!!treatmentSchema?.bnoCodes?.length && (
        <Box mt={1}>
          <Typography component="span" variant="subtitle1">
            {t('treatment:protocol.bnoCode')}:{' '}
          </Typography>
          <Typography component="span" variant="subtitle1">
            {treatmentSchema.bnoCodes.map((item) => item.code).join(', ')}
          </Typography>
        </Box>
      )}
      {treatmentSchema?.snowmedCode && (
        <Box mt={1}>
          <Typography component="span" variant="subtitle1">
            {t('treatment:protocol.snowmedCode')}:{' '}
          </Typography>
          <Typography component="span" variant="subtitle1">
            {treatmentSchema.snowmedCode}
          </Typography>
        </Box>
      )}
      {treatmentSchema?.description && (
        <Box mt={1}>
          <Typography variant="subtitle1">
            {treatmentSchema.description[selectedLanguage]}
          </Typography>
        </Box>
      )}
      <Box mt={1}>
        <Typography variant="subtitle1" color="textSecondary">
          {getProfessionString(treatmentSchema.professions, t)}
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="subtitle1" color="textSecondary">
          {treatmentSchema.institutions
            .map((ins: Institution) => ins.name)
            .join(', ')}
        </Typography>
      </Box>
    </Box>
  )
}

import { ApolloError, useQuery } from '@apollo/client'

import {
  GetPatientProfileWithEhr,
  GetPatientProfileWithEhrVariables,
  GetPatientProfileWithEhr_getPatientProfile_ehrDocumentList_documents,
  PaginationInput,
} from '../../../../models/graphql'
import { GET_PATIENT_PROFILE_EHR_DOCUMENT_LIST } from '../../../../operations/doctorPatientOperations'

type Props = {
  skip: boolean
  patientId?: string
  paginationInput?: PaginationInput
}

type ReturnData = {
  total: number
  hasDORError: boolean
  patientEeszt: GetPatientProfileWithEhr_getPatientProfile_ehrDocumentList_documents[]
  loading: boolean
  error?: ApolloError
  permissionError: boolean
}

export const useDoctorPatientEESZTData = ({
  skip,
  patientId,
  paginationInput,
}: Props): ReturnData => {
  const {
    data: patientEesztData,
    error,
    loading,
  } = useQuery<GetPatientProfileWithEhr, GetPatientProfileWithEhrVariables>(
    GET_PATIENT_PROFILE_EHR_DOCUMENT_LIST,
    {
      skip: !patientId || skip,
      fetchPolicy: 'no-cache',
      variables: {
        patientId: patientId || '',
        paginationInput,
      },
    }
  )
  const documentList = patientEesztData?.getPatientProfile.ehrDocumentList
  const patientEeszt = documentList?.documents || []
  const total = patientEesztData?.getPatientProfile.ehrDocumentList.total || 0

  return {
    total,
    hasDORError: !!documentList?.hasDORError,
    patientEeszt,
    loading,
    error,
    permissionError: !!error && error.message.includes('MISSING_KAT'),
  }
}

import { createTheme } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    italic: true
    smallItalic: true
    errorText: true
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  italic: React.CSSProperties
  smallItalic: React.CSSProperties
  errorText: React.CSSProperties
}

export const SPACING = 8
export const BORDER_RADIUS = 6
export const BETMEN_LIST_ITEM_CARD_BOX_SHADOW =
  '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)'

const COLORS = {
  PRIMARY_100: '#EBF8FF',
  PRIMARY_200: '#B0E8FF',
  PRIMARY_300: '#32C8FF',
  PRIMARY_400: '#19AAE1',
  PRIMARY_500: '#006AA3',
  SECONDARY_100: '#F50057',
  SECONDARY_200: '#8884D8',
  SECONDARY_300: '#1EF0BE',
  // General (Text, Background)
  WHITE_100: '#FFFFFF',
  GRAY_200: '#EFEEF3',
  GRAY_300: '#9BA4B4',
  GRAY_400: '#69677A',
  DARK_500: '#0F0A37',
  // State
  SUCCESS_LIGHT: '#EEFDE9',
  SUCCESS_MAIN: '#96DF7B',
  SUCCESS_DARK: '#52AE30',
  WARNING_LIGHT: '#F8F1D7',
  WARNING_MAIN: '#EED169',
  WARNING_DARK: '#E4B819',
  ERROR_LIGHT: '#FFC0CB',
  ERROR_MAIN: '#FF0050',
  ERROR_DARK: '#C51162',
  INFO_LIGHT: '#E5F6FD',
  INFO_MAIN: '#65C5FA',
  INFO_DARK: '#2395D5',
}

const BUTTON_BOX_SHADOW =
  '0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
const BUTTON_BOX_SHADOW_HOVER =
  '0px 1px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)'

const theme = createTheme({
  palette: {
    primary: {
      light: COLORS.PRIMARY_200,
      main: COLORS.PRIMARY_300,
      dark: COLORS.PRIMARY_400,
      '100': COLORS.PRIMARY_100,
      '200': COLORS.PRIMARY_200,
      '300': COLORS.PRIMARY_300,
      '400': COLORS.PRIMARY_400,
      '500': COLORS.PRIMARY_500,
    },
    secondary: {
      light: COLORS.SECONDARY_100,
      main: COLORS.SECONDARY_200,
      dark: COLORS.SECONDARY_300,
      '100': COLORS.SECONDARY_100,
      '200': COLORS.SECONDARY_200,
      '300': COLORS.SECONDARY_300,
    },
    error: {
      light: COLORS.ERROR_LIGHT,
      main: COLORS.ERROR_MAIN,
      dark: COLORS.ERROR_DARK,
    },
    success: {
      light: COLORS.SUCCESS_LIGHT,
      main: COLORS.SUCCESS_MAIN,
      dark: COLORS.SUCCESS_DARK,
    },
    warning: {
      light: COLORS.WARNING_LIGHT,
      main: COLORS.WARNING_MAIN,
      dark: COLORS.WARNING_DARK,
    },
    info: {
      light: COLORS.INFO_LIGHT,
      main: COLORS.INFO_MAIN,
      dark: COLORS.INFO_DARK,
    },
    common: {
      black: COLORS.DARK_500,
      white: COLORS.WHITE_100,
    },
    background: {
      default: COLORS.WHITE_100,
    },
    grey: {
      '200': COLORS.GRAY_200,
      '300': COLORS.GRAY_300,
      '400': COLORS.GRAY_400,
    },
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    fontSize: 10,
    htmlFontSize: 10,
    h1: {
      fontSize: '6.0rem',
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    h2: {
      fontSize: '4.8rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: '3.4rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: '2.4rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    h5: {
      fontSize: '2.0rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    h6: {
      fontSize: '1.6rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    body1: {
      fontSize: '1.6rem',
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '1.4rem',
      letterSpacing: 0,
      lineHeight: 1.5,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1.2rem',
    },
    subtitle2: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    // the following ones are not specified in the design yet
    button: {},
    caption: {
      fontSize: '1.0rem',
      fontWeight: 500,
    },
    overline: {},
    // Custom variants:
    italic: {
      fontSize: '1.4rem',
      fontStyle: 'italic',
      fontWeight: 400,
    },
    smallItalic: {
      fontSize: '1.2rem',
      fontStyle: 'italic',
      fontWeight: 400,
    },
    errorText: {
      fontSize: '1.2rem',
      color: 'red',
      textAlign: 'center',
    },
  } as ExtendedTypographyOptions,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          font-size: 10px;
          font-smooth: antialiased;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        body {
          font-size: 1.4rem;
          line-height: 1.5;
          letter-spacing: 0;
          font-weight: 500;
        }
        html, body, #root {
          height: 100vh;
        }
        input:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        
        .PrivatePickersSlideTransition-root {
          min-height: 200px;
          margin-top: ${1.5 * SPACING}px;
        }
      `,
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 10,
        },
        outlined: {
          borderColor: `${COLORS.DARK_500}34`,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          fontWeight: 400,
          background: COLORS.WHITE_100,
          '&.Mui-disabled': {
            background: COLORS.GRAY_200,
          },
          '&.Mui-error': {
            border: `1px solid ${COLORS.SECONDARY_100}`,
          },
        },
        input: {
          color: COLORS.DARK_500,
          '::placeholder': {
            color: COLORS.GRAY_300,
          },
        },
        sizeSmall: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
        },
        fontSizeLarge: {
          fontSize: '2rem',
        },
        fontSizeSmall: {
          fontSize: '1.6rem',
        },
        fontSizeInherit: {
          fontSize: 'inherit',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          height: SPACING * 2,
          minWidth: SPACING * 2,
        },
        dot: {
          height: SPACING,
          minWidth: SPACING,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 32,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: 6 * SPACING,
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: `${1.5 * SPACING}px 0`,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: COLORS.GRAY_200,
          color: COLORS.GRAY_300,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 500,
          minWidth: 0,
          maxWidth: 36 * SPACING,
          textTransform: 'none',

          '@media (min-width: 600px)': {
            minWidth: 0,
          },

          whiteSpace: 'nowrap',
        },
        textColorPrimary: {
          color: COLORS.DARK_500,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: COLORS.DARK_500,
          '&.Mui-disabled': {
            color: `${COLORS.DARK_500}70`,
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: `${COLORS.PRIMARY_300}11`,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: false,
        variant: 'contained',
        color: 'primary',
      },
      styleOverrides: {
        root: {
          borderRadius: '6px',
          fontSize: '1.4rem',
          padding: `${SPACING}px ${2.5 * SPACING}px`,
          minHeight: '40px',
          fontWeight: 500,
          textTransform: 'none',
          lineHeight: 1.5,
          transition: 'all 0.3s ease',
        },
        sizeLarge: {
          padding: `${SPACING}px ${2.5 * SPACING}px`,
          minHeight: '40px',
        },
        sizeSmall: {
          padding: `${0.5 * SPACING}px 48px`,
          minHeight: '32px',
        },
        contained: {
          color: COLORS.WHITE_100,
          background: COLORS.PRIMARY_300,
          boxShadow: BUTTON_BOX_SHADOW,
          ':hover': {
            background: COLORS.PRIMARY_400,
            boxShadow: BUTTON_BOX_SHADOW_HOVER,
          },
          ':disabled': {
            color: COLORS.WHITE_100,
            background: `${COLORS.DARK_500}20`,
            boxShadow: 'none',
          },
        },
        outlined: {
          background: COLORS.WHITE_100,
          boxShadow: BUTTON_BOX_SHADOW,
          border: `1px solid ${COLORS.DARK_500}20`,
          ':not(:hover)': {
            color: COLORS.DARK_500,
          },
          '&:hover': {
            boxShadow: BUTTON_BOX_SHADOW_HOVER,
          },
          ':disabled': {
            color: COLORS.GRAY_300,
            background: COLORS.WHITE_100,
            boxShadow: 'none',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        variant: 'body2',
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
          cursor: 'pointer',
          color: COLORS.PRIMARY_300,
          ':hover': {
            color: COLORS.PRIMARY_400,
          },
          ':disabled': {
            color: COLORS.GRAY_300,
          },
          ':active': {
            color: COLORS.PRIMARY_500,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.8rem',
        },
      },
    },
  },
})

export default theme

import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from '../../common/hooks/helper/useTranslation'
import { ScrollablePanel } from '../../common/components/ScrollablePanel'
import { InvitesWidget } from './components/InviteDoctor/InvitesWidget'
import { Statistics } from './components/Statistics'
import { TorSync } from './components/TorSync'

const AdminPage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <ScrollablePanel title={t('footer:menu.admin')}>
      <Box p={3}>
        <TorSync />
      </Box>
      <Box p={3}>
        <InvitesWidget />
      </Box>
      <Box p={3}>
        <Statistics />
      </Box>
    </ScrollablePanel>
  )
}

export { AdminPage }

import { useQuery } from '@apollo/client'
import React, { FC } from 'react'
import { DoctorProfileList } from '../../common/components/DoctorProfileList'
import { useUserProfile } from '../../common/hooks/useUserProfile'
import {
  getDoctorProfilesForAssistant,
  getDoctorProfilesForAssistantVariables,
} from '../../models/graphql'
import { GET_DOCTOR_PROFILES_FOR_ASSISTANT } from '../../operations/doctorProfileOperations'

export const AssistantDoctors: FC = () => {
  const profile = useUserProfile()

  const { data, loading } = useQuery<
    getDoctorProfilesForAssistant,
    getDoctorProfilesForAssistantVariables
  >(GET_DOCTOR_PROFILES_FOR_ASSISTANT, {
    variables: { assistantId: profile?.id ?? '' },
  })

  return (
    <DoctorProfileList
      doctorProfiles={data?.getDoctorProfilesForAssistant}
      loading={loading}
    />
  )
}

import { gql } from '@apollo/client'

export const DOWLOAD_DOCUMENT = gql`
  mutation generateFileDocuments($documentIds: [String!]!) {
    downloadDocuments(documentIds: $documentIds) {
      document {
        id
        type
        originalFileName
      }
      base64EncodedFile
    }
  }
`

import {
  Assignment as AssignmentIcon,
  Edit as EditIcon,
} from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import get from 'lodash/get'
import React, { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { BnoCodeSelector } from '../../../../common/components/selectors/BnoCodeSelector'
import { BoxWithLabel } from '../../../../common/components/BoxWithLabel'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { getTreatmentSchema_getTreatmentSchema } from '../../../../models/graphql'
import { MultiLangDetailType } from './TreatmentSchemaDetailsEdit/treatmentSchemaDetailsEdit.types'
import { TreatmentSchemaMultiLangPopup } from './TreatmentSchemaDetailsEdit/TreatmentSchemaMultiLangPopup'
import { Reference } from '../../../../common/components/Reference/Reference'
import { getNumberOfReferences } from '../../../../common/components/Reference/referenceUtil'
import { useColorPalette } from '../../../../common/hooks/helper/useColor'
import { ProfessionSelector } from '../../../../common/components/selectors/ProfessionSelector'
import { OPTIMAL_DURATION_MAX_LIMIT } from '../../../../config'
import { debounce } from 'lodash'

interface Props {
  treatmentSchema: getTreatmentSchema_getTreatmentSchema
  onSubmit: () => void
}

export const TreatmentSchemaDetailsEdit: React.FC<Props> = ({
  treatmentSchema,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<getTreatmentSchema_getTreatmentSchema>()
  const [isReferenceOpen, setReferenceOpen] = useState<boolean>(false)
  const [detailsAnchor, setDetailsAnchor] = useState<HTMLButtonElement | null>(
    null
  )
  const detailType = detailsAnchor?.getAttribute('data-edit-type') || ''

  const { onChange: optimalDurationOnChange, ...optimalDurationRegister } =
    register('optimalDuration', {
      min: 1,
      max: OPTIMAL_DURATION_MAX_LIMIT,
    })

  const isReadonly = useMemo(
    () => !treatmentSchema.disabled,
    [treatmentSchema.disabled]
  )

  return (
    <>
      <form
        id="TreatmentSchema_details_form"
        data-cy="TreatmentSchemaDetailsEdit-form"
        onSubmit={onSubmit}
      >
        <Stack direction="row" spacing={0.5} mb={3} alignItems="center">
          <Typography variant="h6">{treatmentSchema.title.hu}</Typography>
          {!isReadonly && (
            <IconButton
              data-cy="TreatmentSchemaDetailsEdit-icon-title"
              data-edit-type={MultiLangDetailType.Title}
              onClick={(e) => setDetailsAnchor(e.currentTarget)}
            >
              <EditIcon />
            </IconButton>
          )}
        </Stack>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ sm: 'flex-end' }}
          gap={{ xs: 1, sm: 4 }}
        >
          <BnoCodeSelector
            name="bnoCodes"
            label={t('treatment:protocol.bnoCode')}
            disabled={isReadonly}
          />

          <BoxWithLabel label={t('treatment:protocol.snowmedCode')}>
            <TextField
              {...register('snowmedCode')}
              variant="outlined"
              fullWidth
              placeholder={t('treatment:editor.snomed.placeholder')}
              disabled={isReadonly}
            />
          </BoxWithLabel>
          <Button
            startIcon={<AssignmentIcon fontSize="large" />}
            onClick={() => setReferenceOpen(true)}
            variant="text"
            sx={{ mb: 1 }}
            disabled={isReadonly}
          >
            {t('treatment:protocol.url')}
            {getNumberOfReferences(watch('url'))}
          </Button>
        </Stack>

        <ProfessionSelector
          label={t('treatment:protocol.profession')}
          name="professions"
          required
          hasError={!!errors.professions}
          helperText={get(errors, 'professions.message', '')}
          disabled={isReadonly}
        />

        <Box my={1}>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant="subtitle2">
              {t('treatment:protocol.description')}
            </Typography>
            {!isReadonly && (
              <IconButton
                data-cy="TreatmentSchemaDetailsEdit-icon-description"
                data-edit-type={MultiLangDetailType.Description}
                onClick={(e) => setDetailsAnchor(e.currentTarget)}
              >
                <EditIcon />
              </IconButton>
            )}
          </Stack>
          <TextField
            {...register('description.hu', {
              required: t('messages:warnings.required') as string,
            })}
            fullWidth
            variant="outlined"
            minRows={3}
            multiline
            error={!!errors.description}
            helperText={get(errors, 'description.hu.message', '')}
            disabled={isReadonly}
          />
          <input type="hidden" {...register('description.en')} />
        </Box>

        <Stack direction="row" alignItems="flex-start" gap={1} mt={2} mb={2}>
          <Typography
            sx={{ color: colorPalette.grey[400] }}
            variant="subtitle2"
            mt={1.2}
          >
            {t('treatment:protocol.length')}
          </Typography>

          <TextField
            {...optimalDurationRegister}
            onChange={debounce(optimalDurationOnChange, 200)}
            variant="outlined"
            size="small"
            type="number"
            error={!!errors.optimalDuration}
            helperText={
              !!errors.optimalDuration &&
              t('treatment:optimalDurationError', {
                max: OPTIMAL_DURATION_MAX_LIMIT,
              })
            }
            InputProps={{
              inputProps: { min: 1, max: OPTIMAL_DURATION_MAX_LIMIT },
            }}
            disabled={isReadonly}
          />
          <Typography
            variant="subtitle2"
            mt={1.2}
            sx={{ color: colorPalette.grey[400] }}
          >
            {t('treatment:protocol.day')}
          </Typography>
        </Stack>

        <input
          {...register('disabled', {
            setValueAs: (value) => value === 'true',
          })}
          type="hidden"
        />
      </form>

      {isReferenceOpen && (
        <Reference
          name="url"
          isOpen={isReferenceOpen}
          onClose={() => setReferenceOpen(false)}
          isStringified={true}
        />
      )}

      {detailsAnchor && (
        <Popover
          open={!!detailsAnchor}
          anchorEl={detailsAnchor}
          onClose={() => setDetailsAnchor(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <TreatmentSchemaMultiLangPopup
            treatmentSchema={treatmentSchema}
            detailType={detailType as MultiLangDetailType}
            onClose={() => setDetailsAnchor(null)}
            onReset={(value) => {
              setValue(
                detailType as keyof getTreatmentSchema_getTreatmentSchema,
                value,
                {
                  shouldDirty: false,
                  shouldValidate: true,
                }
              )
            }}
          />
        </Popover>
      )}
    </>
  )
}

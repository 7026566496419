import {
  AddBox as AddBoxIcon,
  CancelRounded as CancelRoundedIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import {
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { maxBy } from 'lodash'
import React, { useMemo } from 'react'
import {
  Controller,
  FieldPath,
  useFieldArray,
  useFormContext,
} from 'react-hook-form'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { Section } from '../EditSurvey/editSurvey.types'
import { QuestionForm } from '../Questions/questions.types'
import { InputLimit } from '../../../../../common/components/InputLimit/InputLimit'
import {
  cutInputAtLimit,
  MULTIPLECHOICE_ASPECT_LENGTH_LIMIT,
} from '../../utils/question.util'

interface Props {
  sections?: Section[]
  sectionOrder?: number
  disabled?: boolean
}

export const EditMultipleChoiceQuestion: React.FC<Props> = ({
  sections,
  sectionOrder,
  disabled,
}) => {
  const { t } = useTranslation()

  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<QuestionForm>()

  const isJumpToSectionAvailable = watch('isJumpToNextSectionAvailable')
  const isMultipleChoiceAvailable = watch('data.multipleChoice')
  const maxSectionOrder = maxBy(sections, 'order')?.order

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'choices',
  })

  const sectionOptions = useMemo(() => {
    if (
      !maxSectionOrder ||
      maxSectionOrder < 2 ||
      !sectionOrder ||
      sectionOrder === maxSectionOrder
    ) {
      return null
    }

    return Array.from({ length: maxSectionOrder }, (_, i) => i + 1).filter(
      (it) => {
        const actualSection = sections?.find((section) => section.order === it)
        return !!actualSection?.questions.length && it > sectionOrder
      }
    )
  }, [maxSectionOrder, sectionOrder, sections])

  return (
    <Stack data-cy="EditMultipleChoiceQuestion" gap={2} flex={1}>
      <TextField
        {...register('title', {
          required: true,
        })}
        error={!!errors.title}
        helperText={
          !!errors.title && (t('messages:warnings.required') as string)
        }
        fullWidth
        variant="outlined"
        minRows={2}
        multiline
        placeholder={t('survey:question.title')}
        disabled={disabled}
      />

      <TextField
        fullWidth
        variant="outlined"
        minRows={2}
        multiline
        placeholder={t('survey:question.description')}
        {...register('description')}
        disabled={disabled}
      />

      <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
        <Controller
          name="data.multipleChoice"
          control={control}
          render={({ field }) => (
            <Stack direction="row" alignItems="center" marginRight={5}>
              <Checkbox
                disabled={isJumpToSectionAvailable || disabled}
                checked={!!field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
              <Typography variant="body2">
                {t('survey:question.multipleChoice')}
              </Typography>
            </Stack>
          )}
        />

        {sectionOptions && (
          <Controller
            name="isJumpToNextSectionAvailable"
            control={control}
            defaultValue={false}
            render={({ field: { value, onChange } }) => (
              <Stack direction="row" alignItems="center">
                <Checkbox
                  disabled={!!isMultipleChoiceAvailable || disabled}
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked)}
                />
                <Typography variant="body2">
                  {t('survey:question.jumpToSection')}
                </Typography>
              </Stack>
            )}
          />
        )}
      </Stack>

      <Stack gap={1}>
        {fields.map((field, index: number) => {
          const name: FieldPath<QuestionForm> = `choices.${index}.name`
          return (
            <Stack key={field.id} direction="row" alignItems="center">
              <input
                type="hidden"
                {...register(`choices.${index}.idInDb`)}
                defaultValue={field.idInDb || undefined}
              />
              <Stack flex={1}>
                <Controller
                  control={control}
                  name={name}
                  defaultValue={field.name}
                  rules={{
                    required: t('messages:warnings.required') as string,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      size="small"
                      placeholder={t('survey:question.choice.placeholder', {
                        index: index + 1,
                      })}
                      fullWidth
                      sx={{ mr: 1 }}
                      disabled={disabled}
                      error={!!errors.choices?.[index]?.name}
                      helperText={
                        !!errors.choices?.[index]?.name &&
                        (t('messages:warnings.required') as string)
                      }
                      data-cy="MultipleChoiceQuestion-TextField-choice"
                      onChange={(e) =>
                        cutInputAtLimit(
                          e,
                          field.onChange,
                          MULTIPLECHOICE_ASPECT_LENGTH_LIMIT
                        )
                      }
                    />
                  )}
                />
                <InputLimit
                  actualLength={watch(name)?.length}
                  limitLength={MULTIPLECHOICE_ASPECT_LENGTH_LIMIT}
                />
              </Stack>
              {/* Minimum 2 choices needed */}
              {!disabled && fields.length > 2 && (
                <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
                </IconButton>
              )}
              {isJumpToSectionAvailable && sectionOptions && (
                <Controller
                  control={control}
                  name={`choices.${index}.nextSectionOrder`}
                  // TODO - ('' as unknown as number)
                  defaultValue={
                    field.nextSectionOrder ?? ('' as unknown as number)
                  }
                  render={({ field }) => (
                    <TextField
                      label={t('survey:question.jumpToSectionLabel')}
                      {...field}
                      size="small"
                      select
                      sx={{ minWidth: 250 }}
                      disabled={disabled}
                      InputProps={{
                        endAdornment: watch(
                          `choices.${index}.nextSectionOrder`
                        ) && (
                          <IconButton
                            onClick={() =>
                              setValue(
                                `choices.${index}.nextSectionOrder`,
                                '' as unknown as number
                              )
                            }
                          >
                            <CancelRoundedIcon />
                          </IconButton>
                        ),
                      }}
                    >
                      {sectionOptions.map((option, sectionIndex) => (
                        <MenuItem key={sectionIndex} value={option}>
                          {t(`survey:editor.section.title`, {
                            index: option,
                          })}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              )}
            </Stack>
          )
        })}
      </Stack>

      <Button
        startIcon={<AddBoxIcon />}
        disabled={disabled}
        onClick={() => append({ name: '' })}
        sx={{ alignSelf: 'flex-start' }}
        data-cy="MultipleChoiceQuestion-Button-addChoice"
      >
        {t('survey:question.addNewChoice')}
      </Button>
    </Stack>
  )
}

import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { EmptyState } from '../../../../common/components/EmptyState'
import { Loading } from '../../../../common/components/Loading'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { SurveyStatusEnum } from '../../../../models/graphql'
import { compareDates } from '../../../../utils/dates'
import { usePatientSurveyToFill } from '../hooks/usePatientSurveyToFill'
import { FillSurveyState } from './FillSurvey/FillSurveyState'

interface RouteParams {
  surveyId: string
}

export const GetFillSurvey: React.FC = ({}) => {
  const { t } = useTranslation()
  const { surveyId } = useParams<RouteParams>()
  const { survey, loading, refetch } = usePatientSurveyToFill({
    variables: { surveyId },
  })
  const history = useHistory()

  useEffect(() => {
    refetch()

    if (!survey) {
      return
    }

    const isSurveyFillableDateValid =
      compareDates(survey.fillableFrom, new Date(), { isBeforeOrSame: true }) &&
      (!survey.fillableUntil ||
        compareDates(survey.fillableUntil, new Date(), { isAfterOrSame: true }))

    if (
      survey.status !== SurveyStatusEnum.Finished &&
      !isSurveyFillableDateValid
    ) {
      history.push('/patient/home/surveys')
    }
  }, [history, refetch, survey])

  return (
    <>
      {loading && <Loading />}
      {!survey && !loading && (
        <Box
          flexGrow={1}
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <EmptyState
            title={t('survey:surveySchemaNotFoundTitle')}
            description={t('survey:surveySchemaNotFoundMessage', {
              surveySchemaId: surveyId,
            })}
          />
        </Box>
      )}
      {survey && !loading && <FillSurveyState survey={survey} />}
    </>
  )
}

import { gql } from '@apollo/client'
import {
  FRAGMENT_INSTITUTION_TO_DOCTOR,
  FRAGMENT_PATIENT_CHORE,
  FRAGMENT_DOCTOR_PROFILE_FOR_PATIENTS,
} from './fragments'

export const GET_DOCTOR_PROFILE = gql`
  query getDoctorProfile($doctorProfileId: String!) {
    getDoctorProfile(doctorProfileId: $doctorProfileId) {
      id
      phone
      firstName
      lastName
      title
      user {
        email
      }
      professions {
        id
        code
        name
      }
      institutionToDoctor {
        ...institutionToDoctor
      }
    }
  }
  ${FRAGMENT_INSTITUTION_TO_DOCTOR}
`

export const GET_DOCTOR_PROFILES_FOR_PATIENTS = gql`
  query getDoctorProfilesForPatients($patientId: String!) {
    getDoctorProfilesForPatients(patientId: $patientId) {
      ...doctorProfileForPatients
    }
  }
  ${FRAGMENT_DOCTOR_PROFILE_FOR_PATIENTS}
`

export const GET_DOCTOR_PROFILES_FOR_ASSISTANT = gql`
  query getDoctorProfilesForAssistant($assistantId: ID!) {
    getDoctorProfilesForAssistant(assistantId: $assistantId) {
      ...doctorProfileForPatients
    }
  }
  ${FRAGMENT_DOCTOR_PROFILE_FOR_PATIENTS}
`

export const GET_DOCTOR_INVITES = gql`
  query getDoctorInvites($doctorProfileId: String!) {
    getDoctorProfile(doctorProfileId: $doctorProfileId) {
      invitations {
        doctors {
          id
          email
          lastLogin
          createdAt
          doctorProfile {
            title
            firstName
            lastName
          }
        }
      }
    }
  }
`

export const GET_DOCTOR_OWN_PATIENTS = gql`
  query getDoctorOwnPatients(
    $doctorProfileId: String!
    $isActivePatient: Boolean
  ) {
    getDoctorProfile(doctorProfileId: $doctorProfileId) {
      id
      ownPatients(isActivePatient: $isActivePatient) {
        id
        closedAt
        createdAt
        patient {
          id
          user {
            id
            email
          }
          ...patientChore
          birthDate
          weight
          height
          isDeletableForDoctor
        }
      }
    }
  }
  ${FRAGMENT_PATIENT_CHORE}
`

export const GET_DOCTORS_FOR_STATISTICS = gql`
  query getDoctorProfiles($paginationInput: PaginationInput!) {
    getDoctorProfiles(paginationInput: $paginationInput) {
      id
      firstName
      lastName
      title
      ownPatients {
        id
      }
      treatments {
        createdAt
        id
        appointments {
          proposedDate
          id
        }
      }
    }
  }
`

export const UPDATE_DOCTOR_PROFILE = gql`
  mutation updateDoctorProfile(
    $doctorProfileId: String!
    $updateDoctorInput: EditAbleDoctorData!
  ) {
    updateDoctorProfile(
      doctorProfileId: $doctorProfileId
      updateDoctorInput: $updateDoctorInput
    ) {
      id
      phone
      title
      professions {
        id
        code
        name
      }
      hasEESZTToken
      institutionToDoctor {
        ...institutionToDoctor
      }
    }
  }
  ${FRAGMENT_INSTITUTION_TO_DOCTOR}
`

export const LIST_ALL_PROFESSIONS = gql`
  query listAllProfessions {
    listAllProfessions {
      id
      code
      name
    }
  }
`

export const INVITE_DOCTOR = gql`
  mutation inviteDoctor($createDoctorArgs: CreateDoctorProfileData!) {
    inviteDoctor(createDoctorArgs: $createDoctorArgs) {
      id
    }
  }
`

export const LIST_ALL_DOCTORS = gql`
  query listAllDoctors($doctorListFilterData: DoctorProfileFilter) {
    listAllDoctors(doctorListFilterData: $doctorListFilterData) {
      id
      firstName
      lastName
      title
      stampNumber
      userId
      user {
        id
      }
    }
  }
`

export const HAS_EESZT_TOKEN = gql`
  query hasEESZTToken($doctorProfileId: String!) {
    getDoctorProfile(doctorProfileId: $doctorProfileId) {
      id
      hasEESZTToken
    }
  }
`

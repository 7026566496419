import { Box } from '@mui/material'
import { isFunction } from 'lodash'
import React, { FC } from 'react'
import { Waypoint } from 'react-waypoint'
import { PAGE_SIZE } from '../../../config'
import { useBetmenList } from './BetmenList'
import { BetmenListItemCard } from './BetmenListItemCard'

export const BetmenListBody: FC = ({ children }) => {
  const { dataSource, hasMoreItem, fetchMore } = useBetmenList()

  const modifiedChildren = React.Children.map(children, (child, i) => {
    if (React.isValidElement(child) && child.type === BetmenListItemCard) {
      return React.cloneElement(child, { ...child.props, itemindex: i })
    }

    console.warn(
      'Invalid child component found in BetmenListBody. Allowed: BetmenListItemCard',
      child
    )
    return child
  })

  const renderChildrenWithWaypoint = (): JSX.Element => {
    const childArr = React.Children.toArray(modifiedChildren)
    return (
      <>
        {childArr.slice(0, dataSource.length - 3)}
        {hasMoreItem && isFunction(fetchMore) && (
          <Waypoint
            key={'list-waypoint'}
            onEnter={() => fetchMore(dataSource.length)}
          />
        )}
        {childArr.slice(dataSource.length - 3)}
      </>
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      {isFunction(fetchMore) && dataSource.length >= PAGE_SIZE
        ? renderChildrenWithWaypoint()
        : modifiedChildren}
    </Box>
  )
}

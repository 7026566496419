import React from 'react'
import { useSelectedLanguage } from '../../hooks/useSelectedLanguage'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { Link } from 'react-router-dom'
import { Badge, Box, ButtonBase, Divider, Typography } from '@mui/material'

import {
  getNotifications_getNotifications,
  NotificationTypes,
} from '../../../models/graphql'

interface NotificationItem {
  notification: getNotifications_getNotifications
  handleClick: (notificationId: string) => void
}

const NotificationItem: React.FC<NotificationItem> = ({
  notification,
  handleClick,
}) => {
  const { t } = useTranslation()
  const notificationData = { ...notification.data }

  const selectedLanguage = useSelectedLanguage()

  // this is a hack until we got i18n and locize on backend
  if (notificationData.status) {
    notificationData.status = t(
      `appointment:status.${notificationData.status}`
    ).toLowerCase()
  }
  const dateTypeKeys = ['originalDate', 'newDate', 'date', 'treatmentStart']
  dateTypeKeys.forEach((key) => {
    if (notificationData[key]) {
      notificationData[key] = t('common:datePrintedDayWithHours', {
        date: notificationData[key],
      })
    }
  })
  const multiLangKeys = [
    'appointmentTitle',
    'treatmentTitle',
    'originalPlace',
    'newPlace',
    'place',
  ]
  multiLangKeys.forEach((key) => {
    if (notificationData[key]) {
      notificationData[key] = notificationData[key][selectedLanguage]
    }
  })

  return (
    <>
      <Divider />

      <Link
        to={
          notificationData?.link && {
            pathname: notificationData?.link,
          }
        }
        target={
          notification.type === NotificationTypes.EESZTNewFeedItem
            ? '_blank'
            : undefined
        }
        style={{ color: 'initial', textDecoration: 'none' }}
      >
        <ButtonBase
          data-cy={`NotificationItem-type-${notification.type}`}
          onClick={() => handleClick(notification.id)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            textAlign: 'left',
            p: 0,
            m: 0,
          }}
        >
          <Box py={2} mx={3} display="flex" flexDirection="column" flexGrow={1}>
            <Badge invisible={notification.isRead} color="error" variant="dot">
              <Box
                display="flex"
                flexGrow={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1">
                  {t(
                    `notification:alerts.${notification.type}.title`,
                    notificationData
                  )}
                </Typography>
                <Box ml={2}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {t('common:datePrintedDayWithHours', {
                      date: notification.createdAt,
                    })}
                  </Typography>
                </Box>
              </Box>
            </Badge>
            <Typography color="textSecondary" variant="body2">
              {t(
                `notification:alerts.${notification.type}.message`,
                notificationData
              )}
            </Typography>
          </Box>
        </ButtonBase>
      </Link>
    </>
  )
}

export { NotificationItem }

import PreviewIcon from '@mui/icons-material/Preview'
import { Box, Stack, Typography } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { useMe } from '../../../../../common/hooks/useMe'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { getSurveySchemaById_getSurveySchemaById as SurveySchema } from '../../../../../models/graphql'
import { SurveyContext } from '../EditSurveyState'
import { DeleteSurveyButton } from '../SurveyActions/DeleteSurveyButton'
import { PreviewSurveyButton } from '../SurveyActions/PreviewSurveyButton'
import { PublishSurveyButton } from '../SurveyActions/PublishSurveyButton'
import { SaveSurveyButton } from '../SurveyActions/SaveSurveyButton'
import { SendSurveyButton } from '../SurveyActions/SendSurveyButton'
import { ShareSurveyButton } from '../SurveyActions/ShareSurveyButton'
import { Section } from './editSurvey.types'
import { MoreActionsMenu } from '../../../../../common/components/MoreActionsMenu/MoreActionsMenu'

interface Props {
  surveySchema?: SurveySchema
  refetchSurveySchema?: () => void
  sections: Section[]
  isPreviewOnState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

export const EditSurveyActions: React.FC<Props> = ({
  surveySchema,
  sections,
  isPreviewOnState,
  refetchSurveySchema,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const { isReadonly, shouldSave } = useContext(SurveyContext)

  const { data: { me } = {} } = useMe()

  const isOwnerOrAdmin = useMemo(
    () => me?.id === surveySchema?.user.id || me?.isAdmin,
    [me?.id, me?.isAdmin, surveySchema?.user.id]
  )

  const [isPreviewOn] = isPreviewOnState

  return (
    <>
      <Stack direction="row" spacing={1} flex={1} justifyContent="flex-end">
        {isPreviewOn && (
          <Box
            bgcolor={colorPalette.warning.main}
            sx={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 10,
              gap: 1,
              mx: 2,
            }}
          >
            <PreviewIcon />
            <Typography variant="h6">
              {t('survey:survey.preview.title')}
            </Typography>
          </Box>
        )}

        <PreviewSurveyButton isPreviewOnState={isPreviewOnState} />
        {!isReadonly && shouldSave && (
          <SaveSurveyButton surveySchema={surveySchema} sections={sections} />
        )}
        {surveySchema && isOwnerOrAdmin && !shouldSave && (
          <PublishSurveyButton surveySchema={surveySchema} />
        )}

        {isReadonly && !isOwnerOrAdmin ? (
          <>
            {surveySchema && (
              <SendSurveyButton
                surveySchema={surveySchema}
                sx={{ justifyContent: 'flex-start' }}
              />
            )}
          </>
        ) : (
          <>
            {surveySchema && (
              <Box display="flex" alignItems="center">
                <MoreActionsMenu>
                  <ShareSurveyButton
                    surveySchema={surveySchema}
                    variant="text"
                    sx={{ justifyContent: 'flex-start' }}
                  />
                  {surveySchema && (
                    <SendSurveyButton
                      surveySchema={surveySchema}
                      sx={{ justifyContent: 'flex-start' }}
                    />
                  )}
                  {surveySchema && !isPreviewOn && (
                    <DeleteSurveyButton
                      surveySchema={surveySchema}
                      refetchSurveySchema={refetchSurveySchema}
                      sx={{ justifyContent: 'flex-start' }}
                    />
                  )}
                </MoreActionsMenu>
              </Box>
            )}
          </>
        )}
      </Stack>
    </>
  )
}

import React, { useState } from 'react'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { Box, Button, Grid, Typography } from '@mui/material'
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material'
import { useUserType } from '../../../../common/hooks/useUserType'
import { AddTreatmentModal } from './AddTreatmentModal'
import {
  treatmentChore,
  TreatmentStatus,
  UserType,
} from '../../../../models/graphql'
import { TreatmentsEmptyState } from './TreatmentsEmptyState'
import { TreatmentHeader } from './TreatmentHeader'
import dayjs from 'dayjs'
import { useDoctorPatientTreatmentsData } from '../hooks/useDoctorPatientTreatmentsData'
import { Loading } from '../../../../common/components/Loading'
import { useEesztTokenState } from '../../../../common/hooks/eeszt/useEesztTokenState'
import { TreatmentEESZTWarning } from './TreatmentEESZTWarning'

type Props = {
  patientId: string
}

const TreatmentSelector: React.FC<Props> = ({ patientId }) => {
  const { t } = useTranslation()
  const [upserting, setUpserting] = useState<treatmentChore | boolean>(false)

  const userType = useUserType()
  const isEesztTokenValid = useEesztTokenState()

  const { treatments, loading } = useDoctorPatientTreatmentsData({})

  const isDoctor = userType === UserType.Doctor

  const sortedTreatments = [...treatments].sort((a, b) => {
    const aDate = a.doctorAppointments?.length
      ? a.doctorAppointments[0].proposedDate
      : null
    const bDate = b.doctorAppointments?.length
      ? b.doctorAppointments[0].proposedDate
      : null

    if (!bDate) {
      return aDate ? -1 : 0
    }
    if (!aDate) {
      return bDate ? 1 : 0
    }
    return dayjs(aDate).isBefore(bDate) ? -1 : 1
  })

  const activeTreatments = sortedTreatments.filter(
    (treatment) => treatment.status !== TreatmentStatus.Cancelled
  )

  const deletedTreatments = sortedTreatments.filter(
    (treatment) => treatment.status === TreatmentStatus.Cancelled
  )

  const hasAnyTreatment = !!treatments.length
  const hasActiveTreatment = !!activeTreatments.length
  const hasDeletedTreatment = !!deletedTreatments.length

  return (
    <Box height="100%" width="100%">
      {loading && (
        <Box display="flex" height="100%">
          <Loading />
        </Box>
      )}
      {!loading && (
        <Box p={2}>
          <Grid container alignItems="center" sx={{ mb: 1 }}>
            {hasActiveTreatment && (
              <>
                <Grid item xs={12} sm>
                  <Typography variant="h6">
                    {t('treatment:doctor.patientsActiveTreatmentPlans')}
                  </Typography>
                </Grid>
                {isDoctor && (
                  <>
                    {!isEesztTokenValid && <TreatmentEESZTWarning />}
                    <Grid item>
                      <Button
                        startIcon={<AddCircleOutlineIcon />}
                        size="small"
                        variant="outlined"
                        onClick={() => setUpserting(true)}
                      >
                        {t('treatment:doctor.addTreatmentProposal')}
                      </Button>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
          {activeTreatments.map((treatment) => (
            <TreatmentHeader
              key={treatment.id}
              treatment={treatment}
              patientId={patientId}
              onEdit={setUpserting}
            />
          ))}
          {!hasActiveTreatment && (
            <Typography variant="h6" align="center">
              {t('treatment:noPatientsActiveTreatmentPlans')}
            </Typography>
          )}
        </Box>
      )}

      {!loading && hasDeletedTreatment && (
        <Box p={2}>
          <Box mb={1.5}>
            <Typography variant="h6">
              {t('treatment:doctor.patientsClosedTreatmentPlans')}
            </Typography>
          </Box>
          {deletedTreatments.map((treatment) => (
            <TreatmentHeader
              key={treatment.id}
              treatment={treatment}
              patientId={patientId}
            />
          ))}
        </Box>
      )}

      {!loading && !hasAnyTreatment && (
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="center"
        >
          <TreatmentsEmptyState patientId={patientId} />
        </Box>
      )}

      <AddTreatmentModal
        isOpen={Boolean(upserting)}
        onClose={() => setUpserting(false)}
        patientId={patientId}
        treatment={typeof upserting === 'boolean' ? undefined : upserting}
      />
    </Box>
  )
}

export { TreatmentSelector }

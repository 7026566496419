import { Typography } from '@mui/material'
import React from 'react'
import { useColorPalette } from '../../hooks/helper/useColor'

interface Props {
  actualLength: number
  limitLength: number
}

export const InputLimit: React.FC<Props> = ({ actualLength, limitLength }) => {
  const colorPalette = useColorPalette()

  return (
    <Typography
      sx={{ alignSelf: 'flex-end' }}
      variant="caption"
      color={colorPalette.grey[300]}
    >
      {actualLength}/{limitLength}
    </Typography>
  )
}

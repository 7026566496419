import { AddBox as AddBoxIcon, Delete as DeleteIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { FC, useEffect, useRef, useState } from 'react'
import {
  Controller,
  FieldPath,
  useFieldArray,
  useFormContext,
} from 'react-hook-form'
import { InputLimit } from '../../../../../common/components/InputLimit/InputLimit'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  cutInputAtLimit,
  getDefaultScaleData,
  getDefaultScaleMinMax,
  SCALE_CHOICE_LENGTH_LIMIT,
  SCALE_OPTION_LENGTH_LIMIT,
} from '../../utils/question.util'
import { QuestionForm } from '../Questions/questions.types'

interface Props {
  disabled?: boolean
}

export const EditScaleQuestion: FC<Props> = ({ disabled }) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const {
    register,
    control,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext<QuestionForm>()

  const {
    fields: aspectFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'aspects',
  })

  const {
    fields: optionFields,
    append: appendScaleOption,
    remove: removeScaleOption,
  } = useFieldArray({
    control,
    name: 'data.scaleData.options',
  })

  const { scaleMin, scaleMax } = getDefaultScaleMinMax(optionFields)

  const [minValue, setMinValue] = useState(scaleMin)
  const [maxValue, setMaxValue] = useState(scaleMax)

  const isFirstRun = useRef(true)

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    const prevScaleOptions = (
      getValues().data?.scaleData?.options ?? []
    ).slice()

    const newScaleOptions = getDefaultScaleData(minValue, maxValue).options.map(
      ({ value }) => ({
        value,
        label: prevScaleOptions.find((o) => o.value === value)?.label ?? '',
      })
    )

    removeScaleOption()
    appendScaleOption(newScaleOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minValue, maxValue])

  return (
    <Stack data-cy="EditScaleQuestion" gap={2} flex={1}>
      <TextField
        {...register('title', {
          required: true,
        })}
        error={!!errors.title}
        helperText={
          !!errors.title && (t('messages:warnings.required') as string)
        }
        fullWidth
        variant="outlined"
        minRows={2}
        multiline
        placeholder={t('survey:question.title')}
        disabled={disabled}
      />

      <TextField
        fullWidth
        variant="outlined"
        minRows={2}
        multiline
        placeholder={t('survey:question.description')}
        {...register('description')}
        disabled={disabled}
      />

      <Grid container spacing={2}>
        <Grid item xs={6} lg={7}>
          {aspectFields.map((field, index: number) => {
            const name: FieldPath<QuestionForm> = `aspects.${index}.name`
            return (
              <Stack key={field.id} direction="row" alignItems="center" mb={1}>
                <input
                  type="hidden"
                  {...register(`aspects.${index}.idInDb`)}
                  defaultValue={field.idInDb || undefined}
                  disabled={disabled}
                />

                <Stack flex={1}>
                  <Controller
                    control={control}
                    name={name}
                    defaultValue={field.name}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        size="small"
                        disabled={disabled}
                        placeholder={t('survey:question.aspect.placeholder', {
                          index: index + 1,
                        })}
                        fullWidth
                        sx={{ mr: 1 }}
                        data-cy="EditScaleQuestion-TextField-aspect"
                        onChange={(e) =>
                          cutInputAtLimit(
                            e,
                            field.onChange,
                            SCALE_CHOICE_LENGTH_LIMIT
                          )
                        }
                      />
                    )}
                  />

                  <InputLimit
                    actualLength={watch(name)?.length}
                    limitLength={SCALE_CHOICE_LENGTH_LIMIT}
                  />
                </Stack>

                {!disabled && (
                  <IconButton onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
            )
          })}

          <Button
            startIcon={<AddBoxIcon />}
            onClick={() => append({ name: '' })}
            sx={{ alignSelf: 'flex-start' }}
            disabled={disabled}
          >
            {t('survey:question.addNewAspect')}
          </Button>
        </Grid>
        <Grid item xs={6} lg={5}>
          <Card
            elevation={0}
            sx={{ backgroundColor: colorPalette.grey[200], p: 2, pt: 1.5 }}
          >
            <Typography variant="body2" mb={1.5}>
              {t('survey:range')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
              mb={2}
            >
              <Select
                value={minValue}
                onChange={(e) => setMinValue(+e.target.value)}
                size="small"
                disabled={disabled}
              >
                {Array.from({ length: 11 }, (_, i) => i).map((number) => (
                  <MenuItem
                    key={`min_${number}`}
                    value={number}
                    disabled={maxValue <= number}
                  >
                    {number}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2" ml={1} mr={4}>
                -{t('survey:rangeFrom')}
              </Typography>
              <Select
                value={maxValue}
                onChange={(e) => setMaxValue(+e.target.value)}
                size="small"
                disabled={disabled}
              >
                {Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
                  <MenuItem
                    key={`max_${number}`}
                    value={number}
                    disabled={minValue >= number}
                  >
                    {number}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2" ml={1}>
                -{t('survey:rangeTo')}
              </Typography>
            </Box>
            {optionFields
              .sort((fieldA, fieldB) => fieldA.value - fieldB.value)
              .map((field, index) => {
                const name: FieldPath<QuestionForm> = `data.scaleData.options.${index}.label`
                return (
                  <Stack key={index} flexDirection="row" mt={1}>
                    <Box pr={2} mt={1} width={32}>
                      {field.value}
                    </Box>
                    <Stack flex={1}>
                      <Controller
                        control={control}
                        name={name}
                        defaultValue={field.label ?? ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={disabled}
                            variant="outlined"
                            size="small"
                            placeholder={t('survey:scaleExplanation')}
                            fullWidth
                            sx={{ mr: 1 }}
                            data-cy="EditScaleQuestion-TextField-option"
                            onChange={(e) =>
                              cutInputAtLimit(
                                e,
                                field.onChange,
                                SCALE_OPTION_LENGTH_LIMIT
                              )
                            }
                          />
                        )}
                      />
                      <InputLimit
                        actualLength={watch(name)?.length || 0}
                        limitLength={SCALE_OPTION_LENGTH_LIMIT}
                      />
                    </Stack>
                  </Stack>
                )
              })}
          </Card>
        </Grid>
      </Grid>
    </Stack>
  )
}

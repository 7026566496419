import { gql } from '@apollo/client'
import { FRAGMENT_SURVEY_QUESTION_CHORE } from '../../../../operations/fragments'

export const GET_SURVEY_QUESTIONS = gql`
  query getSurveyQuestions(
    $filterInput: SurveyQuestionFilterInput
    $paginationInput: PaginationInput
  ) {
    getSurveyQuestions(
      filterInput: $filterInput
      paginationInput: $paginationInput
    ) {
      surveyQuestions {
        ...surveyQuestionChore
        user {
          id
        }
        isGlobal
      }
      total
    }
  }
  ${FRAGMENT_SURVEY_QUESTION_CHORE}
`

export const CREATE_SURVEY_QUESTION = gql`
  mutation createSurveyQuestion(
    $surveyQuestionData: CreateSurveyQuestionInput!
  ) {
    createSurveyQuestion(surveyQuestionData: $surveyQuestionData) {
      id
    }
  }
`

export const UPDATE_SURVEY_QUESTION = gql`
  mutation updateSurveyQuestion(
    $surveyQuestionData: UpdateSurveyQuestionInput!
  ) {
    updateSurveyQuestion(surveyQuestionData: $surveyQuestionData) {
      id
    }
  }
`

export const DELETE_SURVEY_QUESTION = gql`
  mutation deleteSurveyQuestion($surveyQuestionId: String!) {
    deleteSurveyQuestion(surveyQuestionId: $surveyQuestionId) {
      id
    }
  }
`

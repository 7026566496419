import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { EmptyState } from '../../../../../common/components/EmptyState'
import { Loading } from '../../../../../common/components/Loading'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  DateIntervalInput,
  getSurveySchemaById_getSurveySchemaById as SurveySchema,
} from '../../../../../models/graphql'
import { useAggregatedSurveyResults } from '../../hooks/useAggregatedSurveyResults'
import { AggregatedSurveyResult } from './AggregatedSurveyResult'

interface Props {
  surveySchema: SurveySchema
  dateIntervalFilter: DateIntervalInput | null
}

export const GetAggregatedSurveyResult: React.FC<Props> = ({
  surveySchema,
  dateIntervalFilter,
}) => {
  const { t } = useTranslation()

  const { loading, aggregatedSurveyResults, refetch } =
    useAggregatedSurveyResults({
      variables: {
        surveySchemaId: surveySchema.id,
        dateIntervalInput: dateIntervalFilter,
      },
    })

  useEffect(() => {
    refetch()
  }, [dateIntervalFilter, refetch])

  return (
    <>
      {loading && <Loading />}
      {!aggregatedSurveyResults && !loading && (
        <Box
          flexGrow={1}
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <EmptyState
            title={t('survey:surveySchemaNotFoundTitle')}
            description={t('survey:surveySchemaNotFoundMessage', {
              surveySchemaId: surveySchema.id,
            })}
          />
        </Box>
      )}
      {aggregatedSurveyResults && !loading && (
        <AggregatedSurveyResult
          surveySchema={surveySchema}
          aggregatedSurveyResults={aggregatedSurveyResults}
        />
      )}
    </>
  )
}

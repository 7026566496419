/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import { DataTableColDef, DataTableOrder } from './interfaces'

export const makeTruncatedTextStyles = (
  maxWidth?: string
): React.CSSProperties => ({
  maxWidth: maxWidth || '200px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

export function descendingComparator<T = any>(
  a: T,
  b: T,
  orderBy: DataTableColDef<T>
) {
  const propA =
    orderBy.valueGetter?.({ row: a, field: orderBy.field }) ??
    a[orderBy.field as keyof T]
  const propB =
    orderBy.valueGetter?.({ row: b, field: orderBy.field }) ??
    b[orderBy.field as keyof T]
  if (propB < propA) {
    return 1
  }
  if (propB > propA) {
    return -1
  }
  return 0
}

export function getComparator<T>(
  order: DataTableOrder,
  orderBy: DataTableColDef<T> | null
): (a: T, b: T) => number {
  if (!orderBy) {
    return () => 0
  }
  const comparator = orderBy.sortComparator ?? descendingComparator

  return order === 'desc'
    ? (a, b) => -comparator(a, b, orderBy)
    : (a, b) => comparator(a, b, orderBy)
}

import { Theme } from '@mui/material/styles/createTheme'
import React, { Dispatch, SetStateAction } from 'react'
import { Box, Grid, IconButton, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useUserType } from '../hooks/useUserType'
import { UserType } from '../../models/graphql'
import { Logo } from '../components/Logo'
import { NotificationPanel } from '../components/Notification'
import { HEADER_HEIGHT } from './constants'
import { ProfileMenu } from './ProfileMenu'
import { Nav } from './Nav'
import { MenuIcon } from '../icons/MenuIcon'
import { NewMessagesPanel } from '../../components/MessageBoard/NewMessagesPanel'
import { Link } from 'react-router-dom'
import { EesztPanel } from '../components/EesztConnect'

const useStyles = makeStyles((theme) => ({
  navButton: {
    color: theme.palette.common.white,
    padding: 0,
  },
}))

type Props = {
  isSideMenuOpen: boolean
  setIsSideMenuOpen: Dispatch<SetStateAction<boolean>>
}
const Header: React.FC<Props> = ({ isSideMenuOpen, setIsSideMenuOpen }) => {
  const classes = useStyles()
  const userType = useUserType()
  const mdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  return (
    <Grid
      container
      alignItems="center"
      wrap="nowrap"
      sx={{
        height: HEADER_HEIGHT,
        pl: { xs: 2, md: 2, lg: 3 },
        pr: { xs: 2, md: 1, lg: 2 },
        bgcolor: 'common.black',
        position: 'fixed',
        zIndex: 99,
      }}
    >
      <Grid item>
        <Box mr={1} mb={-0.5}>
          <Link to="/">
            <Logo width={{ xs: 150, md: 130 }} />
          </Link>
        </Box>
      </Grid>

      {mdUp && <Nav />}

      <Grid item xs />

      {(userType === UserType.Doctor || userType === UserType.Admin) && (
        <Grid item>
          <EesztPanel />
        </Grid>
      )}

      <Grid item>
        <NewMessagesPanel />
      </Grid>

      <Grid item>
        <Box mr={{ xs: 2, md: 0 }}>
          <NotificationPanel />
        </Box>
      </Grid>

      {mdUp && (
        <Grid item>
          <ProfileMenu />
        </Grid>
      )}

      {!mdUp && (
        <Grid item>
          <IconButton
            data-cy="Header-IconButton-menu"
            className={classes.navButton}
            onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

export { Header }

import { useQuery } from '@apollo/client'
import {
  hasEESZTToken,
  hasEESZTTokenVariables,
  UserType,
} from '../../../models/graphql'
import { HAS_EESZT_TOKEN } from '../../../operations/doctorProfileOperations'
import { useUserProfile } from '../useUserProfile'
import { useUserType } from '../useUserType'

export const useEesztTokenState = (): boolean => {
  const userType = useUserType()
  const doctorProfile = useUserProfile()
  const doctorProfileId = doctorProfile?.id

  const { data } = useQuery<hasEESZTToken, hasEESZTTokenVariables>(
    HAS_EESZT_TOKEN,
    {
      skip: !doctorProfileId || userType !== UserType.Doctor,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: { doctorProfileId: doctorProfileId! },
      // the token can expire any time, so we need to refetch it every time when we want to use it
      // but we use the cache for optimistic rendering
      fetchPolicy: 'cache-and-network',
    }
  )

  return !!data?.getDoctorProfile.hasEESZTToken
}

import { Add } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React, { FC, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { SurveySchemaSelector } from '../../../../common/components/selectors/SurveySchemaSelector'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { getSurveySchemas_getSurveySchemas_surveySchemas as SurveySchema } from '../../../../models/graphql'

interface TreatmentSchemaAddSurveyProps {
  addSurveySchema: (surveySchema: SurveySchema) => void
}

interface FormValues {
  surveySchema: SurveySchema | null
}

export const TreatmentSchemaAddSurvey: FC<TreatmentSchemaAddSurveyProps> = ({
  addSurveySchema,
}) => {
  const { t } = useTranslation()

  const [showInput, setShowInput] = useState<boolean>(false)
  const formMethods = useForm<FormValues>({
    defaultValues: {
      surveySchema: null,
    },
  })
  const { handleSubmit, reset, control } = formMethods

  const onSubmit = handleSubmit(async (formValues) => {
    if (!formValues.surveySchema) {
      return
    }

    await addSurveySchema(formValues.surveySchema)
    setShowInput(false)
    reset()
  })

  return (
    <Box
      mt={1}
      mb={1}
      width="50%"
      position="relative"
      zIndex={2}
      display="flex"
      justifyContent="end"
    >
      {showInput ? (
        <Box display="flex" width="100%">
          <Controller
            control={control}
            name="surveySchema"
            rules={{ required: true }}
            render={({ field }) => (
              <SurveySchemaSelector
                field={field}
                onSubmit={onSubmit}
                helperText={t('messages:warnings.required')}
                hintText={t('protocol:schedule.addSurveySchema.hint')}
              />
            )}
          />
        </Box>
      ) : (
        <Button
          onClick={() => setShowInput(true)}
          variant="text"
          startIcon={<Add />}
          data-cy="TreatmentSchemaAddSurvey-Button-add"
        >
          {t('protocol:addSurvey')}
        </Button>
      )}
    </Box>
  )
}

import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  getSurveys_getOwnSurveys_surveys as Survey,
  SurveyStatusEnum,
} from '../../../../../models/graphql'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import dayjs from 'dayjs'

interface Props {
  survey: Survey
}

export const PatientSurveyStatus: React.FC<Props> = ({ survey }) => {
  const { t } = useTranslation()
  const { status, fillableUntil, finishedAt } = survey

  const isLessThenFiveDaysUntilFillable =
    dayjs(fillableUntil).diff(new Date(), 'day') <= 5

  const FillableUntil = () => (
    <Typography
      color={isLessThenFiveDaysUntilFillable ? 'error' : ''}
      variant="smallItalic"
    >
      {t('survey:fillableUntilDate', {
        // TODO - Split needed to remove TZ and avoid wrong date.
        date: dayjs(fillableUntil.split('T')[0]).format('YYYY. MMMM DD.'),
      })}
    </Typography>
  )

  switch (status) {
    case SurveyStatusEnum.InProgress:
      return (
        <Stack direction="column">
          <Stack direction="row" alignItems="center" gap={1}>
            <ReportProblemOutlinedIcon color="warning" fontSize="large" />
            <Typography>{t(`patients:surveys.${status}`)}</Typography>
          </Stack>
          {fillableUntil && <FillableUntil />}
        </Stack>
      )

    case SurveyStatusEnum.Finished:
      return (
        <Stack direction="row" alignItems="center" gap={1}>
          <CheckBoxOutlinedIcon color="success" fontSize="large" />
          <Typography variant="h6" mr={1}>
            {t(`patients:surveys.${status}`)}
          </Typography>
          <Typography>{dayjs(finishedAt).format('YYYY. MMMM DD.')}</Typography>
        </Stack>
      )
    default:
      return fillableUntil ? (
        <Stack direction="row" alignItems="center" gap={1}>
          <FillableUntil />
        </Stack>
      ) : null
  }
}

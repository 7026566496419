import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogProps,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { QuestionIcon } from '../Questions/QuestionIcon'
import { Question } from '../Questions/questions.types'
import { ViewQuestionByType } from './ViewQuestionByType'

interface Props extends DialogProps {
  question: Question
  onClose: () => void
}

export const ViewQuestionModal: React.FC<Props> = ({
  question,
  onClose,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {t(`survey:questions.view`)}
      </DialogTitle>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
        ml={5}
        mt={2}
      >
        <QuestionIcon questionType={question.type} fontSize="large" />
        <Typography variant="h6">{question.title}</Typography>
      </Stack>
      <ViewQuestionByType question={question} />
      <DialogActions sx={{ pb: 2, px: 3 }}>
        <Button variant="outlined" onClick={onClose}>
          {t('common:close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

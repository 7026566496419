import { Checkbox, ChipProps, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { ClearFiltersButton } from './ClearFiltersButton'
import { FilterPopover } from './FilterPopover'
import { SelectedChip } from '../SelectedChip/SelectedChip'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface Props extends ChipProps {
  categories: string[]
  categoryFilter: string[]
  setCategoryFilter: (newCategoryFilter: string[]) => void
  translationKey: string
}

export const CategoryFilter: React.FC<Props> = ({
  categories,
  categoryFilter,
  setCategoryFilter,
  translationKey,
  ...chipProps
}) => {
  const { t } = useTranslation()

  const [filterAnchor, setFilterAnchor] = useState<HTMLElement | null>(null)

  const toggleCategoryFilter = (category: string) =>
    setCategoryFilter(
      categoryFilter.includes(category)
        ? categoryFilter.filter((t) => t !== category)
        : [...categoryFilter, category]
    )

  return (
    <>
      <SelectedChip
        selected={!!categoryFilter.length}
        onClick={(e) => setFilterAnchor(e.currentTarget)}
        // we need this onDelete function to see the deleteIcon
        onDelete={(e) => setFilterAnchor(e.currentTarget)}
        deleteIcon={<KeyboardArrowDownIcon />}
        {...chipProps}
      />

      {filterAnchor && (
        <FilterPopover
          filterAnchorState={[filterAnchor, setFilterAnchor]}
          open={!!filterAnchor}
        >
          <ClearFiltersButton
            onClear={() => setCategoryFilter([])}
            disabled={!categoryFilter.length}
          />
          <hr />
          {categories.map((category: string, i: number) => (
            <Stack
              key={i}
              direction="row"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={() => toggleCategoryFilter(category)}
            >
              <Checkbox checked={categoryFilter.includes(category) || false} />
              {t(`${translationKey}.${category}`)}
            </Stack>
          ))}
        </FilterPopover>
      )}
    </>
  )
}

import { Stack, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { formatDayjsToStringDateType } from '../../../../../utils/formatToDateType'

export const DEFAULT_FILLOUT_TIME_IN_DAYS = 7

interface Props {
  fillableFromName: string
  fillableUntilName: string
}
/**
 * Always make sure that SurveyDatePicker component is wrapped by a FormProvider,
 * otherwise the form doesn't going to have the proper values.
 * @param fillableFromName - Key name that you add in useForm to define when the survey is become fillable.
 * @param fillableUntilName - Key name that you add in useForm to define how long the survey can be filled.
 */
export const SurveyDatePicker: React.FC<Props> = ({
  fillableFromName,
  fillableUntilName,
}) => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useFormContext()

  const fillableFromErrorMessage = errors[fillableFromName]?.message
  const fillableUntilErrorMessage = errors[fillableUntilName]?.message
  const errorMessage = fillableFromErrorMessage ?? fillableUntilErrorMessage

  const setFillableUntilValueOneWeekLaterThanFillableUntilValue = () => {
    const fillableFromValue = getValues(fillableFromName)
    setValue(
      fillableUntilName,
      formatDayjsToStringDateType(
        dayjs(fillableFromValue).add(DEFAULT_FILLOUT_TIME_IN_DAYS, 'day')
      )
    )
  }

  return (
    <Stack direction={'column'} py={2}>
      {fillableFromName && (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap={1.5}
          sx={{
            alignItems: 'center',
            mt: { xs: 2, md: 0 },
            mb: 2,
          }}
        >
          <Typography variant="body1" width={'170px'}>
            {t('survey:surveyFillableFromDate')}
          </Typography>
          <Controller
            control={control}
            name={fillableFromName}
            render={({ field: { onChange, value } }) => (
              <TextField
                type="date"
                size="small"
                variant="outlined"
                error={!!errors[fillableFromName]}
                value={value}
                onChange={(e) => {
                  onChange(e)
                  setFillableUntilValueOneWeekLaterThanFillableUntilValue()
                }}
                InputProps={{
                  inputProps: {
                    min: dayjs().format('YYYY-MM-DD'),
                  },
                }}
              />
            )}
          />
        </Stack>
      )}
      {fillableUntilName && (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap={1.5}
          sx={{
            alignItems: 'center',
            mt: { xs: 2, md: 0 },
            mb: !errors[fillableUntilName] ? 2 : 0,
          }}
        >
          <Typography variant="body1" width={'170px'}>
            {t('survey:surveyFillableUntilDate')}
          </Typography>
          <Controller
            control={control}
            name={fillableUntilName}
            render={({ field: { onChange, value } }) => (
              <TextField
                type="date"
                size="small"
                variant="outlined"
                error={!!errors[fillableUntilName]}
                value={value}
                onChange={onChange}
                InputProps={{
                  inputProps: {
                    min: dayjs(watch(fillableFromName)).format('YYYY-MM-DD'),
                  },
                }}
              />
            )}
          />
        </Stack>
      )}
      {!!errorMessage && (
        <Typography variant="subtitle2" color="error">
          {errorMessage}
        </Typography>
      )}
    </Stack>
  )
}

import {
  getTreatmentSchema_getTreatmentSchema_schedules,
  getTreatmentSchema_getTreatmentSchema_surveySchemas,
} from '../../../../models/graphql'

export interface DayConstraints {
  minDay: number | null
  maxDay: number | null
}

export enum CUSTOM_DAY_ERROR {
  ORDER_ERROR = 'orderError',
  EXCEEDS_MAX_DURATION = 'exceedsMaxDuration',
  DEPENDENCY_ERROR = 'dependencyError',
}

export interface LocalSchedule
  extends getTreatmentSchema_getTreatmentSchema_schedules {
  dayError?: CUSTOM_DAY_ERROR
  dayConstraints: DayConstraints
}

export interface LocalSurvey
  extends getTreatmentSchema_getTreatmentSchema_surveySchemas {
  dayError?: CUSTOM_DAY_ERROR
}

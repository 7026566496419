import { gql } from '@apollo/client'
import { FRAGMENT_NOTIFICATION } from './fragments'

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      ...notification
    }
  }
  ${FRAGMENT_NOTIFICATION}
`

export const MARK_NOTIFICATION_READ = gql`
  mutation markNotificationAsRead($notificationId: String!) {
    markNotificationAsRead(notificationId: $notificationId) {
      ...notification
    }
  }
  ${FRAGMENT_NOTIFICATION}
`

export const MARK_ALL_NOTIFICATION_READ = gql`
  mutation markAllNotificationAsRead {
    markAllNotificationAsRead
  }
`

export const NEW_NOTIFICATION = gql`
  subscription newNotification {
    newNotification {
      ...notification
    }
  }
  ${FRAGMENT_NOTIFICATION}
`

export const TRIGGER_DOCTOR_NEXT_WEEK_TASKS = gql`
  query triggerDoctorNextWeekTasks {
    triggerDoctorNextWeekTasks
  }
`

export const TRIGGER_PATIENT_NEXT_WEEK_APPOINTMENTS = gql`
  query triggerPatientNextWeekAppointments {
    triggerPatientNextWeekAppointments
  }
`

export const TRIGGER_PATIENT_DAILY_APPOINTMENT_REMINDER = gql`
  query triggerPatientDailyAppointmentReminder {
    triggerPatientDailyAppointmentReminder
  }
`

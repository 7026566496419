import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const HelpIcon: React.FC<SvgIconProps> = (props) => {
  const viewBox = `0 0 ${props.width ?? 20} ${props.height ?? 20}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <path d="M10,0 C4.4771525,-3.38176876e-16 6.76353751e-16,4.4771525 0,10 C-6.76353751e-16,15.5228475 4.4771525,20 10,20 C15.5228475,20 20,15.5228475 20,10 C19.9940293,4.47962754 15.5203725,0.00597070634 10,0 Z M10,15.8333333 C9.30964406,15.8333333 8.75,15.2736893 8.75,14.5833333 C8.75,13.8929774 9.30964406,13.3333333 10,13.3333333 C10.6903559,13.3333333 11.25,13.8929774 11.25,14.5833333 C11.25,15.2736893 10.6903559,15.8333333 10,15.8333333 Z M11.3333333,10.7666667 C11.0295099,10.899266 10.8331726,11.1993349 10.8333333,11.5308333 C10.8333333,11.9910706 10.4602373,12.3641667 10,12.3641667 C9.53976271,12.3641667 9.16666667,11.9910706 9.16666667,11.5308333 C9.16651602,10.5366404 9.75547379,9.63684383 10.6666667,9.23916667 C11.4296819,8.9061594 11.8299406,8.06118458 11.6042605,7.25983914 C11.3785803,6.45849371 10.5961872,5.94658505 9.77152859,6.06070886 C8.94687,6.17483267 8.33290452,6.87998222 8.33333333,7.7125 C8.33333333,8.17273729 7.96023729,8.54583333 7.5,8.54583333 C7.03976271,8.54583333 6.66666667,8.17273729 6.66666667,7.7125 C6.66664146,6.04767531 7.89497424,4.638101 9.54415831,4.41043205 C11.1933424,4.1827631 12.7576362,5.20681685 13.208672,6.80937978 C13.6597079,8.4119427 12.8591704,10.1015701 11.3333333,10.7675 L11.3333333,10.7666667 Z" />
    </SvgIcon>
  )
}

export { HelpIcon }

import { Card, Stack } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  getNumberOfQuestionsInSurvey,
  getOrderedSections,
} from '../../utils/survey.util'
import { Section, QuestionInSection } from '../EditSurvey/editSurvey.types'
import { mapSurveySchemaQuestionsToSections } from '../../utils/survey.mapper'
import {
  getSurveyById_getSurvey,
  getSurveySchemaById_getSurveySchemaById as SurveySchema,
  GetIndividualSurveyResults_getIndividualSurveyResults_surveys as SurveyResult,
} from '../../../../../models/graphql'
import { FillQuestion } from '../FillQuestion/FillQuestion'
import { SurveyQuestionNumber } from '../SurveyDetails/SurveyQuestionNumber'
import { SurveyFillTime } from '../SurveyDetails/SurveyFillTime'
import { getInititialAnswers } from '../../../../patient/PatientEvents/util/fillSurvey.util'

interface Props {
  surveySchema: SurveySchema
  surveyResult?: SurveyResult
  showSurveyDetails?: boolean
  isPreview?: boolean
}

export const SurveyResultQuestions: React.FC<Props> = ({
  surveySchema,
  surveyResult,
  showSurveyDetails,
  isPreview,
}) => {
  const sections = !!surveySchema.surveySchemaQuestions.length
    ? mapSurveySchemaQuestionsToSections(surveySchema?.surveySchemaQuestions)
    : [{ order: 1, questions: [], name: '' }]

  const orderedSections = getOrderedSections(sections)
  const numberOfQuestionsInSurvey = getNumberOfQuestionsInSurvey(sections)

  const filteredSections = orderedSections
    .filter((section) => !!section.questions.length)
    .sort((aSection, bSection) => aSection.order - bSection.order)
    .map((section) => ({
      ...section,
      questions: section.questions.sort(
        (aQuestion, bQuestion) =>
          (aQuestion.orderInSurvey || aQuestion.orderInSection) -
          (bQuestion.orderInSurvey || bQuestion.orderInSection)
      ),
    }))

  const getAnswers = useCallback(
    () =>
      surveyResult
        ? getInititialAnswers({
            surveySchema,
            surveyQuestionAnswers: surveyResult?.surveyQuestionAnswers?.map(
              (answer) => ({
                ...answer,
                surveyQuestionId: answer.surveyQuestion?.id,
              })
            ),
          } as unknown as getSurveyById_getSurvey)
        : [],
    [surveyResult, surveySchema]
  )

  const formMethods = useForm({
    defaultValues: {
      answers: getAnswers(),
    },
  })

  // This reset is necessary because we need to update the answer values
  // in the form when the user switches between patient results
  useEffect(
    () => formMethods.reset({ answers: getAnswers() }),
    [getAnswers, formMethods]
  )

  return (
    <>
      {showSurveyDetails && (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap={5}
          sx={{ p: 3, pt: 0 }}
        >
          <SurveyQuestionNumber numberOfQuestions={numberOfQuestionsInSurvey} />
          <SurveyFillTime numberOfQuestions={numberOfQuestionsInSurvey} />
        </Stack>
      )}

      <FormProvider {...formMethods}>
        {filteredSections.map((section: Section) =>
          section.questions.map((question: QuestionInSection) => (
            <Card sx={{ p: 3, mb: 2 }} key={question.idForRender}>
              <FillQuestion
                questionOrder={question.orderInSurvey || 0}
                question={question}
                isPreview={!!isPreview}
                readOnly={true}
              />
            </Card>
          ))
        )}
      </FormProvider>
    </>
  )
}

import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import omit from 'lodash/omit'
import React, { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { MultiLangTextField } from '../../../common/components/MultiLangTextField'
import { SaveButton } from '../../../common/components/SaveButton'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import {
  createRoom as CreateRoom,
  MultiLangTextInput,
  OptionalMultiLangTextInput,
  updateRoom as UpdateRoom,
  createRoomVariables,
  institutionChore,
  updateRoomVariables,
} from '../../../models/graphql'
import {
  CREATE_ROOM,
  UPDATE_ROOM,
} from '../../../operations/institutionOperations'
import { RoomTableRowData } from '../pages/LocationAdmin'

type roomFrom = {
  name: MultiLangTextInput
  address: OptionalMultiLangTextInput | null
  description: OptionalMultiLangTextInput | null
  neakCode: string
  active: string
}

interface LocationModal {
  selectedInstitution: institutionChore | null | undefined
  selectedRoom: RoomTableRowData | null | undefined
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onComplete?: () => void
}

const LocationModal: React.FC<LocationModal> = ({
  isOpen,
  setIsOpen,
  onComplete,
  selectedInstitution,
  selectedRoom,
}) => {
  const { t } = useTranslation()
  const isEdit = !!selectedRoom

  const methods = useForm<roomFrom>({
    mode: 'onBlur',
    defaultValues: {
      name: { en: '', hu: '' },
      address: null,
      description: null,
      neakCode: '',
      active: '',
    },
  })

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    reset,
  } = methods

  useEffect(() => {
    if (isEdit) {
      reset({
        name: omit(selectedRoom?.name, '__typename'),
        address: selectedRoom?.address,
        description: selectedRoom?.description,
        neakCode: selectedRoom?.neakCode || '',
        active: !!selectedRoom?.isActive ? 'active' : 'inactive',
      })
    }
  }, [selectedRoom, isEdit, reset])

  const closeModal = () => {
    setIsOpen(false)
    onComplete && onComplete()
    reset({
      name: { en: '', hu: '' },
      address: null,
      description: null,
      neakCode: '',
      active: '',
    })
  }

  const [createRoom, { loading: isCreateLoading }] = useMutation<
    CreateRoom,
    createRoomVariables
  >(CREATE_ROOM, {
    refetchQueries: ['listInstitutions'],
    onCompleted: () => {
      closeModal()
      onComplete && onComplete()
    },
  })
  const [updateRoom, { loading: isUpdateLoading }] = useMutation<
    UpdateRoom,
    updateRoomVariables
  >(UPDATE_ROOM, {
    refetchQueries: ['listInstitutions'],
    onCompleted: () => {
      closeModal()
      onComplete && onComplete()
    },
  })

  const onSubmit = handleSubmit(
    ({ name, address, description, neakCode, active }) => {
      if (selectedInstitution) {
        const roomInput = {
          name,
          address: {
            hu: '',
            en: '',
          },
          description: {
            hu: '',
            en: '',
          },
          neakCode: neakCode,
          isActive: active === 'active',
        }

        if (address) {
          roomInput.address = {
            hu: address.hu ?? '',
            en: address.en ?? '',
          }
        }

        if (description) {
          roomInput.description = {
            hu: description.hu ?? '',
            en: description.en ?? '',
          }
        }

        if (isEdit && selectedRoom?.id) {
          return updateRoom({
            variables: {
              updateRoomInput: {
                roomId: selectedRoom?.id,
                ...roomInput,
              },
            },
          })
        } else {
          return createRoom({
            variables: {
              createRoomInput: {
                institutionId: selectedInstitution?.id,
                ...roomInput,
              },
            },
          })
        }
      }
    }
  )

  const required = {
    value: true,
    message: t('messages:warnings.required'),
  }

  const isLoading = isCreateLoading || isUpdateLoading

  return (
    <FormProvider {...methods}>
      <form>
        <Dialog open={isOpen} onClose={closeModal}>
          <DialogTitle>
            {isEdit
              ? t('common:editLocationTitle')
              : t('common:addLocationTitle')}
          </DialogTitle>

          <DialogContent sx={{ width: { sm: 400 } }}>
            <Box mb={3}>
              <DialogContentText>
                {t('common:addLocationPanelText')}
              </DialogContentText>
            </Box>

            <Box my={1}>
              <FormControl error={!!errors.active}>
                <Controller
                  name="active"
                  control={control}
                  rules={{ required }}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Box display="flex">
                        <FormControlLabel
                          value="active"
                          control={<Radio />}
                          label={t('common:active')}
                        />
                        <FormControlLabel
                          value="inactive"
                          control={<Radio />}
                          label={t('common:inactive')}
                        />
                      </Box>
                    </RadioGroup>
                  )}
                />
                <FormHelperText>{errors.active?.message}</FormHelperText>
              </FormControl>
            </Box>

            <Box my={1}>
              <Typography variant="subtitle2">{t('common:neak')}</Typography>
              <TextField
                {...register('neakCode')}
                fullWidth
                variant="outlined"
              />
            </Box>

            <MultiLangTextField
              name="name"
              required
              title={t('common:LocationName')}
              fullWidth
              variant="outlined"
              errors={errors}
            />

            <MultiLangTextField
              name="address"
              title={t('common:LocationAddress')}
              fullWidth
              variant="outlined"
            />

            <MultiLangTextField
              name="description"
              title={t('common:LocationDescription')}
              fullWidth
              variant="outlined"
              maxRows={3}
              minRows={3}
              multiline
            />
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={closeModal}>
              {t('common:cancel')}
            </Button>
            <SaveButton
              isSaving={isLoading}
              size="medium"
              text={isEdit ? t('common:save') : t('common:addLocation')}
              onClick={onSubmit}
            />
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  )
}

export { LocationModal }

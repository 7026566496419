import round from 'lodash/round'
import {
  FileUploadDataInput,
  SurveyQuestionFileSize,
  SurveyQuestionValidFileType,
} from '../../../../../models/graphql'
import { UploadFileInput } from './fileUpload.types'

export const getAcceptedTypes = (
  fileUploadData: FileUploadDataInput | null
): string => {
  const validFileTypes = !!fileUploadData?.validFileTypes.length
    ? fileUploadData?.validFileTypes
    : Object.keys(SurveyQuestionValidFileType)
  return validFileTypes
    .map((type: string) => `.${type.toLocaleLowerCase()}`)
    .join(', ')
}

export const getSizeText = (sizeInBytes: number): string => {
  const sizeInKiloBytes = sizeInBytes / 1024
  const sizeInMegaBytes = sizeInKiloBytes / 1024
  return sizeInMegaBytes < 1
    ? round(sizeInKiloBytes, 1) + 'KB'
    : round(sizeInMegaBytes, 1) + 'MB'
}

export const getIsFileItemsCountSmaller = (
  fileUploadData: FileUploadDataInput | null,
  files: UploadFileInput[]
): boolean =>
  !fileUploadData?.maxItemsCount || files.length < fileUploadData?.maxItemsCount

export const getMaxSize = (
  fileUploadData: FileUploadDataInput | null
): number => {
  switch (fileUploadData?.maxFileSize) {
    case SurveyQuestionFileSize.FiveMB:
      return 5
    case SurveyQuestionFileSize.TenMB:
      return 10
    case SurveyQuestionFileSize.TwentyFourMB:
      return 24
    default:
      return 0
  }
}

export const getIsFileSizeSmaller = (
  file: File,
  fileUploadData: FileUploadDataInput | null
): boolean => {
  if (fileUploadData?.maxFileSize === SurveyQuestionFileSize.Unlimited) {
    return true
  }
  return file.size / 1024 / 1024 < getMaxSize(fileUploadData)
}

import React, { useEffect, useState } from 'react'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'

import { useMutation } from '@apollo/client'
import {
  ConnectWithoutContact as ConnectWithoutContactIcon,
  EditOutlined as EditOutlinedIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material'
import {
  Avatar as MUIAvatar,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import dayjs from 'dayjs'
import { DoctorInfo } from '../../../../../common/components/DoctorInfo/DoctorInfo'
import { SaveButton } from '../../../../../common/components/SaveButton'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useAppointmentLocation } from '../../../../../common/hooks/useAppointmentLocation'
import { useDownloadAppointmentIcs } from '../../../../../common/hooks/useDownloadAppointmentIcs'
import { useUserType } from '../../../../../common/hooks/useUserType'
import { CalendarAddIcon } from '../../../../../common/icons/CalendarAddIcon'
import { LocationIcon } from '../../../../../common/icons/LocationIcon'
import {
  getTreatment_getTreatment,
  getTreatment_getTreatment_appointments,
  updateAppointmentGoodToKnow,
  updateAppointmentGoodToKnowVariables,
  updateAppointmentOeno,
  updateAppointmentOenoVariables,
  UserType,
} from '../../../../../models/graphql'
import {
  UPDATE_APPOINTMENT_GOODTOKNOW,
  UPDATE_APPOINTMENT_OENO,
} from '../../../../../operations/appointmentOperations'
import { useAppointmentDeleteModal } from '../../hooks/useAppointmentDeleteModal'
import { AppointmentDependency } from './AppointmentDependency'
import { ConnectToPocDialog } from './ConnectToPocDialog'
import { DeleteProtocolModal } from './DeleteProtocolModal'
import { ShowTreatmentReference } from './ShowTreatmentReference'
import { useEesztTokenState } from '../../../../../common/hooks/eeszt/useEesztTokenState'

type Props = {
  treatment: getTreatment_getTreatment
  appointment?: getTreatment_getTreatment_appointments
  onDeleteComplete: () => void
  onEditLocation?: () => void
  onDeleteProtocolComplete: () => void
  isPast: boolean
  isOwnTreatment: boolean
  isDraft: boolean
  isAssistantOfTreatment: boolean
}

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      background: `${theme.palette.primary.main}11`,
    },
  },
}))

const AppointmentContent: React.FC<Props> = ({
  treatment,
  appointment,
  onDeleteComplete,
  onEditLocation,
  onDeleteProtocolComplete,
  isPast,
  isOwnTreatment,
  isDraft,
  isAssistantOfTreatment,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const userType = useUserType()
  const selectedLanguage = useSelectedLanguage()
  const isEesztTokenValid = useEesztTokenState()
  const [goodToKnow, setGoodToKnow] = useState<string>(
    appointment?.info?.goodToKnow[selectedLanguage] || ''
  )
  const [oeno, setOeno] = useState<string>(appointment?.info?.oeno || '')
  const [deleteProtocolModal, toggleDeleteProtocolModal] = useState(false)
  const [isConnectPocDialogOpen, setIsConnectPocDialogOpen] = useState(false)

  const { downloadIcs, loadingIcs } = useDownloadAppointmentIcs()
  const { appointments } = treatment

  const editingDisabled = !isOwnTreatment || (isPast ? !isDraft : false)
  const canEditLocation =
    (isAssistantOfTreatment || isOwnTreatment) && (isPast ? isDraft : true)

  const appointmentDependencies =
    treatment.appointments?.flatMap((item) => item.dependent) ?? []
  const dependencies = appointmentDependencies
    .filter((item) => item.toId === appointment?.id)
    .map((item) => ({
      id: item.id,
      distance: item.distance,
      isBroken: !!item.isBroken,
      appointment: appointments?.find((ap) => ap.id === item.fromId),
      isBefore: true,
    }))

  const dependents = (appointment?.dependent || []).map(
    ({ id, toId, distance, isBroken }) => ({
      id,
      appointment: appointments?.find((item) => item.id === toId),
      distance,
      isBroken: !!isBroken,
      isBefore: false,
    })
  )

  const [updateGoodtoKnow, { loading: isUpdatingGoodToKnow }] = useMutation<
    updateAppointmentGoodToKnow,
    updateAppointmentGoodToKnowVariables
  >(UPDATE_APPOINTMENT_GOODTOKNOW, { refetchQueries: ['getTreatment'] })

  // TODO - change to oeno update
  const [updateOeno, { loading: isUpdatingOeno }] = useMutation<
    updateAppointmentOeno,
    updateAppointmentOenoVariables
  >(UPDATE_APPOINTMENT_OENO, { refetchQueries: ['getTreatment'] })

  useEffect(() => {
    if (appointment?.info?.goodToKnow) {
      setGoodToKnow(appointment?.info?.goodToKnow[selectedLanguage])
    }
  }, [appointment, selectedLanguage])
  const isGoodToKnowChanged =
    appointment?.info?.goodToKnow[selectedLanguage] !== goodToKnow
  const isOenoChanged = appointment?.info?.oeno !== oeno

  const { openDeleteModal, confirmDeleteModal } = useAppointmentDeleteModal({
    appointmentId: appointment?.id,
    onDeleteComplete,
  })

  const { address, mapLink, roomName, institutionName } =
    useAppointmentLocation(appointment)

  const protocolId = appointment?.treatmentSchema?.id

  const isCustomProtocol = !appointment?.treatmentSchema
  const hasFutureAppointmentsWithinProtocol = !!treatment?.appointments?.find(
    (item) =>
      item.treatmentSchema?.id === appointment?.treatmentSchema?.id &&
      dayjs().endOf('day').isBefore(item.proposedDate)
  )

  const protocolTitle = appointment?.treatmentSchema?.title[selectedLanguage]

  const handleGoodToKnowUpdate = () => {
    appointment?.id &&
      updateGoodtoKnow({
        variables: {
          appointmentId: appointment?.id,
          updateGoodToKnowInput: {
            // For now we save the same for both language
            // goodToKnowHu: selectedLanguage === 'hu' ? goodToKnow : null,
            // goodToKnowEn: selectedLanguage === 'en' ? goodToKnow : null,
            goodToKnowHu: goodToKnow,
            goodToKnowEn: goodToKnow,
          },
        },
      })
  }

  // TODO - change to oeno update
  const handleOenoUpdate = () => {
    appointment?.id &&
      updateOeno({
        variables: {
          appointmentId: appointment?.id,
          oeno,
        },
      })
  }

  // const showFeedback = appointment?.ratingText || appointment?.ratingText

  return (
    <Box
      pb={2}
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems="flex-start"
      justifyContent="flex-start"
      display="flex"
      mt={3}
    >
      {/* Info column */}
      <Box
        width={{ xs: '100%', md: 280 }}
        pr={2}
        flexShrink={0}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        {/* Address */}
        <Box display="flex" justifyContent="flex-start">
          <Button
            variant="text"
            disabled={!mapLink}
            fullWidth
            href={mapLink || ''}
            target="_blank"
            className={classes.button}
            startIcon={
              <MUIAvatar>
                <LocationIcon />
              </MUIAvatar>
            }
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexGrow={1}
              textAlign="left"
            >
              {!institutionName && (
                <Typography variant="body2">
                  {t('appointment:doctor.chooseLocation')}
                </Typography>
              )}
              {institutionName && (
                <>
                  <Typography color="primary" variant="body2">
                    {institutionName}
                  </Typography>
                  <Typography color="primary" variant="subtitle2">
                    {roomName}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {address}
                  </Typography>
                </>
              )}
            </Box>
          </Button>
          {onEditLocation && canEditLocation && (
            <Box flexShrink={0} display="flex" alignItems="center">
              <IconButton
                className={classes.button}
                onClick={onEditLocation}
                size="large"
              >
                <EditOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        {/* Download ICS */}
        <Box mt={2}>
          <Button
            variant="text"
            onClick={() => appointment && downloadIcs(appointment.id)}
            disabled={!appointment || loadingIcs}
            className={classes.button}
            startIcon={
              <MUIAvatar>
                <CalendarAddIcon />
              </MUIAvatar>
            }
          >
            <Typography variant="body2">{t('appointment:saveDate')}</Typography>
          </Button>
        </Box>
        {/* Connect event entry to POC if not connected yet and POC exist */}
        {!appointment?.katId &&
          userType === UserType.Doctor &&
          !!treatment.pocId &&
          isEesztTokenValid && (
            <Box mt={2}>
              <Button
                variant="text"
                onClick={() => setIsConnectPocDialogOpen(true)}
                disabled={!appointment || loadingIcs}
                className={classes.button}
                startIcon={
                  <MUIAvatar>
                    <ConnectWithoutContactIcon />
                  </MUIAvatar>
                }
              >
                <Typography variant="body2">
                  {t('appointment:connectToPoc')}
                </Typography>
              </Button>
            </Box>
          )}
        {/* Cancel */}
        <Box mt={2}>
          <Button
            variant="text"
            color="error"
            disabled={editingDisabled}
            onClick={openDeleteModal}
            startIcon={<HighlightOffIcon style={{ fontSize: 40 }} />}
          >
            <Typography variant="body2">
              {t('appointment:cancelButtonText')}
            </Typography>
          </Button>
        </Box>
        {/* Delete protocol */}
        {!isCustomProtocol && (
          <Box mt={2}>
            <Button
              variant="text"
              color="error"
              disabled={editingDisabled || !hasFutureAppointmentsWithinProtocol}
              onClick={() => toggleDeleteProtocolModal(true)}
              startIcon={<HighlightOffIcon style={{ fontSize: 40 }} />}
            >
              <Typography variant="body2" align="left">
                {t('appointment:deleteProtocolButtonText', {
                  protocolTitle,
                })}
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
      {/* Details column */}
      <Box
        pt={{ xs: 0.5, md: 0 }}
        pr={1}
        flexGrow={1}
        display="flex"
        flexDirection="column"
        alignSelf={{ xs: 'flex-start' }}
      >
        <Typography variant="h6">{t('appointment:oeno')}</Typography>
        <Box
          display="flex"
          alignItems="top"
          flexDirection="column"
          textAlign="right"
          mt={2}
        >
          <TextField
            sx={{ flexGrow: 1 }}
            variant="outlined"
            disabled={editingDisabled || isUpdatingOeno}
            value={oeno}
            onChange={(ev) => setOeno(ev.target.value)}
          />

          <Box mt={1} flexGrow={0}>
            <SaveButton
              size="small"
              disabled={editingDisabled || !isOenoChanged}
              isSaving={isUpdatingOeno}
              onClick={handleOenoUpdate}
            />
          </Box>
        </Box>
        <Typography mt={2} variant="h6">
          {t('common:toDo')}
        </Typography>
        <Typography variant="subtitle1">
          {appointment?.info?.doctorTodo?.[selectedLanguage] ||
            t('common:emptyField')}
        </Typography>
        <Box mt={2}>
          <Typography variant="h6">
            {t('appointment:goodToKnowAndNotes')}
          </Typography>
          <Typography variant="subtitle1">
            {appointment?.info?.goodToKnow?.[selectedLanguage] ||
              t('common:emptyField')}
          </Typography>
        </Box>
        <AppointmentDependency
          dependencies={[...dependencies, ...dependents]}
          title={t('appointment:dependencies')}
          selectedLanguage={selectedLanguage}
        />

        <Box mt={2}>
          <Typography variant="h6">
            {t('appointment:treatmentDetails')}:
          </Typography>

          <Box mt={0.5}>
            <Typography variant="subtitle1" color="primary">
              {treatment?.title?.[selectedLanguage]}
            </Typography>
          </Box>
          <Typography variant="subtitle1">
            {treatment?.description?.[selectedLanguage] || ''}
          </Typography>
          <Box mt={1} display="flex">
            {treatment?.firstAndLastDates && (
              <Typography variant="subtitle1">
                {`${t('common:intlDateFormattedLongMonth', {
                  date: treatment.firstAndLastDates.firstAppointmentDate,
                })} - ${t('common:intlDateFormattedLongMonth', {
                  date: treatment.firstAndLastDates.lastAppointmentDate,
                })}`}
              </Typography>
            )}
            <Box mx={1}>
              <Typography variant="subtitle1" color="primary">
                {t('common:formattedNameFull', {
                  title: treatment?.doctor.title,
                  firstName: treatment?.doctor.firstName,
                  lastName: treatment?.doctor.lastName,
                })}
              </Typography>
            </Box>
          </Box>
        </Box>

        {!!appointment?.treatmentSchema && (
          <Box mt={2}>
            <Typography variant="h6">
              {t('appointment:treatmentSchemaDetails')}:
            </Typography>
            {userType !== UserType.Assistant && (
              <Box ml={-1}>
                <Button
                  variant="text"
                  href={`/${userType}/treatment-option/${protocolId}`}
                  target="_blank"
                  color="primary"
                  sx={{ px: 1 }}
                >
                  <Typography variant="subtitle1" color="primary">
                    {appointment.treatmentSchema.title[selectedLanguage]}
                  </Typography>
                </Button>
              </Box>
            )}
            {appointment.treatmentSchema.url && (
              <ShowTreatmentReference
                reference={appointment.treatmentSchema.url}
              />
            )}
            {!!appointment.treatmentSchema.bnoCodes?.length && (
              <Box mt={1}>
                <Typography component="span" variant="subtitle1">
                  {t('treatment:protocol.bnoCode')}:{' '}
                </Typography>
                <Typography component="span" variant="subtitle1">
                  {appointment.treatmentSchema.bnoCodes
                    .map((item) => item.code)
                    .join(', ')}
                </Typography>
              </Box>
            )}
            {appointment.treatmentSchema.snowmedCode && (
              <Box mt={1}>
                <Typography component="span" variant="subtitle1">
                  {t('treatment:protocol.snowmedCode')}:{' '}
                </Typography>
                <Typography component="span" variant="subtitle1">
                  {appointment.treatmentSchema.snowmedCode}
                </Typography>
              </Box>
            )}
            {!!appointment.treatmentSchema.description && (
              <Box mt={1}>
                <Typography variant="subtitle1">
                  {appointment.treatmentSchema.description[selectedLanguage]}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        <Box mt={2}>
          <Typography variant="h6">{t('appointment:goodToKnow')}:</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="top"
          flexDirection="column"
          textAlign="right"
          mt={2}
        >
          <TextField
            sx={{ flexGrow: 1 }}
            multiline
            minRows={6}
            variant="outlined"
            disabled={editingDisabled || isUpdatingGoodToKnow}
            value={goodToKnow}
            onChange={(ev) => setGoodToKnow(ev.target.value)}
          />

          <Box mt={1} flexGrow={0}>
            <SaveButton
              size="small"
              disabled={editingDisabled || !isGoodToKnowChanged}
              isSaving={isUpdatingGoodToKnow}
              onClick={handleGoodToKnowUpdate}
            />
          </Box>
        </Box>
      </Box>
      <Box
        width={{ xs: '100%', md: 350 }}
        minWidth={350}
        pl={{ md: 3 }}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        {!!appointment?.doctor && <DoctorInfo doctor={appointment.doctor} />}
      </Box>
      {confirmDeleteModal}

      <DeleteProtocolModal
        isOpen={deleteProtocolModal}
        toggleIsOpen={toggleDeleteProtocolModal}
        treatment={treatment}
        treatmentSchemaId={appointment?.treatmentSchema?.id}
        onCompleted={onDeleteProtocolComplete}
      />

      {isConnectPocDialogOpen && !!treatment.pocId && !!appointment?.id && (
        <ConnectToPocDialog
          isDialogOpen={isConnectPocDialogOpen && !!treatment.pocId}
          close={() => setIsConnectPocDialogOpen(false)}
          pocId={treatment.pocId}
          appointmentId={appointment.id}
          tajNumber={treatment.patient.tajNumber}
        />
      )}
    </Box>
  )
}

export { AppointmentContent }

import { TableBody } from '@mui/material'
import { isFunction } from 'lodash'
import React, { PropsWithChildren, ReactElement, useCallback } from 'react'
import { Waypoint } from 'react-waypoint'
import { DataTableRow } from './DataTableRow'
import {
  DataTableColDef,
  DataTableRowAction,
  DataTableRowData,
} from './interfaces'

type Props<T> = {
  columns: DataTableColDef<T>[]
  visibleColumns: DataTableColDef<T>[]
  hiddenColumns: DataTableColDef<T>[]
  rows: T[]
  actions?: DataTableRowAction<T>[]
  showRowDetails: boolean
  hasMoreRows?: boolean
  fetchMoreRows?: (offset: number) => void
}

export const DataTableBody = <T extends DataTableRowData>({
  rows,
  columns,
  visibleColumns,
  hiddenColumns,
  actions,
  showRowDetails,
  hasMoreRows,
  fetchMoreRows,
}: PropsWithChildren<Props<T>>): ReactElement => {
  const fetchMore = useCallback(() => {
    const offset = rows.length
    isFunction(fetchMoreRows) && fetchMoreRows(offset)
  }, [fetchMoreRows, rows.length])

  return (
    <TableBody>
      {rows.map((row, idx) => (
        <React.Fragment key={'wp+' + row.id}>
          <DataTableRow
            key={row.id}
            row={row}
            columns={columns}
            visibleColumns={visibleColumns}
            hiddenColumns={hiddenColumns}
            actions={actions}
            showDetails={showRowDetails}
          />
          {idx === rows.length - 3 && hasMoreRows && (
            <tr>
              <td>
                <Waypoint onEnter={fetchMore} />
              </td>
            </tr>
          )}
        </React.Fragment>
      ))}
    </TableBody>
  )
}

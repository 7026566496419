import React, { FC } from 'react'
import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  TextField,
  Typography,
  PaperProps,
} from '@mui/material'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  getTreatment_getTreatment,
  getTreatment_getTreatment_appointments,
  TreatmentStatus,
} from '../../../../../models/graphql'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import { BoxWithLabel } from '../../../../../common/components/BoxWithLabel'
import { Alert, Autocomplete } from '@mui/material'
import dayjs, { OpUnitType } from 'dayjs'
import { useAppointmentDependencyCreate } from '../../hooks/useAppointmentDependencyCreate'
import { useAppointmentDependencyDelete } from '../../hooks/useAppointmentDependencyDelete'
import { useAppointmentDependencyUpdate } from '../../hooks/useAppointmentDependencyUpdate'
import { appointmentDistanceFormatter } from '../../../../../utils/appointmentDistanceFromatter'
import { parseDistance } from '../../../../../utils/parseDistance'
import { DependencyModalActions } from './DependencyModalActions'

type FormValues = {
  unit: OpUnitType
  distance: number
}

type Props = {
  isOwnTreatment: boolean
  treatment: getTreatment_getTreatment
  linking: getTreatment_getTreatment_appointments[]
  isOpen: boolean
  onClose: () => void
  onDone: () => void
}

export const AppointmentDependencyModal: FC<Props> = (props) => {
  const { isOpen, onClose, isOwnTreatment, treatment, linking, onDone } = props

  const shouldReverse = linking[1].dependent.some(
    ({ toId }) => toId === linking[0].id
  )

  const appointment = shouldReverse ? linking[1] : linking[0]
  const dependentAppointment = shouldReverse ? linking[0] : linking[1]
  const { t } = useTranslation()
  const selectedLanguage = useSelectedLanguage()
  const isDraft = treatment.status === TreatmentStatus.Draft

  const dependency = appointment.dependent.find(
    (item) =>
      item.fromId === appointment.id && item.toId === dependentAppointment.id
  )

  const isDependentPast =
    dayjs().startOf('day') > dayjs(dependentAppointment.proposedDate)

  const isProtocolDependency = dependency && !dependency.isCustom
  const canEdit =
    dependency &&
    dependency.isCustom &&
    isOwnTreatment &&
    (!isDependentPast || isDraft)

  const [createDependency] = useAppointmentDependencyCreate(
    { treatmentId: treatment.id, appointment, dependentAppointment },
    { onCompleted: onDone }
  )

  const [updateDependency] = useAppointmentDependencyUpdate(
    { treatmentId: treatment.id, appointment, dependentAppointment },
    {
      onCompleted: onDone,
    }
  )

  const [removeDependency, { loading: isRemoving }] =
    useAppointmentDependencyDelete(
      { treatmentId: treatment.id, appointment, dependentAppointment },
      { onCompleted: onDone }
    )

  const handleRemove = () => {
    if (!dependency) {
      return
    }
    return removeDependency({
      variables: {
        dependencyId: dependency.id,
      },
    })
  }

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      unit: !dependency ? 'd' : parseDistance(dependency.distance).unit,
      distance: !dependency ? 1 : +parseDistance(dependency.distance).value,
    },
  })

  const distance = useWatch({ name: 'distance', control })
  const unit = useWatch({ name: 'unit', control })

  const isDependencyBroken = (
    distance: string,
    appointmentDate: Date,
    fromDate: Date
  ) => {
    const { unit, value } = parseDistance(distance)
    const isSameDay = dayjs(fromDate).isSame(appointmentDate, 'day')
    const minDate = isSameDay
      ? dayjs(fromDate).add(value, unit)
      : dayjs(fromDate).startOf('day').add(value, unit)
    return minDate.isAfter(appointmentDate)
  }

  const isBroken = isDependencyBroken(
    `${distance}${unit}`,
    dependentAppointment.proposedDate,
    appointment.proposedDate
  )

  const handleSave = handleSubmit((formValues) => {
    if (dependency) {
      return updateDependency({
        variables: {
          updateAppointmentDependencyInput: {
            dependencyId: dependency.id,
            distance: `${formValues.distance}${formValues.unit}`,
          },
        },
      })
    }
    return createDependency({
      variables: {
        createAppointmentDependencyInput: {
          appointmentId: dependentAppointment.id,
          fromId: appointment.id,
          distance: `${formValues.distance}${formValues.unit}`,
        },
      },
    })
  })

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onSubmit={handleSave}
      PaperProps={
        {
          component: 'form',
        } as Partial<PaperProps<'div'>>
      }
      BackdropProps={{
        onClick: (event) => event.stopPropagation(),
      }}
    >
      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box mt={2} flex={1}>
            <Typography variant="h6">
              {appointment.info?.doctorTitle[selectedLanguage]}
            </Typography>
            <Typography variant="subtitle1">
              {t('common:patientFormattedDate', {
                date: appointment.proposedDate,
              })}
            </Typography>
          </Box>
          <Box mt={2} mx={2}>
            <ArrowForwardIcon fontSize="large" />
          </Box>
          <Box mt={2} flex={1}>
            <Typography variant="h6">
              {dependentAppointment.info?.doctorTitle[selectedLanguage]}
            </Typography>
            <Typography variant="subtitle1">
              {t('common:patientFormattedDate', {
                date: dependentAppointment.proposedDate,
              })}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogContent>
        <Collapse in={isBroken}>
          <Box mb={1}>
            <Alert elevation={0} variant="filled" severity="error">
              <Typography variant="body2">
                {`${t(
                  'appointment:dependencyBroken'
                )} ${appointmentDistanceFormatter(`${distance}${unit}`, t)}`}
              </Typography>
            </Alert>
          </Box>
        </Collapse>
        {isProtocolDependency && (
          <Alert elevation={0} variant="filled" severity="info">
            <Typography variant="body2">
              {t('appointment:protocolDependencyWarning')}
            </Typography>
          </Alert>
        )}
        <Box display="flex">
          <Box mr={2}>
            <BoxWithLabel label={t('common:minDistance')}>
              <TextField
                {...register('distance', { required: true, min: 1 })}
                size="small"
                variant="outlined"
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                error={!!errors.distance}
                disabled={!!dependency && !canEdit}
              />
            </BoxWithLabel>
          </Box>
          <Box minWidth={100}>
            <BoxWithLabel label={t('common:unit')}>
              <Controller
                control={control}
                name="unit"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    size="small"
                    options={['h', 'd', 'w']}
                    getOptionLabel={(unit) =>
                      t(`common:timingInterval.${unit}`)
                    }
                    isOptionEqualToValue={(unit) => unit === value}
                    disableClearable
                    disabled={!!dependency && !canEdit}
                    value={value}
                    onChange={(_, newValue) => {
                      onChange(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                )}
              />
            </BoxWithLabel>
          </Box>
        </Box>
      </DialogContent>
      <DependencyModalActions
        hideRemoveButton={!canEdit}
        hideSubmitButton={!!dependency && !canEdit}
        disableRemoveButton={isSubmitting || isRemoving}
        disableSubmitButton={isSubmitting || isRemoving}
        onCancel={onClose}
        onRemove={handleRemove}
      />
    </Dialog>
  )
}

import { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  getAppointmentIcs,
  getAppointmentIcsVariables,
} from '../../models/graphql'
import { GET_APPOINTMENT_ICS } from '../../operations/appointmentOperations'
import { downloadFile } from '../../utils/downloadFile'

type ReturnTypes = {
  downloadIcs: (appointmentId: string) => void
  loadingIcs: boolean
}

/**
 * Download appointment event as an ICS file.
 */
export const useDownloadAppointmentIcs = (): ReturnTypes => {
  const [appointmentId, setAppointmentId] = useState<string | null>(null)

  const [queryGetAppointmentIcs, { loading: loadingIcs }] = useLazyQuery<
    getAppointmentIcs,
    getAppointmentIcsVariables
  >(GET_APPOINTMENT_ICS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.getAppointment.ics) {
        downloadFile(
          data.getAppointment.ics,
          'text/calendar',
          `${appointmentId}.ics`
        )
      }
    },
  })

  const downloadIcs = (newAppointmentId: string) => {
    setAppointmentId(newAppointmentId)
    queryGetAppointmentIcs({
      variables: {
        appointmentId: newAppointmentId,
      },
    })
  }

  return {
    downloadIcs,
    loadingIcs,
  }
}

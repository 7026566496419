import { useMutation } from '@apollo/client'
import { UPDATE_ASSISTANT_PROFILE } from '../../../operations/assistantProfileOperations'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import {
  updateAssistantProfile,
  updateAssistantProfileVariables,
} from '../../../models/graphql'

type Options = MutationHookOptions<
  updateAssistantProfile,
  updateAssistantProfileVariables
>

type Return = MutationTuple<
  updateAssistantProfile,
  updateAssistantProfileVariables
>

export const useAssistantProfileUpdate = (options: Options = {}): Return => {
  return useMutation<updateAssistantProfile, updateAssistantProfileVariables>(
    UPDATE_ASSISTANT_PROFILE,
    options
  )
}

import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  addAssistantToDoctor,
  addAssistantToDoctorVariables,
} from '../../../../models/graphql'
import { ADD_ASSISTANT_TO_DOCTOR } from '../operations/doctorOwnAssistantsOperations'

type Options = MutationHookOptions<
  addAssistantToDoctor,
  addAssistantToDoctorVariables
>

type Return = MutationTuple<addAssistantToDoctor, addAssistantToDoctorVariables>

export const useAddAssistantToDoctor = (options: Options = {}): Return => {
  return useMutation<addAssistantToDoctor, addAssistantToDoctorVariables>(
    ADD_ASSISTANT_TO_DOCTOR,
    options
  )
}

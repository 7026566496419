import React, { FC, useState } from 'react'
import { Button, TextField, Typography, Box, Link, Zoom } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Loading } from '../../../common/components/Loading'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useLoginWithTwoFactor } from '../hooks/useLoginWithTwoFactor'
import { LoginWithTwoFactorTokenInput } from '../../../models/graphql'
import { storeDataAfterLogin } from '../utils/login.utils'
import { VerifyPassword } from './VerifyPassword'

const TwoFactor: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [loginWithTwoFactor, { error }] = useLoginWithTwoFactor()
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const verificationError = `messages:warnings.${
    error?.message === 'Unauthorized' ? 'wrongVerification' : 'serverError'
  }`

  // TODO - update react to ^v6 and use useSearchParams() hook instead
  // https://stackoverflow.com/a/37568368
  const email = location.search.split('&')[0].split('=')[1]

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<LoginWithTwoFactorTokenInput>({
    defaultValues: {
      email,
      token: '',
    },
  })

  const onSubmit = handleSubmit(async (values) => {
    await loginWithTwoFactor({
      variables: {
        loginWithTwoFactorTokenInput: {
          email: values?.email,
          token: values.token,
        },
      },
      onCompleted: ({ loginWithTwoFactorToken: { jwtToken } }) => {
        // TODO - update react to ^v6 and use useSearchParams() hook instead
        // https://stackoverflow.com/a/37568368
        const isFirstLogin =
          location.search.split('&')[1].split('=')[1] === 'true'
        storeDataAfterLogin(jwtToken, isFirstLogin)
        history.push('/')
      },
    })
  })

  if (isSubmitting) {
    return <Loading />
  }

  return (
    <>
      <Box mt={3}>
        <Typography component="h1" variant="h3">
          {t('auth:verify')}
        </Typography>
      </Box>
      <Box mt={1} flexGrow={1} display="flex" alignItems="center">
        <Typography component="p" variant="body1">
          {t('auth:verifyText')}
        </Typography>
      </Box>
      {/* 100% width: Fix IE 11 issue. */}
      <Box width="100%" mt={3}>
        <form onSubmit={onSubmit} noValidate>
          <Controller
            name="token"
            control={control}
            rules={{
              maxLength: 8,
              required: 'messages:warnings.emptyField',
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label={t('auth:verifyCode')}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                autoFocus
              />
            )}
          />
          <Zoom in={!!error}>
            <Box mt={3}>
              {error && (
                <Typography
                  variant="body2"
                  color="error"
                  data-error={verificationError}
                >
                  {t(verificationError)}
                </Typography>
              )}
            </Box>
          </Zoom>
          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
            >
              {t('auth:verify')}
            </Button>
          </Box>
        </form>
        <Box my={3}>
          <Typography variant="subtitle2" color="textSecondary">
            {t('auth:verifyCodeNotReceivedText')}
          </Typography>
          <Link onClick={() => setDialogIsOpen(true)} underline="hover">
            <Typography variant="subtitle2" color="textSecondary">
              {t('auth:verifyResendCode')}
            </Typography>
          </Link>
        </Box>
      </Box>
      <VerifyPassword
        email={email}
        isOpen={dialogIsOpen}
        close={() => setDialogIsOpen(false)}
      />
    </>
  )
}

export { TwoFactor }

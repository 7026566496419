import { UserType } from '../../models/graphql'
import { useMe } from './useMe'
import { useUserType } from './useUserType'

type Return = {
  isAdmin: boolean
  isOwnTreatment: (treatmentDoctorId?: string) => boolean
  isAssistantOfTreatment: (treatmentDoctorId?: string) => boolean
}

export const usePermissions = (): Return => {
  const { data: { me: user } = {} } = useMe({ fetchPolicy: 'cache-only' })

  const profileId = user?.profile?.id
  const userType = useUserType()
  const assistantDoctorIds = user?.assistantDoctorIds
  const isDoctor = userType === UserType.Doctor
  const isAssistant = userType === UserType.Assistant

  const isOwnTreatment = (treatmentDoctorId?: string) =>
    isDoctor && treatmentDoctorId === profileId

  const isAssistantOfTreatment = (treatmentDoctorId?: string) =>
    !!(
      isAssistant &&
      treatmentDoctorId &&
      assistantDoctorIds?.includes(treatmentDoctorId)
    )

  return {
    isAdmin: user?.isAdmin ?? false,
    isOwnTreatment,
    isAssistantOfTreatment,
  }
}

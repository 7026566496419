import { SurveyQuestionEnum } from './../../../../models/graphql'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import {
  getSurveyById_getSurvey,
  InputSurveyQuestionAnswer,
  getSurveyById_getSurvey_surveySchema_surveySchemaQuestions as SurveyQuestion,
  getSurveyById_getSurvey_surveyQuestionAnswers as SurveyAnswer,
  getSurveyById_getSurvey_surveyQuestionAnswers_mcQuestionAnswer,
  getSurveyById_getSurvey_surveyQuestionAnswers_scaleQuestionAnswer,
  getSurveyById_getSurvey_surveyQuestionAnswers_simpleTextQuestionAnswer,
} from '../../../../models/graphql'

const omitTypeName = (
  answer:
    | getSurveyById_getSurvey_surveyQuestionAnswers_mcQuestionAnswer
    | getSurveyById_getSurvey_surveyQuestionAnswers_scaleQuestionAnswer
    | getSurveyById_getSurvey_surveyQuestionAnswers_simpleTextQuestionAnswer
    | null
) => {
  const withoutTypeName = omit(answer, '__typename')
  return isEmpty(withoutTypeName) ? null : withoutTypeName
}

const getInititialAnswerForQuestion = (
  survey: getSurveyById_getSurvey,
  question: SurveyQuestion,
  answers: SurveyAnswer[]
): InputSurveyQuestionAnswer => {
  const savedAnswer = answers.find(
    (answer) => answer.surveyQuestionId === question.surveyQuestion.id
  )
  // TODO addTypename: false in ApolloProvider instead of omit
  const initialAnswers = savedAnswer
    ? {
        mcQuestionAnswer: omitTypeName(savedAnswer.mcQuestionAnswer),
        scaleQuestionAnswer: !!savedAnswer.scaleQuestionAnswer
          ? {
              ...omitTypeName(savedAnswer.scaleQuestionAnswer),
              aspectAnswers:
                savedAnswer.scaleQuestionAnswer?.aspectAnswers?.map(
                  (aspectAnswer) => omit(aspectAnswer, '__typename')
                ) || null,
            }
          : null,

        simpleTextQuestionAnswer: omitTypeName(
          savedAnswer.simpleTextQuestionAnswer
        ),
        fileUploadQuestionAnswer: !!savedAnswer.fileUploadQuestionAnswer
          ?.documents
          ? {
              documents: savedAnswer.fileUploadQuestionAnswer.documents.map(
                (doc) => omit(doc, '__typename') || null
              ),
            }
          : null,
      }
    : {
        mcQuestionAnswer: null,
        scaleQuestionAnswer: null,
        simpleTextQuestionAnswer: null,
        fileUploadQuestionAnswer: null,
      }

  return {
    surveyId: survey.id,
    surveyQuestionId: question.surveyQuestion.id,
    ...initialAnswers,
  }
}

export const getInititialAnswers = (
  survey: getSurveyById_getSurvey
): InputSurveyQuestionAnswer[] => {
  return survey.surveySchema.surveySchemaQuestions.map(
    (question: SurveyQuestion) =>
      getInititialAnswerForQuestion(
        survey,
        question,
        survey.surveyQuestionAnswers
      )
  )
}

export const getIsAnswerFilled = (
  answer: InputSurveyQuestionAnswer,
  type: SurveyQuestionEnum
): boolean => {
  switch (type) {
    case SurveyQuestionEnum.SimpleText:
      return !isEmpty(answer.simpleTextQuestionAnswer?.text)
    case SurveyQuestionEnum.MultipleChoice:
      return !!answer.mcQuestionAnswer?.selectedChoiceIds?.length
    case SurveyQuestionEnum.Scale:
      return !isEmpty(answer.scaleQuestionAnswer?.aspectAnswers)
    case SurveyQuestionEnum.FileUpload:
      return !isEmpty(answer.fileUploadQuestionAnswer?.documents)
    default:
      return true
  }
}

export const filterFilledAnswers = (
  answers: InputSurveyQuestionAnswer[],
  questions: SurveyQuestion[]
): InputSurveyQuestionAnswer[] =>
  answers
    .filter((answer) => {
      const questionOfAnswer = questions.find(
        (question) => question.surveyQuestion.id === answer.surveyQuestionId
      )
      return getIsAnswerFilled(
        answer,
        questionOfAnswer?.surveyQuestion.type as SurveyQuestionEnum
      )
    })
    .map((answer) => {
      const questionOfAnswer = questions.find(
        (question) => question.surveyQuestion.id === answer.surveyQuestionId
      )
      if (questionOfAnswer?.surveyQuestion.type === SurveyQuestionEnum.Scale) {
        return {
          ...answer,
          scaleQuestionAnswer: {
            ...answer.scaleQuestionAnswer,
            aspectAnswers: answer.scaleQuestionAnswer?.aspectAnswers?.map(
              (aspectAnswer) =>
                isNaN(+aspectAnswer.aspectId)
                  ? aspectAnswer
                  : { ...aspectAnswer, aspectId: '' }
            ),
          },
        }
      }
      return answer
    })

export const getAreAllQuestionsNotRequired = (
  questions: SurveyQuestion[]
): boolean =>
  questions.length === questions.filter((question) => !question.required).length

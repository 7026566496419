import React, { FC, useState } from 'react'
import {
  Avatar as MUIAvatar,
  Box,
  Button,
  Collapse,
  Hidden,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  AppointmentStatus,
  getPatientAppointment_getAppointment,
} from '../../../../../models/graphql'
import { CalendarAddIcon } from '../../../../../common/icons/CalendarAddIcon'
import { ArrowUpIcon } from '../../../../../common/icons/ArrowUpIcon'
import { useHistory } from 'react-router-dom'
import { useDownloadAppointmentIcs } from '../../../../../common/hooks/useDownloadAppointmentIcs'
import { DoctorInfo } from '../../../../../common/components/DoctorInfo/DoctorInfo'
import { DeclineAppointmentDialog } from './DeclineAppointmentDialog'

type TreatmentRowRecord = {
  title?: string
  content?: string
}
const TreatmentDetailsRow: FC<TreatmentRowRecord> = ({ title, content }) => (
  <Box ml={1} display="flex">
    <Box width={70} textAlign="right">
      <Typography variant="subtitle1">{title}:</Typography>
    </Box>
    <Box pl={0.5} textAlign="left">
      <Typography variant="subtitle1">{content}</Typography>
    </Box>
  </Box>
)
type Props = {
  appointment: getPatientAppointment_getAppointment
}
const useStyles = makeStyles((theme) => ({
  arrow: {
    fontSize: '1.1rem !important',
  },
  button: {
    '&:hover': {
      background: `${theme.palette.primary.main}11`,
    },
  },
  doctorButton: {
    padding: 0,
  },
}))
const AppointmentContent: React.FC<Props> = ({ appointment }) => {
  const [treatmentDetailOpen, setTreatmentDetailOpen] = useState(false)
  const [treatmentSchemaDetailOpen, setTreatmentSchemaDetailOpen] =
    useState(false)
  const [moreContactOpen, setMoreContactOpen] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const selectedLanguage = useSelectedLanguage()

  const { downloadIcs, loadingIcs } = useDownloadAppointmentIcs()
  const { treatment } = appointment

  const declineAppointmentDialogState = useState(false)

  const treatmentDescription = treatment.description
    ? treatment.description[selectedLanguage]
    : ''

  const treatmentSchemaDescription = appointment.treatmentSchema?.description
    ? appointment.treatmentSchema?.description[selectedLanguage]
    : ''

  const isBooked =
    appointment?.status === AppointmentStatus.BetmenBooked ||
    appointment?.status === AppointmentStatus.EesztBooked

  const moreContactContent = (
    <Box
      width={{ xs: '100%', md: 250 }}
      minWidth={250}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      my={{ xs: 2, md: 0 }}
    >
      <Box mt={1} display="flex">
        <Button
          variant="text"
          onClick={() => setMoreContactOpen((prevState) => !prevState)}
          endIcon={
            <ArrowUpIcon
              className={classes.arrow}
              color="primary"
              transform={moreContactOpen ? 'rotate(0)' : 'rotate(180)'}
            />
          }
          color="primary"
        >{` ${t('appointment:moreContact')}:`}</Button>
      </Box>
      <Collapse in={moreContactOpen}>
        <Box ml={2}>
          <DoctorInfo
            doctor={appointment.doctorProfile}
            hideAvatar
            linkToDoctor="/patient/doctors"
          />
        </Box>
      </Collapse>
    </Box>
  )
  return (
    <>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems="flex-start"
        justifyContent="flex-start"
        mt={3}
      >
        {/* Info column */}
        <Box
          width={{ xs: '100%', md: 250 }}
          minWidth={250}
          pr={2}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {/* Calendar add */}
          {isBooked && (
            <Button
              variant="text"
              onClick={() => appointment && downloadIcs(appointment.id)}
              disabled={!appointment || loadingIcs}
              startIcon={
                <MUIAvatar>
                  <CalendarAddIcon />
                </MUIAvatar>
              }
              sx={{ p: 0 }}
            >
              {t('appointment:saveDate')}
            </Button>
          )}
        </Box>

        <Hidden mdUp implementation="js">
          <Box display="flex" width="100%" my={2} alignItems="center">
            <DoctorInfo
              doctor={appointment.doctorProfile}
              linkToDoctor="/patient/doctors"
              hideTitle
              hideAssistants
              hideDetails
            />
          </Box>
        </Hidden>
        <Hidden mdUp>{moreContactContent}</Hidden>

        {/* Details column */}
        <Box
          pt={{ xs: 0.5, md: 0 }}
          flexGrow={1}
          pr={2}
          display="flex"
          flexDirection="column"
          alignSelf={{ xs: 'flex-start' }}
        >
          <Typography variant="body1">
            {t('appointment:detailsTitle')}:
          </Typography>
          <Typography variant="subtitle1">
            {appointment.info?.goodToKnow[selectedLanguage]}
          </Typography>
          <Typography variant="body1" mt={2}>
            {t('treatment:header.locationInfo')}:
          </Typography>
          <Typography variant="subtitle1">
            {appointment.room?.description?.[selectedLanguage] ?? '-'}
          </Typography>

          <Box mt={{ xs: 2, md: 1 }} mx={-1} display="flex">
            <Button
              onClick={() => setTreatmentDetailOpen((prevState) => !prevState)}
              variant="text"
              color="primary"
              endIcon={
                <ArrowUpIcon
                  className={classes.arrow}
                  color="primary"
                  transform={treatmentDetailOpen ? 'rotate(0)' : 'rotate(180)'}
                />
              }
            >
              {treatment.title[selectedLanguage]}
              {` ${t('appointment:moreDetails')}:`}
            </Button>
          </Box>
          <Collapse in={treatmentDetailOpen}>
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle1">
                {treatmentDescription}
              </Typography>
              <Box mt={2}>
                <Typography variant="body1">
                  {t('appointment:treatmentDetails')}:
                </Typography>
              </Box>
              <Box ml={1} display="flex" alignItems="center">
                <Box width={70} textAlign="right">
                  <Typography variant="subtitle1">
                    {t('professions:doctor')}:
                  </Typography>
                </Box>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => history.push('/patient/doctors')}
                >
                  <Typography variant="subtitle1">
                    {t('common:formattedNameFull', treatment.doctor)}
                  </Typography>
                </Button>
              </Box>
              <TreatmentDetailsRow
                title={t('common:beginning')}
                content={t('common:dateFormatted', {
                  date: treatment.firstAndLastDates?.firstAppointmentDate,
                })}
              />
              <TreatmentDetailsRow
                title={t('common:ending')}
                content={t('common:dateFormatted', {
                  date: treatment.firstAndLastDates?.lastAppointmentDate,
                })}
              />
            </Box>
          </Collapse>

          {appointment.treatmentSchema && (
            <>
              <Box mt={{ xs: 2, md: 1 }} mx={-1} display="flex">
                <Button
                  onClick={() =>
                    setTreatmentSchemaDetailOpen((prevState) => !prevState)
                  }
                  variant="text"
                  color="primary"
                  endIcon={
                    <ArrowUpIcon
                      className={classes.arrow}
                      color="primary"
                      transform={
                        treatmentSchemaDetailOpen ? 'rotate(0)' : 'rotate(180)'
                      }
                    />
                  }
                >
                  {appointment.treatmentSchema.title[selectedLanguage]}
                  {` ${t('appointment:schemaMoreDetails')}:`}
                </Button>
              </Box>
              <Collapse in={treatmentSchemaDetailOpen}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle1">
                    {treatmentSchemaDescription}
                  </Typography>
                </Box>
              </Collapse>
            </>
          )}
        </Box>

        {/* More contact column */}
        <Hidden mdDown>{moreContactContent}</Hidden>
      </Box>
      <DeclineAppointmentDialog
        doctor={appointment.doctorProfile}
        openState={declineAppointmentDialogState}
      />
    </>
  )
}

export default AppointmentContent

import { gql } from '@apollo/client'
import { FRAGMENT_NOTIFICATION_SETTINGS } from './fragments'

export const GET_NOTIFICATION_SETTINGS = gql`
  query getNotificationSettings {
    getNotificationSettings {
      ...notificationSettings
    }
  }
  ${FRAGMENT_NOTIFICATION_SETTINGS}
`

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation updateNotificationSettings(
    $newSettings: NotificationSettingsInput!
  ) {
    updateNotificationSettings(newSettings: $newSettings) {
      ...notificationSettings
    }
  }
  ${FRAGMENT_NOTIFICATION_SETTINGS}
`

import { ApolloError } from '@apollo/client'
import { Delete as DeleteIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  ConfirmDialog,
  ConfirmDialogValue,
} from '../../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  SurveyQuestionEnum,
  SurveyQuestionFileSize,
} from '../../../../../models/graphql'
import { useStoreActions } from '../../../../../store/store.hooks'
import { useSurveyQuestionCreate } from '../../hooks/useSurveyQuestionCreate'
import { useSurveyQuestionDelete } from '../../hooks/useSurveyQuestionDelete'
import { useSurveyQuestionUpdate } from '../../hooks/useSurveyQuestionUpdate'
import {
  getDefaultScaleData,
  getDefaultValues,
  getQuestionData,
  getValuesToSave,
} from '../../utils/question.util'
import { QuestionIcon } from '../Questions/QuestionIcon'
import { Question, QuestionForm } from '../Questions/questions.types'
import { EditQuestionByType } from './EditQuestionByType'

interface Props extends DialogProps {
  questionToEdit?: Question
  shouldCopyQuestion?: boolean
  refetchQuestions: () => void
  onClose: () => void
}

export const EditQuestionModal: React.FC<Props> = ({
  questionToEdit,
  shouldCopyQuestion = false,
  refetchQuestions,
  onClose,
  ...props
}) => {
  const { t } = useTranslation()

  const [confirmDelete, setConfirmDelete] = useState<ConfirmDialogValue>(false)
  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const formMethods = useForm<QuestionForm>({
    defaultValues: {
      title: questionToEdit?.title || '',
      description: questionToEdit?.description || '',
      type: questionToEdit?.type || Object.values(SurveyQuestionEnum)[0],
      data: questionToEdit?.data || {
        multipleChoice: false,
        scaleData: questionToEdit?.data?.scaleData || getDefaultScaleData(),
        fileUploadData: {
          maxItemsCount: 1,
          maxFileSize: SurveyQuestionFileSize.FiveMB,
          validFileTypes: [],
        },
      },
      choices: getDefaultValues(questionToEdit?.choices, {
        shouldAddDoubleEmptyField: true,
      }),
      aspects: getDefaultValues(questionToEdit?.aspects),
    },
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = formMethods

  const onCompleted = (successMessageKey: string) => {
    refetchQuestions()
    onClose()
    setToast({
      text: t(`notification:${successMessageKey}`),
      type: 'success',
    })
  }

  const onError = (error: ApolloError) => {
    setToast({
      text: t(error.message),
      type: 'error',
    })
  }

  const [createQuestion] = useSurveyQuestionCreate({
    onCompleted: () => onCompleted('successInsertQuestion'),
    onError,
  })

  const [updateQuestion] = useSurveyQuestionUpdate({
    onCompleted: () => onCompleted('successUpdateQuestion'),
    onError,
  })

  const [deleteQuestion] = useSurveyQuestionDelete({
    onCompleted: () => onCompleted('successRemoveQuestion'),
    onError,
  })

  const onSubmit = handleSubmit(async (formValues) => {
    const surveyQuestionData = {
      title: formValues.title,
      description: formValues.description,
      type: formValues.type,
      data: getQuestionData(formValues),
      mcSurveyQuestionChoices: getValuesToSave(formValues.choices),
      sclSurveyQuestionAspects: getValuesToSave(formValues.aspects),
    }

    if (questionToEdit && !shouldCopyQuestion) {
      await updateQuestion({
        variables: {
          surveyQuestionData: { id: questionToEdit.id, ...surveyQuestionData },
        },
      })
    } else {
      await createQuestion({
        variables: {
          surveyQuestionData,
        },
      })
    }
  })

  return (
    <FormProvider {...formMethods}>
      <Dialog fullWidth {...props}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {t(`survey:questions.${questionToEdit ? 'editQuestion' : 'add'}`)}
          {!!questionToEdit && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => setConfirmDelete(true)}
              startIcon={<DeleteIcon />}
            >
              {t('common:delete')}
            </Button>
          )}
        </DialogTitle>
        <DialogContent>
          <Stack>
            <Typography variant="body2" my={1}>
              {t('survey:question.chooseType')}
            </Typography>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={!!questionToEdit}
                  onChange={(e) =>
                    field.onChange(e.target.value as SurveyQuestionEnum)
                  }
                >
                  {Object.keys(SurveyQuestionEnum).map(
                    (type: string, i: number) => (
                      <MenuItem key={i} value={type}>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <QuestionIcon
                            questionType={type as SurveyQuestionEnum}
                            fontSize="large"
                          />
                          {t(`survey:question.type.${type}`)}
                        </Stack>
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
          </Stack>
          <Box mt={2}>
            <EditQuestionByType
              questionType={questionToEdit?.type || watch('type')}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ pb: 2, px: 3 }}>
          <Button variant="outlined" onClick={onClose}>
            {t('common:close')}
          </Button>
          <Button disabled={shouldCopyQuestion && !isDirty} onClick={onSubmit}>
            {t('common:save')}
          </Button>
        </DialogActions>
      </Dialog>

      {questionToEdit && (
        <ConfirmDialog
          isAlertingDialog
          valueState={[confirmDelete, setConfirmDelete]}
          text={t(`survey:question.confirmQuestionRemoval`)}
          onAccept={() => {
            deleteQuestion({
              variables: { surveyQuestionId: questionToEdit.id },
            })
          }}
        />
      )}
    </FormProvider>
  )
}

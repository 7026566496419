import {
  BnoCodeChore,
  listAllProfessions_listAllProfessions,
  MultiLangTextInput,
} from '../../../../../models/graphql'

export interface TreatmentSchemaDetailsFormValues {
  title: MultiLangTextInput | null
  description: MultiLangTextInput | null
  url: string
  active: string
  bnoCodes: Omit<BnoCodeChore, '__typename'>[]
  snowmedCode: string
  professions: Array<
    Omit<listAllProfessions_listAllProfessions, '__typename' | 'type'> & {
      type?: string | null
    }
  >
  optimalDuration: string | ''
}

export enum MultiLangDetailType {
  Title = 'title',
  Description = 'description',
}

export type MultiLangFormValues = Record<
  MultiLangDetailType,
  MultiLangTextInput | null
>

export enum ReferenceKey {
  Name = 'name',
  Url = 'url',
}

export type Reference = Record<ReferenceKey, string>

export type ReferenceFormValues = { reference: Reference[] }

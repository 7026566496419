import React, { FC } from 'react'
import {
  Button,
  TextField,
  Typography,
  Box,
  InputAdornment,
  Zoom,
  useTheme,
  Link,
} from '@mui/material'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Loading } from '../../../common/components/Loading'
import { useLogin } from '../hooks/useLogin'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { ProfileIcon } from '../../../common/icons/ProfileIcon'
import { LockIcon } from '../../../common/icons/LockIcon'
import { ValidEmailPattern } from '../../../utils/isValidEmail'
import { store } from '../../../store'
import { useLogout } from '../hooks/useLogout'
import {
  getLoginErrorTranslateKey,
  storeDataAfterLogin,
} from '../utils/login.utils'

export interface LoginForm {
  email: string
  password: string
}

const LoginFields: FC = () => {
  const history = useHistory()
  const logout = useLogout()
  const { t } = useTranslation()
  const theme = useTheme()
  const [login, { error }] = useLogin()
  const loginError = getLoginErrorTranslateKey(error?.message)

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = handleSubmit(async (values) => {
    await login({
      variables: {
        email: values?.email,
        password: values?.password,
      },
      onCompleted: ({ login: { jwtToken, isFirstLogin } }) => {
        if (!jwtToken) {
          history.push({
            pathname: '/login/verify',
            search: `?email=${values?.email}&isFirstLogin=${isFirstLogin}`,
          })
          return
        }

        storeDataAfterLogin(jwtToken, isFirstLogin)
        history.push('/')
      },
      onError: () => {
        store.getActions().toast.reset()
        logout()
      },
    })
  })

  if (isSubmitting) {
    return <Loading />
  }

  return (
    <>
      <Box mt={3}>
        <Typography component="h1" variant="h3">
          {t('auth:login')}
        </Typography>
      </Box>
      <Box mt={1} flexGrow={1} display="flex" alignItems="center">
        <Typography component="p" variant="body1">
          {t('auth:loginText')}
        </Typography>
      </Box>
      {/* 100% width: Fix IE 11 issue. */}
      <Box width="100%" mt={3}>
        <form onSubmit={onSubmit} noValidate>
          <Controller
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                value={value}
                onChange={(e) => {
                  onChange(e.target.value.toLowerCase())
                }}
                onBlur={onBlur}
                inputRef={ref}
                variant="outlined"
                margin="normal"
                error={!!errors.email}
                helperText={
                  errors.email?.message ? t(errors.email?.message) : undefined
                }
                required
                fullWidth
                label={t('auth:email')}
                autoComplete="off"
                autoFocus
                data-error={errors.email?.message}
                data-cy="Login-TextField-email"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <ProfileIcon
                        width={16}
                        height={18}
                        sx={{ color: `${theme.palette.common.black}66` }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            name="email"
            control={control}
            rules={{
              pattern: {
                value: ValidEmailPattern,
                message: 'messages:warnings.notEmail',
              },
              required: 'messages:warnings.emptyField',
            }}
          />
          <Controller
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                variant="outlined"
                margin="normal"
                fullWidth
                label={t('auth:password')}
                type="password"
                error={!!errors.password}
                helperText={
                  errors.password?.message
                    ? t(errors.password?.message)
                    : undefined
                }
                required
                autoComplete="current-password"
                data-error={errors.password?.message}
                data-cy="Login-TextField-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <LockIcon
                        width={14}
                        height={18}
                        sx={{ color: `${theme.palette.common.black}66` }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            name="password"
            control={control}
            rules={{
              required: 'messages:warnings.emptyField',
            }}
          />
          <Zoom in={!!error}>
            <Box mt={3}>
              {error && (
                <Typography
                  variant="body2"
                  color="error"
                  data-error={loginError}
                >
                  {t(loginError)}
                </Typography>
              )}
            </Box>
          </Zoom>
          <Box mt={3}>
            <Button
              data-cy="Login-Button-submit"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
            >
              {t('auth:login')}
            </Button>
          </Box>
        </form>
        <Box my={3}>
          <Link component={RouterLink} to="/password-reset" underline="hover">
            <Typography variant="subtitle2" color="textSecondary">
              {t('auth:passwordReminder')}
            </Typography>
          </Link>
        </Box>
      </Box>
    </>
  )
}

export { LoginFields }

import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import { finish, finishVariables } from '../../../../models/graphql'
import { FINISH_SURVEY } from '../operations/patientSurveyOperations'

type Options = MutationHookOptions<finish, finishVariables>

type Return = MutationTuple<finish, finishVariables>

export const usePatientSurveyFinish = (options: Options = {}): Return => {
  return useMutation<finish, finishVariables>(FINISH_SURVEY, options)
}

import { TableCell } from '@mui/material'
import React, { PropsWithChildren, ReactElement } from 'react'
import { DataTableColDef, DataTableRowData } from './interfaces'
import { useCellData } from './useCellData'

type Props<T> = {
  column: DataTableColDef<T>
  row: T
}

export const DataTableCell = <T extends DataTableRowData>(
  props: PropsWithChildren<Props<T>>
): ReactElement => {
  const { column, row } = props
  const { value, formattedValue } = useCellData(column, row)
  const { renderCell = (p) => p.formattedValue } = column

  return (
    <TableCell align={column.align} style={column.cellStyle}>
      {renderCell({
        field: column.field,
        row,
        value,
        formattedValue,
      })}
    </TableCell>
  )
}

import { TFunction } from 'i18next'
import { AppointmentDependencyTypes } from '../models/graphql'

export const appointmentDistanceFormatter = (
  distance: string,
  t: TFunction,
  type?: AppointmentDependencyTypes
): string => {
  let typeText = ''
  switch (type) {
    case AppointmentDependencyTypes.maxDistance:
      typeText = t(`protocol:dependency.maxConstraint`) + ' '
      break
    case AppointmentDependencyTypes.minDistance:
    case AppointmentDependencyTypes.notSpecified:
    default:
      typeText = t(`protocol:dependency.minConstraint`) + ' '
  }
  const value = distance.slice(0, distance.length - 1)
  const interval = t(`common:timingInterval.${distance.slice(-1)}`)
  return `${typeText}${value} ${interval}`
}

import { useMutation, useQuery } from '@apollo/client'
import { Button, Paper, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { BoxWithLabel } from '../../../common/components/BoxWithLabel'
import { Loading } from '../../../common/components/Loading'
import { useEesztTokenState } from '../../../common/hooks/eeszt/useEesztTokenState'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import {
  EesztTorSyncState,
  synchronizeEesztTorData,
} from '../../../models/graphql'
import {
  EESZT_TOR_SYNC_STATE,
  SYNCHRONIZE_EESZT_TOR_DATA,
} from '../../../operations/eesztOperation'

interface SyncData {
  synchronizationInProgress: boolean
  lastSyncSuccessful: boolean | null
  lastSyncEndDate: Date
}

const TorSync: React.FC = () => {
  const { t } = useTranslation()
  const isEesztTokenValid = useEesztTokenState()
  const [pollingStarted, setPollingStarted] = useState(false)

  const { loading, data, startPolling, stopPolling, refetch } =
    useQuery<EesztTorSyncState>(EESZT_TOR_SYNC_STATE)
  const [syncEesztTorData] = useMutation<synchronizeEesztTorData>(
    SYNCHRONIZE_EESZT_TOR_DATA,
    {
      onCompleted: () => refetch(),
    }
  )

  const syncData: SyncData | undefined = useMemo(() => {
    if (data?.eesztTorSyncState.synchronizationInProgress && !pollingStarted) {
      startPolling(10 * 1000) // provide in milliseconds
      setPollingStarted(true)
    }
    if (
      data &&
      !data.eesztTorSyncState.synchronizationInProgress &&
      pollingStarted
    ) {
      stopPolling()
      setPollingStarted(false)
    }
    return data
      ? {
          synchronizationInProgress:
            data.eesztTorSyncState.synchronizationInProgress,
          lastSyncSuccessful: data.eesztTorSyncState.lastSyncSuccessful,
          lastSyncEndDate: data.eesztTorSyncState.lastSyncEndDate,
        }
      : undefined
  }, [data, pollingStarted, startPolling, stopPolling])

  const prevSyncNotFound = useMemo(
    () => !syncData?.synchronizationInProgress && !syncData?.lastSyncEndDate,
    [syncData]
  )

  const onSyncClick = () => {
    syncEesztTorData()
  }

  return (
    <Paper elevation={3}>
      <Typography padding={2}>
        {t('eeszt:torSyncTitle')}
        {!isEesztTokenValid ? ` (${t('eeszt:torLoginMessage')})` : ''}
      </Typography>
      <Stack
        p={3}
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={'space-around'}
      >
        <Stack p={3}>
          <Button
            disabled={
              data?.eesztTorSyncState.synchronizationInProgress ||
              !isEesztTokenValid
            }
            onClick={() => onSyncClick()}
            variant="contained"
          >
            {t('eeszt:torSyncButtonText')}
          </Button>
        </Stack>
        <Stack>
          {loading ? (
            <Loading />
          ) : (
            <>
              {prevSyncNotFound ? (
                <Typography>{t('eeszt:torSyncNotFound')}</Typography>
              ) : (
                <>
                  <BoxWithLabel label={t('eeszt:torLastSyncDate')}>
                    <Typography>
                      {data?.eesztTorSyncState.lastSyncEndDate
                        ? dayjs(data?.eesztTorSyncState.lastSyncEndDate).format(
                            'YYYY.MM.DD HH:mm'
                          )
                        : '-'}
                    </Typography>
                  </BoxWithLabel>
                  <BoxWithLabel label={t('eeszt:torSyncStatus')}>
                    <Typography>
                      {data?.eesztTorSyncState.lastSyncSuccessful === true
                        ? t('common:success')
                        : t('common:error')}
                    </Typography>
                  </BoxWithLabel>
                </>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Paper>
  )
}

export { TorSync }

import { QueryHookOptions, QueryTuple, useLazyQuery } from '@apollo/client'
import {
  findAssistantProfile,
  findAssistantProfileVariables,
} from '../../../../models/graphql'
import { FIND_ASSISTANT_PROFILE } from '../operations/doctorOwnAssistantsOperations'

type Options = QueryHookOptions<
  findAssistantProfile,
  findAssistantProfileVariables
>

type Return = QueryTuple<findAssistantProfile, findAssistantProfileVariables>

export const useFindAssistantProfile = (options: Options = {}): Return => {
  return useLazyQuery<findAssistantProfile, findAssistantProfileVariables>(
    FIND_ASSISTANT_PROFILE,
    options
  )
}

import { useMutation } from '@apollo/client'
import { REMOVE_SCHEDULES } from '../operations/treatmentSchemaOperations'
import {
  getTreatmentSchema_getTreatmentSchema,
  removeSchedules as RemoveSchedulesData,
  removeSchedulesVariables as RemoveSchedulesVariables,
} from '../../../../models/graphql'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'

type Options = MutationHookOptions<
  RemoveSchedulesData,
  RemoveSchedulesVariables
>

type Return = MutationTuple<RemoveSchedulesData, RemoveSchedulesVariables>

export const useSchedulesRemove = (
  treatmentSchema: getTreatmentSchema_getTreatmentSchema,
  options: Options = {}
): Return => {
  return useMutation<RemoveSchedulesData, RemoveSchedulesVariables>(
    REMOVE_SCHEDULES,
    {
      refetchQueries: ['getTreatmentSchema'],
      ...options,
    }
  )
}

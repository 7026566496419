import React from 'react'
import { Box } from '@mui/material'

type Props = {
  width?: {
    xs?: number
    sm?: number
    md?: number
    lg?: number
    xl?: number
  }
}

const Logo: React.FC<Props> = ({ width }) => {
  const defaultWidth = 188

  return (
    <Box
      component="img"
      width={width || defaultWidth}
      src="/images/dr-betmen-logo-light.png"
      alt="BetMen Logo"
    />
  )
}

export { Logo }

import {
  LocalSchedule,
  CUSTOM_DAY_ERROR,
  LocalSurvey,
} from '../treatmentSchemaSchedule.types'

export interface Day {
  dayFromStart: number
  schedules: Array<LocalSchedule | LocalSurvey>
  isOptimalDuration: boolean
  isDependency: boolean
  error?: CUSTOM_DAY_ERROR
}

export type Week = Day[]

export enum EventType {
  event = 'event',
  dependency = 'dependency',
  error = 'error',
}

export type Color = Record<EventType, string> & {
  optimalDuration: string
  tooltip: string
}

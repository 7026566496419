import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { FormControl, MenuItem, Select, Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'

enum DownloadType {
  csv = 'csv',
  xlsx = 'xlsx',
}

interface Props {
  csvInBase64: string
  xlsxInBase64: string
}

export const SurveyResultDownloadSelector: React.FC<Props> = ({
  csvInBase64,
  xlsxInBase64,
}) => {
  const { t } = useTranslation()

  // this function can be used for csv and xlsx download,
  // we will need a different function for pdfs
  const onDownload = (type: DownloadType) => {
    const downloadLink = document.createElement('a')
    const fileName = `survey_result_export.${type}`

    downloadLink.href = 'data:application/octet-stream;base64,' + xlsxInBase64

    switch (type) {
      case DownloadType.csv: {
        downloadLink.href =
          'data:application/octet-stream;base64,' + csvInBase64
        break
      }
      case DownloadType.xlsx: {
        downloadLink.href =
          'data:application/octet-stream;base64,' + xlsxInBase64
        break
      }
      default: {
        break
      }
    }

    downloadLink.download = fileName
    downloadLink.click()
  }
  return (
    <FormControl fullWidth>
      <Select
        value="download"
        onChange={(e) => onDownload(e.target.value as DownloadType)}
        size="small"
      >
        <MenuItem sx={{ display: 'none' }} value="download">
          {t('survey:result.downloadAnswersTitle')}
        </MenuItem>
        <MenuItem value={DownloadType.csv}>
          <Stack direction="row" gap={1} alignItems="center">
            <FileDownloadIcon /> {t('survey:result.downloadAnswersCsv')}
          </Stack>
        </MenuItem>
        <MenuItem value={DownloadType.xlsx}>
          <Stack direction="row" gap={1} alignItems="center">
            <FileDownloadIcon /> {t('survey:result.downloadAnswersXlsx')}
          </Stack>
        </MenuItem>
      </Select>
    </FormControl>
  )
}

import { Stack, Typography } from '@mui/material'
import React from 'react'
import { ANSWER_TIME_FOR_QUESTION_IN_MIN } from '../../utils/survey.util'
import TimerIcon from '@mui/icons-material/Timer'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'

interface Props {
  numberOfQuestions: number
}

export const SurveyFillTime: React.FC<Props> = ({ numberOfQuestions }) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" gap={1}>
      <TimerIcon fontSize="large" />
      <Typography variant="body2">
        {t('survey:editor.time', {
          time: numberOfQuestions * ANSWER_TIME_FOR_QUESTION_IN_MIN,
        })}
      </Typography>
    </Stack>
  )
}

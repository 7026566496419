import { ApolloQueryResult, useQuery } from '@apollo/client'

import {
  getTreatment,
  getTreatmentVariables,
  getTreatment_getTreatment,
} from '../../../../models/graphql'
import { GET_TREATMENT } from '../../../../operations/treatmentOperations'
import { QueryHookOptions } from '@apollo/client/react/types/types'

type Options = QueryHookOptions<getTreatment, getTreatmentVariables>

type Return = {
  loading: boolean
  treatment: getTreatment_getTreatment | undefined
  refetch: (
    variables?: Partial<getTreatmentVariables> | undefined
  ) => Promise<ApolloQueryResult<getTreatment>>
}

export const useDoctorPatientTreatmentData = (
  options: Options = {}
): Return => {
  const { data, loading, refetch } = useQuery<
    getTreatment,
    getTreatmentVariables
  >(GET_TREATMENT, {
    ...options,
    fetchPolicy: 'network-only',
  })

  const treatment = data?.getTreatment

  return {
    treatment,
    loading,
    refetch,
  }
}

import { useMutation } from '@apollo/client'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  PaperProps,
} from '@mui/material'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { SaveButton } from '../../../../common/components/SaveButton'
import { DoctorSelector } from '../../../../common/components/selectors/DoctorSelector'
import { InstitutionSelector } from '../../../../common/components/selectors/InstitutionSelector'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import {
  getTreatmentSchema_getTreatmentSchema_institutions,
  listAllDoctors_listAllDoctors,
  shareTreatmentSchema,
  shareTreatmentSchemaVariables,
  treatmentOptionsChore,
} from '../../../../models/graphql'
import { SHARE_TREATMENT_SCHEMA } from '../operations/treatmentSchemaOperations'

type FormValues = {
  sharedWith: listAllDoctors_listAllDoctors[]
  institutions: getTreatmentSchema_getTreatmentSchema_institutions[]
}

interface ShareProtocolModal {
  selectedProtocol: treatmentOptionsChore
  isOpen: boolean
  toggleShareProtocolModal: React.Dispatch<React.SetStateAction<boolean>>
  onComplete?: () => void
  doctors: listAllDoctors_listAllDoctors[]
}

const ShareProtocolModal: React.FC<ShareProtocolModal> = ({
  isOpen,
  toggleShareProtocolModal,
  selectedProtocol,
  onComplete,
  doctors,
}) => {
  const { t } = useTranslation()

  const methods = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      sharedWith: [],
      institutions: [],
    },
  })

  const { handleSubmit, reset } = methods

  useEffect(() => {
    const sharedWith = doctors.filter(
      (doctor) =>
        doctor.user &&
        selectedProtocol?.sharedWith
          ?.map((user) => user?.id)
          ?.includes(doctor.user.id)
    )
    reset({ sharedWith, institutions: selectedProtocol.institutions })
  }, [selectedProtocol, reset, doctors])

  const closeModal = () => {
    toggleShareProtocolModal(false)
    onComplete && onComplete()
    reset({
      sharedWith: [],
      institutions: [],
    })
  }

  const [shareTreatment] = useMutation<
    shareTreatmentSchema,
    shareTreatmentSchemaVariables
  >(SHARE_TREATMENT_SCHEMA, {
    onCompleted: () => {
      closeModal()
    },
    refetchQueries: ['getTreatmentSchemas'],
  })

  const onSubmit = handleSubmit(({ sharedWith, institutions }) => {
    return shareTreatment({
      variables: {
        treatmentData: {
          schemaId: selectedProtocol.id,
          sharedWithUserIds: sharedWith.map((doctor) => doctor.userId),
          institutionIds: institutions.map(({ id }) => id),
        },
      },
    })
  })

  return (
    <FormProvider {...methods}>
      <Dialog
        open={isOpen}
        onClose={closeModal}
        PaperProps={{ component: 'form' } as Partial<PaperProps<'div'>>}
      >
        <DialogTitle>{t('treatment:protocol.shareTitle')}</DialogTitle>

        <DialogContent sx={{ width: { sm: 400 } }}>
          <DoctorSelector
            label={t('treatment:protocol.shareWithDoctors')}
            name="sharedWith"
            disabled={selectedProtocol.isGlobal ?? false}
            excludeOwnProfile={true}
          />

          <InstitutionSelector
            label={t('treatment:protocol.shareWithInstitutions')}
            name="institutions"
            disabled={selectedProtocol.isGlobal ?? false}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={closeModal}>
            {t('common:cancel')}
          </Button>
          <SaveButton
            size="medium"
            text={t('common:save')}
            onClick={onSubmit}
          />
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}

export { ShareProtocolModal }

import React from 'react'
import { InputAdornment, Link } from '@mui/material'
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  CloudDone as CloudDoneIcon,
  Edit as EditIcon,
} from '@mui/icons-material'

enum InputStatusAdornmentTypes {
  Unmodified = 'Unmodified',
  Modified = 'Modified',
  Saved = 'Saved',
  Error = 'Error',
}

type InputStatusAdornmentProps = {
  error?: boolean
  modified?: boolean
  saved?: boolean
  required?: boolean
  getStatus?: () => InputStatusAdornmentTypes
  onErrorClick?: () => void
}
const InputStatusAdornment: React.FC<InputStatusAdornmentProps> = ({
  error,
  modified,
  saved,
  required,
  getStatus,
  onErrorClick,
}) => {
  const status = getStatus
    ? getStatus()
    : error
    ? InputStatusAdornmentTypes.Error
    : modified
    ? InputStatusAdornmentTypes.Modified
    : saved
    ? InputStatusAdornmentTypes.Saved
    : InputStatusAdornmentTypes.Unmodified

  return (
    <InputAdornment position="end">
      {status === InputStatusAdornmentTypes.Error ? (
        onErrorClick ? (
          <Link component="button" onClick={onErrorClick}>
            <ClearIcon color="error" fontSize="inherit" />
          </Link>
        ) : (
          <ClearIcon color="error" fontSize="inherit" />
        )
      ) : status === InputStatusAdornmentTypes.Modified ? (
        required ? (
          <CheckIcon color="primary" fontSize="inherit" />
        ) : (
          <EditIcon color="primary" fontSize="inherit" />
        )
      ) : (
        status === InputStatusAdornmentTypes.Saved && (
          <CloudDoneIcon color="primary" fontSize="inherit" />
        )
      )}
    </InputAdornment>
  )
}

export { InputStatusAdornment }

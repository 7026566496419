import { Autocomplete, TextField } from '@mui/material'
import React, { FC } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { useTranslation } from '../../../hooks/helper/useTranslation'
import { SurveyCategoryEnum } from '../../../../models/graphql'
import { BoxWithLabel } from '../../BoxWithLabel'
import { SxProps, Theme } from '@mui/system'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: ControllerRenderProps<any, 'categories'>
  hasError?: boolean
  label?: string
  disabled?: boolean
  helperText?: string
  sx?: SxProps<Theme> | undefined
}

export const SurveyCategoryMultiSelector: FC<Props> = ({
  field: { name, value, onBlur, onChange },
  hasError,
  label,
  disabled,
  helperText,
  sx,
}) => {
  const { t } = useTranslation()

  return (
    <BoxWithLabel label={label}>
      <Autocomplete
        disabled={disabled}
        multiple
        options={Object.keys(SurveyCategoryEnum)}
        disableClearable
        filterSelectedOptions
        getOptionLabel={(category) =>
          t(`survey:survey.type.${category.toLowerCase()}`)
        }
        value={value || []}
        onChange={(_, value) => onChange([...value])}
        onBlur={onBlur}
        data-cy="SurveyCategoryMultiSelector-Autocomplete"
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={disabled}
            placeholder={t('survey:surveys.category')}
            error={hasError}
            helperText={helperText}
            variant="outlined"
            name={name}
            sx={sx}
          />
        )}
      />
    </BoxWithLabel>
  )
}

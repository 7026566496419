import { Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BetmenPage } from '../../../common/components/BetmenPage'
import { BetmenList } from '../../../common/components/BetmenList/BetmenList'
import { BetmenListActions } from '../../../common/components/BetmenList/BetmenListActions'
import { BetmenListBody } from '../../../common/components/BetmenList/BetmenListBody'
import { BetmenListFilter } from '../../../common/components/BetmenList/BetmenListFilter'
import { BetmenListHeader } from '../../../common/components/BetmenList/BetmenListHeader'
import { BetmenListHeaderCell } from '../../../common/components/BetmenList/BetmenListHeaderCell'
import { BetmenListItemCard } from '../../../common/components/BetmenList/BetmenListItemCard'
import { BetmenListItemCardCell } from '../../../common/components/BetmenList/BetmenListItemCardCell'
import { useMe } from '../../../common/hooks/useMe'
import { useSelectedLanguage } from '../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useTreatmentSchemaCreate } from './hooks/useTreatmentSchemaCreate'
import { useTreatmentSchemas } from './hooks/useTreatmentSchemas'
import {
  getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas as TreatmentSchema,
  TreatmentSchemaFilter,
} from '../../../models/graphql'
import { ChipGroup } from '../../../common/components/ChipGroup/ChipGroup'
import { AddButton } from '../../../common/components/AddButton/AddButton'
import { Status, StatusEnum } from '../../../common/components/Status/Status'
import { TreatmentSchemaActionsMenu } from './components/TreatmentSchemaActions/TreatmentSchemaActionsMenu'
import { ProfessionFilter } from '../../../common/components/Filters/ProfessionFilter'
import { BooleanFilter } from '../../../common/components/Filters/BooleanFilter'
import { PAGE_SIZE } from '../../../config'

const TreatmentSchemas: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const selectedLanguage = useSelectedLanguage()
  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })
  const userId = me?.id
  const [filterInput, setFilterInput] = useState<TreatmentSchemaFilter>({})

  const {
    loading,
    fetchingMore,
    fetchMoreSchemas,
    refetch,
    treatmentSchemas,
    hasMoreSchemas,
  } = useTreatmentSchemas({
    variables: {
      treatmentSchemaFilter: filterInput,
      paginationInput: {
        offset: 0,
        limit: PAGE_SIZE,
      },
    },
  })

  useEffect(() => {
    refetch()
  }, [refetch, filterInput])

  const [createEmptyTreatmentSchema] = useTreatmentSchemaCreate()

  const navigateToDetailsPage = (treatment: TreatmentSchema) =>
    history.push(
      `/${me?.userType.toLowerCase()}/treatment-option/${treatment.id}`
    )

  const onTreatmentSchemaEnter = useCallback(() => {
    const offset = treatmentSchemas.length

    fetchMoreSchemas({
      variables: {
        treatmentSchemaFilter: filterInput,
        paginationInput: {
          offset,
          limit: PAGE_SIZE,
        },
      },
    })
  }, [treatmentSchemas, fetchMoreSchemas, filterInput])

  const Filters: JSX.Element = (
    <>
      <BooleanFilter
        filterInput={!!filterInput.isOwn}
        setFilterInput={(newFilter: boolean) =>
          setFilterInput((prevInput) => ({ ...prevInput, isOwn: newFilter }))
        }
        label={t('survey:question.filter.own')}
      />
      <BooleanFilter
        filterInput={!!filterInput.active}
        setFilterInput={(newFilter: boolean) =>
          setFilterInput((prevInput) => ({ ...prevInput, active: newFilter }))
        }
        label={t('survey:survey.filter.active')}
      />
      <ProfessionFilter filterState={[filterInput, setFilterInput]} />
    </>
  )

  return (
    <BetmenPage
      title={t('treatment:doctor.templates')}
      withoutPadding
      headerRightContent={
        <AddButton
          buttonText={t('treatment:newSchema')}
          handleAdd={() => createEmptyTreatmentSchema()}
        />
      }
    >
      <BetmenList
        dataSource={treatmentSchemas}
        loading={loading && !fetchingMore}
        fetchMore={onTreatmentSchemaEnter}
        fetchMoreLoading={fetchingMore}
        hasMoreItem={hasMoreSchemas}
        gridColumnSizes={[3, 5, 2, 1, 1]}
        filters={
          <BetmenListFilter
            searchPlaceholder={t('treatment:searchTreatment')}
            searchTerm={filterInput.searchTerm || ''}
            handleSearch={(searchTerm) =>
              setFilterInput((prevInput) => ({
                ...prevInput,
                searchTerm,
              }))
            }
          >
            {Filters}
          </BetmenListFilter>
        }
        header={
          <BetmenListHeader>
            <BetmenListHeaderCell>
              {t('treatment:header.name')}
            </BetmenListHeaderCell>
            <BetmenListHeaderCell>
              {t('treatment:protocol.profession')}
            </BetmenListHeaderCell>
            <BetmenListHeaderCell>
              {t('treatment:protocol.owner')}
            </BetmenListHeaderCell>
            <BetmenListHeaderCell>{t('common:status')}</BetmenListHeaderCell>
          </BetmenListHeader>
        }
      >
        <BetmenListBody>
          {treatmentSchemas.map((treatment) => (
            <BetmenListItemCard
              click={() => navigateToDetailsPage(treatment)}
              key={'wp+' + treatment.id}
            >
              <BetmenListItemCardCell>
                <Typography variant="body2">
                  {treatment.title[selectedLanguage]}
                </Typography>
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                <ChipGroup
                  values={treatment.professions.map((profession) =>
                    t(`professions:${profession.name}`)
                  )}
                  limitTags={2}
                />
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                {treatment.isGlobal
                  ? t('common:global')
                  : treatment.user.id === userId
                  ? t('common:own')
                  : t(
                      'common:formattedNameFull',
                      treatment.user?.doctorProfile ?? undefined
                    )}
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                <Status
                  status={
                    treatment.disabled ? StatusEnum.Inactive : StatusEnum.Active
                  }
                />
              </BetmenListItemCardCell>
              <BetmenListActions
                more={
                  <TreatmentSchemaActionsMenu
                    treatmentSchema={treatment}
                    refetchTreatments={refetch}
                  />
                }
              />
            </BetmenListItemCard>
          ))}
        </BetmenListBody>
      </BetmenList>
    </BetmenPage>
  )
}

export { TreatmentSchemas }

import { Box, Typography } from '@mui/material'
import React, { PropsWithChildren, ReactElement } from 'react'
import { DataTableColDef, DataTableRowData } from './interfaces'
import { useCellData } from './useCellData'

type Props<T> = {
  column: DataTableColDef<T>
  row: T
}

export const DataTableCellDetails = <T extends DataTableRowData>(
  props: PropsWithChildren<Props<T>>
): ReactElement => {
  const { column, row } = props
  const { value, formattedValue } = useCellData(column, row)
  const { renderCell } = column

  return (
    <Box my={1} display="flex" alignContent="center" flexDirection="column">
      <Typography variant="body2">{column.title}: </Typography>
      {!renderCell ? (
        <Typography variant="body2">{formattedValue}</Typography>
      ) : (
        renderCell({
          field: column.field,
          row,
          value,
          formattedValue,
        })
      )}
    </Box>
  )
}

import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  DoNotDisturbAlt as DoNotDisturbAltIcon,
  FiberManualRecord as FiberManualRecordIcon,
} from '@mui/icons-material'
import { Box, Typography, useTheme } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { useUserType } from '../../hooks/useUserType'
import {
  AppointmentStatus as AppointmentStatusModel,
  UserType,
} from '../../../models/graphql'

type Props = {
  status: string
}
export const AppointmentStatusComponent: FC<Props> = ({ status }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const userType = useUserType()

  const statusMap: {
    [key: string]: { icon: React.ReactNode; color: string }
  } = {
    [AppointmentStatusModel.Proposed]: {
      icon: <FiberManualRecordIcon />,
      color:
        userType === UserType.Doctor
          ? theme.palette.error.main
          : theme.palette.text.secondary,
    },
    [AppointmentStatusModel.BetmenBooked]: {
      icon: <CheckCircleIcon />,
      color: theme.palette.success.dark,
    },
    [AppointmentStatusModel.Expired]: {
      icon: <DoNotDisturbAltIcon />,
      color: theme.palette.primary.main,
    },
    [AppointmentStatusModel.Happened]: {
      icon: <CheckCircleIcon />,
      color: theme.palette.success.dark,
    },
    [AppointmentStatusModel.Missed]: {
      icon: <CancelIcon />,
      color: theme.palette.error.main,
    },
  }

  const currentStatus = statusMap[status]

  return (
    <Box
      display="flex"
      data-cy={`AppoindmentStatus-Box-${status}`}
      alignItems="center"
    >
      <Box color={currentStatus.color} display="flex" alignItems="center">
        {currentStatus.icon}
        <Typography variant="subtitle2" pl={{ xs: 0, md: 0.5 }}>
          {t(`appointment:status.${status}`)}
        </Typography>
      </Box>
    </Box>
  )
}

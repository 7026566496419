import { QueryResult, useQuery } from '@apollo/client'
import { getSurveyCountByUserIdAndNotFinished } from '../../../../models/graphql'
import { GET_SURVEY_COUNT_BY_USER_ID_AND_NOT_FINISHED } from '../operations/patientSurveyOperations'

type Return = QueryResult<getSurveyCountByUserIdAndNotFinished>

export const usePatientSurveyCount = (): Return => {
  return useQuery<getSurveyCountByUserIdAndNotFinished>(
    GET_SURVEY_COUNT_BY_USER_ID_AND_NOT_FINISHED
  )
}

import AddBoxIcon from '@mui/icons-material/AddBox'
import { Button } from '@mui/material'
import { isFunction } from 'lodash'
import React from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'

interface Props {
  handleAdd?: () => void
  buttonText?: string
}

export const AddButton: React.FC<Props> = ({ buttonText, handleAdd }) => {
  const { t } = useTranslation()

  if (isFunction(handleAdd)) {
    return (
      <Button
        data-cy={'BetmenListFilter-Button-Add'}
        startIcon={<AddBoxIcon />}
        color="primary"
        onClick={handleAdd}
      >
        {buttonText || t('common:add')}
      </Button>
    )
  }
  return null
}

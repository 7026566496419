import dayjs, { UnitType } from 'dayjs'

type ISOUnitType = UnitType | 'isoWeek'

interface CompareDatesType {
  isBefore?: boolean
  isAfter?: boolean
  isBeforeOrSame?: boolean
  isAfterOrSame?: boolean
  isSame?: boolean
}

export const compareDates = (
  date: dayjs.ConfigType,
  dateToCompare: dayjs.ConfigType,
  typeOfCompare: CompareDatesType,
  unit?: ISOUnitType | undefined
): boolean => {
  if (typeOfCompare.isBefore) {
    return dayjs(date).isBefore(dateToCompare, unit)
  }
  if (typeOfCompare.isAfter) {
    return dayjs(date).isAfter(dateToCompare, unit)
  }
  if (typeOfCompare.isBeforeOrSame) {
    return (
      dayjs(date).isBefore(dateToCompare, unit) ||
      dayjs(date).isSame(dateToCompare, unit)
    )
  }
  if (typeOfCompare.isAfterOrSame) {
    return (
      dayjs(date).isAfter(dateToCompare, unit) ||
      dayjs(date).isSame(dateToCompare, unit)
    )
  }
  if (typeOfCompare.isSame) {
    return dayjs(date).isSame(dateToCompare, unit)
  }
  console.error('Add proper compare options, as no matche found!')
  return false
}

export const getToday = (options?: { skipTime: boolean }): Date => {
  const today = new Date()
  return options?.skipTime ? new Date(today.setHours(0, 0, 0, 0)) : today
}

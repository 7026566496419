import React from 'react'
import { Box, Typography, useTheme, Chip } from '@mui/material'
import { useTranslation } from '../../common/hooks/helper/useTranslation'

import { getPartners_getPartners, UserType } from '../../models/graphql'
import { MessagePartnerProfile } from './MessagePartnerProfile'
import { Avatar } from '../../common/components/Avatar'
import { getProfessionString } from '../../utils/professions'

type Props = {
  partner: getPartners_getPartners
  isSelected: boolean
  onClick?: () => void
}

const MessageBoardPartner: React.FC<Props> = ({
  partner,
  isSelected,
  onClick,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isPatient = partner.user.userType === UserType.Patient
  const isDoctor = partner.user.userType === UserType.Doctor
  const isAssistant = partner.user.userType === UserType.Assistant
  const profile: MessagePartnerProfile | null = isDoctor
    ? partner.user.doctorProfile
    : isAssistant
    ? partner.user.assistantProfile
    : partner.user.patientProfile
  const newMessagesCount = partner.newMessages.length

  return (
    <>
      {profile && (
        <Box
          border={1}
          borderRadius="6px"
          borderColor={
            isSelected ? theme.palette.primary.light : theme.palette.grey[300]
          }
          bgcolor={
            isSelected
              ? theme.palette.primary.light
              : theme.palette.common.white
          }
          color={
            isSelected ? theme.palette.common.white : theme.palette.common.black
          }
          p={1}
          display="flex"
          alignItems="center"
          style={{ cursor: 'pointer' }}
          onClick={() => onClick && onClick()}
        >
          <Avatar
            firstName={profile.firstName}
            lastName={profile.lastName}
            size="small"
          />

          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box display="flex" flexGrow={1} alignItems="flex-start">
              <Box flexGrow={1}>
                <Typography variant="h6">
                  {t('common:formattedNameFull', {
                    title: profile.title,
                    firstName: profile.firstName,
                    lastName: profile.lastName,
                  })}
                </Typography>
              </Box>
              {newMessagesCount > 0 && (
                <Chip
                  size="small"
                  color="error"
                  label={newMessagesCount}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </Box>
            <Box>
              <Typography variant="subtitle2">
                {isPatient
                  ? t('common:patient')
                  : isAssistant
                  ? t('common:assistant')
                  : 'professions' in profile && profile.professions
                  ? getProfessionString(profile?.professions, t)
                  : ''}
              </Typography>
            </Box>
            <Box flexGrow={1} />
            {partner.lastMessageAt && (
              <Box textAlign="right">
                <Typography variant="caption">
                  {t('common:datePrintedDayWithHours', {
                    date: partner.lastMessageAt,
                  })}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

export { MessageBoardPartner }

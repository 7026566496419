import {
  AddBox as AddBoxIcon,
  Delete as DeleteIcon,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React from 'react'
import {
  Controller,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { isValidHttpUrl } from './referenceUtil'
import {
  ReferenceFormValues,
  ReferenceKey,
} from '../../../views/doctor/TreatmentSchema/components/TreatmentSchemaDetailsEdit/treatmentSchemaDetailsEdit.types'

interface Props {
  name: string
  isOpen: boolean
  onClose: () => void
  isStringified: boolean
}

export const Reference: React.FC<Props> = ({
  name,
  isOpen,
  onClose,
  isStringified,
}) => {
  const { t } = useTranslation()
  const { setValue, watch: mainFormWatch } = useFormContext()
  const defaultValue = mainFormWatch(name)
  const { control, handleSubmit, watch } = useForm<ReferenceFormValues>({
    defaultValues: {
      reference:
        // this parsing is necessary because the treatmentschema urls are saved as string and not json.
        // it can be removed if the db changes for the treatmentschemas
        isStringified && defaultValue.includes('[')
          ? JSON.parse(defaultValue)
          : defaultValue,
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'reference',
  })

  const reference = watch('reference')
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...reference[index],
    }
  })

  const onSubmit = handleSubmit(async (formValues) => {
    const validReferences = formValues.reference.filter(
      (reference) => reference.name || reference.url
    )
    const newValue = isStringified
      ? // this stringify is necessary because the treatmentschema urls are saved as string and not json.
        // it can be removed if the db changes for the treatmentschemas
        JSON.stringify(validReferences || [])
      : validReferences

    setValue(name, newValue, {
      shouldDirty: true,
    })
    onClose()
  })

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={false}>
      <Box component="form" onSubmit={onSubmit}>
        <DialogTitle>{t('treatment:protocol.url')}</DialogTitle>

        <DialogContent sx={{ width: { xs: '90vw', lg: '40vw' } }}>
          <Stack gap={2}>
            {controlledFields.length < 1 ? (
              <Typography variant="subtitle1">
                {t('treatment:protocol.reference.missing')}
              </Typography>
            ) : (
              controlledFields.map((controlledField, index: number) => (
                <Stack
                  direction="row"
                  key={controlledField.id}
                  alignItems="center"
                >
                  {Object.values(ReferenceKey).map((key: ReferenceKey) => (
                    <Controller
                      control={control}
                      name={`reference.${index}.${key}`}
                      key={key + index}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          size="small"
                          placeholder={t(`treatment:protocol.reference.${key}`)}
                          fullWidth
                          sx={{ mr: 1 }}
                          {...field}
                        />
                      )}
                    />
                  ))}

                  <Tooltip
                    title={
                      isValidHttpUrl(controlledField.url)
                        ? (t('treatment:protocol.reference.open') as string)
                        : ''
                    }
                  >
                    <span>
                      <IconButton
                        disabled={!isValidHttpUrl(controlledField.url)}
                        href={controlledField.url}
                        target="_blank"
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </span>
                  </Tooltip>

                  <Tooltip title={t('common:delete') as string}>
                    <IconButton onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ))
            )}
            <Button
              startIcon={<AddBoxIcon />}
              onClick={() => append({ name: '', url: '' })}
              sx={{ alignSelf: 'flex-start' }}
            >
              {t('treatment:protocol.reference.add')}
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            {t('common:cancel')}
          </Button>
          <Button type="submit">{t('common:save')}</Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

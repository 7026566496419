import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material'
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material'
import React, {
  PropsWithChildren,
  ReactElement,
  useMemo,
  useState,
} from 'react'
import { DataTableActionButton } from './DataTableActionButton'
import { DataTableCell } from './DataTableCell'
import { DataTableCellDetails } from './DataTableCellDetails'
import {
  DataTableColDef,
  DataTableRowAction,
  DataTableRowData,
} from './interfaces'

type Props<T> = {
  columns: DataTableColDef<T>[]
  visibleColumns: DataTableColDef<T>[]
  hiddenColumns: DataTableColDef<T>[]
  row: T
  actions?: DataTableRowAction<T>[]
  showDetails: boolean
}

export const DataTableRow = <T extends DataTableRowData>(
  props: PropsWithChildren<Props<T>>
): ReactElement => {
  const { row, columns, visibleColumns, hiddenColumns, actions, showDetails } =
    props
  const [isOpen, setIsOpen] = useState(false)

  const isDetailsVisible = hiddenColumns.length > 0 || showDetails

  const colSpan = useMemo(() => {
    const details = isDetailsVisible ? 1 : 0
    const act = (actions?.length ?? 0) > 0 ? 1 : 0
    return visibleColumns.length + details + act
  }, [actions?.length, isDetailsVisible, visibleColumns.length])

  return (
    <>
      <TableRow>
        {isDetailsVisible && (
          <TableCell padding="checkbox">
            <IconButton
              aria-label="expand row"
              onClick={() => setIsOpen((v) => !v)}
            >
              {isOpen ? (
                <KeyboardArrowUpIcon fontSize="large" />
              ) : (
                <KeyboardArrowDownIcon fontSize="large" />
              )}
            </IconButton>
          </TableCell>
        )}
        {visibleColumns.map((col) => (
          <DataTableCell<T> key={col.field} column={col} row={row} />
        ))}
        {(actions?.length ?? 0) > 0 && (
          <TableCell align="center" padding="checkbox">
            {actions?.map((action, idx) => (
              <DataTableActionButton<T> key={idx} action={action} row={row} />
            ))}
          </TableCell>
        )}
      </TableRow>
      {isDetailsVisible && isOpen && (
        <TableRow>
          <TableCell colSpan={colSpan} sx={{ py: 0 }}>
            <Collapse unmountOnExit timeout={0} in={isOpen}>
              {columns.map((col) => (
                <DataTableCellDetails key={col.field} column={col} row={row} />
              ))}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

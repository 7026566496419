import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from '../../hooks/helper/useTranslation'

import { assistantProfileChore, userChore } from '../../../models/graphql'
import { LocationIcon } from '../../icons/LocationIcon'

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      background: `${theme.palette.primary.main}11`,
    },
  },
}))

type DoctorProfileInstitutionProps = {
  institution: {
    id: string
    assistant: { email: string; name: string; phone: string } | null
    facebookId: string | null
    phone: string | null
    workingHours: string[] | null
    institution: {
      id: string
      mapLink: string | null
      name: string
    }
  }
  assistants: Array<
    | (Omit<assistantProfileChore, '__typename' | 'user'> & {
        user: Omit<userChore, '__typename'> | null
      })
    | null
  >
}
const DoctorProfileInstitution: React.FC<DoctorProfileInstitutionProps> = ({
  institution,
}) => {
  const { t } = useTranslation()
  const { workingHours } = institution
  const classes = useStyles()

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="body2" color="textSecondary">
            {institution.institution?.name}
          </Typography>
        </Box>
        <Box width={50} flexShrink={0}>
          <IconButton
            className={classes.button}
            target="_blank"
            rel="noreferrer"
            href={institution.institution?.mapLink || ''}
            size="large"
          >
            <LocationIcon />
          </IconButton>
        </Box>
      </Box>

      <Box ml={4}>
        {(workingHours || []).length > 0 && (
          <Box>
            <Typography variant="subtitle2">
              {t('institution:workingHours')}
            </Typography>
            <Typography variant="subtitle1" style={{ whiteSpace: 'pre-line' }}>
              {workingHours?.join(', ')}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  )
}

export { DoctorProfileInstitution }

import React from 'react'
import { alpha, Stack, Typography } from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { ViewSurveyDetailsLabel } from './ViewSurveyDetailsLabel'
import { SurveySchema } from '../Surveys/surveys.types'
import { SurveySchemaStatusEnum } from '../../../../../models/graphql'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'

interface Props {
  surveySchema: SurveySchema
  surveySchemaStatus?: SurveySchemaStatusEnum
}

export const ViewSurveyDetailsCore: React.FC<Props> = ({
  surveySchema,
  surveySchemaStatus,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  const status = surveySchemaStatus ?? surveySchema.status

  return (
    <Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
        <ViewSurveyDetailsLabel
          title={t(`survey:status.${status.toLowerCase()}`)}
          color={
            status === SurveySchemaStatusEnum.Active
              ? colorPalette.success.main
              : alpha(colorPalette.common.black, 0.2)
          }
        />
        {surveySchema.isGlobal && (
          <ViewSurveyDetailsLabel
            title={t('survey:survey.isGlobal')}
            color={colorPalette.warning.main}
          />
        )}
        {surveySchema.categories.map((category, i) => (
          <ViewSurveyDetailsLabel
            key={i}
            title={t(`survey:survey.type.${category.toLowerCase()}`)}
            color={colorPalette.info.main}
          />
        ))}
      </Stack>
      <Stack sx={{ pt: 2 }}>
        <Typography variant="h5">{surveySchema.title}</Typography>
        <Typography variant="subtitle1">
          {surveySchema.professions
            .map((profession) => profession.name)
            .join(', ')}
        </Typography>
      </Stack>
    </Stack>
  )
}

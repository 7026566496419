import { action } from 'easy-peasy'
import { ToastModel } from './toast.interface'

const toastModel: ToastModel = {
  toast: undefined,
  setToast: action((state, payload) => {
    state.toast = payload
  }),
  reset: action((state) => {
    state.toast = undefined
  }),
}

export { toastModel }

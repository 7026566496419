export const printBetmenConsole = (): void => {
  console.log('%cDr Betmen👩‍⚕️', 'font-size: 20px')
  console.log(
    `%cversion: ${process.env.REACT_APP_VERSION_VERSION}`,
    'font-size: 20px'
  )
  console.log(
    `%clast update: ${process.env.REACT_APP_VERSION_DATE}`,
    'font-size: 20px'
  )
}

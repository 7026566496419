import { ApolloQueryResult, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import {
  getDoctorPatientProfile,
  getDoctorPatientProfile_getPatientProfile,
  getDoctorPatientProfileVariables,
} from '../../../../models/graphql'
import { GET_DOCTOR_PATIENT_PROFILE } from '../operations/doctorPatientOperations'

type Props = {
  skip: boolean
}

type ReturnData = {
  patientProfile?: getDoctorPatientProfile_getPatientProfile
  loading: boolean
  refetch: (
    variables?: Partial<getDoctorPatientProfileVariables> | undefined
  ) => Promise<ApolloQueryResult<getDoctorPatientProfile>>
}

type RouteParams = {
  patientId?: string
}

export const useDoctorPatientProfileData = ({ skip }: Props): ReturnData => {
  const { patientId } = useParams<RouteParams>()
  const { data, loading, refetch } = useQuery<
    getDoctorPatientProfile,
    getDoctorPatientProfileVariables
  >(GET_DOCTOR_PATIENT_PROFILE, {
    variables: {
      patientId: patientId || '',
    },
    skip: !patientId || skip,
    displayName: 'Patient data',
  })

  const patientProfile = data?.getPatientProfile

  return {
    patientProfile,
    loading,
    refetch,
  }
}

import { QueryResult, useQuery } from '@apollo/client'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import { useMemo } from 'react'
import {
  bnoCodes,
  bnoCodes_bnoCodes,
  bnoCodesVariables,
} from '../../models/graphql'
import { GET_BNO_CODES } from '../../operations/bnoCodeOperations'

type Options = QueryHookOptions<bnoCodes, bnoCodesVariables>

type Return = {
  loading: boolean
  bnoCodes: bnoCodes_bnoCodes[]
  refetch: QueryResult<bnoCodes, bnoCodesVariables>['refetch']
}

export const useBnoCodesData = (options: Options = {}): Return => {
  const { data, loading, refetch } = useQuery<bnoCodes, bnoCodesVariables>(
    GET_BNO_CODES,
    { ...options }
  )

  return useMemo(() => {
    return {
      bnoCodes: (data?.bnoCodes ?? []).slice().sort((a, b) => {
        const nameA = a.code.toUpperCase() // ignore upper and lowercase
        const nameB = b.code.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }),
      loading,
      refetch,
    }
  }, [data?.bnoCodes, refetch, loading])
}

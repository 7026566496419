import { Box, Card, Stack, Typography } from '@mui/material'
import get from 'lodash/get'
import React from 'react'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  aggregatedSurveyResults_getAggregatedSurveyResults as AggregatedSurveyResults,
  getSurveySchemaById_getSurveySchemaById as SurveySchema,
} from '../../../../../models/graphql'
import { mapSurveySchemaQuestionsToSections } from '../../utils/survey.mapper'
import { getOrderedSections } from '../../utils/survey.util'
import { QuestionResult } from '../AggregatedQuestionResult/QuestionResult'
import { QuestionInSection, Section } from '../EditSurvey/editSurvey.types'
import { SurveyResultDownloadSelector } from './SurveyResultDownloadSelector'

interface Props {
  surveySchema: SurveySchema
  aggregatedSurveyResults: AggregatedSurveyResults
}

export const AggregatedSurveyResult: React.FC<Props> = ({
  surveySchema,
  aggregatedSurveyResults,
}) => {
  const { t } = useTranslation()
  const sections = !!surveySchema.surveySchemaQuestions.length
    ? mapSurveySchemaQuestionsToSections(surveySchema?.surveySchemaQuestions)
    : [{ order: 1, questions: [], name: '' }]

  const orderedSections = getOrderedSections(sections)

  const filteredSections = orderedSections.filter(
    (section) => !!section.questions.length
  )

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        sx={{ px: 3, pb: 1 }}
      >
        <Typography variant="body2" sx={{ mt: 1.5 }}>
          {t('survey:result.numberOfAllAnswers', {
            numberOfAnswers: aggregatedSurveyResults.totalSubmitters,
          })}
        </Typography>

        <Box sx={{ minWidth: 120 }}>
          <SurveyResultDownloadSelector
            csvInBase64={aggregatedSurveyResults.csvInBase64}
            xlsxInBase64={aggregatedSurveyResults.xlsxInBase64}
          />
        </Box>
      </Stack>

      {filteredSections.map((section: Section) =>
        section.questions.map((question: QuestionInSection) => {
          const questionResult =
            aggregatedSurveyResults.surveyQuestionResultList.find(
              (result) => get(result, 'questionId') === question.id
            )

          if (!!questionResult) {
            return (
              <Card sx={{ p: 3, mb: 2 }} key={question.idForRender}>
                <QuestionResult
                  question={question}
                  questionOrder={question.orderInSurvey || 0}
                  questionResult={questionResult}
                />
              </Card>
            )
          }
        })
      )}
    </>
  )
}

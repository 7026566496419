import { Edit as EditIcon } from '@mui/icons-material'
import PreviewIcon from '@mui/icons-material/Preview'
import { alpha, Box, Button, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useColorPalette } from '../../../../common/hooks/helper/useColor'
import { BetmenList } from '../../../../common/components/BetmenList/BetmenList'
import { BetmenListActions } from '../../../../common/components/BetmenList/BetmenListActions'
import { BetmenListBody } from '../../../../common/components/BetmenList/BetmenListBody'
import { BetmenListHeader } from '../../../../common/components/BetmenList/BetmenListHeader'
import { BetmenListHeaderCell } from '../../../../common/components/BetmenList/BetmenListHeaderCell'
import { BetmenListItemCard } from '../../../../common/components/BetmenList/BetmenListItemCard'
import { BetmenListItemCardCell } from '../../../../common/components/BetmenList/BetmenListItemCardCell'
import { ChipGroup } from '../../../../common/components/ChipGroup/ChipGroup'
import { PAGE_SIZE } from '../../../../config'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import {
  SurveyCategoryEnum,
  SurveyStatusEnum,
} from '../../../../models/graphql'
import { CategoryFilter } from '../../../../common/components/Filters/CategoryFilter'
import { usePatientSurveys } from '../hooks/usePatientSurveys'
import { PatientSurveyStatus } from './PatientSurveys/PatientSurveyStatus'

export const PatientSurveys: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const colorPalette = useColorPalette()

  const [showFinishedSurveys, setShowFinishedSurveys] = useState<boolean>(false)
  const [categoryFilter, setCategoryFilter] = useState<SurveyCategoryEnum[]>([])

  const {
    loading,
    fetchingMore,
    fetchMoreSurveys,
    refetch,
    patientSurveys,
    hasMoreSurveys,
  } = usePatientSurveys({
    variables: {
      filterData: {
        isFinished: showFinishedSurveys,
        categories: categoryFilter,
      },
      paginationInput: {
        offset: 0,
        limit: PAGE_SIZE,
      },
    },
  })

  useEffect(() => {
    refetch()
  }, [refetch, showFinishedSurveys, categoryFilter])

  const onSurveyEnter = useCallback(() => {
    const offset = patientSurveys.length

    fetchMoreSurveys({
      variables: {
        filterData: {
          isFinished: showFinishedSurveys,
          categories: categoryFilter,
        },
        paginationInput: {
          offset,
          limit: PAGE_SIZE,
        },
      },
    })
  }, [patientSurveys, fetchMoreSurveys, showFinishedSurveys, categoryFilter])

  return (
    <BetmenList
      dataSource={patientSurveys}
      loading={loading}
      hasMoreItem={hasMoreSurveys}
      fetchMore={onSurveyEnter}
      fetchMoreLoading={fetchingMore}
      gridColumnSizes={[4, 3, 2, 2, 1]}
      filters={
        <Stack>
          <Typography variant="italic" sx={{ mx: 4, my: 2 }}>
            {t('patients:survey.description')}
          </Typography>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Stack direction="row" alignItems="center" sx={{ mx: 4 }} gap={2}>
              <Typography variant="body2">
                {t('patients:survey.filters')}
              </Typography>
              <CategoryFilter
                label={t('survey:surveys.category')}
                categories={Object.keys(SurveyCategoryEnum)}
                categoryFilter={categoryFilter}
                setCategoryFilter={(newCategoryFilter) =>
                  setCategoryFilter(newCategoryFilter as SurveyCategoryEnum[])
                }
                translationKey="survey:survey.type"
              />
            </Stack>
            <Button
              variant="text"
              sx={{ my: 'auto', mx: 2, whiteSpace: 'nowrap' }}
              onClick={() => setShowFinishedSurveys(!showFinishedSurveys)}
              startIcon={<PreviewIcon />}
              data-cy="Patient-Appointment-List-Button-showPast"
            >
              {showFinishedSurveys
                ? t('patients:survey.newSurveys.title')
                : t('patients:survey.finishedSurveys.title')}
            </Button>
          </Box>
        </Stack>
      }
      header={
        <BetmenListHeader>
          <BetmenListHeaderCell>
            {t('patients:survey.header.title')}
          </BetmenListHeaderCell>
          <BetmenListHeaderCell>
            {t('patients:survey.header.category')}
          </BetmenListHeaderCell>
          <BetmenListHeaderCell>
            {t('patients:survey.header.doctor')}
          </BetmenListHeaderCell>
          <BetmenListHeaderCell>
            {t('patients:survey.header.status')}
          </BetmenListHeaderCell>
        </BetmenListHeader>
      }
    >
      <BetmenListBody>
        {patientSurveys.map((survey) => (
          <BetmenListItemCard
            sx={{
              bgcolor: showFinishedSurveys
                ? alpha(colorPalette.common.black, 0.1)
                : colorPalette.common.white,
            }}
            key={survey.id}
          >
            <BetmenListItemCardCell>
              {survey.surveySchema.title}
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              <ChipGroup
                values={survey.surveySchema.categories.map((category) =>
                  t(`survey:survey.type.${category.toLowerCase()}`)
                )}
                limitTags={3}
              />
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              {t('common:formattedNameFull', {
                title: survey.doctor?.title,
                firstName: survey.doctor?.firstName,
                lastName: survey.doctor?.lastName,
              })}
            </BetmenListItemCardCell>
            <BetmenListItemCardCell>
              <PatientSurveyStatus survey={survey} />
            </BetmenListItemCardCell>
            <BetmenListActions>
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                {survey.status !== SurveyStatusEnum.Finished && (
                  <Button
                    variant="text"
                    data-cy="PatientSurveys-Button-fillOut"
                    onClick={() =>
                      history.push(`/patient/home/surveys/${survey.id}`)
                    }
                    startIcon={
                      survey.status === SurveyStatusEnum.InProgress ? (
                        <EditIcon />
                      ) : null
                    }
                  >
                    {t(
                      `patients:survey.${
                        survey.status === SurveyStatusEnum.InProgress
                          ? 'continue'
                          : 'fillOut'
                      }`
                    )}
                  </Button>
                )}
              </Stack>
            </BetmenListActions>
          </BetmenListItemCard>
        ))}
      </BetmenListBody>
    </BetmenList>
  )
}

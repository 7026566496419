import { AddBoxOutlined } from '@mui/icons-material'
import { Box, Button, Divider, Typography } from '@mui/material'
import React from 'react'
import { EmptyState } from '../../../../../common/components/EmptyState'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'

type AppointmentEmptyStateProps = {
  toggleAddAppointment: () => void
  toggleAddProtocol: () => void
  toggleAddSurvey: () => void
}

const AppointmentEmptyState: React.FC<AppointmentEmptyStateProps> = ({
  toggleAddAppointment,
  toggleAddProtocol,
  toggleAddSurvey,
}) => {
  const { t } = useTranslation()

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      mt={5}
      alignItems="center"
    >
      <EmptyState
        title={t('treatment:createCarePlan.title')}
        description={t('treatment:createCarePlan.description')}
        warn={t('treatment:createCarePlan.warn')}
      >
        <Box mt={4} display="flex" width="100%" justifyContent="center">
          <Button
            color="primary"
            variant="contained"
            onClick={toggleAddProtocol}
            startIcon={<AddBoxOutlined />}
            data-cy="AppointmentEmptyState-Button-addNewProtocol"
          >
            {t('treatment:addNewProtocol')}
          </Button>
        </Box>
        <Box
          mt={4}
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Divider sx={{ width: '40px' }}></Divider>
          <Typography sx={{ mx: 2 }} variant="italic">
            {t('common:or')}
          </Typography>
          <Divider sx={{ width: '40px' }}></Divider>
        </Box>
        <Box mt={3} display="flex" width="100%" justifyContent="space-evenly">
          <Button
            color="primary"
            variant="text"
            onClick={toggleAddAppointment}
            data-cy="AppointmentEmptyState-Button-addNewAppointment"
          >
            {t('treatment:addNewAppointment')}
          </Button>
          <Button
            color="primary"
            variant="text"
            onClick={toggleAddSurvey}
            data-cy="AppointmentEmptyState-Button-addNewSurvey"
          >
            {t('treatment:addNewSurvey')}
          </Button>
        </Box>
      </EmptyState>
    </Box>
  )
}

export { AppointmentEmptyState }

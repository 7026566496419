import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC, useState } from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { SaveButton } from '../../../../../common/components/SaveButton'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useUserType } from '../../../../../common/hooks/useUserType'
import {
  getTreatment_getTreatment,
  getTreatment_getTreatment_appointments,
  updateAppointment_updateAppointment_CalculatedSchedule,
  updateAppointment_updateAppointment_CalculatedSchedule_schedule,
  UserType,
} from '../../../../../models/graphql'
import { Loading } from '../../../../../common/components/Loading'
import { appointmentDistanceFormatter } from '../../../../../utils/appointmentDistanceFromatter'
import { AppointmentDiffItem } from './AppointmentDiffItem'

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
}))

type RowType = {
  element: updateAppointment_updateAppointment_CalculatedSchedule_schedule
  appointment: getTreatment_getTreatment_appointments
}

type Props = {
  appointmentId: string
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onSave: (reschedule: boolean) => void
  data: updateAppointment_updateAppointment_CalculatedSchedule
  patientName: string
  treatment: getTreatment_getTreatment
  isProposed: boolean
  wasProposed: boolean
}

const ShowDiffModal: FC<Props> = (props) => {
  const {
    appointmentId,
    isOpen,
    isLoading,
    onClose,
    onSave,
    data,
    patientName,
    treatment,
    isProposed,
    wasProposed,
  } = props
  const {
    schedule: schedules,
    brokenDependingFrom = [],
    brokenDependants = [],
  } = data
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const classes = useStyles()
  const selectedLanguage = useSelectedLanguage()
  const userType = useUserType()
  const isAssistant = userType === UserType.Assistant

  const [isAllSelected, setIsAllSelected] = useState(true)

  const currentSchedule = schedules.find((item) => item.id === appointmentId)
  const modifiedSchedules = schedules.filter(
    (item) => item.isModified && item.id !== appointmentId
  )

  const modifiedDependencyCount = modifiedSchedules.filter(
    (item) => item.id !== appointmentId
  ).length

  const hasBrokenDependingFrom = brokenDependingFrom.length > 0
  const hasBrokenDependant = brokenDependants.length > 0
  const hasModifiedDependency = modifiedDependencyCount > 0

  const hasBrokenDeps =
    hasBrokenDependingFrom || hasBrokenDependant || hasModifiedDependency

  const hasError =
    hasBrokenDependingFrom || (hasBrokenDependant && !isAllSelected)
  const disableSave = isAssistant && hasError

  const oldFormat = wasProposed
    ? 'common:intlDateFormattedLongMonth'
    : 'common:intlDateFormattedWithHours'
  const newFormat = isProposed
    ? 'common:intlDateFormattedLongMonth'
    : 'common:intlDateFormattedWithHours'
  const firstAppointmentOldDate = t(oldFormat, {
    date: currentSchedule?.oldDate?.start,
  })
  const firstAppointmentNewDate = t(newFormat, {
    date: currentSchedule?.date?.start,
  })

  const rows = modifiedSchedules
    .map((item) => ({
      element: item,
      appointment: treatment.appointments?.find((ap) => ap.id === item.id),
    }))
    .filter((item): item is RowType => !!item.appointment)

  const handleSubmit = async () => {
    onSave(isAllSelected)
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          fontWeight="bold"
        >
          <ErrorOutlineIcon color="error" sx={{ height: 24, width: 24 }} />
          <Box ml={2} fontWeight="bold">
            <Typography variant="body2">
              {t('treatment:doctor.showDiff.title', {
                patientName,
                treatment: treatment.title[selectedLanguage],
              })}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent classes={{ root: classes.root }}>
        <Box px={3} py={2} bgcolor={colorPalette.grey[200]}>
          <Box fontWeight="bold">{t('treatment:doctor.showDiff.subtitle')}</Box>
          <Typography variant="subtitle2" color="primary">
            {currentSchedule?.doctorTitle?.[selectedLanguage]}
          </Typography>
          {`${firstAppointmentOldDate} -> ${firstAppointmentNewDate}`}
        </Box>
        <Collapse in={hasError}>
          <Box px={3} py={2} bgcolor={`${colorPalette.error.main}16`}>
            <Box fontWeight="bold">
              {t('treatment:doctor.showDiff.brokenDependencyTitle')}
            </Box>
            {brokenDependingFrom.map((dep) => (
              <Box key={dep.id} pt={0.5}>
                {t('treatment:doctor.showDiff.brokenDependency', {
                  title: dep.from.info?.doctorTitle?.[selectedLanguage],
                  distance: appointmentDistanceFormatter(
                    dep.distance,
                    t,
                    dep.type
                  ),
                })}
              </Box>
            ))}
            {brokenDependants.map((dep) => (
              <Box key={dep.id} pt={0.5}>
                {t('treatment:doctor.showDiff.brokenDependant', {
                  title: dep.to.info?.doctorTitle?.[selectedLanguage],
                  distance: appointmentDistanceFormatter(
                    dep.distance,
                    t,
                    dep.type
                  ),
                })}
              </Box>
            ))}
          </Box>
        </Collapse>
        <Box px={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              {t('treatment:doctor.showDiff.modifiedAppointments', {
                modifiedAppointmentsLength: modifiedDependencyCount,
              })}
            </Typography>
            {hasBrokenDeps && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAllSelected}
                    onChange={() => setIsAllSelected(!isAllSelected)}
                  />
                }
                label={t('common:selectAll')}
                labelPlacement="start"
              />
            )}
          </Box>
          {rows.map((item) => {
            return (
              <AppointmentDiffItem
                key={item.element.id}
                element={item.element}
                appointment={item.appointment}
                isAllSelected={isAllSelected}
              />
            )
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <SaveButton
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={disableSave}
          startIcon={
            isLoading ? <Loading inline size={18} color="inherit" /> : undefined
          }
        >
          {t('common:save')}
        </SaveButton>
      </DialogActions>
    </Dialog>
  )
}

export { ShowDiffModal }

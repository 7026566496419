import { Chip, ChipProps } from '@mui/material'
import React from 'react'
import { useColorPalette } from '../../hooks/helper/useColor'

interface Props extends ChipProps {
  selected: boolean
}

export const SelectedChip: React.FC<Props> = ({ selected, ...chipProps }) => {
  const colorPalette = useColorPalette()

  return (
    <Chip
      color="primary"
      variant={selected ? 'filled' : 'outlined'}
      sx={{
        backgroundColor: selected
          ? colorPalette.primary.main
          : colorPalette.common.white,
        color: selected ? colorPalette.common.white : colorPalette.primary.main,
        '& .MuiChip-deleteIcon': {
          color: selected
            ? colorPalette.common.white
            : colorPalette.primary.main,
        },
      }}
      {...chipProps}
    />
  )
}

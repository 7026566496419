import {
  Stack,
  TextField,
  Typography,
  Switch,
  Select,
  MenuItem,
  Checkbox,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { QuestionForm } from '../Questions/questions.types'
import { Controller, useFormContext } from 'react-hook-form'
import { MAXIMUM_NUMBER_OF_FILES } from '../../utils/survey.util'
import {
  SurveyQuestionFileSize,
  SurveyQuestionValidFileType,
} from '../../../../../models/graphql'

interface Props {
  disabled?: boolean
}

export const EditFileUploadQuestion: React.FC<Props> = ({ disabled }) => {
  const { t } = useTranslation()
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<QuestionForm>()
  const validFileTypes: SurveyQuestionValidFileType[] =
    watch('data.fileUploadData.validFileTypes') || []

  const [specialFilesOpen, setSpecialFilesOpen] = useState<boolean>(
    !!validFileTypes.length
  )

  return (
    <Stack data-cy="EditFileUploadQuestion" gap={2} flex={1}>
      <TextField
        fullWidth
        variant="outlined"
        minRows={2}
        multiline
        placeholder={t('survey:question.title')}
        {...register('title', {
          required: true,
        })}
        error={!!errors.title}
        helperText={
          !!errors.title && (t('messages:warnings.required') as string)
        }
        disabled={disabled}
      />

      <TextField
        fullWidth
        variant="outlined"
        minRows={2}
        multiline
        placeholder={t('survey:question.description')}
        {...register('description')}
        disabled={disabled}
      />

      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="body2">
          {t('survey:file.onlySpecificTypes')}
        </Typography>

        <Switch
          checked={specialFilesOpen}
          onChange={() => {
            setValue('data.fileUploadData.validFileTypes', [])
            setSpecialFilesOpen((prevValue) => !prevValue)
          }}
          disabled={disabled}
        />
      </Stack>

      {specialFilesOpen && (
        <Stack direction="row" flexWrap="wrap" spacing={2}>
          {Object.values(SurveyQuestionValidFileType).map(
            (type: SurveyQuestionValidFileType, i: number) => (
              <Stack key={i} direction="row" alignItems="center" my={1}>
                <Checkbox
                  disabled={disabled}
                  checked={validFileTypes.includes(type)}
                  onChange={() => {
                    const newValidFileTypes = validFileTypes.includes(type)
                      ? validFileTypes.filter((t) => t !== type)
                      : [...validFileTypes, type]
                    setValue(
                      'data.fileUploadData.validFileTypes',
                      newValidFileTypes
                    )
                  }}
                />

                <Typography variant="body2">{type}</Typography>
              </Stack>
            )
          )}
        </Stack>
      )}

      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="body2">
          {t('survey:file.maxAmountOfFiles')}
        </Typography>
        <Controller
          name="data.fileUploadData.maxItemsCount"
          control={control}
          render={({ field }) => (
            <Select {...field} size="small" disabled={disabled}>
              {Array.from(
                { length: MAXIMUM_NUMBER_OF_FILES },
                (_, i) => i + 1
              ).map((maxCount: number, i: number) => (
                <MenuItem key={i} value={maxCount}>
                  {maxCount}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="body2">
          {t('survey:file.maxSizeOfFiles')}
        </Typography>
        <Controller
          name="data.fileUploadData.maxFileSize"
          control={control}
          render={({ field }) => (
            <Select {...field} size="small" disabled={disabled}>
              {Object.values(SurveyQuestionFileSize).map(
                (size: SurveyQuestionFileSize, i: number) => (
                  <MenuItem key={i} value={size}>
                    {t(`survey:file.size.${size}`)}
                  </MenuItem>
                )
              )}
            </Select>
          )}
        />
      </Stack>
    </Stack>
  )
}

import { Card, Popover } from '@mui/material'
import React from 'react'

interface Props {
  actionsMenuAnchor: HTMLButtonElement | null
  setActionsMenuAnchor: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >
}

export const PopoverCard: React.FC<Props> = ({
  actionsMenuAnchor,
  setActionsMenuAnchor,
  children,
}) => {
  return (
    <Popover
      open={!!actionsMenuAnchor}
      anchorEl={actionsMenuAnchor}
      onClose={() => setActionsMenuAnchor(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 1.5,
        }}
      >
        {children}
      </Card>
    </Popover>
  )
}

import { gql } from '@apollo/client'
import {
  FRAGMENT_SURVEY_ANSWER_CHORE,
  FRAGMENT_SURVEY_SCHEMA_CHORE,
  FRAGMENT_SURVEY_SCHEMA_QUESTION_CHORE,
} from '../../../../operations/fragments'

export const GET_SURVEY_SCHEMAS = gql`
  query getSurveySchemas(
    $filterData: SurveySchemaFilter
    $paginationInput: PaginationInput
  ) {
    getSurveySchemas(
      filterData: $filterData
      paginationInput: $paginationInput
    ) {
      surveySchemas {
        ...surveySchemaChore
        surveySchemaQuestions {
          id
        }
        user {
          id
          doctorProfile {
            title
            firstName
            lastName
          }
        }
      }
      total
    }
  }
  ${FRAGMENT_SURVEY_SCHEMA_CHORE}
`

export const GET_SURVEY_SCHEMA_BY_ID = gql`
  query getSurveySchemaById($surveySchemaId: String!) {
    getSurveySchemaById(surveySchemaId: $surveySchemaId) {
      ...surveySchemaChore
      user {
        id
      }
      surveySchemaQuestions {
        ...surveySchemaQuestionChore
      }
    }
  }
  ${FRAGMENT_SURVEY_SCHEMA_CHORE}
  ${FRAGMENT_SURVEY_SCHEMA_QUESTION_CHORE}
`

export const CREATE_SURVEY_SCHEMA = gql`
  mutation createSurveySchema($surveySchemaData: CreateSurveySchemaInput!) {
    createSurveySchema(surveySchemaData: $surveySchemaData) {
      id
    }
  }
`

export const UPDATE_SURVEY_SCHEMA = gql`
  mutation updateSurveySchema($surveySchemaData: UpdateSurveySchemaInput!) {
    updateSurveySchema(surveySchemaData: $surveySchemaData) {
      id
    }
  }
`

export const UPDATE_SURVEY_SCHEMA_STATUS = gql`
  mutation updateSurveySchemaStatus(
    $surveySchemaId: String!
    $status: SurveySchemaStatusEnum!
  ) {
    updateSurveySchemaStatus(surveySchemaId: $surveySchemaId, status: $status) {
      id
    }
  }
`

export const DELETE_SURVEY_SCHEMA = gql`
  mutation deleteSurveySchema($surveySchemaId: String!) {
    deleteSurveySchema(surveySchemaId: $surveySchemaId) {
      id
    }
  }
`

export const SEND_SURVEY_TO_USER = gql`
  mutation sendSurveyToUsers(
    $surveySchemaId: String!
    $userIds: [String!]!
    $fillableFrom: DateTime
    $fillableUntil: DateTime
  ) {
    sendSurveyToUsers(
      surveySchemaId: $surveySchemaId
      userIds: $userIds
      fillableFrom: $fillableFrom
      fillableUntil: $fillableUntil
    ) {
      id
    }
  }
`

export const DELETE_SURVEYS_FROM_TREATMENT = gql`
  mutation deleteSurveysFromTreatment(
    $treatmentId: String!
    $surveyIds: [String!]!
  ) {
    deleteSurveysFromTreatment(treatmentId: $treatmentId, surveyIds: $surveyIds)
  }
`

export const SHARE_SURVEY_SCHEMA = gql`
  mutation shareSurveySchema($shareSurveySchemaInput: ShareSurveySchemaInput!) {
    shareSurveySchema(shareSurveySchemaInput: $shareSurveySchemaInput) {
      id
    }
  }
`

export const DUPLICATE_SURVEY_SCHEMA = gql`
  mutation duplicateSurveySchema($surveySchemaId: String!) {
    duplicateSurveySchema(surveySchemaId: $surveySchemaId) {
      id
    }
  }
`

export const GET_INDIVIDUAL_SURVEY_RESULTS = gql`
  query GetIndividualSurveyResults(
    $surveySchemaId: String!
    $isAnonymous: Boolean!
    $paginationInput: PaginationInput
    $dateIntervalInput: DateIntervalInput
  ) {
    getIndividualSurveyResults(
      surveySchemaId: $surveySchemaId
      isAnonymous: $isAnonymous
      paginationInput: $paginationInput
      dateIntervalInput: $dateIntervalInput
    ) {
      total
      surveys {
        fillableFrom
        finishedAt
        doctor {
          title
          firstName
          lastName
          institutionToDoctor {
            institution {
              name
            }
          }
        }
        patient {
          firstName
          lastName
          tajNumber
        }
        surveyQuestionAnswers {
          surveyQuestion {
            id
          }
          ...surveyAnswerChore
        }
      }
      csvInBase64
      xlsxInBase64
    }
  }
  ${FRAGMENT_SURVEY_ANSWER_CHORE}
`

export const GET_AGGREGATED_SURVEY_RESULTS = gql`
  query aggregatedSurveyResults(
    $surveySchemaId: String!
    $dateIntervalInput: DateIntervalInput
  ) {
    getAggregatedSurveyResults(
      surveySchemaId: $surveySchemaId
      dateIntervalInput: $dateIntervalInput
    ) {
      surveyQuestionResultList {
        ... on MultipleChoiceQuestionResult {
          questionId: id
          totalAnswers
          choiceResults {
            id
            name
            total
          }
        }
        ... on ScaleQuestionResult {
          questionId: id
          totalAnswersWithoutScaleAspect
          scaleOptionResultsWithoutScaleAspect {
            value
            label
            total
          }
          scaleAspectResults {
            totalAnswers
            id
            name
            scaleOptionResults {
              value
              label
              total
            }
          }
        }
        ... on SimpleTextQuestionResult {
          questionId: id
          totalAnswers
          answers
        }
        ... on FileUploadQuestionResult {
          questionId: id
          totalSubmitters
          documents {
            documentId
            fileName
          }
        }
      }
      totalSubmitters
      csvInBase64
      xlsxInBase64
    }
  }
`

export const GET_SURVEY_SCHEMA_FOR_RESULTS = gql`
  query getSurveySchemaForResults($surveySchemaId: String!) {
    getSurveySchemaById(surveySchemaId: $surveySchemaId) {
      ...surveySchemaChore
      user {
        id
      }
      surveySchemaQuestions: surveySchemaQuestionsBySameRoot {
        ...surveySchemaQuestionChore
      }
    }
  }
  ${FRAGMENT_SURVEY_SCHEMA_CHORE}
  ${FRAGMENT_SURVEY_SCHEMA_QUESTION_CHORE}
`

import { QueryResult, useQuery } from '@apollo/client'
import { getAppointmentCountByUserIdAndInFuture } from '../../../../models/graphql'
import { GET_APPOINTMENT_COUNT_BY_USER_ID_AND_IN_FUTURE } from '../operations/patientSurveyOperations'

type Return = QueryResult<getAppointmentCountByUserIdAndInFuture>

export const usePatientAppointmentCount = (): Return => {
  return useQuery<getAppointmentCountByUserIdAndInFuture>(
    GET_APPOINTMENT_COUNT_BY_USER_ID_AND_IN_FUTURE
  )
}

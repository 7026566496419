import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import { Button, ButtonProps } from '@mui/material'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { SurveySchemaStatusEnum } from '../../../../../models/graphql'
import { useStylesForSurveyActionButtons } from '../../utils/style.util'
import { SendSurveyToPatientModal } from '../Surveys/SendSurveyToPatientModal'
import { SurveySchema } from '../Surveys/surveys.types'

interface Props extends ButtonProps {
  surveySchema: SurveySchema
}

export const SendSurveyButton: React.FC<Props> = ({
  surveySchema,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  const classes = useStylesForSurveyActionButtons()

  const [isSendSurveyToPatientModalOpen, setIsSendSurveyToPatientModalOpen] =
    useState<boolean>(false)

  const { watch } = useFormContext()
  const status = watch('status')

  return (
    <>
      {status === SurveySchemaStatusEnum.Active && (
        <Button
          classes={{ textPrimary: classes.textPrimary }}
          variant="text"
          startIcon={<ForwardToInboxIcon />}
          onClick={() => setIsSendSurveyToPatientModalOpen(true)}
          {...buttonProps}
          data-cy={'SendSurveyButton'}
        >
          {t('survey:buttons.sendSurvey')}
        </Button>
      )}

      {isSendSurveyToPatientModalOpen && (
        <SendSurveyToPatientModal
          open={isSendSurveyToPatientModalOpen}
          onClose={() => setIsSendSurveyToPatientModalOpen(false)}
          surveySchemaId={surveySchema.id}
        />
      )}
    </>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from 'react'
import { Box, Divider, TextField, Typography } from '@mui/material'
import { useQuery, useMutation, NetworkStatus } from '@apollo/client'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useUserProfile } from '../../../common/hooks/useUserProfile'

import {
  GET_PATIENT_PROFILE,
  UPDATE_PATIENT_PROFILE,
} from '../../../operations/patientProfileOperations'
import {
  getPatientProfile,
  getPatientProfile_getPatientProfile,
  getPatientProfileVariables,
  updatePatientProfile,
  updatePatientProfileVariables,
  EditAblePatientData,
} from '../../../models/graphql'
import { ValidPhonePattern } from '../../../utils/isValidPhone'
import { ScrollablePanel } from '../../../common/components/ScrollablePanel'
import { useForm } from 'react-hook-form'
import { InputStatusAdornment } from '../../../common/components/InputStatusAdornment'
import { BoxWithLabel } from '../../../common/components/BoxWithLabel'
import { SaveButton } from '../../../common/components/SaveButton'
import { PasswordReset } from '../../../common/components/PasswordReset'
import { BloodType } from '../../../store/store.interface'

const BLOOD_TYPE_ENTRIES = Object.entries(BloodType)
interface PatientFormData extends EditAblePatientData {
  emergencyContactName?: string
  emergencyContactPhone?: string
}

const PatientProfile: React.FC = () => {
  const { t } = useTranslation()

  const {
    watch,
    register,
    reset,
    formState: { isDirty, isValid, errors, dirtyFields },
    handleSubmit,
    trigger,
  } = useForm<PatientFormData>({ mode: 'all' })
  const watchAllFields = watch()
  const [savedFields, setSavedFields] = useState<typeof dirtyFields>()
  useEffect(() => {
    trigger()
  }, [trigger, isValid])

  const resetFormWithPatientProfile = (
    patientProfile: getPatientProfile_getPatientProfile
  ) => {
    reset({
      gender: patientProfile.gender,
      title: patientProfile.title,
      phone: patientProfile.phone,
      address: patientProfile.address,
      height: patientProfile.height,
      weight: patientProfile.weight,
      takenMedicines: patientProfile.takenMedicines,
      medicineIntolerance: patientProfile.medicineIntolerance,
      emergencyContactName: patientProfile.emergencyContacts?.[0]?.name || '',
      emergencyContactPhone: patientProfile.emergencyContacts?.[0]?.phone || '',
    })
  }

  const profile = useUserProfile()
  const patientId = profile?.id

  const {
    data: patientProfileData,
    refetch: patientProfileRefetch,
    networkStatus: patientProfileNetworkStatus,
  } = useQuery<getPatientProfile, getPatientProfileVariables>(
    GET_PATIENT_PROFILE,
    {
      skip: !patientId,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data.getPatientProfile) {
          resetFormWithPatientProfile(data.getPatientProfile)
        }
      },
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        patientId: patientId!,
      },
    }
  )

  const isLoadingPatientProfile =
    patientProfileNetworkStatus === NetworkStatus.loading

  const [updatePatientProfileMutation, { loading: isUpdatingProfile }] =
    useMutation<updatePatientProfile, updatePatientProfileVariables>(
      UPDATE_PATIENT_PROFILE,
      {
        onCompleted: () => {
          patientProfileRefetch()
          setSavedFields(dirtyFields)
          reset(watchAllFields)
        },
      }
    )

  const patientProfile = patientProfileData?.getPatientProfile
  const prettifyBloodType = (uglyBloodType: BloodType) => {
    return (
      BLOOD_TYPE_ENTRIES.find((entry) => entry[0] === uglyBloodType)?.[1] ||
      uglyBloodType
    )
  }
  const submitForm = useCallback(
    (formFields: PatientFormData) => {
      const height = formFields.height as any
      const weight = formFields.weight as any
      const emergencyContacts = [
        {
          name: formFields.emergencyContactName,
          phone: formFields.emergencyContactPhone,
        },
      ]

      delete formFields.emergencyContactName
      delete formFields.emergencyContactPhone

      if (!patientId) {
        return
      }

      updatePatientProfileMutation({
        variables: {
          patientId,
          updatePatientInput: {
            ...formFields,
            emergencyContacts,
            height: isNaN(height) ? undefined : +height,
            weight: isNaN(weight) ? undefined : +weight,
          },
        },
      })
    },
    [patientId, updatePatientProfileMutation]
  )

  const required = {
    value: true,
    message: t('messages:warnings.required'),
  }

  const editableFields = [
    {
      key: 'phone',
      pattern: {
        value: ValidPhonePattern,
        message: 'messages:warnings.notPhone',
      },
      placeholder: '+36 12 3456789',
      required,
    },
    { key: 'address' },
    { key: 'height', placeholder: '162' },
    { key: 'weight', placeholder: '77' },
    { key: 'takenMedicines', multiline: true, required },
    { key: 'medicineIntolerance', multiline: true, required },
    { key: 'emergencyContactName' },
    {
      key: 'emergencyContactPhone',
      pattern: {
        value: ValidPhonePattern,
        message: 'messages:warnings.notPhone',
      },
      placeholder: '+36 12 3456789',
    },
  ]

  return (
    <ScrollablePanel
      title={t('patients:personalData')}
      headerRightContent={
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <PasswordReset />
          </Box>
          <Box>
            <SaveButton
              isSaving={isUpdatingProfile}
              disabled={!isDirty || isUpdatingProfile || !isValid}
              onClick={handleSubmit(submitForm)}
            />
          </Box>
        </Box>
      }
      isLoading={isLoadingPatientProfile}
    >
      <Box display="flex">
        <Box maxWidth={400} width="100%">
          <Box my={1}>
            <Typography variant="subtitle2">{t('patients:name')}</Typography>
            <Typography variant="body2">
              {t('common:formattedNameFull', patientProfile)}
            </Typography>
          </Box>
          <Box my={1}>
            <Typography variant="subtitle2">{t('patients:taj')}</Typography>
            <Typography variant="body2">{patientProfile?.tajNumber}</Typography>
          </Box>
          <Box my={1}>
            <Typography variant="subtitle2">
              {t('patients:birthdate')}
            </Typography>
            <Typography variant="body2">
              {t('common:dateFormatted', {
                date: patientProfile?.birthDate,
              })}
            </Typography>
          </Box>
          {!!patientProfile?.bloodType && (
            <Box my={1}>
              <Typography variant="subtitle2">
                {t('patients:bloodType')}
              </Typography>
              <Typography variant="body2">
                {prettifyBloodType(patientProfile.bloodType)}
              </Typography>
            </Box>
          )}
          <Divider />

          <form>
            {editableFields.map((field) => {
              const { key, multiline, pattern, placeholder, required } = field
              const error = (errors as any)[key]

              return (
                <BoxWithLabel key={key} label={t(`patients:${key}`)}>
                  <TextField
                    {...register(key as keyof PatientFormData, {
                      pattern: pattern,
                      required,
                    })}
                    size="small"
                    multiline={multiline}
                    type="textarea"
                    fullWidth
                    placeholder={placeholder}
                    error={!!error}
                    helperText={error?.message ? t(error?.message) : undefined}
                    onBlur={() => trigger()}
                    InputProps={{
                      endAdornment: (
                        <InputStatusAdornment
                          modified={(dirtyFields as any)?.[key]}
                          saved={(savedFields as any)?.[key]}
                        />
                      ),
                    }}
                  />
                </BoxWithLabel>
              )
            })}
          </form>
        </Box>
      </Box>
    </ScrollablePanel>
  )
}

export { PatientProfile }

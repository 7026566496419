export type ArrowDistanceConfig = {
  maxDistance: string
  color?: string
  strokeWidth?: number
  dashness?:
    | boolean
    | {
        strokeLen?: number
        nonStrokeLen?: number
        animation?: boolean | number
      }
}

export const ARROW_DISTANCES: ArrowDistanceConfig[] = [
  {
    maxDistance: '1d',
    color: '#b9b900',
  },
  {
    maxDistance: '2d',
    color: '#7bbf28',
  },
  {
    maxDistance: '3d',
    color: '#1a995e',
  },
  {
    maxDistance: '1w',
    color: '#1e88bd',
  },
  {
    maxDistance: '2w',
    color: '#1649c0',
  },
  {
    maxDistance: '4w',
    color: '#9317b3',
  },
  {
    maxDistance: 'broken',
    color: '#e30c0c',
    strokeWidth: 3,
    dashness: true,
  },
  {
    maxDistance: 'default',
    color: '#000000',
  },
]

export const MESSAGES_LIMIT = 20
export const PAGE_SIZE = 20
export const OPTIMAL_DURATION_MAX_LIMIT = 4000

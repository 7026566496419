import React, { useEffect, MouseEvent } from 'react'

import { useHistory } from 'react-router-dom'
import { Box, Divider } from '@mui/material'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { useUserType } from '../../../../common/hooks/useUserType'

import theme from '../../../../theme'

import { HeaderInfo } from './HeaderInfo'
import { Contact } from './Contact'
import { ProfileInfo } from './ProfileInfo'
import { DeleteAction } from './DeleteAction'
import {
  EmergencyContactInput,
  getDoctorPatientProfile_getPatientProfile,
} from '../../../../models/graphql'
import { useDoctorPatientProfileData } from '../hooks/useDoctorPatientProfileData'

type Props = {
  patientProfile: getDoctorPatientProfile_getPatientProfile
  isEesztTokenValid: boolean
  takenMedicines?: string[]
  medicineIntolerance?: string[]
  emergencyContacts?: EmergencyContactInput[]
  isDeletable?: boolean
  isRemoved?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  onRemove?: () => void
}

const PatientDetails: React.FC<Props> = ({
  patientProfile,
  isDeletable,
  isEesztTokenValid,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userType = useUserType()

  const { refetch: refetchPatientProfile } = useDoctorPatientProfileData({
    skip: false,
  })

  useEffect(() => {
    refetchPatientProfile()
  }, [refetchPatientProfile])

  return (
    <>
      <Box
        borderColor={theme.palette.grey[300]}
        bgcolor={theme.palette.background.default}
      >
        <Box
          display="flex"
          alignItems="center"
          minHeight={112}
          position="relative"
          data-cy={`Patient-Box-${patientProfile.tajNumber}`}
          data-id={patientProfile.id}
          pl={1.5}
        >
          <HeaderInfo patientProfile={patientProfile} />
        </Box>

        <Divider />
        <ProfileInfo
          patient={patientProfile}
          isEesztTokenValid={isEesztTokenValid}
        />
        {patientProfile.user && (
          <Contact
            email={patientProfile.user.email}
            phone={patientProfile.phone}
            chatUserId={patientProfile.user.id}
          />
        )}
        {isDeletable && (
          <>
            <Divider />
            <DeleteAction
              patientId={patientProfile.id}
              patientName={t('common:formattedNameFull', patientProfile)}
              tajNumber={patientProfile.tajNumber}
              onRemove={() => history.push(`/${userType}/patients`)}
            />
          </>
        )}
      </Box>
    </>
  )
}

export { PatientDetails }

import { Button, Box, Typography } from '@mui/material'
import React from 'react'
import { useAppointmentLocation } from '../../../../../common/hooks/useAppointmentLocation'
import { getPatientHomeData_getAppointments_appointments as PatientAppointment } from '../../../../../models/graphql'

type Props = {
  appointment: PatientAppointment
}

export const AppointmentLocation: React.FC<Props> = ({ appointment }) => {
  const { address, mapLink, roomName, institutionName } =
    useAppointmentLocation(appointment)

  return (
    <Button
      variant="text"
      disabled={!mapLink}
      href={mapLink || ''}
      target="_blank"
      sx={{ p: 0 }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        display="flex"
        flexDirection="column"
        textAlign="left"
        maxWidth={300}
      >
        <Typography color="primary" variant="body2">
          {institutionName}
        </Typography>
        <Typography color="primary" variant="subtitle2">
          {roomName}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {address}
        </Typography>
      </Box>
    </Button>
  )
}

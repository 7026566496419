import CloseIcon from '@mui/icons-material/Close'
import { Box, Card, IconButton, Typography } from '@mui/material'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import React from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { useDocumentDownload } from '../../../../../common/hooks/useDocumentDownload'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { UploadFileInput } from './fileUpload.types'
import { getSizeText } from './fileUpload.util'

interface Props {
  files: UploadFileInput[]
  setFiles?: (newFiles: UploadFileInput[]) => void
}

export const FileDisplay: React.FC<Props> = ({ files, setFiles }) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  const removeFileFromList = (index: number) => {
    const newList = files.filter(
      (_file: UploadFileInput, i: number) => i !== index
    )
    isFunction(setFiles) && setFiles(newList)
  }

  const [downloadDocument] = useDocumentDownload()

  const onDownload = async (documentId: string) => {
    const { data } = await downloadDocument({
      variables: { documentIds: [documentId] },
    })

    const file = data?.downloadDocuments[0]

    if (!!file) {
      const downloadLink = document.createElement('a')
      const fileName = file.document.originalFileName

      downloadLink.href =
        'data:application/octet-stream;base64,' + file.base64EncodedFile
      downloadLink.download = fileName
      downloadLink.click()
    }
  }

  return (
    <>
      {!isFunction(setFiles) && files.length === 0 && (
        <Typography variant="h6">{t('survey:result.noFileData')}</Typography>
      )}
      {files.map((file: UploadFileInput, i: number) => (
        <Card
          key={i}
          elevation={0}
          sx={{
            my: 1,
            p: 2,
            border: `solid 1px ${colorPalette.grey[200]}`,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            cursor: isString(file.documentId) ? 'pointer' : 'default',
          }}
          onClick={() =>
            isString(file.documentId) && onDownload(file.documentId)
          }
        >
          <Typography variant="body2">{file.file.name}</Typography>
          {file.file.size !== 0 && (
            <Typography variant="subtitle1">
              {getSizeText(file.file.size)}
            </Typography>
          )}
          {isFunction(setFiles) && (
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
              <IconButton onClick={() => removeFileFromList(i)}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Card>
      ))}
    </>
  )
}

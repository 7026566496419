import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Button, ButtonProps } from '@mui/material'
import isFunction from 'lodash/isFunction'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { NotificationContext } from '../../../../../common/providers/NotificationProvider'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useSurveySchemaDuplicate } from '../../hooks/useSurveySchemaDuplicate'
import { useStylesForSurveyActionButtons } from '../../utils/style.util'
import { SurveySchema } from '../Surveys/surveys.types'
import { useUserType } from '../../../../../common/hooks/useUserType'

interface Props extends ButtonProps {
  surveySchema: SurveySchema
  refetchSurveys?: () => void
}

export const DuplicateSurveyButton: React.FC<Props> = ({
  surveySchema,
  refetchSurveys,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userType = useUserType()
  const { setSuccessToast, setErrorToast } = useContext(NotificationContext)
  const classes = useStylesForSurveyActionButtons()

  const [duplicateSurveySchema] = useSurveySchemaDuplicate()

  const onDuplicate = async () =>
    await duplicateSurveySchema({
      variables: {
        surveySchemaId: surveySchema.id,
      },
      onCompleted: () => {
        if (isFunction(refetchSurveys)) {
          refetchSurveys()
        } else {
          history.push(`/${userType?.toLowerCase()}/survey/${surveySchema.id}`)
        }
        setSuccessToast('successDuplicateSurvey')
      },
      onError: (e) => setErrorToast(e),
    })

  return (
    <Button
      classes={{ textPrimary: classes.textPrimary }}
      variant="text"
      startIcon={<ContentCopyIcon />}
      onClick={onDuplicate}
      {...buttonProps}
      data-cy={'DuplicateSurveyButton'}
    >
      {t('survey:buttons.duplicate')}
    </Button>
  )
}

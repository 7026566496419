import { authModel } from './auth/auth.model'
import { toastModel } from './toast/toast.model'

import { StoreModel } from './store.interface'

const storeModel: StoreModel = {
  auth: authModel,
  toast: toastModel,
}

export { storeModel }

import { Theme } from '@mui/material/styles/createTheme'
import React from 'react'
import { Box, Typography, useMediaQuery, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { About } from './About'
import { Law } from './Law'
import { Contact } from './Contact'
import { Logo } from '../../components/Logo'
import { useTranslation } from '../../hooks/helper/useTranslation'

interface Props {
  setIsFooterOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const OpenFooter: React.FC<Props> = ({ setIsFooterOpen }) => {
  const { t } = useTranslation()
  const mdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  return (
    <Stack
      direction={mdUp ? 'row' : 'column'}
      pt={2}
      pb={{ xs: 5, sm: 2 }}
      px={{ xs: 2, lg: 4 }}
      height={{ xs: 370, sm: 340, md: 200 }}
    >
      <Stack flex={1} mt={2} flexDirection={mdUp ? 'column' : 'row'}>
        <Box>
          <Logo width={{ xs: 120, md: 100 }} />
        </Box>
        {!mdUp && (
          <Stack
            alignItems="flex-end"
            onClick={() => setIsFooterOpen(false)}
            sx={{ cursor: 'pointer' }}
            flex={1}
          >
            <CloseIcon />
          </Stack>
        )}
      </Stack>
      <Box sx={{ borderRight: 'solid' }} />
      <Stack flex={{ md: 4, lg: 2, xl: 1 }}>
        {mdUp && (
          <Stack
            alignItems="flex-end"
            onClick={() => setIsFooterOpen(false)}
            sx={{ cursor: 'pointer' }}
          >
            <CloseIcon />
          </Stack>
        )}
        <Stack
          direction={mdUp ? 'row' : 'column'}
          justifyContent="space-between"
          pl={{ md: 3 }}
          mb={4}
        >
          <About isFooterOpen variant="h6" />
          <Law isFooterOpen variant="body2" fontWeight={400} />
          <Contact isFooterOpen variant="body2" />
        </Stack>
        <Typography variant="body2" textAlign={mdUp ? 'right' : 'left'}>
          {t('footer:rights')}
        </Typography>
      </Stack>
    </Stack>
  )
}

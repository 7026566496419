import { LocalSchedule } from '../treatmentSchemaSchedule.types'

// Note: value is locize key
export enum DEPENDENCY_ERROR {
  ALREADY_EXISTS = 'protocol:dependency.error.alreadyExists',
  INVALID = 'protocol:dependency.error.missing',
  CIRCULAR_DEPENDENCY = 'protocol:dependency.error.circularDependency',
  MINMAX_DISTANCE = 'protocol:dependency.error.minmaxDistance',
}

export interface LocalDependency {
  id: string
  startId?: string
  endId?: string
  distance?: string
  constraint?: MinMax
  error?: DEPENDENCY_ERROR | null
}

export enum TimeUnit {
  H = 'h',
  D = 'd',
  W = 'w',
}

export enum MinMax {
  MIN = 'MIN',
  MAX = 'MAX',
  NotSpecified = 'NotSpecified',
}
export interface FormValues {
  start: LocalSchedule | null
  end: LocalSchedule | null
  unit: TimeUnit
  timeConstraintNeeded: boolean
  distanceValue: number
  minMaxConstraint: MinMax
}

export type DependencyMap = Record<string, LocalDependency[]>

import { useQuery } from '@apollo/client'
import {
  getTreatmentSchema,
  getTreatmentSchema_getTreatmentSchema,
  getTreatmentSchemaVariables,
} from '../../../../models/graphql'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import { GET_TREATMENT_SCHEMA } from '../operations/treatmentSchemaOperations'

type Options = QueryHookOptions<getTreatmentSchema, getTreatmentSchemaVariables>

type Return = {
  treatmentSchema?: getTreatmentSchema_getTreatmentSchema
  loading: boolean
  refetch: () => void
}

export const useTreatmentSchemaData = (options?: Options): Return => {
  const {
    data: { getTreatmentSchema: treatmentSchema } = {},
    loading,
    refetch,
  } = useQuery<getTreatmentSchema, getTreatmentSchemaVariables>(
    GET_TREATMENT_SCHEMA,
    options
  )

  return {
    loading,
    treatmentSchema,
    refetch,
  }
}

import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { DoctorInfo } from '../../../../../common/components/DoctorInfo/DoctorInfo'
import { getPatientHomeData_getAppointments_appointments_doctor } from '../../../../../models/graphql'

type DeclineAppointmentDialogProps = {
  openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  doctor: getPatientHomeData_getAppointments_appointments_doctor
}
const DeclineAppointmentDialog: React.FC<DeclineAppointmentDialogProps> = ({
  openState,
  doctor,
}) => {
  const { t } = useTranslation()
  const [isOpen, setOpenState] = openState

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        {t('appointment:patient.declineAppointmentDialogTitle')}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t('appointment:patient.declineAppointmentDialogText')}
        </Typography>
      </DialogContent>
      <DialogContent>
        <DoctorInfo
          doctor={doctor}
          linkToDoctor={`/patient/doctors/${doctor.id}`}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenState(false)} variant="outlined">
          {t('common:close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { DeclineAppointmentDialog }

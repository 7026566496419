import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  sendSurveyToUsers,
  sendSurveyToUsersVariables,
} from '../../../../models/graphql'
import { SEND_SURVEY_TO_USER } from '../operations/surveyOperations'

type Options = MutationHookOptions<
  sendSurveyToUsers,
  sendSurveyToUsersVariables
>

type Return = MutationTuple<sendSurveyToUsers, sendSurveyToUsersVariables>

export const useSendSurveyToUsers = (options: Options = {}): Return => {
  return useMutation<sendSurveyToUsers, sendSurveyToUsersVariables>(
    SEND_SURVEY_TO_USER,
    options
  )
}

import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { Controller, useFormContext } from 'react-hook-form'
import { Autocomplete } from '@mui/material'
import { TextField } from '@mui/material'
import {
  useInstitutionList,
  UseInstitutionListOptions,
} from '../../../common/hooks/useInstitutionList'
import { BoxWithLabel } from '../../../common/components/BoxWithLabel'
import { useDebounce } from '../../../common/hooks/helper/useDebounce'
import { isFunction } from 'lodash'

type Props = {
  name: string
  label?: string
  required?: boolean
  hasError?: boolean
  multiple?: boolean
  queryOptions?: UseInstitutionListOptions
  disabled?: boolean
  onSelect?: () => void
  dataCy?: string
}

export const InstitutionSelector: FC<Props> = ({
  name,
  label,
  required,
  hasError,
  queryOptions,
  multiple = true,
  disabled = false,
  onSelect,
  dataCy,
}) => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()
  const selectedInstitutions = watch(name) ?? []

  const [inputValue, setInputValue] = useState('')
  const nameSearchTerm = useDebounce(inputValue, 200)

  const { loading, activeInstitutions, refetch } = useInstitutionList({
    ...queryOptions,
    notifyOnNetworkStatusChange: true,
    variables: { ...queryOptions?.variables, nameSearchTerm },
  })

  useEffect(() => {
    refetch()
  }, [refetch, nameSearchTerm])

  // we need to calculate the options to avoid this warning: The value provided to Autocomplete is invalid.
  const options = multiple
    ? [...selectedInstitutions, ...activeInstitutions]
    : [selectedInstitutions, ...activeInstitutions]

  return (
    <BoxWithLabel label={label}>
      <Controller
        control={control}
        name={name}
        defaultValue={!multiple ? null : []}
        rules={{
          required,
          validate: (value) => (required ? value?.length > 0 : true),
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Autocomplete
            data-cy={dataCy}
            disabled={disabled}
            multiple={multiple}
            options={options}
            disableClearable
            filterSelectedOptions
            loading={loading}
            getOptionLabel={(institution) => institution.name ?? ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={value || (!multiple ? null : [])}
            loadingText={t('common:loading')}
            noOptionsText={t('common:noOptions')}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(event: any, value, reason) => {
              const removedByBackspaceKey =
                event.key === 'Backspace' && reason === 'removeOption'
              if (!removedByBackspaceKey) {
                onChange(multiple ? [...value] : value)
              }
              isFunction(onSelect) && onSelect()
            }}
            onBlur={onBlur}
            renderOption={(props, option, index) => {
              return (
                <li {...props} key={option.id ?? index}>
                  {option.name}
                </li>
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder={t('common:search')}
                error={hasError}
                helperText={
                  hasError ? t('messages:warnings.required') : undefined
                }
                variant="outlined"
              />
            )}
          />
        )}
      />
    </BoxWithLabel>
  )
}

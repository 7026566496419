import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { EmptyState } from '../../../../../common/components/EmptyState'
import { Loading } from '../../../../../common/components/Loading'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  DateIntervalInput,
  getSurveySchemaById_getSurveySchemaById as SurveySchema,
} from '../../../../../models/graphql'
import { useSurveyResultByPatient } from '../../hooks/useSurveyResultByPatient'
import { SurveyResultByPatient } from './SurveyResultByPatient'

const MAX_RESULT_AMOUNT = 20

interface Props {
  surveySchema: SurveySchema
  dateIntervalFilter: DateIntervalInput | null
}

export const GetSurveyResultByPatient: React.FC<Props> = ({
  surveySchema,
  dateIntervalFilter,
}) => {
  const { t } = useTranslation()

  const [actualPatientIndex, setActualPatientIndex] = useState<number>(0)
  const [hidePatientDetails, setHidePatientDetails] = useState<boolean>(false)

  const {
    loading,
    surveyResultsByPatient,
    totalSurveyResults,
    csvInBase64,
    xlsxInBase64,
    fetchMore,
    refetch,
  } = useSurveyResultByPatient({
    variables: {
      surveySchemaId: surveySchema.id,
      isAnonymous: hidePatientDetails,
      dateIntervalInput: dateIntervalFilter,
      paginationInput: {
        offset: 0,
        limit: MAX_RESULT_AMOUNT,
      },
    },
  })

  const fetchMoreResult = () =>
    fetchMore({
      variables: {
        surveySchemaId: surveySchema.id,
        isAnonymous: hidePatientDetails,
        dateIntervalInput: dateIntervalFilter,
        paginationInput: {
          offset: surveyResultsByPatient?.length,
          limit: MAX_RESULT_AMOUNT,
        },
      },
    })

  // If date filter or hidePatientDetails changes we need to refetch the data
  // and reset the index to 0
  useEffect(() => {
    refetch()
    setActualPatientIndex(0)
  }, [dateIntervalFilter, hidePatientDetails, refetch])

  return (
    <>
      {loading && <Loading />}
      {!surveyResultsByPatient && !loading && (
        <Box
          flexGrow={1}
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <EmptyState
            title={t('survey:surveySchemaNotFoundTitle')}
            description={t('survey:surveySchemaNotFoundMessage', {
              surveySchemaId: surveySchema.id,
            })}
          />
        </Box>
      )}
      {surveyResultsByPatient && !loading && (
        <SurveyResultByPatient
          surveySchema={surveySchema}
          surveyResults={surveyResultsByPatient}
          totalSurveyResults={totalSurveyResults}
          fetchMoreResult={fetchMoreResult}
          hidePatientDetails={hidePatientDetails}
          setHidePatientDetails={setHidePatientDetails}
          actualPatientIndexState={[actualPatientIndex, setActualPatientIndex]}
          csvInBase64={csvInBase64}
          xlsxInBase64={xlsxInBase64}
        />
      )}
    </>
  )
}

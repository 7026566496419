import { useMutation } from '@apollo/client'
import { ADD_SURVEY_SCHEMA_TO_TREATMENT_SCHEMA } from '../operations/treatmentSchemaOperations'
import {
  addSurveySchemaToTreatmentSchema as AddSurveySchemaToTreatmentSchemaData,
  addSurveySchemaToTreatmentSchemaVariables as AddSurveySchemaToTreatmentSchemaVariables,
} from '../../../../models/graphql'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'

type Options = MutationHookOptions<
  AddSurveySchemaToTreatmentSchemaData,
  AddSurveySchemaToTreatmentSchemaVariables
>

type Return = MutationTuple<
  AddSurveySchemaToTreatmentSchemaData,
  AddSurveySchemaToTreatmentSchemaVariables
>

export const useAddSurveySchemaToTreatmentSchema = (
  options: Options = {}
): Return => {
  return useMutation<
    AddSurveySchemaToTreatmentSchemaData,
    AddSurveySchemaToTreatmentSchemaVariables
  >(ADD_SURVEY_SCHEMA_TO_TREATMENT_SCHEMA, {
    refetchQueries: ['getTreatmentSchema'],
    ...options,
  })
}

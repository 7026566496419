import { ApolloClient, InMemoryCache } from '@apollo/client'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { getLink, simpleLink } from './apollo-links'
import typePolicies from './apollo-type-policies'

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies,
  }),
  link: getLink(),
})

apolloClient.onResetStore(async () => {
  apolloClient.setLink(simpleLink)
})

const ApolloApp: React.FC = ({ children }) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default ApolloApp

import React from 'react'
import { Grid } from '@mui/material'

import { getPartners_getPartners } from '../../models/graphql'
import { MessageBoardPartner } from './MessageBoardPartner'

type Props = {
  partners: getPartners_getPartners[] | null | undefined
  selectedPartnerId: string | undefined
  onSelectPartner?: (partner: getPartners_getPartners) => void
}

const MessageBoardPartners: React.FC<Props> = ({
  partners,
  selectedPartnerId,
  onSelectPartner,
}) => {
  return (
    <>
      <Grid container spacing={1} direction="column" alignItems="stretch">
        {partners &&
          partners.map((partner) => (
            <Grid item key={partner.id} xs={12}>
              <MessageBoardPartner
                partner={partner}
                isSelected={partner.id === selectedPartnerId}
                onClick={() => {
                  onSelectPartner && onSelectPartner(partner)
                }}
              />
            </Grid>
          ))}
      </Grid>
    </>
  )
}

export { MessageBoardPartners }

import React, { FC } from 'react'
import { Box, BoxProps, Typography, useTheme } from '@mui/material'
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  FiberManualRecord as FiberManualRecordIcon,
  DoNotDisturbAlt as DoNotDisturbAltIcon,
} from '@mui/icons-material'
import { AppointmentStatus } from '../../../../../models/graphql'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'

type Props = {
  status: string
  proposedDate: Date
  isMobile?: boolean
  containerProps?: BoxProps
  isBetmenBooked: boolean
  eesztBookingId: string | null
}
const AppointmentTimeAndStatus: FC<Props> = (props) => {
  const {
    children,
    status,
    proposedDate: date,
    isMobile,
    containerProps,
    isBetmenBooked,
    eesztBookingId,
  } = props
  const theme = useTheme()
  const { t } = useTranslation()

  const statusMap: {
    [key: string]: { icon: React.ReactNode; color: string }
  } = {
    [AppointmentStatus.Proposed]: {
      icon: <FiberManualRecordIcon />,
      color: theme.palette.error.main,
    },
    [AppointmentStatus.BetmenBooked]: {
      icon: <CheckCircleIcon />,
      color: theme.palette.success.dark,
    },
    [AppointmentStatus.Expired]: {
      icon: <DoNotDisturbAltIcon />,
      color: theme.palette.primary.main,
    },
    [AppointmentStatus.Happened]: {
      icon: <CheckCircleIcon />,
      color: theme.palette.success.dark,
    },
    [AppointmentStatus.Missed]: {
      icon: <CancelIcon />,
      color: theme.palette.error.main,
    },
  }

  const dateString = t('common:patientFormattedDate', { date })
  const timeString = t('common:patientFormattedTime', { date })
  const shouldDisplayTime = isBetmenBooked || !!eesztBookingId

  const dateTimeString = `${dateString}${
    shouldDisplayTime ? `, ${timeString}` : ''
  }`

  const currentStatus = statusMap[status]

  return isMobile ? (
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      {...containerProps}
    >
      <Box>
        <Typography variant="subtitle1">{dateTimeString}</Typography>
        {children}
      </Box>
      <Box color={currentStatus.color} display="flex">
        <Box pr={{ xs: 0, md: 0.5 }}>{currentStatus.icon}</Box>
      </Box>
    </Box>
  ) : (
    <Box
      width={250}
      display="flex"
      flexShrink={0}
      flexDirection="column"
      alignItems="flex-start"
      {...containerProps}
    >
      <Typography variant="subtitle1">{dateString}</Typography>
      {children}
      <Box
        display="flex"
        data-cy={`AppoindmentStatus-Box-${status}`}
        alignItems="center"
      >
        {shouldDisplayTime && <Box width={60}>{timeString}</Box>}
        <Box color={currentStatus.color} display="flex" alignItems="center">
          {currentStatus.icon}
          <Typography variant="subtitle2" pl={{ xs: 0, md: 0.5 }}>
            {t(`appointment:status.${status}`)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default AppointmentTimeAndStatus

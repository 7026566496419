import { gql } from '@apollo/client'
import { FRAGMENT_TREATMENT_SCHEMA } from '../../../../operations/fragments'

export const GET_TREATMENT_SCHEMAS = gql`
  query getTreatmentSchemas(
    $treatmentSchemaFilter: TreatmentSchemaFilter
    $paginationInput: PaginationInput
  ) {
    getTreatmentSchemas(
      treatmentSchemaFilter: $treatmentSchemaFilter
      paginationInput: $paginationInput
    ) {
      treatmentSchemas {
        ...treatmentOptionsChore
        isDeletable
      }
      total
    }
  }
  ${FRAGMENT_TREATMENT_SCHEMA}
`

export const GET_TREATMENT_SCHEMA = gql`
  query getTreatmentSchema($treatmentSchemaId: String!) {
    getTreatmentSchema(treatmentSchemaId: $treatmentSchemaId) {
      ...treatmentOptionsChore
      isDeletable
      surveySchemas {
        id
        customDays
        surveySchema {
          id
          title
        }
      }
      schedules {
        id
        createdAt
        customDays
        appointmentInfo {
          id
          goodToKnow {
            ...multiLangText
          }
          doctorTitle {
            ...multiLangText
          }
          patientTitle {
            ...multiLangText
          }
          doctorTodo {
            ...multiLangText
          }
          professions {
            id
            name
          }
          beforeTreatmentNote
          oeno
        }
        doctorTitle {
          ...multiLangText
        }
        date {
          start
          end
        }
        dependencies {
          id
          fromId
          distance
          type
        }
        dependents {
          id
          fromId
          distance
        }
      }
    }
  }
  ${FRAGMENT_TREATMENT_SCHEMA}
`

export const CREATE_NEW_TREATMENT_SCHEMA = gql`
  mutation createNewTreatmentSchema($schema: TreatmentSchemaCreateInput!) {
    createNewTreatmentSchema(schema: $schema) {
      id
    }
  }
`

export const ADD_SCHEDULE = gql`
  mutation addSchedule(
    $treatmentSchemaId: String!
    $appointmentInfoId: String!
  ) {
    addSchedule(
      treatmentSchemaId: $treatmentSchemaId
      appointmentInfoId: $appointmentInfoId
    ) {
      id
    }
  }
`

export const CREATE_SCHEDULES = gql`
  mutation createSchedules(
    $treatmentSchemaId: String!
    $appointmentInfoIds: [String!]!
  ) {
    createSchedules(
      treatmentSchemaId: $treatmentSchemaId
      appointmentInfoIds: $appointmentInfoIds
    ) {
      id
    }
  }
`

export const REMOVE_SCHEDULES = gql`
  mutation removeSchedules($scheduleIds: [String!]!) {
    removeSchedules(scheduleIds: $scheduleIds)
  }
`

export const REMOVE_SCHEDULE = gql`
  mutation removeSchedule($scheduleId: String!) {
    removeSchedule(scheduleId: $scheduleId) {
      id
    }
  }
`

export const ADD_SCHEMA_DEPENDENCY = gql`
  mutation addSchemaDependency($dependencyInput: CreateDependencyInput!) {
    addSchemaDependency(dependencyInput: $dependencyInput) {
      id
    }
  }
`

export const REMOVE_SCHEMA_DEPENDENCY = gql`
  mutation removeSchemaDependency(
    $treatmentSchemaId: String!
    $dependencyId: String!
  ) {
    removeSchemaDependency(
      treatmentSchemaId: $treatmentSchemaId
      dependencyId: $dependencyId
    ) {
      id
    }
  }
`

export const UPDATE_SCHEMA_DEPENDENCY = gql`
  mutation updateSchemaDependency($dependencyInput: UpdateDependencyInput!) {
    updateSchemaDependency(dependencyInput: $dependencyInput) {
      id
    }
  }
`

export const REMOVE_TREATMENT_SCHEMA = gql`
  mutation removeTreatmentSchema($treatmentSchemaId: String!) {
    removeTreatmentSchema(treatmentSchemaId: $treatmentSchemaId) {
      id
    }
  }
`

export const UPDATE_TREATMENT_SCHEMA = gql`
  mutation updateTreatmentSchema($treatmentData: UpdateSchemaInput!) {
    updateTreatmentSchema(treatmentData: $treatmentData) {
      ...treatmentOptionsChore
    }
  }
  ${FRAGMENT_TREATMENT_SCHEMA}
`

export const UPDATE_TREATMENT_SCHEMA_STATUS = gql`
  mutation updateTreatmentSchemaStatus(
    $treatmentSchemaId: ID!
    $publish: Boolean!
  ) {
    updateTreatmentSchemaStatus(
      treatmentSchemaId: $treatmentSchemaId
      publish: $publish
    ) {
      id
      disabled
      isDeletable
    }
  }
`

export const DUPLICATE_TREATMENT_SCHEMA = gql`
  mutation duplicateTreatmentSchema($treatmentSchemaId: String!) {
    duplicateTreatmentSchema(treatmentSchemaId: $treatmentSchemaId) {
      id
    }
  }
`

export const SHARE_TREATMENT_SCHEMA = gql`
  mutation shareTreatmentSchema($treatmentData: ShareSchemaInput!) {
    shareTreatmentSchema(treatmentData: $treatmentData) {
      ...treatmentOptionsChore
    }
  }
  ${FRAGMENT_TREATMENT_SCHEMA}
`

export const UNSUBSCRIBE_TREATMENT_SCHEMA = gql`
  mutation unSubscribeTreatmentSchema($treatmentData: UnsubscribeSchemaInput!) {
    unSubscribeTreatmentSchema(treatmentData: $treatmentData) {
      ...treatmentOptionsChore
    }
  }
  ${FRAGMENT_TREATMENT_SCHEMA}
`

export const SET_CUSTOM_DAYS_OF_SCHEMA_SCHEDULE = gql`
  mutation setCustomDaysOfSchedule($scheduleId: String!, $customDays: Float) {
    setCustomDaysOfSchedule(scheduleId: $scheduleId, customDays: $customDays) {
      id
      customDays
    }
  }
`

export const ADD_SURVEY_SCHEMA_TO_TREATMENT_SCHEMA = gql`
  mutation addSurveySchemaToTreatmentSchema(
    $treatmentSchemaId: String!
    $surveySchemaId: String!
  ) {
    addSurveySchemaToTreatmentSchema(
      treatmentSchemaId: $treatmentSchemaId
      surveySchemaId: $surveySchemaId
    ) {
      id
    }
  }
`

export const REMOVE_SURVEY_FROM_TREATMENT_SCHEMA = gql`
  mutation removeSurveySchemaFromTreatmentSchema(
    $treatmentSchemaToSurveyId: String!
  ) {
    removeSurveySchemaFromTreatmentSchema(
      treatmentSchemaToSurveyId: $treatmentSchemaToSurveyId
    ) {
      id
    }
  }
`

export const SET_CUSTOM_DAYS_OF_TREATMENT_SCHEMA_SURVEY = gql`
  mutation setCustomDaysOfTreatmentSchemaSurvey(
    $treatmentSchemaToSurveyId: String!
    $customDays: Float
  ) {
    setCustomDaysOfTreatmentSchemaSurvey(
      treatmentSchemaToSurveyId: $treatmentSchemaToSurveyId
      customDays: $customDays
    ) {
      id
      customDays
    }
  }
`

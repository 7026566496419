import { ApolloLink, from, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { apolloClient } from './ApolloProvider'
import { store } from './store'

const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'

const httpLink = createUploadLink({
  uri: `${window.location.origin}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      store.getActions().toast.setToast({
        text: `[GraphQL error]: ${message} in ${path}`,
        type: 'error',
      })
    })
  }
  if (networkError) {
    console.log(networkError)
    if (networkError.message.includes('Unexpected token')) {
      localStorage.removeItem('token')
      window.location.href = '/login'
      apolloClient.resetStore()
    } else {
      store.getActions().toast.setToast({
        text: `[Network error]: ${networkError?.message || networkError}`,
        type: 'error',
      })
    }
  }
})

export const simpleLink = from([authLink, errorLink, httpLink])

export const getLink = (): ApolloLink => {
  const wsLink = new WebSocketLink({
    uri: `${wsProtocol}//${window.location.host}/graphql`,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  })

  const operationSplitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )

  return split(
    (operation) => {
      const { isCms } = operation.getContext()
      return isCms
    },
    from([errorLink]),
    from([authLink, errorLink, operationSplitLink])
  )
}

import { gql } from '@apollo/client'
import { FRAGMENT_PATIENT_CHORE } from './fragments'

export const GET_ASSISTANT_PATIENTS = gql`
  query getAssistantPatients {
    getAssistantProfile {
      id
      ownPatients {
        id
        closedAt
        createdAt
        patient {
          id
          user {
            id
            email
          }
          ...patientChore
          birthDate
          weight
          height
          isDeletableForDoctor
        }
      }
    }
  }
  ${FRAGMENT_PATIENT_CHORE}
`

export const UPDATE_ASSISTANT_PROFILE = gql`
  mutation updateAssistantProfile(
    $updateAssistantInput: UpdateAssistantProfileInput!
  ) {
    updateAssistantProfile(updateAssistantInput: $updateAssistantInput) {
      id
      title
      phone
    }
  }
`

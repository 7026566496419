import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  duplicateTreatmentSchema,
  duplicateTreatmentSchemaVariables,
} from '../../../../models/graphql'
import { DUPLICATE_TREATMENT_SCHEMA } from '../operations/treatmentSchemaOperations'

type Options = MutationHookOptions<
  duplicateTreatmentSchema,
  duplicateTreatmentSchemaVariables
>

type Return = MutationTuple<
  duplicateTreatmentSchema,
  duplicateTreatmentSchemaVariables
>

export const useTreatmentSchemaDuplicate = (options: Options = {}): Return => {
  return useMutation<
    duplicateTreatmentSchema,
    duplicateTreatmentSchemaVariables
  >(DUPLICATE_TREATMENT_SCHEMA, options)
}

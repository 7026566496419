import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  getSurveySchemas_getSurveySchemas_surveySchemas as SurveySchema,
  Me_me,
  SurveySchemaStatusEnum,
} from '../../../../../models/graphql'
import { SurveyActionsMenu } from '../SurveyActions/SurveyActionsMenu'
import { SurveySchemaActionProperties } from './surveys.types'

interface Props {
  surveySchema: SurveySchema
  refetchSurveys: () => void
  me?: Me_me
}

export const SurveyListMore: React.FC<Props> = ({
  surveySchema,
  refetchSurveys,
  me,
}) => {
  const isOwnSurvey = useMemo(
    () => surveySchema.user?.id === me?.id,
    [me?.id, surveySchema.user?.id]
  )

  const formMethods = useForm<SurveySchemaActionProperties>({
    defaultValues: {
      status: surveySchema.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema.canBeDeleted ?? true,
    },
  })

  useEffect(() => {
    formMethods.reset({
      status: surveySchema.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema.canBeDeleted ?? true,
    })
  }, [formMethods, surveySchema])

  return (
    <FormProvider {...formMethods}>
      <SurveyActionsMenu
        surveySchema={surveySchema}
        refetchSurveys={refetchSurveys}
        hideSensitiveMenuItems={!isOwnSurvey && !me?.isAdmin}
      />
    </FormProvider>
  )
}

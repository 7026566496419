import React, { useRef, useEffect } from 'react'
import useMeasure from 'use-measure'

type TableSizeHelperProps = {
  breakpoints: string[]
  currentSize: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCurrentSize: (size: any) => void
}
const TableSizeHelper: React.FC<TableSizeHelperProps> = ({
  breakpoints,
  currentSize,
  setCurrentSize,
}) => {
  const measureRef = useRef(null)
  const { width } = useMeasure(measureRef)

  const actualSize = breakpoints
    .map(Number)
    .sort((a, b) => (a > b ? 1 : -1))
    .find((s) => width < s)

  useEffect(() => {
    if (actualSize !== currentSize) {
      setCurrentSize(actualSize || 0)
    }
  }, [actualSize, currentSize, setCurrentSize])

  return <div ref={measureRef} style={{ width: '100%' }} />
}

export { TableSizeHelper }

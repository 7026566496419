import React, { useState, useMemo } from 'react'
import {
  Box,
  Fade,
  Button,
  IconButton,
  Typography,
  Popper,
  PopperProps,
  Paper,
  ClickAwayListener,
  Tooltip,
} from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { CloseIcon } from '../../../../common/icons/CloseIcon'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { isNumber } from 'lodash'
import { OPTIMAL_DURATION_MAX_LIMIT } from '../../../../config'

type Props = PopperProps & {
  onClose: () => void
  selectedDay?: number | null
  onDayChanged: (day?: number) => void
  minValue?: number | null
  maxValue?: number | null
  isDayDeletable: boolean
}

const WEEK_PAGE_SIZE = 5
const INITIAL_START_WEEK = 1
const days = Array.from(new Array(7).fill(new Array(WEEK_PAGE_SIZE).fill(0)))

export const DayPickerModal: React.FC<Props> = ({
  open,
  anchorEl,
  onClose,
  onDayChanged,
  minValue,
  maxValue,
  selectedDay,
  isDayDeletable,
}) => {
  const [startWeek, setStartWeek] = useState<number>(INITIAL_START_WEEK)

  const { t } = useTranslation()

  const dayButtons = useMemo(() => {
    return days.map((column, columnIndex) => (
      <Box
        key={`week-${columnIndex}`}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        {column.map((_day: number, rowIndex: number) => {
          // Note: day starts from 0, but labels starts from 1
          const dayValue = rowIndex * 7 + columnIndex + 7 * (startWeek - 1)
          const dayLabel = dayValue + 1
          const isNotSelectable =
            (isNumber(minValue) && dayValue < minValue) ||
            (isNumber(maxValue) && dayValue > maxValue) ||
            dayValue >= OPTIMAL_DURATION_MAX_LIMIT
          return (
            <IconButton
              key={`td-${columnIndex}-${rowIndex}`}
              size="small"
              sx={{
                border:
                  isNumber(selectedDay) && dayValue === selectedDay ? 1 : 0,
              }}
              onClick={() => {
                if (!isNotSelectable) {
                  onDayChanged(dayValue)
                }
              }}
            >
              {isNotSelectable ? (
                <Tooltip
                  title={
                    t(
                      'protocol:dayPicker.dependencyConstraintMessage'
                    ) as string
                  }
                  leaveDelay={200}
                  placement="top"
                >
                  <Typography variant="body2">{'x'}</Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" color={'black'}>
                  {dayLabel}
                </Typography>
              )}
            </IconButton>
          )
        })}
      </Box>
    ))
  }, [startWeek, minValue, maxValue, selectedDay, t, onDayChanged])

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="top"
      transition
      style={{ zIndex: 2 }}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClose}>
          <Fade {...TransitionProps} timeout={{ enter: 350, exit: 0 }}>
            <Paper
              elevation={5}
              sx={{
                padding: 1,
                minWidth: 270,
                marginX: 2,
                flexDirection: 'column',
                borderRadius: 1,
              }}
            >
              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
              >
                <Typography variant="body2">
                  {t('protocol:dayPicker.title')}
                </Typography>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <IconButton
                  disabled={startWeek === 1}
                  onClick={() =>
                    setStartWeek(Math.max(1, startWeek - WEEK_PAGE_SIZE))
                  }
                >
                  <ArrowBackIos />
                </IconButton>
                <Typography>
                  {t('protocol:dayPicker.weekInterval', {
                    startWeek,
                    endWeek: startWeek + WEEK_PAGE_SIZE - 1,
                  })}
                </Typography>
                <IconButton
                  onClick={() => setStartWeek(startWeek + WEEK_PAGE_SIZE)}
                >
                  <ArrowForwardIos />
                </IconButton>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                padding={2}
              >
                {dayButtons}
              </Box>
              {isDayDeletable && (
                <Box
                  display="flex"
                  flexDirection={'row'}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button variant="text" onClick={() => onDayChanged()}>
                    {t('protocol:dayPicker.deleteCustomDay')}
                  </Button>
                </Box>
              )}
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  )
}

import { gql } from '@apollo/client'
import { FRAGMENT_PATIENT_CHORE } from '../../../../operations/fragments'

export const GET_DOCTOR_PATIENT_PROFILE = gql`
  query getDoctorPatientProfile($patientId: String!) {
    getPatientProfile(patientId: $patientId) {
      ...patientChore
      gender
      takenMedicines
      medicineIntolerance
      emergencyContacts {
        name
        phone
      }
      phone
      address
      birthDate
      emailSettings
      bloodType
      bloodTypeAttachmentEESZTId
      weight
      height
      isDeletableForDoctor
      doctors {
        id
        firstName
        lastName
        title
        professions {
          id
          code
          name
        }
      }
      user {
        id
        email
      }
    }
  }
  ${FRAGMENT_PATIENT_CHORE}
`

export const DELETE_DOCTORS_OWN_PATIENT = gql`
  mutation deleteDoctorsOwnPatient($patientId: String!) {
    deleteDoctorsOwnPatient(patientId: $patientId) {
      id
      closedAt
    }
  }
`

export const UPDATE_PATIENT_BLOOD_TYPE = gql`
  mutation updatePatientsBloodType(
    $patientId: String!
    $bloodType: String!
    $bloodTypeAttachmentEESZTId: String
  ) {
    updatePatientsBloodType(
      patientId: $patientId
      bloodType: $bloodType
      bloodTypeAttachmentEESZTId: $bloodTypeAttachmentEESZTId
    ) {
      id
      bloodType
      bloodTypeAttachmentEESZTId
    }
  }
`

import React, { FC } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  TextField,
} from '@mui/material'
import { SaveButton } from '../../../common/components/SaveButton'
import { useForm } from 'react-hook-form'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { institutionChore } from '../../../models/graphql'
import { useStoreActions } from '../../../store/store.hooks'
import { useStoreCertificate } from '../hooks/useStoreCertificate'
import { BoxWithLabel } from '../../../common/components/BoxWithLabel'

type FormValues = {
  passphrase: string
  certificateFileList: FileList | null
}
type Props = {
  institution: institutionChore
  isOpen: boolean
  onClose: () => void
}

export const CertificateModal: FC<Props> = (props) => {
  const { institution, isOpen, onClose } = props
  const { t } = useTranslation()
  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      passphrase: '',
      certificateFileList: null,
    },
  })
  const currentCertificateFileList = watch('certificateFileList')

  const [storeCertificate, { loading }] = useStoreCertificate({
    onCompleted: () => {
      setToast({
        text: t('messages:notification.certificateUpload.success'),
        type: 'success',
      })
      onClose()
    },
  })

  const onSubmit = handleSubmit(({ certificateFileList, passphrase }) => {
    if (!certificateFileList?.[0]) {
      return Promise.reject()
    }
    const file = certificateFileList[0]

    return storeCertificate({
      variables: {
        institutionId: institution.id,
        certificate: file,
        passphrase,
      },
    })
  })

  const required = {
    value: true,
    message: t('messages:warnings.required'),
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>
        {t('institution:certificateUpload.userDialogTitle')}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Button component="label">
              <Box
                {...register('certificateFileList', {
                  validate: (data) => {
                    if (!data?.[0]) {
                      return t('messages:warnings.required') as string
                    }
                    return true
                  },
                })}
                display="none"
                component="input"
                type="file"
                accept=".pfx"
              />
              {t('institution:certificateUpload.buttonText')}
            </Button>
            {errors.certificateFileList?.message && (
              <Typography
                sx={{
                  display: 'block',
                  color: 'red',
                  marginTop: '3px',
                  marginLeft: '14px',
                }}
                variant="caption"
              >
                {errors.certificateFileList?.message}
              </Typography>
            )}
          </Box>
          {currentCertificateFileList && (
            <Typography>{currentCertificateFileList[0]?.name}</Typography>
          )}
        </Box>

        <BoxWithLabel
          label={t('institution:certificateUpload.passphraseTitle')}
        >
          <TextField
            {...register('passphrase', { required })}
            variant="outlined"
            type="password"
            disabled={!currentCertificateFileList}
            error={!!errors.passphrase}
            helperText={errors.passphrase?.message}
            fullWidth
            placeholder={t(
              'institution:certificateUpload.passphrasePlaceholder'
            )}
          />
        </BoxWithLabel>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <SaveButton isSaving={loading} onClick={onSubmit} />
      </DialogActions>
    </Dialog>
  )
}

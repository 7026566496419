import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  addSurveyToTreatment,
  addSurveyToTreatmentVariables,
} from '../../../../models/graphql'
import { ADD_SURVEY_TO_TREATMENT } from '../../../../operations/treatmentOperations'

type Options = MutationHookOptions<
  addSurveyToTreatment,
  addSurveyToTreatmentVariables
>

type Return = MutationTuple<addSurveyToTreatment, addSurveyToTreatmentVariables>

export const useAddSurveyToTreatment = (options: Options = {}): Return => {
  return useMutation<addSurveyToTreatment, addSurveyToTreatmentVariables>(
    ADD_SURVEY_TO_TREATMENT,
    options
  )
}

import React from 'react'
import { Tabs, Tab } from '@mui/material'
import { useTranslation } from '../../hooks/helper/useTranslation'

type Props = {
  selectedTabIndex: number
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>
}

export const EesztLoginTabs: React.FC<Props> = ({
  selectedTabIndex,
  setSelectedTabIndex,
}) => {
  const isPasswordLoginEnabled =
    process.env.REACT_APP_EESZT_PASSWORD_LOGIN === 'true'
  const { t } = useTranslation()

  return (
    <Tabs
      value={selectedTabIndex}
      indicatorColor="primary"
      textColor="primary"
      scrollButtons="auto"
      variant="fullWidth"
      onChange={(_, tabIndex) => setSelectedTabIndex(tabIndex)}
    >
      {isPasswordLoginEnabled && (
        <Tab
          label={t(`eeszt:auth.password`)}
          value={2}
          data-cy="EesztLoginTabs-Password"
        />
      )}
      <Tab label={t(`eeszt:auth.mobileToken`)} value={0} />
      <Tab label={t(`eeszt:auth.eszig`)} value={1} />
    </Tabs>
  )
}

import { gql } from '@apollo/client'

export const LIST_PROFILES = gql`
  query listProfiles($types: [UserType!]!) {
    listProfiles(types: $types) {
      id
      title
      firstName
      lastName
      user {
        id
      }
    }
  }
`

import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from '../../../hooks/helper/useTranslation'

const useStyles = makeStyles((theme: Theme) => ({
  dialogActions: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
}))

export type ConfirmDialogValue = string | number | boolean | null

export interface ConfirmDialogProps {
  valueState: [
    ConfirmDialogValue,
    React.Dispatch<React.SetStateAction<ConfirmDialogValue>>
  ]
  title?: string
  text: string
  dontClose?: boolean
  isAlertingDialog?: boolean
  onAccept: (value: ConfirmDialogValue) => void
  onCancel?: (value: ConfirmDialogValue) => void
}
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  valueState,
  text,
  title,
  dontClose,
  isAlertingDialog,
  onAccept,
  onCancel,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [value, setValue] = valueState
  const isDialogOpen = !!value

  const handleCancel = () => {
    if (!dontClose) {
      setValue(null)
    }
    if (onCancel) {
      onCancel(value)
    }
  }

  const handleAccept = () => {
    if (!dontClose) {
      setValue(null)
    }
    onAccept(value)
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
    >
      {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleCancel} variant="outlined">
          {t(`common:cancel`)}
        </Button>
        <Button
          color={isAlertingDialog ? 'secondary' : 'primary'}
          variant="contained"
          onClick={handleAccept}
          data-cy="ConfirmDialog-Button-confirm"
        >
          {t(`common:confirm`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { ConfirmDialog }

import React, { FC, useState } from 'react'
import { BetmenPage, Tab } from '../BetmenPage'
import { useEesztRedirectListener } from '../../hooks/eeszt/useEesztRedirectListener'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { DoctorAppointmentsOnDashboard } from './DoctorAppointmentsOnDashboard'

export const Dashboard: FC = () => {
  const { t } = useTranslation()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const tabs: Tab[] = [
    { label: t('home:appointments.today') },
    { label: t('home:appointments.tomorrow') },
    {
      label: t('home:appointments.week', {
        day: 7,
      }),
    },
    {
      label: t('home:appointments.week', {
        day: 30,
      }),
    },
    {
      label: t('home:appointments.week', {
        day: 90,
      }),
      'data-cy': 'DoctorAppointmentsOnDashboard-Tab-90day',
    },
  ]

  useEesztRedirectListener()

  return (
    <BetmenPage
      title={t('home:appointments.title')}
      tabs={tabs}
      onTabChange={setSelectedTabIndex}
      withoutPadding
    >
      <DoctorAppointmentsOnDashboard selectedTabIndex={selectedTabIndex} />
    </BetmenPage>
  )
}

import React from 'react'

import { Box, Collapse, TextField } from '@mui/material'
import { Alert } from '@mui/material'

import { FormProvider, useForm, Controller } from 'react-hook-form'

import { SaveButton } from '../SaveButton'
import { useTranslation } from '../../hooks/helper/useTranslation'

import { BoxWithLabel } from '../BoxWithLabel'
import { useEesztMobileTokenLogin } from '../../hooks/eeszt/useEesztMobileTokenLogin'
import { institutionChore, roomChore } from '../../../models/graphql'
import { InstitutionSelector } from '../selectors/InstitutionSelector'
import { RoomSelector } from '../selectors/RoomSelector'

type FormValues = {
  mobileToken: string
  institution: institutionChore | null
  room: roomChore | null
}

export const EesztMobileToken: React.FC = () => {
  const { t } = useTranslation()

  const { eesztMobileTokenLogin, error, loading } = useEesztMobileTokenLogin()

  const errorText = error?.message.replace(/[\W_]/g, '-')

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      mobileToken: '',
      institution: null,
      room: null,
    },
  })

  const {
    control,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid },
  } = methods

  const institution = watch('institution')

  const onSubmit = handleSubmit(({ mobileToken, institution, room }) => {
    if (!institution || !room) {
      return Promise.reject()
    }
    return eesztMobileTokenLogin({
      variables: {
        mobileToken,
        institutionId: institution.id,
        institutionUnitId: room.id,
      },
    })
  })

  return (
    <FormProvider {...methods}>
      <Box mb={1}>
        <Alert severity="info">{t(`eeszt:auth.notLoggedInMessage`)}</Alert>
      </Box>
      <InstitutionSelector
        label={t('institution:name')}
        multiple={false}
        name="institution"
        queryOptions={{
          fetchPolicy: 'network-only',
          variables: { hasEESZTCertificate: true },
        }}
      />
      <RoomSelector
        name="room"
        disabled={!institution}
        rooms={institution?.rooms ?? []}
      />
      <BoxWithLabel label={t(`eeszt:auth.mobileToken`)}>
        <Controller
          name="mobileToken"
          rules={{ required: 'messages:warnings.emptyField' }}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e)
                clearErrors('mobileToken')
              }}
              size="small"
              placeholder={t(`eeszt:auth.placeholder.mobileToken`)}
              type="mobileToken"
              error={!!errors.mobileToken}
              helperText={
                errors.mobileToken?.message
                  ? t(errors.mobileToken?.message)
                  : undefined
              }
              required
              fullWidth
            />
          )}
        />
      </BoxWithLabel>
      <Collapse in={!!error} unmountOnExit>
        <Box mt={1}>
          <Alert severity="error">{t(`eeszt:authError.${errorText}`)}</Alert>
        </Box>
      </Collapse>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <SaveButton
          fullWidth
          disabled={loading || !isValid}
          isSaving={loading}
          onClick={onSubmit}
          text={t('eeszt:auth.login')}
          inProgressText={t('eeszt:auth.inProgress')}
          completedIcon={error ? <></> : undefined}
          completedText={
            error ? t('eeszt:auth.errored') : t('eeszt:auth.complete')
          }
        />
      </Box>
    </FormProvider>
  )
}

import { QueryHookOptions, useQuery } from '@apollo/client'
import {
  getSurveySchemaForResults,
  getSurveySchemaByIdVariables as getSurveySchemaForResultsVariables,
  getSurveySchemaForResults_getSurveySchemaById,
} from '../../../../models/graphql'

import { GET_SURVEY_SCHEMA_FOR_RESULTS } from '../operations/surveyOperations'

type Options = QueryHookOptions<
  getSurveySchemaForResults,
  getSurveySchemaForResultsVariables
>

type Return = {
  surveySchema?: getSurveySchemaForResults_getSurveySchemaById
  loading: boolean
  refetch: () => void
}
export const useSurveySchemaForResults = (options: Options = {}): Return => {
  const { data, loading, refetch } = useQuery<
    getSurveySchemaForResults,
    getSurveySchemaForResultsVariables
  >(GET_SURVEY_SCHEMA_FOR_RESULTS, options)

  const surveySchema = data?.getSurveySchemaById

  return {
    loading,
    surveySchema,
    refetch,
  }
}

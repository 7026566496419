import { getSurveySchemaById_getSurveySchemaById_referenceUrls as Url } from '../../../models/graphql'

export function isValidHttpUrl(urlToCheck: string): boolean {
  let url

  try {
    url = new URL(urlToCheck)
  } catch (error) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export function getNumberOfReferences(url: string | null | Url[]): string {
  let arrayLength = 0
  if (Array.isArray(url)) {
    arrayLength = url.length
  } else if (!!url && url?.includes('[')) {
    arrayLength = JSON.parse(url).length
  }
  return !!arrayLength ? ` (${arrayLength})` : ''
}

import React, { useState, useEffect } from 'react'
import {
  Box,
  Card,
  IconButton,
  Popover,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  DateIntervalInput,
  SurveySchemaStatusEnum,
} from '../../../../../models/graphql'
import { SurveyActionsMenu } from '../SurveyActions/SurveyActionsMenu'
import {
  SurveySchema,
  SurveySchemaActionProperties,
} from '../Surveys/surveys.types'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { FormProvider, useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { ViewSurveyDetailsCore } from '../ViewSurvey/ViewSurveyDetailsCore'

interface Props {
  surveySchema: SurveySchema
  tabState: [number, React.Dispatch<React.SetStateAction<number>>]
  dateIntervalFilterState: [
    DateIntervalInput | null,
    React.Dispatch<React.SetStateAction<DateIntervalInput | null>>
  ]
}

export const SurveyResultHeader: React.FC<Props> = ({
  surveySchema,
  tabState,
  dateIntervalFilterState,
}) => {
  const { t } = useTranslation()
  const [actionsMenuOpenAnchor, setActionsMenuAnchor] =
    useState<HTMLButtonElement | null>(null)
  const [selectedTabIndex, setSelectedTabIndex] = tabState
  const [dateIntervalFilter, setDateIntervalFilter] = dateIntervalFilterState
  const [error, setError] = useState<string | null>(null)

  const selectTab = (event: React.SyntheticEvent, newTabIndex: number) => {
    setSelectedTabIndex(newTabIndex)
  }

  const formMethods = useForm<SurveySchemaActionProperties>({
    defaultValues: {
      status: surveySchema.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema.canBeDeleted ?? true,
    },
  })

  useEffect(() => {
    formMethods.reset({
      status: surveySchema.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema.canBeDeleted ?? true,
    })
  }, [formMethods, surveySchema])

  const status = formMethods.watch('status')

  const changeDate = (startDate: string, endDate: string) => {
    if (endDate < startDate) {
      setError(t('survey:result.dateError'))
    } else {
      setError(null)
    }
    setDateIntervalFilter({
      startDate,
      endDate,
    })
  }

  return (
    <FormProvider {...formMethods}>
      <Card>
        <Stack direction="row">
          <Stack flex={1}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{ px: 3, pb: 2 }}
            >
              <ViewSurveyDetailsCore
                surveySchema={surveySchema}
                surveySchemaStatus={status}
              />
              {selectedTabIndex !== 0 && (
                <Stack
                  sx={{
                    flex: 1,
                    justifyContent: { xs: 'flex-start', md: 'flex-end' },
                    alignItems: { xs: 'flex-start', md: 'flex-end' },
                  }}
                >
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    gap={1.5}
                    sx={{
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      mt: { xs: 2, md: 0 },
                      mb: !error ? 2 : 0,
                    }}
                  >
                    <Typography variant="body1">
                      {t('survey:result.dateRange')}
                    </Typography>
                    <TextField
                      type="date"
                      size="small"
                      variant="outlined"
                      error={!!error}
                      onChange={(e) => {
                        if (e.target.value) {
                          changeDate(
                            dayjs(e.target.value).format('YYYY-MM-DD 00:00:00'),
                            dateIntervalFilter?.endDate
                          )
                        } else {
                          setDateIntervalFilter({
                            ...dateIntervalFilter,
                            startDate: undefined,
                          })
                        }
                      }}
                      value={
                        dateIntervalFilter?.startDate
                          ? dayjs(dateIntervalFilter.startDate).format(
                              'YYYY-MM-DD'
                            )
                          : ''
                      }
                    />
                    <Typography variant="body1">-</Typography>
                    <TextField
                      type="date"
                      size="small"
                      variant="outlined"
                      error={!!error}
                      onChange={(e) => {
                        if (e.target.value) {
                          changeDate(
                            dateIntervalFilter?.startDate,
                            dayjs(e.target.value).format('YYYY-MM-DD 23:59:59')
                          )
                        } else {
                          setDateIntervalFilter({
                            ...dateIntervalFilter,
                            endDate: undefined,
                          })
                        }
                      }}
                      value={
                        dateIntervalFilter?.endDate
                          ? dayjs(dateIntervalFilter.endDate).format(
                              'YYYY-MM-DD'
                            )
                          : ''
                      }
                    />
                  </Stack>

                  {!!error && (
                    <Typography variant="subtitle2" color="error">
                      {error}
                    </Typography>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
          <Box m={1}>
            <IconButton onClick={(e) => setActionsMenuAnchor(e.currentTarget)}>
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </Box>
        </Stack>
        <hr />

        <Tabs
          sx={{ px: 3, my: 1 }}
          value={selectedTabIndex}
          onChange={selectTab}
        >
          <Tab label={t('survey:result.tabs.questions')} />
          <Tab label={t('survey:result.tabs.sumResults')} />
          <Tab label={t('survey:result.tabs.patientResults')} />
        </Tabs>
      </Card>

      {actionsMenuOpenAnchor && (
        <Popover
          open={!!actionsMenuOpenAnchor}
          anchorEl={actionsMenuOpenAnchor}
          onClose={() => setActionsMenuAnchor(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <SurveyActionsMenu surveySchema={surveySchema} />
        </Popover>
      )}
    </FormProvider>
  )
}

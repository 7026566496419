import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AddButton } from '../../../common/components/AddButton/AddButton'
import { BetmenPage, Tab } from '../../../common/components/BetmenPage'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useUserType } from '../../../common/hooks/useUserType'
import { Questions } from './components/Questions'
import { Surveys } from './components/Surveys'

export const SurveyStore: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)
  const userType = useUserType()

  const tabs: Tab[] = [
    { label: t('survey:tabs.title.surveys') },
    { label: t('survey:tabs.title.questions') },
  ]

  const [isAddQuestionModalOpen, setAddQuestionModalOpen] =
    useState<boolean>(false)

  const getAddButtonProps = () => {
    switch (selectedTabIndex) {
      case 0:
        return {
          handleAdd: () =>
            history.push(`/${userType?.toLowerCase()}/add-survey`),
          buttonText: t(`survey:surveys.add`),
        }
      case 1:
        return {
          handleAdd: () => setAddQuestionModalOpen(true),
          buttonText: t(`survey:questions.add`),
        }
    }
  }

  return (
    <BetmenPage
      title={t('menu:doctor.surveys')}
      tabs={tabs}
      onTabChange={setSelectedTabIndex}
      withoutPadding
      headerRightContent={<AddButton {...getAddButtonProps()} />}
    >
      {selectedTabIndex === 0 && <Surveys />}
      {selectedTabIndex === 1 && (
        <Questions
          isAddModalOpenState={[
            isAddQuestionModalOpen,
            setAddQuestionModalOpen,
          ]}
        />
      )}
    </BetmenPage>
  )
}

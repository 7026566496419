import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const MenuIcon: React.FC<SvgIconProps> = (props) => {
  const viewBox = `0 0 ${props.width ?? 22} ${props.height ?? 18}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <g>
        <path d="M1.25,17.753 C0.836,17.753 0.5,17.417 0.5,17.003 C0.5,16.589 0.836,16.253 1.25,16.253 L20.75,16.253 C21.164,16.253 21.5,16.589 21.5,17.003 C21.5,17.417 21.164,17.753 20.75,17.753 L1.25,17.753 Z" />
        <path d="M1.25,9.753 C0.836,9.753 0.5,9.417 0.5,9.003 C0.5,8.589 0.836,8.253 1.25,8.253 L20.75,8.253 C21.164,8.253 21.5,8.589 21.5,9.003 C21.5,9.417 21.164,9.753 20.75,9.753 L1.25,9.753 Z" />
        <path d="M1.25,1.753 C0.836,1.753 0.5,1.417 0.5,1.003 C0.5,0.589 0.836,0.253 1.25,0.253 L20.75,0.253 C21.164,0.253 21.5,0.589 21.5,1.003 C21.5,1.417 21.164,1.753 20.75,1.753 L1.25,1.753 Z" />
      </g>
    </SvgIcon>
  )
}

export { MenuIcon }

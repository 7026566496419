import React, { FC } from 'react'

import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { Box, Divider, Typography } from '@mui/material'
import dayjs from 'dayjs'
import theme from '../../../../theme'
import { getDoctorPatientProfile_getPatientProfile } from '../../../../models/graphql'
import { BloodTypeDropdown } from './BloodTypeDropdown'
import { Info } from '@mui/icons-material'

type Props = {
  patient: getDoctorPatientProfile_getPatientProfile
  isEesztTokenValid: boolean
}

type InfoRecord = {
  label: string
  value?: number | string | string[] | null
  unit?: string
}

const Label: FC = ({ children }) => (
  <Box color={theme.palette.grey[600]}>
    <Typography variant="subtitle1">{children}</Typography>
  </Box>
)

/**
 * Info pair in horizontal aligment
 */
const InfoRow: FC<InfoRecord> = (props) => {
  const { label, value, unit = '' } = props
  const { t } = useTranslation()
  const isEmpty =
    value === undefined ||
    value === null ||
    (Array.isArray(value) && value.length === 0)

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      py={1}
    >
      <Label>{label}</Label>
      <Typography variant="subtitle2" align="right">
        {isEmpty ? t('common:emptyField') : `${value} ${unit}`}
      </Typography>
    </Box>
  )
}

export const ProfileInfo: FC<Props> = (props) => {
  const { patient, isEesztTokenValid } = props
  const { t } = useTranslation()

  const age = dayjs().diff(dayjs(patient.birthDate), 'year')

  return (
    <Box py={3} px={2}>
      <InfoRow label={t('common:taj')} value={patient.tajNumber} />
      <InfoRow
        label={t('common:birthdate')}
        value={
          t('common:dateFormatted', {
            date: patient.birthDate,
          }) as string
        }
      />
      <InfoRow label={t('common:email')} value={patient.user?.email} />
      <InfoRow label={t('common:phone')} value={patient.phone} />
      <InfoRow label={t('patients:address')} value={patient.address} />
      <InfoRow label={t('common:age')} value={age} />
      <InfoRow label={t('common:weight')} value={patient.weight} unit="kg" />
      <InfoRow label={t('common:height')} value={patient.height} unit="cm" />
      <Divider />
      {isEesztTokenValid && (
        <BloodTypeDropdown
          patientId={patient.id}
          defaultBloodType={patient.bloodType}
          defaultAttachmentId={patient.bloodTypeAttachmentEESZTId}
        />
      )}
      {!isEesztTokenValid && (
        <>
          <InfoRow
            label={t('patients:assumedloodType')}
            value={patient.bloodType}
          />
          <Box display="flex">
            <Box mr={0.5}>
              <Info fontSize="small" color="primary" />
            </Box>
            <Typography variant="subtitle2">
              {t('patients:loginToEesztToEditBloodType')}
            </Typography>
          </Box>
        </>
      )}
      <Divider />
      <InfoRow
        label={t('patients:takenMedicines')}
        value={patient.takenMedicines}
      />
      <InfoRow
        label={t('patients:medicineIntolerance')}
        value={patient.medicineIntolerance}
      />
      <InfoRow
        label={t('patients:emergencyContactName')}
        value={patient.emergencyContacts?.[0]?.name}
      />
      <InfoRow
        label={t('patients:emergencyContactPhone')}
        value={patient.emergencyContacts?.[0]?.phone}
      />
    </Box>
  )
}

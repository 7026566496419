import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Box,
  Stack,
} from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { QuestionInSection } from '../EditSurvey/editSurvey.types'
import { AddBox as AddBoxIcon } from '@mui/icons-material'
import { useStoreActions } from '../../../../../store/store.hooks'
import { FileDisplay } from './FileDisplay'
import {
  getAcceptedTypes,
  getIsFileItemsCountSmaller,
  getIsFileSizeSmaller,
} from './fileUpload.util'
import { UploadFileInput } from './fileUpload.types'

interface Props extends DialogProps {
  question: QuestionInSection
  onClose: () => void
  onSave: (files: UploadFileInput[]) => void
  savedFiles: UploadFileInput[]
}

export const FileUploadModal: React.FC<Props> = ({
  question,
  onClose,
  onSave,
  savedFiles,
  ...props
}) => {
  const { t } = useTranslation()

  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const [files, setFiles] = useState<UploadFileInput[]>(savedFiles)

  const fileUploadData = question.data?.fileUploadData ?? null

  const uploadFile = (newFile: File) => {
    if (getIsFileSizeSmaller(newFile, fileUploadData)) {
      setFiles([...files, { file: newFile }])
    } else {
      setToast({
        text: t('survey:question.file.tooBigSize'),
        type: 'warning',
      })
    }
  }

  return (
    <Dialog fullWidth {...props}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {t(`survey:questions.fileUpload`)}
      </DialogTitle>
      <DialogContent>
        {!!files.length ? (
          <Stack>
            <FileDisplay files={files} setFiles={setFiles} />

            {getIsFileItemsCountSmaller(fileUploadData, files) && (
              <Button
                startIcon={<AddBoxIcon />}
                component="label"
                variant="outlined"
                sx={{ alignSelf: 'flex-start' }}
              >
                <Box
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files) {
                      uploadFile(e.target.files[0])
                    }
                  }}
                  display="none"
                  component="input"
                  type="file"
                  multiple
                  accept={getAcceptedTypes(fileUploadData)}
                />
                {t('survey:question.addNewFile')}
              </Button>
            )}
          </Stack>
        ) : (
          <Button startIcon={<AddBoxIcon />} component="label">
            <Box
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files) {
                  uploadFile(e.target.files[0])
                }
              }}
              display="none"
              component="input"
              type="file"
              accept={getAcceptedTypes(fileUploadData)}
            />
            {t('survey:question.addNewFile')}
          </Button>
        )}
      </DialogContent>
      <DialogActions sx={{ pb: 2, px: 3 }}>
        <Button variant="outlined" onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <Button
          onClick={() => {
            onSave(files)
            onClose()
          }}
        >
          {t('common:upload')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

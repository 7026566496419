import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import { useStoreActions, useStoreState } from '../../store/store.hooks'

const Toast: React.FC = () => {
  const toast = useStoreState((state) => state.toast?.toast)
  const setToast = useStoreActions((actions) => actions.toast?.setToast)

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setToast(undefined)
  }

  return (
    <Snackbar
      open={!!toast}
      autoHideDuration={toast?.duration || 6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={toast?.type}
        sx={{ width: '100%' }}
      >
        {toast?.text}
      </Alert>
    </Snackbar>
  )
}

export { Toast }

import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Dayjs } from 'dayjs'

dayjs.extend(isoWeek)
dayjs.extend(utc)
dayjs.extend(timezone)

export const defaultTimezone = 'Europe/Budapest'

export const localDayjs = (
  date?: Date | string | dayjs.Dayjs,
  localTimezone?: string
): Dayjs => dayjs(date).tz(localTimezone || defaultTimezone)

import { NetworkStatus, useQuery } from '@apollo/client'
import { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types'
import { useMemo } from 'react'
import {
  institutionChore,
  listInstitutions,
  listInstitutionsVariables,
} from '../../models/graphql'
import { LIST_INSTITUTIONS } from '../../operations/institutionOperations'

export type UseInstitutionListOptions = QueryHookOptions<
  listInstitutions,
  listInstitutionsVariables
>

type Return = {
  loading: boolean
  institutions: institutionChore[]
  activeInstitutions: institutionChore[]
  totalInstitutions: number
  refetch: QueryResult<listInstitutions, listInstitutionsVariables>['refetch']
  hasMoreInstitutions: boolean
  fetchingMore: boolean
  fetchMoreInstitutions: (options: UseInstitutionListOptions) => void
}

/**
 * Returns institutions (all or filtered by appointment type).
 */
export const useInstitutionList = (
  options: UseInstitutionListOptions = {}
): Return => {
  const { data, loading, refetch, fetchMore, networkStatus } = useQuery<
    listInstitutions,
    listInstitutionsVariables
  >(LIST_INSTITUTIONS, options)

  const institutions = useMemo(
    () =>
      (data?.listInstitutions?.institutions || []).map((item) => ({
        ...item,
        __typename: 'Institution' as const,
      })),
    [data?.listInstitutions]
  )

  const activeInstitutions = useMemo(
    () => institutions.filter((institution) => institution.isActive),
    [institutions]
  )
  const totalInstitutions = data?.listInstitutions?.total || 0

  return {
    loading,
    institutions,
    activeInstitutions,
    totalInstitutions,
    refetch,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    fetchMoreInstitutions: fetchMore,
    hasMoreInstitutions: institutions.length < totalInstitutions,
  }
}

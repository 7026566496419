import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { ScrollablePanel } from '../../../../../common/components/ScrollablePanel'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  DateIntervalInput,
  getSurveySchemaById_getSurveySchemaById as SurveySchema,
} from '../../../../../models/graphql'
import { GetAggregatedSurveyResult } from './GetAggregatedSurveyResult'
import { GetSurveyResultByPatient } from './GetSurveyResultByPatient'
import { SurveyResultHeader } from './SurveyResultHeader'
import { SurveyResultQuestions } from './SurveyResultQuestions'
import { useUserType } from '../../../../../common/hooks/useUserType'

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.black,
    },
  },
}))

interface Props {
  surveySchema: SurveySchema
}

export const SurveyResult: React.FC<Props> = ({ surveySchema }) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const history = useHistory()
  const classes = useStyles()
  const userType = useUserType()

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
  const [dateIntervalFilter, setDateIntervalFilter] =
    useState<DateIntervalInput | null>(null)

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
      overflow="hidden"
      sx={{ backgroundColor: '#EFEEF3' }}
    >
      <Box
        px={{ xs: 2, md: 3 }}
        pt={1.375}
        pb={1.5}
        flexShrink={0}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom={`1px solid ${colorPalette.grey[300]}`}
      >
        <Typography variant="h6">{t('survey:result.title')}</Typography>
      </Box>
      <Box px={{ xs: 1, md: 2 }} pt={0.5} pb={1}>
        <Button
          variant="text"
          startIcon={<KeyboardBackspaceIcon />}
          sx={{ mb: 1.5 }}
          onClick={() => history.push(`/${userType?.toLowerCase()}/surveys`)}
          classes={{ textPrimary: classes.textPrimary }}
        >
          {t('survey:result.backToSurveyList')}
        </Button>
        <SurveyResultHeader
          surveySchema={surveySchema}
          tabState={[selectedTabIndex, setSelectedTabIndex]}
          dateIntervalFilterState={[dateIntervalFilter, setDateIntervalFilter]}
        />
      </Box>
      <Box overflow="hidden">
        <ScrollablePanel>
          {selectedTabIndex === 0 && (
            <SurveyResultQuestions
              surveySchema={surveySchema}
              showSurveyDetails
              isPreview={true}
            />
          )}
          {selectedTabIndex === 1 && (
            <GetAggregatedSurveyResult
              surveySchema={surveySchema}
              dateIntervalFilter={dateIntervalFilter}
            />
          )}
          {selectedTabIndex === 2 && (
            <GetSurveyResultByPatient
              surveySchema={surveySchema}
              dateIntervalFilter={dateIntervalFilter}
            />
          )}
        </ScrollablePanel>
      </Box>
    </Box>
  )
}

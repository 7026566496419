import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from '../common/hooks/helper/useTranslation'
import { NotificationSettings } from '../common/components/Notification'
import { ScrollablePanel } from '../common/components/ScrollablePanel'

const Settings: React.FC = () => {
  const { t } = useTranslation()

  return (
    <ScrollablePanel title={t('settings:settings')}>
      <Box px={1} height="100%">
        <Typography component="h3" variant="h6" gutterBottom>
          {t('notification:settingsLabel')}
        </Typography>
        <NotificationSettings />
      </Box>
    </ScrollablePanel>
  )
}

export { Settings }

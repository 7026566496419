import { MultiLangText } from '../types'

export const EHRDocumentTypes: Array<{ id: string; name: MultiLangText }> = [
  {
    id: '10',
    name: {
      hu: 'Zárójelentés',
      en: 'Zárójelentés',
    },
  },
  {
    id: '11',
    name: {
      hu: 'Ambuláns lap',
      en: 'Ambuláns lap',
    },
  },
  {
    id: '12',
    name: {
      hu: 'Általános laboratóriumi ellátás lelete',
      en: 'Általános laboratóriumi ellátás lelete',
    },
  },
  {
    id: '13',
    name: {
      hu: 'Mikrobiológiai laboratóriumi ellátás lelete',
      en: 'Mikrobiológiai laboratóriumi ellátás lelete',
    },
  },
  {
    id: '14',
    name: {
      hu: 'Szövettani és patológiai lelet',
      en: 'Szövettani és patológiai lelet',
    },
  },
  {
    id: '15',
    name: {
      hu: 'Egyéb laboratóriumi ellátás lelete',
      en: 'Egyéb laboratóriumi ellátás lelete',
    },
  },
  {
    id: '16',
    name: {
      hu: 'CT és MR vizsgálatokról készült lelet',
      en: 'CT és MR vizsgálatokról készült lelet',
    },
  },
  {
    id: '17',
    name: {
      hu: 'Egyéb képalkotó vizsgálat lelete',
      en: 'Egyéb képalkotó vizsgálat lelete',
    },
  },
  {
    id: '18',
    name: {
      hu: 'Műtéti leírás',
      en: 'Műtéti leírás',
    },
  },
  {
    id: '19',
    name: {
      hu: 'Mentési lap',
      en: 'Mentési lap',
    },
  },
  {
    id: '20',
    name: {
      hu: 'Szakorvosi javaslat gyógyszerrendeléshez',
      en: 'Szakorvosi javaslat gyógyszerrendeléshez',
    },
  },
  {
    id: '21',
    name: {
      hu: 'Szakorvosi javaslat gyógyászati segédeszköz rendeléséhez',
      en: 'Szakorvosi javaslat gyógyászati segédeszköz rendeléséhez',
    },
  },
  {
    id: '22',
    name: {
      hu: 'Várandósgondozási könyv',
      en: 'Várandósgondozási könyv',
    },
  },
  {
    id: '23',
    name: {
      hu: 'Gyermek-egészségügyi kiskönyv',
      en: 'Gyermek-egészségügyi kiskönyv',
    },
  },
  {
    id: '24',
    name: {
      hu: 'COVID Státuszlap',
      en: 'COVID Státuszlap',
    },
  },
  {
    id: '25',
    name: {
      hu: 'COVID-19 Oltási lap',
      en: 'COVID-19 Oltási lap',
    },
  },
  {
    id: '26',
    name: {
      hu: 'Onkoteam jegyzőkönyv',
      en: 'Onkoteam jegyzőkönyv',
    },
  },
]

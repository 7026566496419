import { ApolloQueryResult, useQuery } from '@apollo/client'

import { QueryHookOptions } from '@apollo/client/react/types/types'
import {
  getKatEvents,
  getKatEventsVariables,
  getKatEvents_getKatEvents,
} from '../../../../models/graphql'
import { GET_KAT_EVENTS } from '../../../../operations/treatmentOperations'

type Options = QueryHookOptions<getKatEvents, getKatEventsVariables>

type Return = {
  loading: boolean
  katEvents: getKatEvents_getKatEvents[] | undefined
  refetch: (
    variables?: Partial<getKatEventsVariables> | undefined
  ) => Promise<ApolloQueryResult<getKatEvents>>
}

export const useKatEvents = (options: Options = {}): Return => {
  const { data, loading, refetch } = useQuery<
    getKatEvents,
    getKatEventsVariables
  >(GET_KAT_EVENTS, options)

  const katEvents = data?.getKatEvents

  return {
    katEvents,
    loading,
    refetch,
  }
}

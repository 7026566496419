import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const ListEditIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" width="16" height="16" {...props}>
      <g transform="translate(-30.000000, -162.000000)" fillRule="nonzero">
        <g transform="translate(30.000000, 160.000000)">
          <g transform="translate(0.000000, 2.000000)">
            <path d="M14.5,15.5073333 C14.3093333,15.5073333 14.138,15.4013333 14.0526667,15.2306667 L13.0526667,13.2306667 C13.018,13.1613333 13,13.084 13,13.0073333 L13,6.50733333 C13,5.68 13.6726667,5.00733333 14.5,5.00733333 C15.3273333,5.00733333 16,5.68 16,6.50733333 L16,13.0073333 C16,13.084 15.982,13.1613333 15.9473333,13.2306667 L14.9473333,15.2313333 C14.862,15.4013333 14.6906667,15.5073333 14.5,15.5073333 Z M14.5,13.8893333 L15,12.8893333 L15,6.50733333 C15,6.23133333 14.776,6.00733333 14.5,6.00733333 C14.224,6.00733333 14,6.23133333 14,6.50733333 L14,12.8893333 L14.5,13.8893333 Z" />
            <path d="M1.5,15.5073333 C0.672666667,15.5073333 0,14.8346667 0,14.0073333 L0,4.50733333 C0,3.68 0.672666667,3.00733333 1.5,3.00733333 L3.042,3.00733333 C3.28266667,1.58866667 4.54,0.507333333 6,0.507333333 C7.46,0.507333333 8.71733333,1.588 8.958,3.00733333 L10.5,3.00733333 C11.3273333,3.00733333 12,3.68 12,4.50733333 L12,14.0073333 C12,14.8346667 11.3273333,15.5073333 10.5,15.5073333 L1.5,15.5073333 Z M1.5,4.00733333 C1.224,4.00733333 1,4.23133333 1,4.50733333 L1,14.0073333 C1,14.2833333 1.224,14.5073333 1.5,14.5073333 L10.5,14.5073333 C10.776,14.5073333 11,14.2833333 11,14.0073333 L11,4.50733333 C11,4.23133333 10.776,4.00733333 10.5,4.00733333 L8.5,4.00733333 C8.224,4.00733333 8,3.78333333 8,3.50733333 C8,2.40466667 7.10266667,1.50733333 6,1.50733333 C4.89733333,1.50733333 4,2.40466667 4,3.50733333 C4,3.78333333 3.776,4.00733333 3.5,4.00733333 L1.5,4.00733333 Z" />
            <circle cx="6" cy="3.25733333" r="1" />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export { ListEditIcon }

import { QueryHookOptions } from '@apollo/client/react/types/types'
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client'
import {
  checkCertificate,
  checkCertificateVariables,
} from '../../../models/graphql'
import { CHECK_INSTITUTION_HAS_CERTIFICATE } from '../../../operations/institutionOperations'

type Return = {
  loading: boolean
  error?: ApolloError
  data?: boolean
  refetch: (
    variables?: Partial<checkCertificateVariables>
  ) => Promise<ApolloQueryResult<checkCertificate>>
}
type Options = QueryHookOptions<checkCertificate, checkCertificateVariables>

export const useCheckCertificate = (options: Options = {}): Return => {
  const { loading, data, error, refetch } = useQuery<
    checkCertificate,
    checkCertificateVariables
  >(CHECK_INSTITUTION_HAS_CERTIFICATE, { fetchPolicy: 'no-cache', ...options })

  return {
    loading,
    error,
    data: data?.checkCertificate,
    refetch,
  }
}

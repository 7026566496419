import { useMutation } from '@apollo/client'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { ASSIGN_ADMINS_TO_INSTITUTION } from '../../../operations/institutionOperations'
import {
  assignAdminsToInstitution,
  assignAdminsToInstitutionVariables,
} from '../../../models/graphql'

type Options = MutationHookOptions<
  assignAdminsToInstitution,
  assignAdminsToInstitutionVariables
>

type Return = MutationTuple<
  assignAdminsToInstitution,
  assignAdminsToInstitutionVariables
>

export const useAdminsToInstitutionAssign = (options: Options = {}): Return => {
  return useMutation<
    assignAdminsToInstitution,
    assignAdminsToInstitutionVariables
  >(ASSIGN_ADMINS_TO_INSTITUTION, {
    refetchQueries: ['institutionAdminProfiles'],
    ...options,
  })
}

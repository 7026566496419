import React, { useState } from 'react'
import { BetmenPage, Tab } from '../../../common/components/BetmenPage'
import { useDoctorPatientsData } from '../../../common/hooks/useDoctorPatientsData'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { PatientList } from './components/PatientList'
import { AddButton } from '../../../common/components/AddButton/AddButton'
import { GroupedPatientList } from './components/GroupedPatientList'
import { AddPatientModal } from './components/AddPatientModal'
import { PatientTypeWithStatus } from './components/patientlist.types'

export const DoctorPatients: React.FC = () => {
  const { t } = useTranslation()
  const { patientList, loading } = useDoctorPatientsData({})
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const patientListWithStatus: PatientTypeWithStatus[] = patientList.map(
    ({ patient, closedAt }) => ({
      ...patient,
      isActive: !closedAt,
    })
  ) as PatientTypeWithStatus[]

  const tabs: Tab[] = [
    { label: t('doctor:patientList.all') },
    { label: t('doctor:patientList.current') },
    { label: t('doctor:patientList.removed') },
  ]

  const [isAddPatientsModalOpen, toggleIsAddPatientModalOpen] =
    useState<boolean>(false)

  const getAddButtonProps = () => {
    return {
      handleAdd: () => toggleIsAddPatientModalOpen(true),
      buttonText: t(`patients:new`),
    }
  }

  return (
    <>
      <BetmenPage
        title={t('menu:doctor.patients')}
        tabs={tabs}
        onTabChange={setSelectedTabIndex}
        withoutPadding
        headerRightContent={<AddButton {...getAddButtonProps()} />}
      >
        {selectedTabIndex === 0 && (
          <PatientList
            patientList={patientListWithStatus}
            isPatientListLoading={loading}
          />
        )}
        {selectedTabIndex === 1 && (
          <GroupedPatientList
            patientList={patientList}
            patientGroupType="current"
            isPatientListLoading={loading}
          />
        )}
        {selectedTabIndex === 2 && (
          <GroupedPatientList
            patientList={patientList}
            patientGroupType="removed"
            isPatientListLoading={loading}
          />
        )}
      </BetmenPage>

      {isAddPatientsModalOpen && (
        <AddPatientModal
          patients={patientList}
          isOpen={isAddPatientsModalOpen}
          toggleIsOpen={toggleIsAddPatientModalOpen}
        />
      )}
    </>
  )
}

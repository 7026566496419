import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Button, ButtonProps } from '@mui/material'
import isFunction from 'lodash/isFunction'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { NotificationContext } from '../../../../../common/providers/NotificationProvider'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useTreatmentSchemaDuplicate } from '../../hooks/useTreatmentSchemaDuplicate'
import { useUserType } from '../../../../../common/hooks/useUserType'

interface Props extends ButtonProps {
  treatmentSchemaId: string
  refetchTreatments?: () => void
}

export const TreatmentSchemaDuplicateButton: React.FC<Props> = ({
  treatmentSchemaId,
  refetchTreatments,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userType = useUserType()
  const { setSuccessToast, setErrorToast } = useContext(NotificationContext)

  const [duplicateTreatmentSchema] = useTreatmentSchemaDuplicate()

  const onDuplicate = async () =>
    await duplicateTreatmentSchema({
      variables: {
        treatmentSchemaId,
      },
      onCompleted: (data) => {
        if (isFunction(refetchTreatments)) {
          refetchTreatments()
        } else {
          history.push(
            `/${userType?.toLowerCase()}/treatment-option/${
              data.duplicateTreatmentSchema.id
            }`
          )
        }
        setSuccessToast('successDuplicateTreatment')
      },
      onError: (e) => setErrorToast(e),
    })

  return (
    <Button
      variant="text"
      startIcon={<ContentCopyIcon />}
      onClick={onDuplicate}
      {...buttonProps}
      data-cy={'DuplicateTreatmentSchemaButton'}
    >
      {t('common:duplicate')}
    </Button>
  )
}

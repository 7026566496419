import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const ProfileIcon: React.FC<SvgIconProps> = (props) => {
  const viewBox = `0 0 ${props.width ?? 16} ${props.height ?? 18}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <g>
        <path d="M8,8.72727273 C5.59418182,8.72727273 3.63636364,6.76945455 3.63636364,4.36363636 C3.63636364,3.59927273 3.83854545,2.84581818 4.22181818,2.184 C4.22327273,2.18109091 4.22836364,2.17309091 4.22836364,2.17163636 C5.01018182,0.832 6.45527273,0 8,0 C10.4058182,0 12.3636364,1.95781818 12.3636364,4.36363636 C12.3636364,6.76945455 10.4058182,8.72727273 8,8.72727273 Z M4.89527273,3.336 C4.784,3.66836364 4.72727273,4.01309091 4.72727273,4.36363636 C4.72727273,6.168 6.19563636,7.63636364 8,7.63636364 C9.72,7.63636364 11.1345455,6.30254545 11.2632727,4.61527273 C10.6189091,4.81018182 9.952,4.90909091 9.27563636,4.90909091 C7.69309091,4.90909091 6.11927273,4.33963636 4.89527273,3.336 Z M9.29018182,3.81890909 C9.91927273,3.81890909 10.5490909,3.71272727 11.1563636,3.50254545 C10.7723636,2.09236364 9.48,1.09090909 8,1.09090909 C6.98909091,1.09090909 6.04145455,1.55781818 5.42181818,2.35054545 C6.47563636,3.28581818 7.86545455,3.81818182 9.27127273,3.81818182 L9.27345455,3.81818182 C9.27854545,3.81818182 9.28509091,3.81818182 9.29018182,3.81890909 Z" />
        <path d="M15.0909091,17.4545455 C14.7898182,17.4545455 14.5454545,17.2101818 14.5454545,16.9090909 C14.5454545,14.5403636 13.296,12.4021818 11.2567273,11.2334545 C11.0930909,12.8865455 9.69527273,14.1818182 8,14.1818182 C6.30472727,14.1818182 4.90690909,12.8865455 4.74327273,11.2334545 C2.704,12.4021818 1.45454545,14.5396364 1.45454545,16.9090909 C1.45454545,17.2101818 1.21018182,17.4545455 0.909090909,17.4545455 C0.608,17.4545455 0.363636364,17.2101818 0.363636364,16.9090909 C0.363636364,13.8269091 2.20363636,11.0625455 5.05090909,9.86545455 C5.05890909,9.86181818 5.07636364,9.85454545 5.09527273,9.848 C6.00872727,9.46836364 6.99127273,9.27272727 8,9.27272727 C9.00654545,9.27272727 9.98618182,9.46763636 10.9127273,9.85090909 C10.9287273,9.85672727 10.944,9.86327273 10.96,9.87054545 C13.7970909,11.0625455 15.6363636,13.8269091 15.6363636,16.9090909 C15.6363636,17.2101818 15.392,17.4545455 15.0909091,17.4545455 Z M5.81818182,10.9090909 C5.81818182,12.112 6.79709091,13.0909091 8,13.0909091 C9.20290909,13.0909091 10.1818182,12.112 10.1818182,10.9090909 L10.1818182,10.7396364 C9.47927273,10.4901818 8.74690909,10.3636364 8,10.3636364 C7.25309091,10.3636364 6.52,10.4901818 5.81818182,10.7396364 L5.81818182,10.9090909 Z" />
      </g>
    </SvgIcon>
  )
}

export { ProfileIcon }

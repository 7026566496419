import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import isFunction from 'lodash/isFunction'
import React, { PropsWithChildren, ReactElement } from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'
import {
  DataTableColDef,
  DataTableOrder,
  DataTableRowAction,
  DataTableRowData,
} from './interfaces'

type Props<T> = {
  visibleColumns: DataTableColDef<T>[]
  hiddenColumns: DataTableColDef<T>[]
  actions?: DataTableRowAction<T>[]
  order: DataTableOrder
  orderBy: DataTableColDef<T> | null
  onSort?: (col: DataTableColDef<T>) => void
  showRowDetails: boolean
}

export const DataTableHead = <T extends DataTableRowData>(
  props: PropsWithChildren<Props<T>>
): ReactElement => {
  const {
    visibleColumns,
    hiddenColumns,
    order,
    orderBy,
    onSort,
    actions,
    showRowDetails,
  } = props
  const { t } = useTranslation()

  const handleSort = (col: DataTableColDef<T>) => () => {
    isFunction(onSort) && onSort(col)
  }

  return (
    <TableHead>
      <TableRow>
        {(hiddenColumns.length > 0 || showRowDetails) && <TableCell />}
        {visibleColumns.map((column) => (
          <TableCell key={column.field} align={column.align}>
            {isFunction(onSort) ? (
              <TableSortLabel
                active={orderBy?.field === column.field}
                direction={orderBy?.field === column.field ? order : 'asc'}
                onClick={handleSort(column)}
              >
                {column.title ?? column.field}
              </TableSortLabel>
            ) : (
              column.title ?? column.field
            )}
          </TableCell>
        ))}
        {(actions?.length ?? 0) > 0 && (
          <TableCell align="center">{t('table:header.actions')}</TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}

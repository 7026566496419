import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const CalendarAddIcon: React.FC<SvgIconProps> = (props) => {
  const viewBox = `0 0 ${props.width ?? 20} ${props.height ?? 20}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <path d="M10,16.875 C9.655,16.875 9.375,16.595 9.375,16.25 L9.375,14.375 L7.5,14.375 C7.155,14.375 6.875,14.095 6.875,13.75 C6.875,13.405 7.155,13.125 7.5,13.125 L9.375,13.125 L9.375,11.25 C9.375,10.905 9.655,10.625 10,10.625 C10.345,10.625 10.625,10.905 10.625,11.25 L10.625,13.125 L12.5,13.125 C12.845,13.125 13.125,13.405 13.125,13.75 C13.125,14.095 12.845,14.375 12.5,14.375 L10.625,14.375 L10.625,16.25 C10.625,16.595 10.345,16.875 10,16.875 Z" />
      <path d="M1.875,20 C0.840833333,20 0,19.1591667 0,18.125 L0,4.375 C0,3.34083333 0.840833333,2.5 1.875,2.5 L5,2.5 L5,0.625 C5,0.28 5.28,0 5.625,0 C5.97,0 6.25,0.28 6.25,0.625 L6.25,2.5 L13.75,2.5 L13.75,0.625 C13.75,0.28 14.03,0 14.375,0 C14.72,0 15,0.28 15,0.625 L15,2.5 L18.125,2.5 C19.1591667,2.5 20,3.34083333 20,4.375 L20,18.125 C20,19.1591667 19.1591667,20 18.125,20 L1.875,20 Z M1.25,18.125 C1.25,18.47 1.53,18.75 1.875,18.75 L18.125,18.75 C18.47,18.75 18.75,18.47 18.75,18.125 L18.75,8.75 L1.25,8.75 L1.25,18.125 Z M18.75,7.5 L18.75,4.375 C18.75,4.03 18.47,3.75 18.125,3.75 L15,3.75 L15,5 C15,5.345 14.72,5.625 14.375,5.625 C14.03,5.625 13.75,5.345 13.75,5 L13.75,3.75 L6.25,3.75 L6.25,5 C6.25,5.345 5.97,5.625 5.625,5.625 C5.28,5.625 5,5.345 5,5 L5,3.75 L1.875,3.75 C1.53,3.75 1.25,4.03 1.25,4.375 L1.25,7.5 L18.75,7.5 Z" />
    </SvgIcon>
  )
}

export { CalendarAddIcon }

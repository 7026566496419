import { Box } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { EmptyState } from '../../../../common/components/EmptyState'
import { Loading } from '../../../../common/components/Loading'
import { useDoctorPatientTreatmentData } from '../hooks/useDoctorPatientTreatmentData'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { TreatmentPanel } from '../TreatmentPanel'

type RouteParams = {
  treatmentId?: string
}

export const GetTreatmentPanelData: React.FC = () => {
  const { t } = useTranslation()

  const { treatmentId = '' } = useParams<RouteParams>()

  const {
    treatment,
    loading,
    refetch: refetchTreatment,
  } = useDoctorPatientTreatmentData({ variables: { treatmentId } })

  return (
    <Box width="100%">
      {loading && (
        <Box height="100%" width="100%" display="flex" alignItems="center">
          <Loading />
        </Box>
      )}
      {!treatment && !loading && (
        <Box
          flexGrow={1}
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <EmptyState
            title={t('treatment:treatmentNotFoundTitle')}
            description={t('treatment:surveySchemaNotFoundMessage', {
              treatmentId,
            })}
          />
        </Box>
      )}

      {treatment && !loading && (
        <TreatmentPanel
          treatment={treatment}
          refetchTreatment={refetchTreatment}
        />
      )}
    </Box>
  )
}

import React, { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useUserProfile } from '../../../common/hooks/useUserProfile'
import { useUserType } from '../../../common/hooks/useUserType'
import { GET_DOCTOR_PROFILES_FOR_PATIENTS } from '../../../operations/doctorProfileOperations'
import { getDoctorProfilesForPatients, UserType } from '../../../models/graphql'
import { DoctorProfileList } from '../../../common/components/DoctorProfileList'
import { BetmenPage } from '../../../common/components/BetmenPage'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'

type RouteParams = {
  doctorProfileId?: string
}

type Props = {
  patientId?: string
}

const PatientDoctors: React.FC<Props> = ({ patientId }) => {
  const { doctorProfileId } = useParams<RouteParams>()
  const { t } = useTranslation()
  const userType = useUserType()
  const profile = useUserProfile()

  const patientProfileId =
    userType === UserType.Patient ? profile?.id : patientId

  const { data, loading } = useQuery<getDoctorProfilesForPatients>(
    GET_DOCTOR_PROFILES_FOR_PATIENTS,
    {
      variables: {
        patientId: patientProfileId,
      },
      skip: !patientProfileId,
    }
  )
  const doctorProfiles = data?.getDoctorProfilesForPatients || []

  // scroll required doctor profile into the view, now i can go to the google too ;)
  useLayoutEffect(() => {
    if (doctorProfileId) {
      const doctorProfileEl = window.document.getElementById(doctorProfileId)
      if (doctorProfileEl) {
        doctorProfileEl.scrollIntoView()
      }
    }
  })

  return (
    <BetmenPage title={t('menu:patient.myDoctors')}>
      <DoctorProfileList doctorProfiles={doctorProfiles} loading={loading} />
    </BetmenPage>
  )
}

export { PatientDoctors }

import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSurveySchemaData } from '../hooks/useSurveySchemaData'
import { Loading } from '../../../../common/components/Loading'
import { Box } from '@mui/material'
import { EmptyState } from '../../../../common/components/EmptyState'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { EditSurveyState } from './EditSurveyState'
import { ViewSurvey } from './ViewSurvey/ViewSurvey'
import { SurveySchemaStatusEnum } from '../../../../models/graphql'
import { useMe } from '../../../../common/hooks/useMe'

type RouteParams = {
  surveySchemaId: string
}

export const GetSurvey: React.FC = () => {
  const { t } = useTranslation()

  const { surveySchemaId } = useParams<RouteParams>()
  const {
    loading,
    surveySchema,
    refetch: refetchSurveySchema,
  } = useSurveySchemaData({
    variables: { surveySchemaId },
  })
  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })

  const isEditable = useMemo(
    () =>
      surveySchema?.status !== SurveySchemaStatusEnum.Archived &&
      (!surveySchema?.isGlobal || me?.isAdmin),
    [surveySchema, me]
  )

  return (
    <>
      {loading && <Loading />}
      {!surveySchema && !loading && (
        <Box
          flexGrow={1}
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <EmptyState
            title={t('survey:surveySchemaNotFoundTitle')}
            description={t('survey:surveySchemaNotFoundMessage', {
              surveySchemaId,
            })}
          />
        </Box>
      )}
      {surveySchema && !loading && (
        <>
          {isEditable ? (
            <EditSurveyState
              surveySchema={surveySchema}
              refetchSurveySchema={refetchSurveySchema}
            />
          ) : (
            <ViewSurvey surveySchema={surveySchema} />
          )}
        </>
      )}
    </>
  )
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import { Badge, Box, IconButton } from '@mui/material'
import { MessageIcon } from '../../common/icons/MessageIcon'

import { useQuery, useSubscription, useApolloClient } from '@apollo/client'
import {
  NEW_MESSAGE,
  GET_PARTNERS,
  GET_PARTNER_MESSAGES,
} from '../../operations/messageOperations'
import { getPartners, newMessage_newMessage } from '../../models/graphql'

const NewMessagesPanel: React.FC = () => {
  const history = useHistory()
  const apolloClient = useApolloClient()

  const { data: partnersData, refetch: refetchPartners } =
    useQuery<getPartners>(GET_PARTNERS)
  const partners = partnersData?.getPartners || []
  const newMessageFromPartnerCount = partners.filter(
    (partner) => partner.newMessages.length
  ).length

  useSubscription(NEW_MESSAGE, {
    onSubscriptionData: ({ subscriptionData }) => {
      const message: newMessage_newMessage = subscriptionData.data.newMessage
      const senderPartner = partners.find(
        (partner) => partner.user.id === message.senderUserId
      )

      const updatePartnerCache = () => {
        if (senderPartner) {
          const cache = apolloClient.cache
          cache.modify({
            // check https://github.com/apollographql/apollo-client/issues/7577 for the reason of any
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            id: cache.identify(senderPartner as any),
            fields: {
              lastMessageAt() {
                return message.createdAt
              },
              newMessages(existing) {
                return [message, ...existing]
              },
            },
          })
        } else {
          refetchPartners()
        }
      }
      updatePartnerCache()

      const updatePartnerMessagesCache = () => {
        const variables = {
          partnerUserId: message.senderUserId,
        }
        const data = apolloClient.readQuery({
          query: GET_PARTNER_MESSAGES,
          variables,
        })

        if (data) {
          apolloClient.writeQuery({
            query: GET_PARTNER_MESSAGES,
            variables,
            data: {
              getPartnerMessages: [message, ...data.getPartnerMessages],
            },
          })
        }
      }
      updatePartnerMessagesCache()
    },
  })

  return (
    <Box color="common.white">
      <IconButton
        aria-label={`show ${newMessageFromPartnerCount} new messages`}
        onClick={() => history.push(`/messages`)}
        color="inherit"
        size="large"
      >
        <Badge badgeContent={newMessageFromPartnerCount} color="error">
          <MessageIcon />
        </Badge>
      </IconButton>
    </Box>
  )
}

export { NewMessagesPanel }

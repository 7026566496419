import { Box } from '@mui/material'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import React from 'react'
import Xarrow, { xarrowPropsType } from 'react-xarrows'
import { useColorPalette } from '../../../../common/hooks/helper/useColor'
import { ArrowDistanceConfig, ARROW_DISTANCES } from '../../../../config'
import { parseDistanceAsDuration } from '../../../../utils/parseDistance'

dayjs.extend(duration)

export type ArrowProps = xarrowPropsType & {
  level?: number
  labelText?: string
  highlighted?: boolean
  distance?: number
  color?: string
  triggerRender?: number
  scale?: number
}

export const calculateArrowLevels = (
  arrows: ArrowProps[],
  targetIds: string[]
): void => {
  // create object to store maximum incoming and outgoing arrow levels for arrow targets
  const targetMaxLevels = targetIds.reduce<{
    [key: string]: { start: number; end: number; index: number }
  }>((targetMaxLevels, targetId, index) => {
    targetMaxLevels[targetId] = { start: -1, end: -1, index }
    return targetMaxLevels
  }, {})

  // cache arrow distances
  const calculateArrowDistance = (arrow: ArrowProps): number => {
    const startIndex = targetIds.findIndex(
      (targetId) => targetId === arrow.start
    )
    const endIndex = targetIds.findIndex((targetId) => targetId === arrow.end)
    return startIndex !== -1 && endIndex !== -1 ? endIndex - startIndex : 0
  }
  arrows.forEach((arrow) => (arrow.distance = calculateArrowDistance(arrow)))

  for (
    let distanceToLevel = 1;
    distanceToLevel < arrows.length;
    distanceToLevel++
  ) {
    arrows.forEach((arrow: ArrowProps) => {
      if (arrow.distance === distanceToLevel) {
        // get start and end target maximum levels
        const startMaxLevels = targetMaxLevels[arrow.start as string]
        const endMaxLevels = targetMaxLevels[arrow.end as string]
        const maxLevels = [startMaxLevels.start, endMaxLevels.end]

        // get targets maximum levels between start and end target
        for (
          let innerMaxLevelIndex = startMaxLevels.index + 1;
          innerMaxLevelIndex < endMaxLevels.index;
          innerMaxLevelIndex++
        ) {
          const innerMaxLevels = targetMaxLevels[targetIds[innerMaxLevelIndex]]
          maxLevels.push(innerMaxLevels.start)
          maxLevels.push(innerMaxLevels.end)
        }

        // find maximum level
        arrow.level = Math.max(...maxLevels) + 1

        // store new maximum level
        startMaxLevels.start = arrow.level
        endMaxLevels.end = arrow.level
      }
    })
  }
}

export const getMaximumArrowLevel = (arrows: ArrowProps[]): number =>
  arrows.length
    ? Math.max(
        ...arrows.map((arrow) =>
          typeof arrow.level === 'number' ? arrow.level + 1 : 0
        )
      )
    : 0

export const getWidthForLevel = (level: number, scale: number): number =>
  20 + 20 * level * scale

export const getArrowConfigByDistance = (
  distance: string
): ArrowDistanceConfig => {
  const defaultArrowConfig = ARROW_DISTANCES[ARROW_DISTANCES.length - 1]

  const arrowDuration = parseDistanceAsDuration(distance)

  const validArrowConfigs = ARROW_DISTANCES.filter((arrowConfig) => {
    const maxDuration = parseDistanceAsDuration(arrowConfig.maxDistance)
    return arrowDuration.as('seconds') <= maxDuration.as('seconds')
  })

  return validArrowConfigs.length ? validArrowConfigs[0] : defaultArrowConfig
}

export const getBrokenArrowConfig = (): ArrowDistanceConfig => {
  const defaultArrowConfig = ARROW_DISTANCES[ARROW_DISTANCES.length - 1]

  return (
    ARROW_DISTANCES.find(
      (distanceConfig) => distanceConfig.maxDistance === 'broken'
    ) ?? defaultArrowConfig
  )
}

/**
 * Arrow component, which hooks together two components with the help of react-xarrows.
 * https://github.com/Eliav2/react-xarrows
 */
export const Arrow: React.FC<ArrowProps> = React.memo((props) => {
  const {
    level = 0,
    labelText,
    highlighted,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    triggerRender,
    color,
    scale = 1,
    ...rest
  } = props
  const colorPalette = useColorPalette()
  const arrowColors = {
    default: colorPalette.common.black,
    broken: colorPalette.error.main,
  }

  const arrowColor = color || arrowColors.default

  return (
    <Xarrow
      _extendSVGcanvas={(level > 2 ? level - 2 : 0) * 6 * scale}
      startAnchor="right"
      endAnchor="right"
      color={arrowColor}
      headSize={5}
      path="grid"
      strokeWidth={2}
      _cpx1Offset={10 + level * 20 * scale}
      _cpx2Offset={10 + level * 20 * scale}
      _cpy1Offset={10}
      _cpy2Offset={-10}
      labels={
        labelText && (
          <Box
            color={arrowColor}
            bgcolor={colorPalette.background.default}
            position="relative"
            left={`${12 + level * 5 * scale}px`}
            fontSize="0.8em"
            style={{
              transform: 'rotate(90deg)',
              textDecoration:
                arrowColor === arrowColors.broken ? 'line-through' : 'none',
            }}
          >
            {labelText}
          </Box>
        )
      }
      divContainerStyle={{
        opacity: highlighted ? 1 : 0.2,
      }}
      divContainerProps={{
        onMouseOver: (e) => {
          e.currentTarget.style.opacity = '1'
        },
        onMouseOut: (e) => {
          e.currentTarget.style.opacity = highlighted ? '1' : '0.2'
        },
      }}
      {...rest}
    />
  )
})

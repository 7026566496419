import { QueryHookOptions, useQuery } from '@apollo/client'
import {
  aggregatedSurveyResults,
  aggregatedSurveyResultsVariables,
  aggregatedSurveyResults_getAggregatedSurveyResults as AggregatedSurveyResult,
} from '../../../../models/graphql'

import { GET_AGGREGATED_SURVEY_RESULTS } from '../operations/surveyOperations'

type Options = QueryHookOptions<
  aggregatedSurveyResults,
  aggregatedSurveyResultsVariables
>

type Return = {
  aggregatedSurveyResults?: AggregatedSurveyResult
  loading: boolean
  refetch: () => void
  fetchMore: (option: Options) => void
}
export const useAggregatedSurveyResults = (options: Options = {}): Return => {
  const { data, loading, refetch, fetchMore } = useQuery<
    aggregatedSurveyResults,
    aggregatedSurveyResultsVariables
  >(GET_AGGREGATED_SURVEY_RESULTS, options)

  return {
    aggregatedSurveyResults: data?.getAggregatedSurveyResults,
    loading,
    refetch,
    fetchMore,
  }
}

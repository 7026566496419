import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const ArrowUpIcon: React.FC<SvgIconProps> = (props) => {
  const viewBox = `0 0 ${props.width ?? 8} ${props.height ?? 8}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <path d="M7.807,3.92166667 L4.58933333,0.266666667 C4.43384742,0.10621105 4.22316695,0.0108805189 4,0 C3.77686494,0.011012566 3.56623072,0.106322169 3.41066667,0.266666667 L0.193,3.92166667 C0.0786809131,4.04752169 0.0410655745,4.22522028 0.0945820516,4.38660283 C0.148098529,4.54798537 0.28443869,4.66799814 0.451306715,4.70060799 C0.618174739,4.73321783 0.789665307,4.67336226 0.9,4.544 L3.38333333,1.722 C3.40630825,1.69610687 3.44289841,1.68711353 3.4752588,1.69940604 C3.5076192,1.71169855 3.52901043,1.74271685 3.529,1.77733333 L3.529,7.529 C3.529,7.78912612 3.73987388,8 4,8 C4.26012612,8 4.471,7.78912612 4.471,7.529 L4.471,1.77733333 C4.47097093,1.74261652 4.49246793,1.71151877 4.52495598,1.69928012 C4.55744403,1.68704147 4.59411596,1.69622627 4.617,1.72233333 L7.1,4.544 C7.21033469,4.67336226 7.38182526,4.73321783 7.54869329,4.70060799 C7.71556131,4.66799814 7.85190147,4.54798537 7.90541795,4.38660283 C7.95893443,4.22522028 7.92131909,4.04752169 7.807,3.92166667 Z" />
    </SvgIcon>
  )
}

export { ArrowUpIcon }

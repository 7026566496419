import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import { useStoreActions } from '../../../../../store/store.hooks'
import {
  deleteProtocol,
  deleteProtocolVariables,
  getTreatment,
  getTreatment_getTreatment,
} from '../../../../../models/graphql'
import {
  DELETE_PROTOCOL,
  GET_TREATMENT,
} from '../../../../../operations/treatmentOperations'
import {
  isAppointment,
  sortAppointmentsAndSurveys,
} from '../../utils/appointment.util'
import { SaveButton } from '../../../../../common/components/SaveButton'

type Props = {
  isOpen: boolean
  toggleIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  treatment?: getTreatment_getTreatment
  treatmentSchemaId?: string | null
  onCompleted?: () => void
}

const DeleteProtocolModal: React.FC<Props> = ({
  isOpen,
  toggleIsOpen,
  treatment,
  treatmentSchemaId,
  onCompleted,
}) => {
  const { t } = useTranslation()

  const selectedLanguage = useSelectedLanguage()

  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const futureAppointments =
    treatment?.appointments?.filter(
      (appointment) =>
        appointment.treatmentSchema?.id === treatmentSchemaId &&
        dayjs().endOf('day').isBefore(appointment.proposedDate)
    ) || []
  const isAnyFutureAppointment = futureAppointments.length > 0

  const futureSurveys =
    treatment?.surveys?.filter(
      (survey) =>
        +new Date(survey.fillableFrom) > Date.now() &&
        survey.createdByTreatmentSchemaId === treatmentSchemaId
    ) || []

  const sortedFutureAppointmentsAndSurveys = sortAppointmentsAndSurveys(
    futureAppointments,
    futureSurveys
  )

  const [deleteAllAppointments, setDeleteAllAppointments] = useState(false)

  const [deleteProtocolMutation, { loading: deletingProtocol }] = useMutation<
    deleteProtocol,
    deleteProtocolVariables
  >(DELETE_PROTOCOL, {
    onCompleted: () => {
      onCompleted && onCompleted()
    },
    update(cache, { data }) {
      if (!treatment || !data?.deleteProtocol) {
        return
      }
      cache.writeQuery<getTreatment>({
        query: GET_TREATMENT,
        variables: {
          treatmentId: treatment.id,
        },
        data: {
          getTreatment: data.deleteProtocol,
        },
      })
    },
  })

  const handleClose = () => {
    setDeleteAllAppointments(false)
    toggleIsOpen(false)
  }

  const handleSubmit = async () => {
    if (isAnyFutureAppointment && treatment && treatmentSchemaId) {
      await deleteProtocolMutation({
        variables: {
          treatmentData: {
            treatmentId: treatment.id,
            treatmentSchemaId,
          },
        },
      })
    }

    setToast({
      text: t('notification:successDeleteProtocol'),
      type: 'success',
    })

    handleClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{t('treatment:doctor.deleteProtocol')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {sortedFutureAppointmentsAndSurveys.length
            ? t('treatment:doctor.deleteProtocolDescription')
            : t('treatment:doctor.deleteEmptyProtocolDescription')}
        </DialogContentText>
        <Box mb={2} mt={2}>
          <List dense={false}>
            {sortedFutureAppointmentsAndSurveys.map((appointmentOrSurvey) => {
              return (
                <ListItem key={appointmentOrSurvey.id}>
                  <Box flex={2}>
                    <ListItemText
                      disableTypography
                      primary={
                        appointmentOrSurvey.__typename === 'Appointment' ? (
                          appointmentOrSurvey.info?.doctorTitle[
                            selectedLanguage
                          ]
                        ) : (
                          <Typography variant="italic" fontWeight={500}>
                            {appointmentOrSurvey.surveySchema.title}
                          </Typography>
                        )
                      }
                    />
                  </Box>
                  <Box flex={1} pl={1}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography
                          variant={
                            isAppointment(appointmentOrSurvey)
                              ? 'inherit'
                              : 'italic'
                          }
                          fontWeight={400}
                        >
                          {t('common:dateFormatted', {
                            date: appointmentOrSurvey.date,
                          })}
                        </Typography>
                      }
                    />
                  </Box>
                </ListItem>
              )
            })}
          </List>
        </Box>
      </DialogContent>
      {isAnyFutureAppointment && (
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={deleteAllAppointments}
                onChange={() => setDeleteAllAppointments((value) => !value)}
                name="deleteAllAppointments"
                color="primary"
              />
            }
            label={t('treatment:doctor.deleteAppointmentsConfirm')}
          />
        </DialogActions>
      )}

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t('common:cancel')}
        </Button>

        <SaveButton
          color="error"
          disabled={isAnyFutureAppointment && !deleteAllAppointments}
          isSaving={deletingProtocol}
          onClick={handleSubmit}
          text={t('common:delete')}
          inProgressText={t('common:deleting')}
          completedText={t('common:deleted')}
        />
      </DialogActions>
    </Dialog>
  )
}

export { DeleteProtocolModal }

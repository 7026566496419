import { useMutation } from '@apollo/client'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import {
  LoginWithTwoFactorToken,
  LoginWithTwoFactorTokenVariables,
} from '../../../models/graphql'
import { LOGIN_WITH_TWO_FACTOR_TOKEN } from '../../../operations/userOperations'

type Return = MutationTuple<
  LoginWithTwoFactorToken,
  LoginWithTwoFactorTokenVariables
>

type Options = MutationHookOptions<
  LoginWithTwoFactorToken,
  LoginWithTwoFactorTokenVariables
>

export const useLoginWithTwoFactor = (options: Options = {}): Return => {
  return useMutation<LoginWithTwoFactorToken, LoginWithTwoFactorTokenVariables>(
    LOGIN_WITH_TWO_FACTOR_TOKEN,
    options
  )
}

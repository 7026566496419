import {
  ProfileMenuItemProps,
  doctorProfileMenuItems,
  patientProfileMenuItems,
  assistantProfileMenuItems,
  sharedProfileMenuItems,
  adminProfileMenuItems,
} from '../layout/menuItems'
import { UserType } from '../../models/graphql'
import { useMe } from './useMe'

export const useMenuItems = (): ProfileMenuItemProps[] => {
  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })

  const menuItemsMap: Record<UserType, ProfileMenuItemProps[]> = {
    Doctor: doctorProfileMenuItems,
    Patient: patientProfileMenuItems,
    Assistant: assistantProfileMenuItems,
    Admin: adminProfileMenuItems,
  }

  const menuItems = me?.userType ? menuItemsMap[me.userType] : []

  // TODO - Currently isLocationAdmin and isAdmin are not used, reintroduce this commented part
  // when institution admin is implemented
  return [...menuItems, ...sharedProfileMenuItems]
  // .filter((item) => {
  // if (item.isLocationAdmin) {
  //   const institutionIds =
  //     me?.institutionPermissions.map((item) => item.institutionId) ?? []
  //   return me?.isAdmin || institutionIds.length > 0
  // }
  // return !item.isAdmin || me?.isAdmin
  // })
}

import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  inviteAssistant,
  inviteAssistantVariables,
} from '../../../../models/graphql'
import { INVITE_ASSISTANT } from '../operations/doctorOwnAssistantsOperations'

type Options = MutationHookOptions<inviteAssistant, inviteAssistantVariables>

type Return = MutationTuple<inviteAssistant, inviteAssistantVariables>

export const useInviteAssistant = (options: Options = {}): Return => {
  return useMutation<inviteAssistant, inviteAssistantVariables>(
    INVITE_ASSISTANT,
    options
  )
}

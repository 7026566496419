import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  PaperProps,
  TextField,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { MultiLangCollapse } from './MultiLangCollapse'
import { SaveButton } from '../../../common/components/SaveButton'
import { useMe } from '../../../common/hooks/useMe'
import { useSelectedLanguage } from '../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import {
  createAppointmentInfo as createAppointmentInfoType,
  createAppointmentInfoVariables,
  createAppointmentInfo_createAppointmentInfo,
  getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo,
  listAppointmentInfos_listAppointmentInfos,
  listAppointmentInfos_listAppointmentInfos_professions,
  MultiLangTextInput,
  updateAppointmentInfo,
  updateAppointmentInfoVariables,
} from '../../../models/graphql'
import {
  CREATE_APPOINTMENT_INFO,
  UPDATE_APPOINTMENT_INFO,
} from '../../../operations/appointmentOperations'
import { useUpdateScheduleAppointmentInfo } from '../../doctor/TreatmentSchema/hooks/useUpdateScheduleAppointmentInfo'
import { ProfessionSelector } from '../../../common/components/selectors/ProfessionSelector'
import { BoxWithLabel } from '../../../common/components/BoxWithLabel'

type AppointmentInfoForm = {
  doctorTitle: MultiLangTextInput | null
  patientTitle: MultiLangTextInput | null
  goodToKnow: MultiLangTextInput | null
  doctorTodo: MultiLangTextInput | null
  professions: listAppointmentInfos_listAppointmentInfos_professions[] | null
  beforeTreatmentNote: boolean
  oeno: string | null
}

type Props = {
  selectedAppointmentInfo?:
    | listAppointmentInfos_listAppointmentInfos
    | getTreatmentSchema_getTreatmentSchema_schedules_appointmentInfo
    | null
    | undefined
  isOpen: boolean
  initName?: string
  scheduleId?: string
  treatmentSchemaId?: string
  setIsOpen: (isOpen: boolean) => void
  onClose?: () => void
  onComplete?: (
    newAppointmentInfo?: createAppointmentInfo_createAppointmentInfo
  ) => void
}

// For not required fields, we use this default object
const emptyMultiLangObject = { hu: '', en: '' }

const defaultValue = {
  doctorTitle: null,
  patientTitle: null,
  goodToKnow: emptyMultiLangObject,
  doctorTodo: emptyMultiLangObject,
  professions: null,
  beforeTreatmentNote: false,
  oeno: null,
}

const AppointmentAdminModal: React.FC<Props> = ({
  isOpen,
  selectedAppointmentInfo,
  initName,
  scheduleId,
  treatmentSchemaId,
  setIsOpen,
  onComplete,
  onClose,
}) => {
  const { t } = useTranslation()
  const selectedLanguage = useSelectedLanguage()
  const { data: { me } = {} } = useMe()
  const [shouldSaveAppointmentGlobally, setShouldSaveAppointmentGlobally] =
    useState(false)

  const isEdit = !!selectedAppointmentInfo
  const refetchQueries = ['listAppointmentInfos', 'getTreatmentSchema']

  const methods = useForm<AppointmentInfoForm>({
    mode: 'onBlur',
    shouldUnregister: false,
    defaultValues: defaultValue,
  })

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    clearErrors,
  } = methods

  useEffect(() => {
    if (isEdit) {
      reset({
        doctorTitle: selectedAppointmentInfo?.doctorTitle,
        patientTitle: selectedAppointmentInfo?.patientTitle,
        goodToKnow: selectedAppointmentInfo?.goodToKnow,
        doctorTodo: selectedAppointmentInfo?.doctorTodo,
        professions: selectedAppointmentInfo?.professions,
        beforeTreatmentNote:
          selectedAppointmentInfo?.beforeTreatmentNote ?? undefined,
        oeno: selectedAppointmentInfo?.oeno,
      })
    }
    if (!isEdit && initName) {
      setValue(`doctorTitle.${selectedLanguage}`, initName)
      setValue(`patientTitle.${selectedLanguage}`, initName)
    }
  }, [
    selectedLanguage,
    selectedAppointmentInfo,
    isEdit,
    reset,
    initName,
    setValue,
  ])

  const closeModal = () => {
    setIsOpen(false)
    onClose && onClose()
    reset(
      {
        ...selectedAppointmentInfo,
        beforeTreatmentNote:
          selectedAppointmentInfo?.beforeTreatmentNote ?? false,
      } || defaultValue
    )
  }

  const onCompleteSuccess = (
    createAppointmentInfo?: createAppointmentInfo_createAppointmentInfo
  ) => {
    onComplete && onComplete(createAppointmentInfo)
    closeModal()
  }

  const [createAppointmentInfo, { loading: isCreateLoading }] = useMutation<
    createAppointmentInfoType,
    createAppointmentInfoVariables
  >(CREATE_APPOINTMENT_INFO, {
    refetchQueries,
    onCompleted: ({ createAppointmentInfo }) =>
      onCompleteSuccess(createAppointmentInfo),
  })

  const [updateAppointmentInfo, { loading: isUpdateLoading }] = useMutation<
    updateAppointmentInfo,
    updateAppointmentInfoVariables
  >(UPDATE_APPOINTMENT_INFO, {
    refetchQueries,
    onCompleted: () => onCompleteSuccess(),
  })

  const [updateScheduleAppointmentInfo, { loading: isUpdateScheduleLoading }] =
    useUpdateScheduleAppointmentInfo({
      refetchQueries,
      onCompleted: () => onCompleteSuccess(),
    })

  const onSubmit = handleSubmit(
    ({
      doctorTitle,
      patientTitle,
      goodToKnow,
      doctorTodo,
      professions,
      beforeTreatmentNote,
      oeno,
    }) => {
      const appointmentInfoInput = {
        doctorTitle,
        patientTitle,
        goodToKnow,
        doctorTodo,
        professionIds: professions?.map((prof) => prof.id),
        beforeTreatmentNote,
        oeno,
      }

      if (selectedAppointmentInfo) {
        // If scheduleId definied it means schedule will be edited only in protocol
        return scheduleId
          ? updateScheduleAppointmentInfo({
              variables: {
                scheduleId,
                appointmentInfoInput,
              },
            })
          : updateAppointmentInfo({
              variables: {
                appointmentInfoInput: {
                  appointmentInfoId: selectedAppointmentInfo.id,
                  ...appointmentInfoInput,
                },
              },
            })
      } else {
        return createAppointmentInfo({
          variables: {
            createAppointmentInfoInput: {
              ...appointmentInfoInput,
              treatmentSchemaId:
                !shouldSaveAppointmentGlobally && treatmentSchemaId
                  ? treatmentSchemaId
                  : undefined,
            },
          },
        })
      }
    }
  )

  const required = {
    value: true,
    message: t('messages:warnings.required'),
  }

  const isLoading =
    isCreateLoading || isUpdateLoading || isUpdateScheduleLoading

  const watchAllFields = watch()

  const handleMultiLangChange = useCallback(
    (
        name: keyof Omit<
          AppointmentInfoForm,
          'professions' | 'beforeTreatmentNote' | 'oeno'
        >
      ) =>
      (newValue: MultiLangTextInput) => {
        Object.keys(newValue).forEach((key) => {
          setValue(
            `${name}.${key as keyof MultiLangTextInput}`,
            newValue[key as keyof MultiLangTextInput]
          )
        })
        clearErrors(name)
      },
    [setValue, clearErrors]
  )

  return (
    <FormProvider {...methods}>
      <Dialog
        data-cy="AppointmentAdminModal"
        PaperProps={
          {
            component: 'form',
          } as Partial<PaperProps<'div'>>
        }
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            closeModal()
          }
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {isEdit
            ? t('appointment:admin.editAppointmentInfoTitle')
            : t('appointment:admin.addAppointmentInfoTitle')}
        </DialogTitle>

        <DialogContent>
          <Box mb={3}>
            <DialogContentText>
              {t('appointment:admin.addAppointmentInfoPanelText')}
            </DialogContentText>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MultiLangCollapse
                {...register('doctorTitle', { required })}
                title={`${t('appointment:admin.appointmentInfoName')} (${t(
                  'common:doctor'
                )})`}
                fullWidth
                variant="outlined"
                errors={errors}
                onSave={handleMultiLangChange('doctorTitle')}
                initValue={watchAllFields.doctorTitle}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MultiLangCollapse
                {...register('patientTitle', { required })}
                title={`${t('appointment:admin.appointmentInfoName')} (${t(
                  'common:patient'
                )})`}
                fullWidth
                variant="outlined"
                errors={errors}
                initValue={watchAllFields.patientTitle}
                onSave={handleMultiLangChange('patientTitle')}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <ProfessionSelector
                name="professions"
                label={t('appointment:admin.appointmentInfoProfessions')}
              />
            </Grid>
            <Grid item xs={12}>
              <BoxWithLabel label={t('appointment:oeno')}>
                <Controller
                  control={control}
                  name="oeno"
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder={t('appointment:oeno')}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </BoxWithLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <MultiLangCollapse
                {...register('doctorTodo')}
                title={t('common:doctorTodo')}
                fullWidth
                variant="outlined"
                maxRows={5}
                minRows={5}
                multiline
                initValue={watchAllFields.doctorTodo}
                onSave={handleMultiLangChange('doctorTodo')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MultiLangCollapse
                title={t('appointment:goodToKnowAndNotes')}
                fullWidth
                variant="outlined"
                {...register('goodToKnow')}
                maxRows={3}
                minRows={3}
                multiline
                initValue={watchAllFields.goodToKnow}
                onSave={handleMultiLangChange('goodToKnow')}
              />
              <FormControlLabel
                control={
                  <Controller
                    name="beforeTreatmentNote"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                      />
                    )}
                  />
                }
                label={t('appointment:admin.dontEatOrDrink')}
              />
              {me?.isAdmin && treatmentSchemaId && !isEdit && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={shouldSaveAppointmentGlobally}
                      onChange={(e) =>
                        setShouldSaveAppointmentGlobally(e.target.checked)
                      }
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                    />
                  }
                  label={t('appointment:isGlobal')}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={closeModal}>
            {t('common:cancel')}
          </Button>
          <SaveButton
            isSaving={isLoading}
            size="medium"
            text={isEdit ? t('common:save') : t('common:add')}
            onClick={onSubmit}
            data-cy="AppointmentAdminModal-Button-add"
          />
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}

export { AppointmentAdminModal }

import { AuthModel } from './auth/auth.interface'
import { ToastModel } from './toast/toast.interface'

export interface StoreModel {
  auth: AuthModel
  toast: ToastModel
}

export enum BloodType {
  'A_plus' = 'A+',
  'A_minus' = 'A-',
  'B_plus' = 'B+',
  'B_minus' = 'B-',
  'O_plus' = '0+',
  'O_minus' = '0-',
  'AB_plus' = 'AB+',
  'AB_minus' = 'AB-',
}

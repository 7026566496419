import React, { useState } from 'react'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import { Controller, useForm } from 'react-hook-form'

import { INVITE_DOCTOR } from '../../../../operations/doctorProfileOperations'
import { inviteDoctor, inviteDoctorVariables } from '../../../../models/graphql'
import { useStoreActions } from '../../../../store/store.hooks'

type newDoctorForm = {
  email: string
  stampNumber: string
  title: string
  firstName: string
  lastName: string
  gender: string
}

interface InviteDoctorModal {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onComplete?: () => void
}

const InviteDoctorModal: React.FC<InviteDoctorModal> = ({
  isOpen,
  setIsOpen,
  onComplete,
}) => {
  const { t } = useTranslation()
  const [invalidFieldValues, setInvalidFieldValues] = useState<{
    [key: string]: string[]
  }>({ email: [], stampNumber: [] })

  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const handleClose = () => setIsOpen(false)

  const {
    watch,
    formState: { errors },
    register,
    handleSubmit,
    control,
    trigger,
  } = useForm<newDoctorForm>({
    defaultValues: {
      email: '',
      stampNumber: '',
      title: '',
      firstName: '',
      lastName: '',
      gender: '',
    },
  })

  const [sendInvite, { loading: isSaving }] = useMutation<
    inviteDoctor,
    inviteDoctorVariables
  >(INVITE_DOCTOR, {
    onCompleted: () => {
      setToast({
        text: t('common:invite.sent'),
        type: 'success',
      })
      handleClose()
      onComplete && onComplete()
    },
    onError: (error) => {
      const errorMessages: string[] = []
      const graphQlError = error.graphQLErrors[0]
      if (graphQlError) {
        const detail =
          (graphQlError.extensions?.exception?.meta as { target: string })
            ?.target || ''
        const fieldsToCheck: (keyof newDoctorForm)[] = ['email', 'stampNumber']
        fieldsToCheck.forEach((field) => {
          const isFieldDuplicate = !!~detail.indexOf(field)
          if (isFieldDuplicate) {
            setInvalidFieldValues({
              ...invalidFieldValues,
              [field]: [...invalidFieldValues[field], watch(field)],
            })
            trigger(field)
            errorMessages.push(t('common:invite.valueExists', { field }))
          }
        })
      }
      setToast({
        text: errorMessages.join(', ') || t('common:serverError'),
        type: 'error',
      })
    },
  })

  const onSubmit = handleSubmit((createDoctorArgs) => {
    sendInvite({ variables: { createDoctorArgs } })
  })

  const required = {
    value: true,
    message: t('messages:warnings.required'),
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>{t('common:invite.doctor.panelTitle')}</DialogTitle>

        <DialogContent sx={{ width: { sm: 400 } }}>
          <Box mb={3}>
            <DialogContentText>
              {t('common:invite.doctor.panelText')}
            </DialogContentText>
          </Box>

          <Box my={1}>
            <Typography variant="subtitle2">
              {t('common:invite.doctor.stampNumber')}
            </Typography>
            <TextField
              {...register('stampNumber', {
                required,
                validate: (value) =>
                  !invalidFieldValues.stampNumber.includes(value),
              })}
              type="number"
              fullWidth
              error={!!errors.stampNumber}
              helperText={errors.stampNumber?.message}
              variant="outlined"
            />
          </Box>

          <Box my={1}>
            <Typography variant="subtitle2">{t('patients:gender')}</Typography>
            <FormControl error={!!errors.gender}>
              <Controller
                name="gender"
                control={control}
                rules={{ required }}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Box display="flex">
                      <FormControlLabel
                        value="f"
                        control={<Radio />}
                        label={t('patients:genders.f')}
                      />
                      <FormControlLabel
                        value="m"
                        control={<Radio />}
                        label={t('patients:genders.m')}
                      />
                    </Box>
                  </RadioGroup>
                )}
              />
              <FormHelperText>{errors.gender?.message}</FormHelperText>
            </FormControl>
          </Box>

          <Box my={1}>
            <Typography variant="subtitle2">
              {t('profile:doctor.title')}
            </Typography>

            <TextField
              {...register('title')}
              error={!!errors.title}
              helperText={errors.title?.message}
              fullWidth
              variant="outlined"
            />
          </Box>

          <Box my={1}>
            <Typography variant="subtitle2">
              {t('patients:lastName')}
            </Typography>
            <TextField
              {...register('lastName', { required })}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box my={1}>
            <Typography variant="subtitle2">
              {t('patients:firstName')}
            </Typography>

            <TextField
              {...register('firstName', { required })}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box my={1}>
            <Typography variant="subtitle2">{t('common:email')}</Typography>
            <TextField
              {...register('email', {
                required,
                validate: (value) => !invalidFieldValues.email.includes(value),
              })}
              type="email"
              error={!!errors.email}
              helperText={errors.email?.message}
              fullWidth
              variant="outlined"
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button disabled={isSaving} variant="outlined" onClick={handleClose}>
            {t('common:cancel')}
          </Button>

          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSaving}
          >
            {t('common:invite.send')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export { InviteDoctorModal }

import CalendarPicker from '@mui/lab/CalendarPicker'
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay'
import { styled, Theme } from '@mui/material/styles'
import dayjs, { Dayjs } from 'dayjs'
import React, { Dispatch } from 'react'
import { BORDER_RADIUS } from '../../../../../theme'

// eslint-disable-next-line
const CustomPickersDay = styled<any>(PickersDay)<PickersDayProps<Dayjs>>(
  ({ theme }) => ({
    fontSize: '1.4rem',
    borderRadius: BORDER_RADIUS,
    margin: '0 2px',
    '&.Mui-selected': {
      color: (theme as Theme).palette.background.default,
    },
    '&.Mui-disabled': {
      color: (theme as Theme).palette.background.default,
    },
    '&.MuiPickersDay-today:not(.Mui-selected)': {
      border: 'none',
      color: (theme as Theme).palette.primary.main,
    },
  })
)

interface CalendarMonth {
  minDate?: Date | string
  selectedDate: Date | string
  handleDateChange: Dispatch<Date>
}

const CalendarMonth: React.FC<CalendarMonth> = (props) => {
  const { minDate = '2018-01-01', selectedDate, handleDateChange } = props

  const handleChange = (newDate: Dayjs | null) => {
    if (!newDate) {
      return
    }
    handleDateChange(newDate.toDate())
  }

  return (
    <CalendarPicker<Dayjs>
      openTo="day"
      views={['day']}
      minDate={dayjs(minDate)}
      date={dayjs(selectedDate)}
      // here we can disable the days that are not operating (weekends)
      shouldDisableDate={() => false}
      onChange={handleChange}
      renderDay={(day, selectedDates, pickersDayProps) => (
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin
          className="CalendarMonth-Day"
        />
      )}
      className="CalendarMonth-Container"
      componentsProps={{
        leftArrowButton: {
          'data-cy': 'CalendarMonth-LeftArrowButton',
        },
        rightArrowButton: {
          'data-cy': 'CalendarMonth-RightArrowButton',
        },
      }}
    />
  )
}

export { CalendarMonth }

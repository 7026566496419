import { ApolloError, useQuery } from '@apollo/client'
import {
  getFreeSlotsByInstitution,
  getFreeSlotsByInstitutionVariables,
  getFreeSlotsByInstitution_getFreeSlotsByInstitution,
} from '../../../models/graphql'
import { EESZT_FREE_SLOTS_BY_INSTITUTION } from '../../../operations/eesztOperation'

type ReturnTypes = {
  loading: boolean
  error: ApolloError | undefined
  data: getFreeSlotsByInstitution_getFreeSlotsByInstitution[]
}

export const useEesztFreeSlotsByInstitution = (
  variables: getFreeSlotsByInstitutionVariables,
  skip: boolean
): ReturnTypes => {
  const { loading, data, error } = useQuery<
    getFreeSlotsByInstitution,
    getFreeSlotsByInstitutionVariables
  >(EESZT_FREE_SLOTS_BY_INSTITUTION, {
    skip,
    variables,
    fetchPolicy: 'network-only',
  })

  return {
    loading,
    error,
    data: data?.getFreeSlotsByInstitution || [],
  }
}

import dayjs from 'dayjs'
import {
  getTreatment_getTreatment as Treatment,
  getTreatment_getTreatment_appointments as Appointment,
  getTreatment_getTreatment_surveys as Survey,
  Language,
} from '../../../../models/graphql'
import { formatQueryString } from '../../../../utils/formatQueryString'

type AppointmentOrSurvey = Appointment | Survey

type AppointmentOrSurveyWithDate = AppointmentOrSurvey & { date: Date }

export interface AppointmentsOrSurveys {
  type: 'appointments' | 'surveys'
  appointments: Appointment[]
  surveys: Survey[]
}

export interface GroupedAppointmentsAndSurveys {
  hasPast: boolean
  groups: { [key: string]: AppointmentsOrSurveys }
}

export const getFilteredAppointments = (
  appointments: Appointment[],
  treatment: Treatment | undefined,
  selectedLanguage: Language,
  filterValue: string
): Appointment[] => {
  const dateSortedOrderedAppointments = [...appointments].sort((a, b) =>
    a.proposedDate > b.proposedDate ? 1 : -1
  )

  return dateSortedOrderedAppointments.filter((appointment) => {
    const searchString = [
      treatment?.title[selectedLanguage],
      appointment.info?.doctorTitle[selectedLanguage],
      appointment.info?.doctorTodo?.[selectedLanguage],
      appointment.info?.goodToKnow?.[selectedLanguage],
      appointment.institution?.name,
      appointment.institution?.concatenatedAddress,
      treatment?.doctor.title,
      treatment?.doctor.firstName,
      treatment?.doctor.lastName,
      appointment.doctor.title,
      appointment.doctor.firstName,
      appointment.doctor.lastName,
    ].join('_')

    const filterArray = filterValue.toLowerCase().replace('-', '').split(' ')
    return (
      filterValue.length === 0 ||
      filterArray.length ===
        filterArray.filter((filterword) =>
          formatQueryString(searchString).includes(filterword)
        ).length
    )
  })
}

export const getGroupedAppointmentsAndSurveys = (
  appointments: Appointment[],
  surveys: Survey[]
): GroupedAppointmentsAndSurveys => {
  return [...appointments, ...surveys].reduce<GroupedAppointmentsAndSurveys>(
    (acc, item) => {
      const isAppointment = item.__typename === 'Appointment'
      const date = isAppointment
        ? (item as Appointment).proposedDate
        : (item as Survey).fillableFrom
      const isPast = dayjs().startOf('day') > dayjs(date)
      acc.hasPast = acc.hasPast || isPast
      const year = dayjs(date).year()
      const month = dayjs(date).month()

      const groupKey = `${year}-${month}`
      const initialGroupItem: AppointmentsOrSurveys = {
        type: isAppointment ? 'appointments' : 'surveys',
        appointments: [],
        surveys: [],
      }
      acc.groups[groupKey] = acc.groups[groupKey] || initialGroupItem
      isAppointment
        ? acc.groups[groupKey].appointments.push(item as Appointment)
        : acc.groups[groupKey].surveys.push(item as Survey)
      return acc
    },
    { hasPast: false, groups: {} }
  )
}

export const isAppointment = (item: AppointmentOrSurvey): boolean =>
  item.__typename === 'Appointment'

export const sortAppointmentsAndSurveys = (
  appointments: Appointment[],
  surveys: Survey[]
): AppointmentOrSurveyWithDate[] =>
  [...appointments, ...surveys]
    .map((item) => {
      const date = isAppointment(item)
        ? (item as Appointment).proposedDate
        : (item as Survey).fillableFrom

      return { ...item, date }
    })
    .sort((a, b) => (a.date > b.date ? 1 : -1))

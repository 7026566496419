import React from 'react'
import { getDoctorOwnPatients_getDoctorProfile_ownPatients as PatientListItem } from '../../../../models/graphql'
import { PatientList } from './PatientList'
import { GroupType, PatientGroupType } from './patientlist.types'

type Props = {
  patientList: PatientListItem[]
  patientGroupType: GroupType
  isPatientListLoading: boolean
}

export const GroupedPatientList: React.FC<Props> = ({
  patientList,
  patientGroupType,
  isPatientListLoading,
}) => {
  const filledGroups = patientList.reduce(
    (acc, { patient, closedAt }) => {
      if (closedAt && patient) {
        acc.removed.push({ ...patient, isActive: false })
      } else if (patient) {
        acc.current.push({ ...patient, isActive: true })
      }
      return acc
    },
    {
      current: [],
      removed: [],
    } as PatientGroupType
  )

  const allActiveUserIds = (Object.keys(filledGroups) as GroupType[])
    .filter((key) => key !== 'removed')
    .map((key) => filledGroups[key])
    .flatMap((patient) => patient)
    .map((patient) => patient.id)

  // this is needed as the server returns duplicated removed users
  const removedUsers = []
  const map = new Map()
  for (const user of filledGroups.removed) {
    const hasAsActiveUser = allActiveUserIds.includes(user.id)
    const hasAsRemovedUser = map.has(user.id)

    if (!hasAsRemovedUser && !hasAsActiveUser) {
      map.set(user.id, true)
      removedUsers.push(user)
    }
  }

  const patientGroup = {
    ...filledGroups,
    removed: removedUsers,
  }

  return (
    <PatientList
      patientList={patientGroup[patientGroupType]}
      isPatientListLoading={isPatientListLoading}
    />
  )
}

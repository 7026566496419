import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BetmenList } from '../../../../common/components/BetmenList/BetmenList'
import { BetmenListBody } from '../../../../common/components/BetmenList/BetmenListBody'
import { BetmenListFilter } from '../../../../common/components/BetmenList/BetmenListFilter'
import { BetmenListHeader } from '../../../../common/components/BetmenList/BetmenListHeader'
import { BetmenListHeaderCell } from '../../../../common/components/BetmenList/BetmenListHeaderCell'
import { BetmenListItemCard } from '../../../../common/components/BetmenList/BetmenListItemCard'
import { BetmenListItemCardCell } from '../../../../common/components/BetmenList/BetmenListItemCardCell'
import { Avatar } from '../../../../common/components/Avatar'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { useUserType } from '../../../../common/hooks/useUserType'
import { formatQueryString } from '../../../../utils/formatQueryString'
import { formatTAJ } from '../../../../utils/formatTaj'
import { Status, StatusEnum } from '../../../../common/components/Status/Status'
import { PatientTypeWithStatus } from './patientlist.types'
import { useColorPalette } from '../../../../common/hooks/helper/useColor'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { useDeleteDoctorOwnPatient } from '../hooks/useDeleteDoctorOwnPatient'
import { useNotifications } from '../../../../common/providers/NotificationProvider'
import {
  ConfirmDialog,
  ConfirmDialogValue,
} from '../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'

type Props = {
  patientList: PatientTypeWithStatus[]
  isPatientListLoading: boolean
}

const PatientList: React.FC<Props> = ({
  patientList,
  isPatientListLoading,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const colorPalette = useColorPalette()
  const { setSuccessToast } = useNotifications()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const [confirmDeletePatients, setConfirmDeletePatients] =
    useState<ConfirmDialogValue>(false)

  const isChecked = useMemo(
    () => selectedIds.length !== 0,
    [selectedIds.length]
  )

  const userType = useUserType()

  // if it is not a fixed value
  // every render will rerun the gql query
  // because the actual time always changing
  // and it causes an infinite loop
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const searchTermArray = searchTerm.replace('-', '').split(' ')
  const filteredPatientList =
    searchTerm.length === 0
      ? patientList
      : patientList.filter((patient) => {
          const searchString = [
            t('common:formattedNameFull', patient),
            patient.tajNumber,
          ].join('_')
          return (
            searchTermArray.length ===
            searchTermArray.filter((filterword) =>
              formatQueryString(searchString).includes(filterword)
            ).length
          )
        })

  // Debounced onChange method
  const onSearchTermChange = (searchTerm: string) => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    timeout.current = setTimeout(() => {
      setSearchTerm(searchTerm)
    }, 300)
  }

  const selectablePatients = useMemo(
    () =>
      filteredPatientList.filter(
        (patient) => patient.isActive && patient.isDeletableForDoctor
      ),
    [filteredPatientList]
  )
  const canSelectAll = !!selectablePatients.length

  const handleSelectAll = useCallback(() => {
    if (selectedIds.length === 0) {
      setSelectedIds(selectablePatients.map((patient) => patient.id))
    } else {
      setSelectedIds([])
    }
  }, [selectedIds.length, selectablePatients])

  const [deleteDoctorsOwnPatient] = useDeleteDoctorOwnPatient()

  const handleDelete = async () => {
    if (selectedIds.length) {
      Promise.all(
        selectedIds.map(
          async (patientId) =>
            await deleteDoctorsOwnPatient({
              variables: {
                patientId,
              },
            })
        )
      )

      setSuccessToast('successDeletePatients')
    }
  }

  const SelectionHandler = useMemo(() => {
    return (
      <Grid
        container
        display="flex"
        flexDirection="row"
        mb={1}
        alignItems="center"
      >
        {canSelectAll && (
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <Checkbox
              checked={selectedIds.length === patientList.length}
              indeterminate={
                isChecked && selectedIds.length !== patientList.length
              }
              onChange={handleSelectAll}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
            />
          </Grid>
        )}

        <Grid item xs={11}>
          {selectedIds.length ? (
            <Box
              display="flex"
              alignItems="center"
              sx={{
                backgroundColor: colorPalette.grey[300],
                borderRadius: 1,
              }}
            >
              <Tooltip title={t('patients:delete') as string}>
                <IconButton
                  onClick={() => setConfirmDeletePatients(true)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {isChecked && (
                <Typography variant="smallItalic" ml={1}>
                  {t('patients:selectedPatients', {
                    count: selectedIds.length,
                  })}
                </Typography>
              )}
            </Box>
          ) : (
            <Box mb={-1}>
              <BetmenListFilter
                searchPlaceholder={t('patients:searchPatient')}
                handleSearch={(searchTerm) =>
                  onSearchTermChange(searchTerm ?? '')
                }
              />
            </Box>
          )}
        </Grid>
      </Grid>
    )
  }, [
    canSelectAll,
    colorPalette.grey,
    handleSelectAll,
    isChecked,
    patientList.length,
    selectedIds.length,
    t,
  ])

  return (
    <>
      <BetmenList
        dataSource={filteredPatientList}
        loading={isPatientListLoading}
        gridColumnSizes={[1, 4, 2, 3, 2]}
        filters={SelectionHandler}
        header={
          <BetmenListHeader>
            <BetmenListHeaderCell />
            <BetmenListHeaderCell>
              {t('patients:namePlaceholder')}
            </BetmenListHeaderCell>
            <BetmenListHeaderCell>{t('common:taj')}</BetmenListHeaderCell>
            <BetmenListHeaderCell>{t('common:birthdate')}</BetmenListHeaderCell>
            <BetmenListHeaderCell>{t('common:status')}</BetmenListHeaderCell>
          </BetmenListHeader>
        }
      >
        <BetmenListBody>
          {filteredPatientList.map((patient, i) => (
            <BetmenListItemCard
              key={`${patient.id}_${i}`}
              data-cy={`Patient-Box-${patient.tajNumber}`}
              click={() => history.push(`/${userType}/patients/${patient.id}`)}
            >
              <BetmenListItemCardCell justifyContent="flex-end" display="flex">
                {patient.isActive && patient.isDeletableForDoctor && (
                  <Checkbox
                    checked={selectedIds.includes(patient.id)}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (selectedIds.includes(patient.id)) {
                        setSelectedIds(
                          selectedIds.filter((id) => id !== patient.id)
                        )
                      } else {
                        setSelectedIds([...selectedIds, patient.id])
                      }
                    }}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                  />
                )}
              </BetmenListItemCardCell>
              <BetmenListItemCardCell display="flex" alignItems="center">
                <Avatar
                  firstName={patient.firstName}
                  lastName={patient.lastName}
                  size="tiny"
                />
                <Typography variant="body2">
                  {t('common:formattedNameFull', {
                    firstName: patient.firstName,
                    lastName: patient.lastName,
                    title: patient.title,
                  })}
                </Typography>
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                {formatTAJ(patient.tajNumber)}
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                {t('common:dateFormatted', {
                  date: patient.birthDate,
                })}
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                <Status
                  status={
                    patient.isActive ? StatusEnum.Active : StatusEnum.Deleted
                  }
                  title={patient.isActive ? undefined : t('patients:removed')}
                />
              </BetmenListItemCardCell>
            </BetmenListItemCard>
          ))}
        </BetmenListBody>
      </BetmenList>

      <ConfirmDialog
        isAlertingDialog
        valueState={[confirmDeletePatients, setConfirmDeletePatients]}
        text={t(`patients:confirmDeletePatients`)}
        onAccept={() => handleDelete()}
      />
    </>
  )
}

export { PatientList }

import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
  PaperProps,
} from '@mui/material'
import { Error as ErrorIcon } from '@mui/icons-material'
import { Autocomplete } from '@mui/material'

import dayjs from 'dayjs'
import React, { useEffect, useState, FormEventHandler } from 'react'
import { AppointmentInfoSelector } from '../../../../../common/components/selectors/AppointmentInfoSelector'
import { BoxWithLabel } from '../../../../../common/components/BoxWithLabel'
import { CalendarDay } from '../CalendarSelector/CalendarDay'
import { CalendarMonth } from '../CalendarSelector/CalendarMonth'
import { SaveButton } from '../../../../../common/components/SaveButton'
import { useEesztFreeSlotsByInstitution } from '../../../../../common/hooks/eeszt/useEesztFreeSlotsByInstitution'
import { useEesztTokenState } from '../../../../../common/hooks/eeszt/useEesztTokenState'
import { useAppointmentDeleteModal } from '../../hooks/useAppointmentDeleteModal'
import { useAppointmentTypes } from '../../../../../common/hooks/useAppointmentTypes'
import { useDoctorsList } from '../../../../../common/hooks/useDoctorsList'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  createAppointment,
  createAppointmentVariables,
  EesztAppointmentBookingTransactionStatusEnum,
  getTreatment_getTreatment,
  getTreatment_getTreatment_appointments,
  getTreatment_getTreatment_appointments_doctor,
  listAllDoctors_listAllDoctors,
  listAppointmentInfos_listAppointmentInfos,
  TreatmentStatus,
  updateAppointment as updateAppointmentData,
  updateAppointmentVariables,
  institutionChore,
  updateAppointment_updateAppointment_CalculatedSchedule as CalculatedSchedule,
  UpdateAppointmentWithDeps,
  UpdateAppointmentWithDepsVariables,
  roomChore,
  AppointmentStatus,
} from '../../../../../models/graphql'
import {
  CREATE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_WITH_DEPS,
} from '../../../../../operations/appointmentOperations'
import { useStoreActions } from '../../../../../store/store.hooks'
import { AppointmentAdminModal } from '../../../../admin/components/AppointmentAdminModal'
import { ShowDiffModal } from './ShowDiffModal'
import { GET_TREATMENT } from '../../../../../operations/treatmentOperations'
import { AddAppointmentModalInfo } from './AddAppointmentModalInfo'
import { FormProvider, useForm } from 'react-hook-form'
import { InstitutionSelector } from '../../../../../common/components/selectors/InstitutionSelector'

const toDateAndSlot = (date: string | Date): { date: Date; slot: string } => {
  const dateObj = dayjs(date)
  return {
    date: dateObj.toDate(),
    slot: dateObj.format('H:mm'),
  }
}

type RoomType = Omit<roomChore, '__typename'>

type Props = {
  treatment: getTreatment_getTreatment
  appointment?: getTreatment_getTreatment_appointments | null
  appointmentType?: string | null
  doctor?: getTreatment_getTreatment_appointments_doctor
  initProposedDate?: Date
  isOpen: boolean
  onClose?: () => void
  onDone?: () => void
}

export type SelectedSlotType = {
  slot: string
  consultationId: string
}

const AddAppointmentModal: React.FC<Props> = (props) => {
  const {
    treatment,
    appointment,
    appointmentType,
    doctor,
    initProposedDate,
    isOpen,
    onClose,
    onDone,
  } = props
  const { t } = useTranslation()
  const setToast = useStoreActions((actions) => actions.toast.setToast)
  const theme = useTheme()
  const isEesztTokenValid = useEesztTokenState()

  const selectedLanguage = useSelectedLanguage()
  const [scheduleDiff, setScheduleDiff] = useState<CalculatedSchedule | null>(
    null
  )

  const patientName = t('common:formattedNameFull', treatment?.patient)
  const todayStart = dayjs().startOf('day').toDate()

  const [selectedAppointmentType, setSelectedAppointmentType] = useState<
    listAppointmentInfos_listAppointmentInfos | null | undefined
  >()

  const formMethods = useForm({
    defaultValues: {
      institution: appointment?.institution,
    },
  })

  const institution = formMethods.watch('institution')
  const [selectedInstitution, setSelectedInstitution] = useState<
    institutionChore | null | undefined
  >(institution)

  useEffect(() => {
    if (!!institution) {
      setSelectedInstitution(institution)
    }
  }, [institution])

  const [selectedRoom, setSelectedRoom] = useState<
    RoomType | null | undefined
  >()

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [proposedDate, setProposedDate] = useState<Date>(
    initProposedDate ?? todayStart
  )

  const [selectedSlot, setSelectedSlot] = useState<SelectedSlotType | null>(
    null
  )

  const [appointmentAdminModalOpen, setAppointmentAdminModalOpen] =
    useState(false)

  const [selectedDoctor, setSelectedDoctor] = useState<
    | Omit<listAllDoctors_listAllDoctors, '__typename' | 'user'>
    | Omit<getTreatment_getTreatment_appointments_doctor, '__typename' | 'user'>
    | null
    | undefined
  >(null)
  const { doctors, loading: isLoadingDoctors } = useDoctorsList()

  const handleClose = () => {
    setSelectedAppointmentType(undefined)
    setSelectedInstitution(undefined)
    setSelectedRoom(undefined)
    setSelectedDate(null)
    setProposedDate(todayStart)
    setSelectedSlot(null)
    setSelectedDoctor(null)
    setScheduleDiff(null)
    onClose && onClose()
  }

  const { loading: isAppointmentTypeLoading, appointmentTypes } =
    useAppointmentTypes()

  const [
    createAppointmentForTreatment,
    {
      loading: isLoadingCreateAppointmentForTreatment,
      error: isLoadingCreateAppointmentForTreatmentErrored,
    },
  ] = useMutation<createAppointment, createAppointmentVariables>(
    CREATE_APPOINTMENT,
    {
      onCompleted: () => {
        setToast({
          text: t('appointment:creationMessage.success'),
          type: 'success',
        })
        onDone && onDone()
        handleClose()
      },
    }
  )

  const [updateAppointment, { loading: isLoadingUpdateAppointment }] =
    useMutation<updateAppointmentData, updateAppointmentVariables>(
      UPDATE_APPOINTMENT,
      {
        onCompleted: (data) => {
          if (data.updateAppointment.__typename === 'CalculatedSchedule') {
            setScheduleDiff(data.updateAppointment)
            return
          }
          setToast({
            text: t('messages:notification.changeTreatmentDate.success'),
            type: 'success',
          })
          onDone && onDone()
          handleClose()
        },
      }
    )

  const [
    updateAppointmentWithDeps,
    { loading: isLoadingUpdateAppointmentWithDeps },
  ] = useMutation<
    UpdateAppointmentWithDeps,
    UpdateAppointmentWithDepsVariables
  >(UPDATE_APPOINTMENT_WITH_DEPS, {
    refetchQueries: [
      {
        query: GET_TREATMENT,
        variables: { treatmentId: treatment.id },
      },
    ],
    onCompleted: () => {
      setToast({
        text: t('messages:notification.changeTreatmentDate.success'),
        type: 'success',
      })
      handleClose()
    },
  })

  const isBooked =
    appointment?.status === AppointmentStatus.BetmenBooked ||
    appointment?.status === AppointmentStatus.EesztBooked

  useEffect(() => {
    if (isOpen && !isAppointmentTypeLoading) {
      if (appointment) {
        // editing an appointment
        if (isBooked) {
          setSelectedSlot({
            slot: dayjs(appointment.proposedDate).format('H:mm'),
            consultationId: '',
          })
        }
        setProposedDate(dayjs(appointment.proposedDate).toDate())
        setSelectedDoctor(appointment.doctor)
        setSelectedAppointmentType(
          appointmentTypes.find((a) => a.id === appointment.appointmentInfo?.id)
        )
        setSelectedInstitution(appointment.institution)
        setSelectedRoom(appointment.room)
      } else {
        // cloning or new appointment
        setSelectedDoctor(doctor || treatment.doctor)
        const foundAppointmentType =
          appointmentType &&
          appointmentTypes.find((a) => a.id === appointmentType)

        if (foundAppointmentType) {
          setSelectedAppointmentType(foundAppointmentType)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isAppointmentTypeLoading])

  // update selectedDate if slot changed
  useEffect(() => {
    if (!selectedSlot) {
      setSelectedDate(null)
      return
    }
    const [hour, minute] = selectedSlot.slot.split(':')
    setSelectedDate(
      dayjs(proposedDate)
        .set('hour', +hour)
        .set('minute', +minute)
        .set('second', 0)
        .toDate()
    )
  }, [selectedSlot, proposedDate, setSelectedDate])

  const filteredRooms = selectedInstitution?.rooms?.filter(
    (room) => room.isActive
  )

  const missingRoom = !!selectedInstitution && !selectedRoom

  const isActiveEesztBooking =
    appointment?.status === AppointmentStatus.EesztBooked

  const hasPendingTransaction =
    appointment?.eesztBookingTransaction?.status ===
    EesztAppointmentBookingTransactionStatusEnum.Pending
  const hasSuccessTransaction =
    appointment?.eesztBookingTransaction?.status ===
    EesztAppointmentBookingTransactionStatusEnum.Pending

  const editingDisabled =
    hasPendingTransaction || hasSuccessTransaction || isActiveEesztBooking

  const savingDisabled =
    editingDisabled ||
    (!selectedAppointmentType && !appointment) ||
    !selectedDoctor ||
    missingRoom ||
    isLoadingCreateAppointmentForTreatment ||
    isLoadingUpdateAppointment

  const appointmentDate =
    appointment?.proposedDate && toDateAndSlot(appointment?.proposedDate)
  const bookedSlot = isBooked ? appointmentDate.slot : undefined

  const eesztInstitutionId = selectedInstitution?.eesztId || ''
  const eesztInstitutionUnitId = selectedRoom?.eesztUnitId || ''
  const eesztServiceId = selectedAppointmentType?.eesztServiceId || ''
  const canQueryEeszt =
    process.env.REACT_APP_EESZT_BOOKING_ENABLED === 'true' &&
    !!(eesztInstitutionId && eesztInstitutionUnitId && eesztServiceId)
  const failedTransactionMessage =
    appointment?.eesztBookingTransaction?.message || undefined
  const isSentTreatment = treatment.status === TreatmentStatus.Sent
  const isBookingToEeszt = canQueryEeszt && isEesztTokenValid && isSentTreatment
  const { data: eesztSlots, loading: loadingEesztSlots } =
    useEesztFreeSlotsByInstitution(
      {
        institutionId: eesztInstitutionId,
        institutionUnitId: eesztInstitutionUnitId,
        serviceId: eesztServiceId,
        startDate: proposedDate,
        endDate: proposedDate,
      },
      !isBookingToEeszt
    )
  const hasEesztSlots = !!eesztSlots.length

  const {
    openDeleteModal,
    confirmDeleteModal,
    isDeletingAppointment,
    isDeletingAppointmentErrored,
  } = useAppointmentDeleteModal({
    appointmentId: appointment?.id,
    onDeleteComplete: handleClose,
  })

  const onSubmit: FormEventHandler = async (event) => {
    event.stopPropagation()
    event.preventDefault()
    const date =
      selectedRoom?.id && selectedDate ? selectedDate?.toISOString() : undefined
    const consultationId = selectedSlot?.consultationId

    if (!appointment && selectedAppointmentType && selectedDoctor) {
      return createAppointmentForTreatment({
        variables: {
          appointmentInput: {
            treatmentId: treatment.id,
            appointmentInfoId: selectedAppointmentType.id,
            isBetmenBooked: !!date,
            proposedDate: date || proposedDate.toISOString(),
            institutionId: selectedInstitution?.id,
            roomId: selectedRoom?.id,
            doctorId: selectedDoctor.id,
            consultationId,
          },
        },
      })
    }
    if (appointment && selectedDoctor) {
      return updateAppointment({
        variables: {
          updateInput: {
            appointmentId: appointment.id,
            proposedDate: date || proposedDate.toISOString(),
            isBetmenBooked: !!date,
            institutionId: selectedInstitution?.id,
            roomId: selectedRoom?.id,
            doctorId: selectedDoctor.id,
            consultationId,
          },
        },
      })
    }

    return Promise.reject()
  }

  const handleSaveWithDeps = (reschedule: boolean) => {
    if (!appointment || !selectedDoctor) {
      return Promise.reject()
    }
    const date =
      selectedRoom?.id && selectedDate ? selectedDate?.toISOString() : undefined
    const consultationId = selectedSlot?.consultationId

    return updateAppointmentWithDeps({
      variables: {
        reschedule,
        updateInput: {
          appointmentId: appointment.id,
          proposedDate: date || proposedDate.toISOString(),
          isBetmenBooked: !!date,
          institutionId: selectedInstitution?.id,
          roomId: selectedRoom?.id,
          doctorId: selectedDoctor.id,
          consultationId,
        },
      },
    })
  }

  return (
    <FormProvider {...formMethods}>
      <Dialog
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        maxWidth="md"
        fullWidth
        PaperProps={
          {
            component: 'form',
          } as Partial<PaperProps<'div'>>
        }
      >
        <DialogTitle>
          {appointment
            ? t('appointment:doctor.editAppointment')
            : t('appointment:doctor.addAppointment')}
        </DialogTitle>

        <DialogContent>
          <Box display="flex" justifyContent="center" width="100%">
            <Box
              display="flex"
              flexGrow={{ xs: 0, md: 1 }}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <Box
                data-cy="AddAppointmentModal-CalendarMonth"
                display="flex"
                flexDirection="column"
                borderRadius={theme.spacing(0.5)}
                border={{ md: `1px solid ${theme.palette.grey[300]}` }}
              >
                <Box px={2}>
                  <AppointmentInfoSelector
                    name="appointmentInfo"
                    disabled={isAppointmentTypeLoading || !!appointment}
                    value={selectedAppointmentType ?? undefined}
                    onChange={(newValue) => {
                      if (!newValue || typeof newValue === 'string') {
                        return
                      }
                      setSelectedAppointmentType(newValue)
                    }}
                    onAppointmentInfoAdd={(newAppointmentInfo) => {
                      setSelectedAppointmentType(newAppointmentInfo)
                    }}
                  />
                </Box>
                <Box>
                  <CalendarMonth
                    minDate="2018-01-01"
                    selectedDate={proposedDate}
                    handleDateChange={(date) => {
                      if (date) {
                        setProposedDate(date)
                        setSelectedSlot(null)
                      }
                    }}
                  />
                </Box>
              </Box>

              <Box
                flexGrow={1}
                borderRadius={theme.spacing(0.5)}
                px={2}
                mx={2}
                border={{ md: `1px solid ${theme.palette.grey[300]}` }}
              >
                <BoxWithLabel label={t('treatment:doctor.choosePlace')}>
                  <InstitutionSelector
                    name="institution"
                    multiple={false}
                    onSelect={() => {
                      setSelectedRoom(null)
                      setSelectedSlot(null)
                    }}
                    dataCy="AddAppointmentModal-TextField-selectedInstitution"
                  />
                  <Box mt={1}>
                    <Autocomplete
                      options={filteredRooms || []}
                      getOptionLabel={(room) => room.name[selectedLanguage]}
                      isOptionEqualToValue={(room) =>
                        room.id === selectedRoom?.id
                      }
                      disabled={editingDisabled || !selectedInstitution}
                      value={selectedRoom || null}
                      onChange={(_, newValue) => {
                        setSelectedRoom(newValue)
                        setSelectedSlot(null)
                      }}
                      fullWidth
                      data-cy="AddAppointmentModal-TextField-selectedRoom"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t('appointment:doctor.chooseRoomLater')}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </BoxWithLabel>

                <BoxWithLabel label={t('appointment:therapist')}>
                  <Autocomplete
                    options={doctors || []}
                    getOptionLabel={(doctor) =>
                      t('common:formattedNameFull', doctor)
                    }
                    isOptionEqualToValue={(doctor) =>
                      doctor.id === selectedDoctor?.id
                    }
                    disabled={editingDisabled || isLoadingDoctors}
                    value={selectedDoctor || null}
                    onChange={(_, newValue) => {
                      setSelectedDoctor(newValue)
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('appointment:therapist')}
                        variant="outlined"
                      />
                    )}
                  />
                </BoxWithLabel>
                <AddAppointmentModalInfo
                  isActiveEesztBooking={isActiveEesztBooking}
                  editingDisabled={editingDisabled}
                  isBookingToEeszt={isBookingToEeszt}
                  loadingEesztSlots={loadingEesztSlots}
                  hasEesztSlots={hasEesztSlots}
                  canQueryEeszt={canQueryEeszt}
                  isEesztTokenValid={isEesztTokenValid}
                  isSentTreatment={isSentTreatment}
                  failedTransactionMessage={failedTransactionMessage}
                />
              </Box>

              <Box mt={{ xs: 1, md: 0 }} display="flex" justifyContent="center">
                <CalendarDay
                  selectedSlot={selectedSlot}
                  setSelectedSlot={setSelectedSlot}
                  bookedSlot={bookedSlot}
                  eesztSlots={eesztSlots}
                  isBookingToEeszt={isBookingToEeszt}
                  disabled={
                    editingDisabled ||
                    (!selectedAppointmentType && !appointment) ||
                    !selectedRoom ||
                    loadingEesztSlots
                  }
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t('common:cancel')}
          </Button>
          {isActiveEesztBooking && (
            <SaveButton
              text={t('common:delete')}
              isSaving={isDeletingAppointment}
              onClick={openDeleteModal}
              completedText={
                isDeletingAppointmentErrored ? t('common:error') : undefined
              }
              completedIcon={
                isDeletingAppointmentErrored ? <ErrorIcon /> : undefined
              }
            />
          )}
          {!isActiveEesztBooking && (
            <SaveButton
              text={appointment ? t('common:save') : t('common:insert')}
              isSaving={
                isLoadingCreateAppointmentForTreatment ||
                isLoadingUpdateAppointment
              }
              onClick={onSubmit}
              disabled={savingDisabled}
              data-cy="AddAppointmentModal-Button-save"
              completedText={
                isLoadingCreateAppointmentForTreatmentErrored
                  ? t('common:error')
                  : undefined
              }
              completedIcon={
                isLoadingCreateAppointmentForTreatmentErrored ? (
                  <ErrorIcon />
                ) : undefined
              }
            />
          )}
        </DialogActions>
      </Dialog>

      <AppointmentAdminModal
        isOpen={appointmentAdminModalOpen}
        setIsOpen={setAppointmentAdminModalOpen}
        onComplete={(newAppointmentType) => {
          setSelectedAppointmentType(newAppointmentType)
        }}
      />

      {isActiveEesztBooking ? confirmDeleteModal : undefined}

      {appointment && scheduleDiff && (
        <ShowDiffModal
          appointmentId={appointment.id}
          treatment={treatment}
          isOpen={!!scheduleDiff}
          isLoading={isLoadingUpdateAppointmentWithDeps}
          patientName={patientName}
          data={scheduleDiff}
          onClose={() => setScheduleDiff(null)}
          isProposed={!selectedDate}
          wasProposed={!isBooked}
          onSave={handleSaveWithDeps}
        />
      )}
    </FormProvider>
  )
}

export { AddAppointmentModal }

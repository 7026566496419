import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import SuccessIcon from '../../../../../assets/icons/success_icon.png'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { CloseIcon } from '../../../../../common/icons/CloseIcon'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'

export const FillSurveySuccess: React.FC = () => {
  const { watch } = useFormContext()
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const history = useHistory()

  const goBackToSurveys = () => history.push('/patient/home/surveys')

  return (
    <Container>
      <Card>
        <CardHeader
          title={watch('title')}
          sx={{ borderBottom: `solid 1px ${colorPalette.grey[200]}` }}
          action={
            <IconButton size="large" onClick={goBackToSurveys}>
              <CloseIcon />
            </IconButton>
          }
        ></CardHeader>
        <CardContent>
          <Typography>{t('patients:surveys.thankYou')}</Typography>
          <Stack alignItems="center" m={3} gap={2}>
            <img src={SuccessIcon} alt="successIcon" />
            <Button onClick={goBackToSurveys}>
              {t('patients:surveys.backToHome')}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  )
}

import React from 'react'
import { Box, useTheme } from '@mui/material'
import { getDoctorProfilesForPatients_getDoctorProfilesForPatients } from '../../../models/graphql'
import { DoctorInfo } from '../DoctorInfo/DoctorInfo'

type Props = {
  doctorProfile: getDoctorProfilesForPatients_getDoctorProfilesForPatients
}

const DoctorProfile: React.FC<Props> = ({ doctorProfile }) => {
  const theme = useTheme()

  return (
    <Box
      mt={2}
      key={doctorProfile.id}
      border={1}
      borderRadius={1}
      borderColor={theme.palette.grey[200]}
      boxShadow={2}
      maxWidth={{ xs: 'auto', md: 400 }}
      flexGrow={1}
      sx={{ background: theme.palette.common.white }}
    >
      <Box id={doctorProfile.id} p={2}>
        <DoctorInfo doctor={doctorProfile} hideTitle showInstitutions />
      </Box>
    </Box>
  )
}

export { DoctorProfile }

import omit from 'lodash/omit'
import {
  CreateSurveyQuestionInput,
  getSurveyQuestions_getSurveyQuestions_surveyQuestions_mcSurveyQuestionChoices as Choice,
  getSurveyQuestions_getSurveyQuestions_surveyQuestions_sclSurveyQuestionAspects as Aspect,
  Me_me,
  QuestionDataInput,
  ScaleDataInput,
  ScaleOptionInput,
  SurveyQuestionEnum,
} from '../../../../models/graphql'
import { Section } from '../components/EditSurvey/editSurvey.types'
import {
  AspectInForm,
  ChoiceInForm,
  Question,
} from '../components/Questions/questions.types'

export const QUESTION_LIST_PAGE_SIZE = 20
export const DEFAULT_SCALE_OPTIONS_MAX = 6
export const DEFAULT_SCALE_OPTIONS_MIN = 1

export const MULTIPLECHOICE_ASPECT_LENGTH_LIMIT = 100
export const SCALE_CHOICE_LENGTH_LIMIT = 100
export const SCALE_OPTION_LENGTH_LIMIT = 40

export const getDefaultScaleData = (
  min = DEFAULT_SCALE_OPTIONS_MIN,
  max = DEFAULT_SCALE_OPTIONS_MAX
): ScaleDataInput => ({
  options: Array(max - min + 1)
    .fill(null)
    .map((_, i) => ({ value: i + min, label: '' })),
})

export const getDefaultScaleMinMax = (
  options: ScaleOptionInput[],
  defaultMin = DEFAULT_SCALE_OPTIONS_MIN,
  defaultMax = DEFAULT_SCALE_OPTIONS_MAX
): { scaleMin: number; scaleMax: number } => {
  const orderedOptions = options
    .slice()
    .sort((optionA, optionB) => optionA.value - optionB.value)
  const scaleMin = orderedOptions[0]?.value ?? defaultMin
  const scaleMax =
    orderedOptions[orderedOptions.length - 1]?.value ?? defaultMax

  return { scaleMin, scaleMax }
}

export const getDefaultValues = (
  arrayToMap?: Choice[] | Aspect[],
  options = { shouldAddDoubleEmptyField: false }
): ChoiceInForm[] | AspectInForm[] | { name: string }[] =>
  arrayToMap?.length
    ? arrayToMap?.map((element: Choice | Aspect) => {
        const withoutId = omit(element, 'id')
        return { ...withoutId, idInDb: element.id }
      })
    : options.shouldAddDoubleEmptyField
    ? [{ name: '' }, { name: '' }]
    : [{ name: '' }]

export const getValuesToSave = (
  arrayToMap?: ChoiceInForm[] | AspectInForm[]
): { name: string; id: string | null }[] | undefined =>
  arrayToMap
    ?.filter((c) => c.name.length)
    .map((c) => {
      return { name: c.name, id: c.idInDb }
    })

export const getQuestionData = (
  question: CreateSurveyQuestionInput
): QuestionDataInput => {
  if (question.data) {
    switch (question.type) {
      case SurveyQuestionEnum.MultipleChoice:
        return { multipleChoice: question.data.multipleChoice }
      case SurveyQuestionEnum.Scale:
        return {
          scaleData: {
            // TODO - handle this __typename omit differently
            ...omit(question.data.scaleData, '__typename'),
            options: question.data.scaleData
              ? question.data.scaleData.options.map((option) =>
                  omit(option, '__typename')
                )
              : [],
          },
        }
      case SurveyQuestionEnum.FileUpload:
        return {
          fileUploadData: omit(question.data.fileUploadData, '__typename'),
        }
    }
  }
  return {}
}

export const getExistingQuestionsWithoutUsedQuestions = (
  surveyQuestions: Question[],
  sections: Section[]
): Question[] => {
  return surveyQuestions.filter((question) => {
    let isQuestionInSurvey = false
    sections.forEach((section: Section) => {
      if (!!section.questions.find((q) => q.id === question.id)) {
        isQuestionInSurvey = true
      }
    })
    return !isQuestionInSurvey
  })
}

export const canEditQuestion = (
  surveyQuestion: Question,
  me?: Me_me
): boolean => !surveyQuestion.isGlobal || !!me?.isAdmin

export const cutInputAtLimit = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  limit: number
): void => {
  onChange({
    ...event,
    target: {
      ...event.target,
      value: event.target.value.substring(0, limit),
    },
  })
}

import _ from 'lodash'
import { useMemo } from 'react'
import {
  DataTableColDef,
  DataTableRowData,
  DataTableScalarValue,
  DataTableValue,
} from './interfaces'

type Return = {
  value: DataTableValue
  formattedValue: DataTableScalarValue
}

export const useCellData = <T extends DataTableRowData>(
  column: DataTableColDef<T>,
  row: T
): Return => {
  const value = useMemo<DataTableValue>(() => {
    const defaultGetter = (): DataTableValue => _.get(row, column.field)
    const get = column.valueGetter ?? defaultGetter
    return get({
      field: column.field,
      row,
    })
  }, [column.field, column.valueGetter, row])

  const formattedValue = useMemo<DataTableScalarValue>(() => {
    const { valueFormatter = (v) => v } = column
    const newValue = valueFormatter(value)
    if (typeof newValue !== 'object') {
      return newValue
    }
    return ''
  }, [column, value])

  return {
    value,
    formattedValue,
  }
}

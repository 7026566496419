import React, { FC } from 'react'
import { SurveyQuestionEnum } from '../../../../../models/graphql'
import { Section } from '../EditSurvey/editSurvey.types'
import { EditFileUploadQuestion } from './EditFileUploadQuestion'
import { EditFreeTextQuestion } from './EditFreeTextQuestion'
import { EditMultipleChoiceQuestion } from './EditMultipleChoiceQuestion'
import { EditScaleQuestion } from './EditScaleQuestion'

interface Props {
  questionType: SurveyQuestionEnum
  sections?: Section[]
  sectionOrder?: number
  isReadonly?: boolean
}

export const EditQuestionByType: FC<Props> = ({
  questionType,
  sections,
  sectionOrder,
  isReadonly = false,
}) => {
  switch (questionType) {
    case SurveyQuestionEnum.SimpleText:
      return <EditFreeTextQuestion disabled={isReadonly} />
    case SurveyQuestionEnum.MultipleChoice:
      return (
        <EditMultipleChoiceQuestion
          sections={sections}
          sectionOrder={sectionOrder}
          disabled={isReadonly}
        />
      )
    case SurveyQuestionEnum.Scale:
      return <EditScaleQuestion disabled={isReadonly} />
    case SurveyQuestionEnum.FileUpload:
      return <EditFileUploadQuestion disabled={isReadonly} />
  }
}

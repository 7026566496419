import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import { IconButton, Paper, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { SurveyQuestionEnum } from '../../../../../models/graphql'
import { QuestionInSection } from '../EditSurvey/editSurvey.types'
import { QuestionIcon } from '../Questions/QuestionIcon'
import { Question } from '../Questions/questions.types'
import { ViewQuestionByType } from '../ViewQuestion/ViewQuestionByType'

interface Props {
  question: QuestionInSection
}

export const ViewQuestionCard: React.FC<Props> = ({ question }) => {
  const [isQuestionOpen, setQuestionOpen] = useState<boolean>(false)
  const colorPalette = useColorPalette()

  return (
    <Paper
      elevation={0}
      sx={{ my: 1, mx: 3, p: 2, border: `solid 1px ${colorPalette.grey[200]}` }}
    >
      <Stack direction="row" alignItems="center">
        <Stack width="45px" alignItems="center" justifyContent="center">
          <QuestionIcon questionType={question.type} fontSize="large" />
        </Stack>

        <Stack flex={1}>
          <Typography variant="h6">{question.title}</Typography>
        </Stack>

        {(question.type !== SurveyQuestionEnum.SimpleText ||
          !!question.description) && (
          <IconButton onClick={() => setQuestionOpen((isOpen) => !isOpen)}>
            {isQuestionOpen ? (
              <ArrowDropUpOutlinedIcon fontSize="large" />
            ) : (
              <ArrowDropDownOutlinedIcon fontSize="large" />
            )}
          </IconButton>
        )}
      </Stack>

      {isQuestionOpen && (
        <ViewQuestionByType
          question={
            { ...question, id: question.id || '', isGlobal: false } as Question
          }
        />
      )}
    </Paper>
  )
}

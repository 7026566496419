import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  deleteSurveySchema,
  deleteSurveySchemaVariables,
} from '../../../../models/graphql'
import { DELETE_SURVEY_SCHEMA } from '../operations/surveyOperations'

type Options = MutationHookOptions<
  deleteSurveySchema,
  deleteSurveySchemaVariables
>

type Return = MutationTuple<deleteSurveySchema, deleteSurveySchemaVariables>

export const useSurveySchemaDelete = (options: Options = {}): Return => {
  return useMutation<deleteSurveySchema, deleteSurveySchemaVariables>(
    DELETE_SURVEY_SCHEMA,
    options
  )
}

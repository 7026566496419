import React, { useState } from 'react'
import { Button, Paper, Dialog, Stack, Typography } from '@mui/material'
import { useTranslation } from '../../common/hooks/helper/useTranslation'
import { CookiePermissions } from './CookiePermissions'

export const CookieComponent: React.FC = () => {
  const [open, setOpen] = useState(true)
  const [customizationOpen, setCustomizationOpen] = useState(false)
  const { t } = useTranslation()

  const cookieNamespace = 'cookie:'

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason !== 'backdropClick') {
      // TODO actual functionality for accept and reject buttons
      localStorage.setItem('cookie', 'accepted')
      setOpen(false)
    }
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
        <Paper elevation={3}>
          <Stack p={3} data-cy="Cookie-Component">
            <Stack pr={3} mb={6} spacing={2}>
              <Typography variant="h6">
                {t(`${cookieNamespace}title`)}
              </Typography>
              <Typography variant="subtitle1">
                {t(`${cookieNamespace}description`)}
              </Typography>
            </Stack>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="flex-end"
              spacing={2}
            >
              <Button
                data-cy="Cookie-Component-Reject-Button"
                variant="outlined"
                onClick={handleClose}
              >
                {t(`${cookieNamespace}button.reject`)}
              </Button>
              <Button
                data-cy="Cookie-Component-Customize-Button"
                variant="outlined"
                onClick={() => {
                  setOpen(false)
                  setCustomizationOpen(true)
                }}
              >
                {t(`${cookieNamespace}button.customize`)}
              </Button>
              <Button
                data-cy="Cookie-Component-Accept-Button"
                color="primary"
                onClick={handleClose}
              >
                {t(`${cookieNamespace}button.accept`)}
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Dialog>

      {customizationOpen && (
        <CookiePermissions
          open={customizationOpen}
          setOpen={setCustomizationOpen}
        />
      )}
    </>
  )
}

import { v4 as uuid } from 'uuid'
import {
  AppointmentDependencyTypes,
  getTreatmentSchema_getTreatmentSchema_schedules_dependencies as BackendDependency,
} from '../../../../../models/graphql'
import { LocalSchedule } from '../treatmentSchemaSchedule.types'
import {
  DependencyMap,
  FormValues,
  LocalDependency,
  MinMax,
  TimeUnit,
} from './dependencyEditor.types'

export const getScheduleById = (
  schedules: LocalSchedule[],
  id?: string
): LocalSchedule | null => {
  if (!id) {
    return null
  }
  return schedules.find((s) => s.id === id) || null
}

export const getTimeUnitFromDistanceString = (distance?: string): TimeUnit => {
  if (distance) {
    const unitCandidate = distance.charAt(distance.length - 1)
    switch (unitCandidate) {
      case TimeUnit.H:
        return TimeUnit.H
      case TimeUnit.W:
        return TimeUnit.W
      default:
        return TimeUnit.D
    }
  }
  return TimeUnit.D
}

export const getValueFromDistanceString = (
  distance?: string
): number | undefined => {
  if (!distance) {
    return
  }
  const onlyDigits = distance.replace(/\D/, '')
  return parseInt(onlyDigits)
}

export const getTimeConstraintString = (
  formValues: FormValues
): string | undefined => {
  if (formValues.timeConstraintNeeded) {
    return `${formValues.distanceValue}${formValues.unit}`
  }
}

export const mapFormValueToLocalDependency = (
  formValues: FormValues,
  id: string
): LocalDependency => ({
  id,
  startId: formValues.start?.id,
  endId: formValues.end?.id,
  distance: getTimeConstraintString(formValues),
  constraint: formValues.minMaxConstraint,
})

export const areDependenciesEqual = (
  dependency1: LocalDependency,
  dependency2: LocalDependency
): boolean => {
  return (
    dependency1.id === dependency2.id &&
    dependency1.startId === dependency2.startId &&
    dependency1.endId === dependency2.endId &&
    dependency1.constraint === dependency2.constraint &&
    dependency1.distance === dependency2.distance
  )
}

export const getGroupedDependenciesToSync = (
  existingDependencies: LocalDependency[],
  localDependencies: LocalDependency[]
): {
  dependenciesToAdd: LocalDependency[]
  dependenciesToDelete: LocalDependency[]
  dependenciesToUpdate: LocalDependency[]
} => {
  // Dependencies that are part of the existing ones but not contained in the local ones
  const dependenciesToDelete = existingDependencies.filter(
    (existing) => !localDependencies.some((local) => existing.id === local.id)
  )

  //Dependencies that are part of the local ones but not part of the existing ones
  const dependenciesToAdd: LocalDependency[] = []
  // Dependencies that are present in both arrays but their inner state is changed
  const dependenciesToUpdate: LocalDependency[] = []

  localDependencies.forEach((local) => {
    const oldDependencyToUpdate = existingDependencies.find(
      (existing) => existing.id === local.id
    )
    if (oldDependencyToUpdate) {
      if (!areDependenciesEqual(oldDependencyToUpdate, local)) {
        dependenciesToUpdate.push(local)
      }
    } else {
      dependenciesToAdd.push(local)
    }
  })

  return {
    dependenciesToAdd,
    dependenciesToDelete,
    dependenciesToUpdate,
  }
}

const mapBEAppointmentDependencyTypeToConstraint = (
  type: AppointmentDependencyTypes
): MinMax => {
  switch (type) {
    case AppointmentDependencyTypes.maxDistance:
      return MinMax.MAX
    case AppointmentDependencyTypes.minDistance:
      return MinMax.MIN
    case AppointmentDependencyTypes.notSpecified:
      return MinMax.NotSpecified
  }
}

export const mapBEDependencyToLocalDependency = (
  beDependency: BackendDependency,
  endId: string
): LocalDependency => ({
  id: beDependency.id || uuid(),
  startId: beDependency.fromId,
  endId,
  distance:
    beDependency.type === AppointmentDependencyTypes.notSpecified
      ? undefined
      : beDependency.distance,
  constraint: mapBEAppointmentDependencyTypeToConstraint(beDependency.type),
})

export const getDependencyTypeFromLocalDependency = (
  dependency: LocalDependency
): AppointmentDependencyTypes => {
  let dependencyType = AppointmentDependencyTypes.notSpecified
  if (dependency.distance) {
    dependencyType =
      dependency.constraint === MinMax.MAX
        ? AppointmentDependencyTypes.maxDistance
        : AppointmentDependencyTypes.minDistance
  }
  return dependencyType
}

export const mapScheduleListToLocalDependencyList = (
  schedules: LocalSchedule[]
): LocalDependency[] =>
  schedules.reduce((acc, curr) => {
    const { dependencies, id: scheduleId } = curr
    return [
      ...acc,
      ...dependencies.map((d) =>
        mapBEDependencyToLocalDependency(d, scheduleId)
      ),
    ]
  }, [] as LocalDependency[])

const getAllParents = (
  dMap: DependencyMap,
  dependency: LocalDependency
): LocalDependency[] => {
  if (!dependency.startId || !dependency.endId || !dMap[dependency.startId]) {
    return [] as LocalDependency[]
  }
  return dMap[dependency.startId].reduce((acc, curr) => {
    if (curr.startId && curr.endId) {
      return [...acc, curr, ...getAllParents(dMap, curr)]
    }
    return acc
  }, [] as LocalDependency[])
}

export const getDependencyMap = (
  dependencyList: LocalDependency[]
): DependencyMap =>
  dependencyList.reduce((acc, curr) => {
    if (curr.startId && curr.endId) {
      if (acc[curr.endId]) {
        acc[curr.endId].push(curr)
      } else {
        acc[curr.endId] = [curr]
      }
    }
    return acc
  }, {} as DependencyMap)

export const hasCircularDependency = (
  dependencyToValidate: LocalDependency,
  dependencyMap: DependencyMap
): boolean => {
  const allParents = getAllParents(dependencyMap, dependencyToValidate)
  return allParents.some(
    (p) =>
      p.startId === dependencyToValidate.endId &&
      dependencyToValidate.id !== p.id
  )
}

import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useUserType } from '../common/hooks/useUserType'
import { UserType } from '../models/graphql'

import { AdminPage } from '../views/admin/AdminPage'
import { AppointmentAdmin } from '../views/admin/pages/AppointmentAdmin'
import { InstitutionAdmin } from '../views/admin/pages/InstitutionAdmin'
import { LocationAdminPage } from '../views/admin/pages/LocationAdmin'
import { EditSurveyState } from '../views/doctor/SurveyStore/components/EditSurveyState'
import { GetSurvey } from '../views/doctor/SurveyStore/components/GetSurvey'
import { GetSurveyResult } from '../views/doctor/SurveyStore/components/SurveyResult/GetSurveyResult'
import { SurveyStore } from '../views/doctor/SurveyStore/SurveyStore'
import { TreatmentSchemaView } from '../views/doctor/TreatmentSchema/components/TreatmentSchemaView'
import { TreatmentSchemas } from '../views/doctor/TreatmentSchema/TreatmentSchemas'
import { RedirectHandler } from './RedirectHandler'

const AdminRoutes: React.FC = () => {
  const userType = useUserType()

  if (userType !== UserType.Admin) {
    return <Redirect to={`/${userType?.toLowerCase()}/home`} />
  }

  return (
    <Switch>
      <Route path="/admin/home">
        <AdminPage />
      </Route>
      <Route path="/admin/statistics">
        <AdminPage />
      </Route>
      <Route
        path={['/admin/institutions/:institutionId', '/admin/institutions']}
      >
        <InstitutionAdmin />
      </Route>
      <Route path={['/admin/locations/:institutionId', '/admin/locations']}>
        <LocationAdminPage />
      </Route>
      <Route
        path={['/admin/appointments/:appointmentId', '/admin/appointments']}
      >
        <AppointmentAdmin />
      </Route>

      <Route path="/admin/survey/:surveySchemaId">
        <GetSurvey />
      </Route>
      <Route path="/admin/add-survey">
        <EditSurveyState />
      </Route>
      <Route path="/admin/survey-result/:surveySchemaId">
        <GetSurveyResult />
      </Route>
      <Route path="/admin/surveys">
        <SurveyStore />
      </Route>

      <Route path="/admin/treatment-options/:treatmentOptionId">
        <TreatmentSchemas />
      </Route>
      <Route path="/admin/treatment-option/:treatmentOptionId">
        <TreatmentSchemaView />
      </Route>
      <Route path="/admin/treatment-options">
        <TreatmentSchemas />
      </Route>

      <Route>
        <RedirectHandler />
      </Route>
    </Switch>
  )
}

export { AdminRoutes }

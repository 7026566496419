import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const LocationIcon: React.FC<SvgIconProps> = (props) => {
  const viewBox = `0 0 ${props.width ?? 16} ${props.height ?? 16}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <g transform="translate(-40.000000, -580.000000)" fillRule="nonzero">
        <g transform="translate(20.000000, 424.000000)">
          <g transform="translate(20.000000, 156.000000)">
            <path d="M8.5,16 C7.67266667,16 7,15.3273333 7,14.5 L7,12.1546667 C7,11.8786667 7.224,11.6546667 7.5,11.6546667 C7.776,11.6546667 8,11.8786667 8,12.1546667 L8,14.5 C8,14.776 8.224,15 8.5,15 L12.5,15 C12.776,15 13,14.776 13,14.5 L13,12.1546667 C13,11.8786667 13.224,11.6546667 13.5,11.6546667 C13.776,11.6546667 14,11.8786667 14,12.1546667 L14,14.5 C14,15.3273333 13.3273333,16 12.5,16 L8.5,16 Z" />
            <path d="M15.5,12.1546667 C15.376,12.1546667 15.2573333,12.1093333 15.166,12.026 L10.8326667,8.128 C10.74,8.04533333 10.6213333,8 10.498,8 C10.3746667,8 10.256,8.046 10.1633333,8.12866667 L5.83,12.0266667 C5.738,12.1093333 5.61933333,12.1546667 5.496,12.1546667 C5.35466667,12.1546667 5.21933333,12.094 5.124,11.9893333 C5.03466667,11.89 4.98933333,11.762 4.996,11.628 C5.00333333,11.4946667 5.062,11.372 5.16133333,11.2826667 L9.49466667,7.38466667 C9.77133333,7.136 10.1273333,6.99933333 10.498,6.99933333 C10.868,6.99933333 11.2246667,7.136 11.5006667,7.38466667 L14,9.63266667 L14,8 C14,7.724 14.224,7.5 14.5,7.5 C14.776,7.5 15,7.724 15,8 L15,10.5326667 L15.834,11.2833333 C15.9333333,11.3726667 15.992,11.4953333 15.9993333,11.6286667 C16.006,11.762 15.9613333,11.89 15.872,11.9893333 C15.7773333,12.094 15.642,12.1546667 15.5,12.1546667 Z" />
            <path d="M5.5,8.5 C3.846,8.5 2.5,7.154 2.5,5.5 C2.5,3.846 3.846,2.5 5.5,2.5 C7.154,2.5 8.5,3.846 8.5,5.5 C8.5,7.154 7.154,8.5 5.5,8.5 Z M5.5,3.5 C4.39733333,3.5 3.5,4.39733333 3.5,5.5 C3.5,6.60266667 4.39733333,7.5 5.5,7.5 C6.60266667,7.5 7.5,6.60266667 7.5,5.5 C7.5,4.39733333 6.60266667,3.5 5.5,3.5 Z" />
            <path d="M5.5,16 C5.16333333,16 4.852,15.8326667 4.66666667,15.552 C4.19933333,14.848 3.03466667,12.9766667 1.97466667,10.9373333 C0.664,8.41733333 0,6.588 0,5.5 C0,2.46733333 2.46733333,0 5.5,0 C8.53266667,0 11,2.46733333 11,5.5 C11,5.776 10.776,6 10.5,6 C10.2246667,6 10,5.776 10,5.5 C10,3.01866667 7.98133333,1 5.5,1 C3.01866667,1 1,3.01866667 1,5.5 C1,7.42066667 3.93066667,12.6346667 5.5,15 C5.776,15 6,15.224 6,15.5 C6,15.776 5.776,16 5.5,16 Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export { LocationIcon }

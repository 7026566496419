import { Card, Typography } from '@mui/material'
import React from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_SimpleTextQuestionResult as SimpleTextQuestionResults } from '../../../../../models/graphql'

interface Props {
  questionResult: SimpleTextQuestionResults
}

export const SimpleTextQuestionResult: React.FC<Props> = ({
  questionResult,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const { answers, totalAnswers } = questionResult

  return (
    <>
      <Typography variant="subtitle2" sx={{ my: 1 }}>
        {t('survey:result.numberOfAnswers', {
          numberOfAnswers: totalAnswers,
        })}
      </Typography>

      {answers.map((answer: string, i: number) => (
        <Card
          key={i}
          sx={{ backgroundColor: colorPalette.grey[200], p: 2, my: 1 }}
          elevation={0}
        >
          <Typography>{answer}</Typography>
        </Card>
      ))}
    </>
  )
}

import React, { useState } from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import { EmptyState } from '../../../../common/components/EmptyState'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { Loading } from '../../../../common/components/Loading'
import { PatientHeadlineInfo } from './PatientHeadlineInfo'
import { PatientTabNavigation } from './PatientTabNavigation'
import { PatientPanel } from './PatientPanel'
import { useDoctorPatientProfileData } from '../hooks/useDoctorPatientProfileData'

interface RouteParams {
  patientId: string
}

const DoctorPatient: React.FC = () => {
  const { patientId } = useParams<RouteParams>()
  const { t } = useTranslation()
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const { patientProfile, loading } = useDoctorPatientProfileData({
    skip: false,
  })

  return (
    <Box flexGrow={1} height="100%" display="flex" flexDirection="column">
      {loading && <Loading />}
      {!loading && !patientProfile && (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <EmptyState
            title={t('patients:patientNotFoundTitle')}
            description={t('patients:patientNotFoundMessage')}
          />
        </Box>
      )}
      {!loading && patientProfile && (
        <>
          <PatientHeadlineInfo patient={patientProfile} />
          <PatientTabNavigation
            patientId={patientId}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
          />
          <PatientPanel
            activeTabIndex={activeTabIndex}
            patientProfile={patientProfile}
          />
        </>
      )}
    </Box>
  )
}

export { DoctorPatient }

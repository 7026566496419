import { Autocomplete, TextField } from '@mui/material'
import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BoxWithLabel } from '../BoxWithLabel'
import { useDoctorsList } from '../../hooks/useDoctorsList'
import { UseInstitutionListOptions } from '../../hooks/useInstitutionList'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { DoctorProfileFilter } from '../../../models/graphql'

type Props = {
  name: string
  excludeOwnProfile?: boolean
  label?: string
  required?: boolean
  hasError?: boolean
  multiple?: boolean
  queryOptions?: UseInstitutionListOptions
  disabled?: boolean
  doctorListFilterData?: DoctorProfileFilter | null | undefined
}

export const DoctorSelector: FC<Props> = (props) => {
  const {
    name,
    label,
    required,
    hasError,
    multiple = true,
    disabled = false,
    excludeOwnProfile = false,
    doctorListFilterData,
  } = props
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { loading, doctors, doctorsWithoutMe } = useDoctorsList({
    variables: { doctorListFilterData },
  })

  return (
    <BoxWithLabel label={label}>
      <Controller
        control={control}
        name={name}
        defaultValue={!multiple ? null : []}
        rules={{
          required,
          validate: (value) => (required ? value?.length > 0 : true),
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Autocomplete
            disabled={disabled}
            multiple={multiple}
            options={excludeOwnProfile ? doctorsWithoutMe : doctors}
            disableClearable
            filterSelectedOptions
            loading={loading}
            getOptionLabel={(doctorProfile) =>
              t('common:formattedNameFull', {
                title: doctorProfile.title,
                firstName: doctorProfile.firstName,
                lastName: doctorProfile.lastName,
              })
            }
            isOptionEqualToValue={(option, value) =>
              option.user?.id === value.user?.id
            }
            value={value || (!multiple ? null : [])}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(event: any, value, reason) => {
              const removedByBackspaceKey =
                event.key === 'Backspace' && reason === 'removeOption'
              if (!removedByBackspaceKey) {
                onChange(multiple ? [...value] : value)
              }
            }}
            onBlur={onBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('common:search')}
                error={hasError}
                helperText={
                  hasError ? t('messages:warnings.required') : undefined
                }
                variant="outlined"
              />
            )}
          />
        )}
      />
    </BoxWithLabel>
  )
}

import {
  getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas as TreatmentSchema,
  Language,
} from '../../../../models/graphql'

export const renameDuplicatedTreatmentTitles = (
  list: TreatmentSchema[],
  selectedLanguage: Language
): TreatmentSchema[] =>
  list.map((listItem, i, arr) => {
    const prevItemsWithSameTitleCount = arr.slice(0, i).filter((item) => {
      return item.title[selectedLanguage] === listItem.title[selectedLanguage]
    }).length
    return prevItemsWithSameTitleCount > 0
      ? {
          ...listItem,
          title: {
            ...listItem.title,
            [selectedLanguage]:
              `${listItem.title[selectedLanguage]} (${
                prevItemsWithSameTitleCount + 1
              })` ?? '',
          },
        }
      : listItem
  })

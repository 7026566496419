import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  deleteDoctorsOwnPatient,
  deleteDoctorsOwnPatientVariables,
} from '../../../../models/graphql'

import { DELETE_DOCTORS_OWN_PATIENT } from '../operations/doctorPatientOperations'

type Options = MutationHookOptions<
  deleteDoctorsOwnPatient,
  deleteDoctorsOwnPatientVariables
>

type Return = MutationTuple<
  deleteDoctorsOwnPatient,
  deleteDoctorsOwnPatientVariables
>

export const useDeleteDoctorOwnPatient = (options: Options = {}): Return => {
  return useMutation<deleteDoctorsOwnPatient, deleteDoctorsOwnPatientVariables>(
    DELETE_DOCTORS_OWN_PATIENT,
    options
  )
}

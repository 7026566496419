import { Delete as DeleteIcon, FileCopy } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  Fade,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { useColorPalette } from '../../../../common/hooks/helper/useColor'
import { DependencyIcon } from '../../../../common/icons/DependencyIcon'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { getSurveySchemas_getSurveySchemas_surveySchemas as SurveySchema } from '../../../../models/graphql'
import { DependencyEditorModal } from './DependencyEditorModal'
import { TreatmentSchemaAddSurvey } from './TreatementSchemaAddSurvey'
import { TreatmentSchemaAddSchedule } from './TreatmentSchemaAddSchedule'
import { LocalSchedule, LocalSurvey } from './treatmentSchemaSchedule.types'
import { TreatmentSchemaScheduleDraggableListItem } from './TreatmentSchemaScheduleDraggableList/TreatmentSchemaScheduleDraggableListItem'
import { TreatmentSchemaSurveyDraggableListItem } from './TreatmentSchemaScheduleDraggableList/TreatmentSchemaSurveyDraggableListItem'

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    position: 'relative',
    marginTop: theme.spacing(2),
  },

  selectHeaderOpen: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    position: 'absolute',
    bottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    alignItems: 'center',
  },

  checkboxAll: {
    position: 'absolute',
    zIndex: 2,
    left: theme.spacing(5.2),
    bottom: theme.spacing(1.1),
    '& .MuiSvgIcon-root': { fontSize: 20 },
  },
}))

interface Props {
  schedulesAndSurveys?: Array<LocalSchedule | LocalSurvey>
  addNewSchedule: (appointmentInfoId: string) => void
  addNewSurvey: (surveySchema: SurveySchema) => void
  onRemove: () => void
  handleOnDragEnd: (result: DropResult) => void
  handleDayChanged: (id: string, newDay?: number) => void
  handleSurveyDayChanged: (id: string, newDay?: number) => void
  treatmentSchemaId: string
  refetchTreatmentSchema: () => void
  isScheduleListEditable: boolean
  selectedItems: string[]
  setSelectedItems: (items: string[]) => void
  selectedSurveys: string[]
  setSelectedSurveys: (items: string[]) => void
  handleCloneMultiple: () => void
}

export const TreatmentSchemaScheduleDraggableList: FC<Props> = ({
  schedulesAndSurveys = [],
  addNewSchedule,
  addNewSurvey,
  onRemove,
  handleDayChanged,
  handleSurveyDayChanged,
  handleOnDragEnd,
  treatmentSchemaId,
  refetchTreatmentSchema,
  isScheduleListEditable,
  selectedItems,
  setSelectedItems,
  selectedSurveys,
  setSelectedSurveys,
  handleCloneMultiple,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const [isDependencyModalOpen, setIsDependencyModalOpen] = useState(false)
  const classes = useStyles()

  const OpenDependencyEditorCallback = useCallback(() => {
    setIsDependencyModalOpen(true)
  }, [])

  const handleSelect = useCallback(
    (id: string) => () => {
      if (selectedItems.includes(id)) {
        setSelectedItems(selectedItems.filter((item) => item !== id))
      } else {
        setSelectedItems([...selectedItems, id])
      }
    },
    [selectedItems, setSelectedItems]
  )

  const handleSelectSurvey = useCallback(
    (id: string) => () => {
      if (selectedSurveys.includes(id)) {
        setSelectedSurveys(selectedSurveys.filter((item) => item !== id))
      } else {
        setSelectedSurveys([...selectedSurveys, id])
      }
    },
    [selectedSurveys, setSelectedSurveys]
  )

  const handleSelectAll = useCallback(() => {
    if (selectedItems.length === 0) {
      setSelectedItems(
        schedulesAndSurveys
          .filter((schedule) => schedule.__typename === 'PlainTreatmentElement')
          .map((schedule) => schedule.id)
      )
      setSelectedSurveys(
        schedulesAndSurveys
          .filter(
            (schedule) => schedule.__typename === 'TreatmentSchemaToSurvey'
          )
          .map((schedule) => schedule.id)
      )
    } else {
      setSelectedItems([])
      setSelectedSurveys([])
    }
  }, [
    schedulesAndSurveys,
    selectedItems.length,
    setSelectedItems,
    setSelectedSurveys,
  ])

  // const handleInsertProtocol = useCallback(() => {
  //   // TODO: insert protocol
  //   console.log('protocol insert')
  // }, [])

  const isChecked = useMemo(
    () =>
      selectedItems.length + selectedSurveys.length !== 0 &&
      isScheduleListEditable,
    [selectedItems.length, selectedSurveys.length, isScheduleListEditable]
  )

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={-1}
      >
        {isScheduleListEditable && (
          <>
            <TreatmentSchemaAddSchedule
              treatmentSchemaId={treatmentSchemaId}
              addNewSchedule={addNewSchedule}
            />
            {/* TODO */}
            {/* <Box>
              <Button
                variant="text"
                startIcon={<Add />}
                sx={{ marginRight: 3 }}
                onClick={handleInsertProtocol}
              >
                {t('protocol:insertProtocol')}
              </Button> */}
            <TreatmentSchemaAddSurvey addSurveySchema={addNewSurvey} />
            {/* </Box> */}
          </>
        )}
      </Box>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {!!schedulesAndSurveys.length ? (
          <Box
            display="flex"
            flexDirection="row"
            width="full"
            className={classes.actionsContainer}
            sx={{ borderBottom: `solid 1px ${colorPalette.grey[300]}`, my: 1 }}
          >
            {isScheduleListEditable && (
              <Checkbox
                checked={
                  selectedItems.length + selectedSurveys.length ===
                  schedulesAndSurveys.length
                }
                indeterminate={
                  isChecked &&
                  selectedItems.length + selectedSurveys.length !==
                    schedulesAndSurveys.length
                }
                onChange={handleSelectAll}
                className={classes.checkboxAll}
              />
            )}
            <Box display="flex" alignItems="center" width="100%">
              <Fade in={!isChecked}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  mb={1}
                >
                  <Box>
                    <Typography
                      variant="smallItalic"
                      ml={isScheduleListEditable ? 11 : 6}
                      mr={2}
                    >
                      {t('protocol:schedule.scheduleDay')}
                    </Typography>
                    <Typography variant="smallItalic">
                      {t('protocol:schedule.scheduleName')}
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="text"
                      startIcon={<DependencyIcon />}
                      onClick={OpenDependencyEditorCallback}
                    >
                      {isScheduleListEditable
                        ? t('protocol:dependency.addDependencies')
                        : t('protocol:dependency.viewDependencies')}
                    </Button>
                    {isDependencyModalOpen && (
                      <DependencyEditorModal
                        isOpen={isDependencyModalOpen}
                        setIsOpen={setIsDependencyModalOpen}
                        schedules={
                          schedulesAndSurveys.filter(
                            (schedule) =>
                              schedule.__typename === 'PlainTreatmentElement'
                          ) as LocalSchedule[]
                        }
                        treatmentSchemaId={treatmentSchemaId}
                        refetchTreatmentSchema={refetchTreatmentSchema}
                        isEditable={isScheduleListEditable}
                      />
                    )}
                  </Box>
                </Box>
              </Fade>
              <Fade in={isChecked}>
                <Box className={classes.selectHeaderOpen}>
                  <Tooltip
                    title={t('doctor:tooltips.remove') as string}
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <IconButton onClick={onRemove} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t('doctor:tooltips.cloneSchedule') as string}
                    enterDelay={500}
                    leaveDelay={200}
                  >
                    <IconButton onClick={handleCloneMultiple} size="large">
                      <FileCopy />
                    </IconButton>
                  </Tooltip>
                  {isChecked && (
                    <Typography variant="smallItalic" ml={1}>
                      {t('appointment:doctor.selectedAppointments', {
                        count: selectedItems.length + selectedSurveys.length,
                      })}
                    </Typography>
                  )}
                </Box>
              </Fade>
            </Box>
          </Box>
        ) : (
          <Stack mt={3} alignItems="center">
            <Typography variant="italic">
              {t('protocol:schedules.empty')}
            </Typography>
          </Stack>
        )}
        <Droppable droppableId="treatment-schema-list-id">
          {(provided) => (
            <Box
              flex={1}
              sx={{
                maxHeight: '50vh',
                overflowY: 'scroll',
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {schedulesAndSurveys.map((item, index) => {
                return item.__typename === 'PlainTreatmentElement' ? (
                  <TreatmentSchemaScheduleDraggableListItem
                    key={item.id}
                    id={item.id}
                    index={index}
                    schedule={item}
                    isEditable={isScheduleListEditable}
                    treatmentSchemaId={treatmentSchemaId}
                    onDayChanged={handleDayChanged}
                    addNewSchedule={addNewSchedule}
                    selected={selectedItems.includes(item.id)}
                    handleSelect={handleSelect(item.id)}
                  />
                ) : (
                  <TreatmentSchemaSurveyDraggableListItem
                    key={item.id}
                    id={item.id}
                    index={index}
                    survey={item}
                    isEditable={isScheduleListEditable}
                    onDayChanged={handleSurveyDayChanged}
                    selected={selectedSurveys.includes(item.id)}
                    handleSelect={handleSelectSurvey(item.id)}
                  />
                )
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  )
}

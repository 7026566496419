import { useMutation } from '@apollo/client'
import {
  deleteAppointmentDependency,
  deleteAppointmentDependencyVariables,
  getTreatment,
  getTreatment_getTreatment_appointments,
} from '../../../../models/graphql'
import { DELETE_APPOINTMENT_DEPENDENCY } from '../../../../operations/appointmentOperations'
import { GET_TREATMENT } from '../../../../operations/treatmentOperations'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'

type AppointmentDependencyDeleteData = {
  treatmentId: string
  appointment: getTreatment_getTreatment_appointments
  dependentAppointment: getTreatment_getTreatment_appointments
}

type AppointmentDependencyDeleteOptions = Pick<
  MutationHookOptions<
    deleteAppointmentDependency,
    deleteAppointmentDependencyVariables
  >,
  'onCompleted'
>

type Return = MutationTuple<
  deleteAppointmentDependency,
  deleteAppointmentDependencyVariables
>

export const useAppointmentDependencyDelete = (
  data: AppointmentDependencyDeleteData,
  options: AppointmentDependencyDeleteOptions
): Return => {
  const { treatmentId, appointment } = data
  const { onCompleted } = options
  return useMutation<
    deleteAppointmentDependency,
    deleteAppointmentDependencyVariables
  >(DELETE_APPOINTMENT_DEPENDENCY, {
    onCompleted,
    update: (cache, { data }) => {
      const deletedDependency = data?.deleteAppointmentDependency
      const cachedQuery = cache.readQuery<getTreatment>({
        query: GET_TREATMENT,
        variables: {
          treatmentId: treatmentId,
        },
      })
      if (!cachedQuery || !deletedDependency) {
        return
      }

      const newAppointments =
        cachedQuery.getTreatment.appointments?.map((item) => {
          if (item.id === appointment.id) {
            return {
              ...item,
              dependent: item.dependent.filter(
                (subItem) => subItem.id !== deletedDependency.id
              ),
            }
          }

          return item
        }) ?? []

      cache.writeQuery<getTreatment>({
        query: GET_TREATMENT,
        data: {
          getTreatment: {
            ...cachedQuery.getTreatment,
            appointments: newAppointments,
          },
        },
      })
    },
  })
}

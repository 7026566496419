import React, { useState } from 'react'
import {
  Box,
  Switch,
  IconButton,
  Grid,
  Divider,
  Typography,
  GridSize,
  Hidden,
} from '@mui/material'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { Send as SendIcon } from '@mui/icons-material'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'

import {
  NotificationTypes,
  triggerDoctorNextWeekTasks,
  triggerPatientDailyAppointmentReminder,
  triggerPatientNextWeekAppointments,
  triggerExpiringTreatmentRemainder,
} from '../../../models/graphql'
import {
  TRIGGER_DOCTOR_NEXT_WEEK_TASKS,
  TRIGGER_PATIENT_DAILY_APPOINTMENT_REMINDER,
  TRIGGER_PATIENT_NEXT_WEEK_APPOINTMENTS,
} from '../../../operations/notificationOperations'
import { TRIGGER_EXPIRING_TREATMENT_REMAINDER } from '../../../operations/treatmentOperations'

import {
  GET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS,
} from '../../../operations/notificationSettingsOperations'
import {
  getNotificationSettings,
  getNotificationSettings_getNotificationSettings,
  updateNotificationSettings,
  updateNotificationSettingsVariables,
} from '../../../models/graphql'
import { usePermissions } from '../../hooks/usePermissions'
import { useStoreActions } from '../../../store/store.hooks'
import { Loading } from '../Loading'

const NotificationSettings: React.FC = () => {
  const isSmsEnabled = process.env.REACT_APP_ENABLE_SMS === 'true'
  const { t } = useTranslation()
  const { isAdmin } = usePermissions()
  const setToast = useStoreActions((actions) => actions.toast.setToast)
  const [isSaving, setIsSaving] = useState<string>('')

  const [queryTriggerDoctorNextWeekTasks] =
    useLazyQuery<triggerDoctorNextWeekTasks>(TRIGGER_DOCTOR_NEXT_WEEK_TASKS, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const status = data.triggerDoctorNextWeekTasks ? 'success' : 'warning'
        setToast({
          text: t(`messages:notification.DoctorNextWeekTasks.${status}`),
          type: status,
        })
      },
    })

  const [queryTriggerPatientDailyAppointmentReminder] =
    useLazyQuery<triggerPatientDailyAppointmentReminder>(
      TRIGGER_PATIENT_DAILY_APPOINTMENT_REMINDER,
      {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          const status = data.triggerPatientDailyAppointmentReminder
            ? 'success'
            : 'warning'
          setToast({
            text: t(`messages:notification.DailyAppointmentReminder.${status}`),
            type: status,
          })
        },
      }
    )

  const [queryTriggerExpiringTreatmentRemainder] =
    useLazyQuery<triggerExpiringTreatmentRemainder>(
      TRIGGER_EXPIRING_TREATMENT_REMAINDER,
      {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          const status = data.triggerExpiringTreatmentRemainder
            ? 'success'
            : 'warning'
          setToast({
            text: t(
              `messages:notification.ExpiringTreatmentReminder.${status}`
            ),
            type: status,
          })
        },
      }
    )

  const [queryTriggerPatientNextWeekAppointments] =
    useLazyQuery<triggerPatientNextWeekAppointments>(
      TRIGGER_PATIENT_NEXT_WEEK_APPOINTMENTS,
      {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          const status = data.triggerPatientNextWeekAppointments
            ? 'success'
            : 'warning'
          setToast({
            text: t(
              `messages:notification.PatientNextWeekAppointments.${status}`
            ),
            type: status,
          })
        },
      }
    )

  const { data, loading } = useQuery<getNotificationSettings>(
    GET_NOTIFICATION_SETTINGS
  )

  const notificationSettings = data?.getNotificationSettings || []

  const [updateNotificationSettingsMutation] = useMutation<
    updateNotificationSettings,
    updateNotificationSettingsVariables
  >(UPDATE_NOTIFICATION_SETTINGS, {
    onCompleted: () => {
      setIsSaving('')
      setToast({
        text: t(`common:saved`),
        type: 'success',
      })
    },
  })

  const saveNotificationSettings = (
    newSettings: getNotificationSettings_getNotificationSettings
  ) => {
    updateNotificationSettingsMutation({
      variables: {
        newSettings: {
          type: newSettings.type,
          allowEmailSending: newSettings.allowEmailSending,
          allowSmsSending: isSmsEnabled ? newSettings.allowSmsSending : false,
        },
      },
    })
  }

  const switchSMSStatus = (
    settings: getNotificationSettings_getNotificationSettings
  ) => {
    setIsSaving(`sms-${settings.type}`)
    settings.allowSmsSending = !settings.allowSmsSending
    saveNotificationSettings(settings)
  }

  const switchEmailStatus = (
    settings: getNotificationSettings_getNotificationSettings
  ) => {
    setIsSaving(`email-${settings.type}`)
    settings.allowEmailSending = !settings.allowEmailSending
    saveNotificationSettings(settings)
  }

  const testActionMap: { [key in NotificationTypes]?: () => void } = {
    [NotificationTypes.DoctorNextWeekTasks]: queryTriggerDoctorNextWeekTasks,
    [NotificationTypes.PatientNextWeekAppointments]:
      queryTriggerPatientNextWeekAppointments,
    [NotificationTypes.DailyAppointmentReminder]:
      queryTriggerPatientDailyAppointmentReminder,
    [NotificationTypes.ExpiringTreatmentReminder]:
      queryTriggerExpiringTreatmentRemainder,
  }

  const smsDisabledFor = [NotificationTypes.ExpiringTreatmentReminder]

  const emailDisabledFor = [NotificationTypes.ExpiringTreatmentReminder]

  const titleColumn = {
    xs: 12 as GridSize,
    sm: (isAdmin ? 6 : 8) as GridSize,
  }
  const settingColumn = {
    xs: (isAdmin ? 4 : 6) as GridSize,
    sm: 2 as GridSize,
  }
  const adminColumn = {
    xs: (isAdmin ? 4 : 6) as GridSize,
    sm: 2 as GridSize,
  }

  if (loading) {
    return (
      <Box display="flex" height="100%" alignItems="center">
        <Loading />
      </Box>
    )
  }

  return (
    <>
      <Hidden smDown>
        <Box mt={2} my={1}>
          <Grid container alignItems="flex-end">
            <Grid item {...titleColumn}>
              <Typography variant="body2">
                {t('notification:typeLabel')}
              </Typography>
            </Grid>
            {isSmsEnabled && (
              <Grid item {...settingColumn}>
                <Box textAlign="center">
                  <Typography variant="body2">
                    {t('notification:allowSmsSendingLabel')}
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid item {...settingColumn}>
              <Box textAlign="center">
                <Typography variant="body2">
                  {t('notification:allowEmailSendingLabel')}
                </Typography>
              </Box>
            </Grid>
            {isAdmin && (
              <Grid item {...adminColumn}>
                <Box textAlign="center">
                  <Typography variant="body2">
                    {t('notification:sendManuallyLabel')}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Hidden>
      {notificationSettings
        .map((ns) => ({ ...ns }))
        .sort((a, b) =>
          t(`notification:types.${a.type}`).localeCompare(
            t(`notification:types.${b.type}`)
          )
        )
        .map((setting) => {
          const isUpdatingSms = isSaving === `sms-${setting.type}`
          const isUpdatingEmail = isSaving === `email-${setting.type}`

          const isDisabledSmsType = smsDisabledFor.includes(setting.type)
          const isDisabledEmailType = emailDisabledFor.includes(setting.type)
          const testAction = testActionMap[setting.type]

          return (
            <Box key={setting.id}>
              <Box my={{ xs: 2, sm: 0.5 }}>
                <Divider />
              </Box>
              <Grid container alignItems="center">
                <Grid item {...titleColumn}>
                  {t(`notification:types.${setting.type}`)}
                </Grid>

                {isSmsEnabled && (
                  <Grid item {...settingColumn}>
                    <Box textAlign="center">
                      <Hidden smUp>
                        <Typography component="div" variant="caption">
                          {t('notification:allowSmsSendingLabel')}
                        </Typography>
                      </Hidden>
                      <Switch
                        checked={setting.allowSmsSending}
                        disabled={isDisabledSmsType || isUpdatingSms}
                        onChange={() => {
                          switchSMSStatus(setting)
                        }}
                        color="primary"
                      />
                    </Box>
                  </Grid>
                )}

                <Grid item {...settingColumn}>
                  <Box textAlign="center">
                    <Hidden smUp>
                      <Typography component="div" variant="caption">
                        {t('notification:allowEmailSendingLabel')}
                      </Typography>
                    </Hidden>
                    <Switch
                      checked={setting.allowEmailSending}
                      disabled={isDisabledEmailType || isUpdatingEmail}
                      onChange={() => {
                        switchEmailStatus(setting)
                      }}
                      color="primary"
                    />
                  </Box>
                </Grid>

                {isAdmin && (
                  <Grid item {...adminColumn}>
                    {testAction && (
                      <Box textAlign="center">
                        <Hidden smUp>
                          <Typography component="div" variant="caption">
                            {t('notification:sendManuallyLabel')}
                          </Typography>
                        </Hidden>
                        <Box alignItems="center">
                          <IconButton
                            color="primary"
                            onClick={testAction}
                            size="large"
                          >
                            <SendIcon fontSize="large" />
                          </IconButton>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                )}
              </Grid>
            </Box>
          )
        })}
    </>
  )
}

export { NotificationSettings }

import { QueryHookOptions, useQuery, NetworkStatus } from '@apollo/client'
import {
  getSurveyQuestions,
  getSurveyQuestionsVariables,
  getSurveyQuestions_getSurveyQuestions_surveyQuestions,
} from '../../../../models/graphql'
import { Question } from '../components/Questions/questions.types'
import { GET_SURVEY_QUESTIONS } from '../operations/questionOperations'

type Options = QueryHookOptions<getSurveyQuestions, getSurveyQuestionsVariables>

export type ReturnData = {
  loading: boolean
  surveyQuestions: Question[]
  totalQuestions: number
  hasMoreQuestions: boolean
  fetchingMore: boolean
  refetch: () => void
  fetchMoreQuestions: (options: Options) => void
}

export const useSurveyQuestions = (options: Options = {}): ReturnData => {
  const {
    data: questionData,
    loading,
    refetch,
    fetchMore,
    networkStatus,
  } = useQuery<getSurveyQuestions, getSurveyQuestionsVariables>(
    GET_SURVEY_QUESTIONS,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    }
  )

  const totalQuestions = questionData?.getSurveyQuestions?.total || 0
  const surveyQuestions: Question[] =
    questionData?.getSurveyQuestions?.surveyQuestions.map(
      (question: getSurveyQuestions_getSurveyQuestions_surveyQuestions) => {
        return {
          ...question,
          choices: question.mcSurveyQuestionChoices,
          aspects: question.sclSurveyQuestionAspects,
        }
      }
    ) || []
  const hasMoreQuestions = surveyQuestions.length < totalQuestions

  return {
    loading,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    fetchMoreQuestions: fetchMore,
    refetch,
    surveyQuestions,
    totalQuestions,
    hasMoreQuestions,
  }
}

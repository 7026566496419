import React from 'react'
import { aggregatedSurveyResults_getAggregatedSurveyResults_surveyQuestionResultList_MultipleChoiceQuestionResult as MultipleChoiceQuestionResults } from '../../../../../models/graphql'
import { QuestionResultChart } from './QuestionResultChart'

interface Props {
  questionResult: MultipleChoiceQuestionResults
}

export const MultipleChoiceQuestionResult: React.FC<Props> = ({
  questionResult,
}) => {
  const { choiceResults, totalAnswers } = questionResult

  const data = choiceResults.map((choiceResult) => ({
    name: choiceResult.name,
    amount: choiceResult.total,
  }))

  return <QuestionResultChart data={data} numberOfAnswers={totalAnswers} />
}

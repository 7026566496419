import { MutationTuple } from '@apollo/client/react/types/types'
import {
  setCustomDaysOfSchedule,
  setCustomDaysOfScheduleVariables,
} from '../../../../models/graphql'
import { useMutation } from '@apollo/client'
import { SET_CUSTOM_DAYS_OF_SCHEMA_SCHEDULE } from '../operations/treatmentSchemaOperations'

type Return = MutationTuple<
  setCustomDaysOfSchedule,
  setCustomDaysOfScheduleVariables
>

export const useSetCustomDayOfSchemaSchedule = (): Return =>
  useMutation<setCustomDaysOfSchedule, setCustomDaysOfScheduleVariables>(
    SET_CUSTOM_DAYS_OF_SCHEMA_SCHEDULE,
    {
      refetchQueries: ['getTreatmentSchema'],
    }
  )

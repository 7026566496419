import { Button, Card, CardContent, Stack, TextField } from '@mui/material'
import get from 'lodash/get'
import React, { useContext, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BnoCodeSelector } from '../../../../../common/components/selectors/BnoCodeSelector'
import { BoxWithLabel } from '../../../../../common/components/BoxWithLabel'
import { SurveyCategoryMultiSelector } from '../../../../../common/components/selectors/SurveyCategoryMultlSelector'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { getSurveySchemaById_getSurveySchemaById as SurveySchema } from '../../../../../models/graphql'
import { useStylesForEditableTitles } from '../../utils/style.util'
import { Assignment as AssignmentIcon } from '@mui/icons-material'
import { Reference } from '../../../../../common/components/Reference/Reference'
import { getNumberOfReferences } from '../../../../../common/components/Reference/referenceUtil'
import { SurveyContext } from '../EditSurveyState'
import { ProfessionSelector } from '../../../../../common/components/selectors/ProfessionSelector'

export const EditSurveyForm: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStylesForEditableTitles()
  const { isReadonly } = useContext(SurveyContext)

  const [isReferenceOpen, setReferenceOpen] = useState<boolean>(false)

  const {
    control,
    formState: { errors },
    register,
    watch,
  } = useFormContext<SurveySchema>()

  return (
    <>
      <Card>
        <CardContent sx={{ padding: 3 }}>
          <TextField
            {...register('title', {
              required: t('messages:warnings.required') as string,
            })}
            disabled={isReadonly}
            fullWidth
            variant="outlined"
            placeholder={t('survey:title.placeholder')}
            error={!!errors.title}
            helperText={errors.title?.message}
            InputProps={{
              classes: { input: classes.input },
            }}
            sx={{ pb: 2 }}
            data-cy="EditSurveyForm-TextField-title"
          />
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'flex-end' }}
            gap={{ xs: 1, sm: 4 }}
          >
            <BnoCodeSelector
              name="bnoCodes"
              label={t('treatment:protocol.bnoCode')}
              disabled={isReadonly}
            />
            <BoxWithLabel label={t('treatment:protocol.snowmedCode')}>
              <TextField
                {...register('snomedCode')}
                variant="outlined"
                fullWidth
                placeholder={t('treatment:editor.snomed.placeholder')}
                disabled={isReadonly}
              />
            </BoxWithLabel>
            <Button
              startIcon={<AssignmentIcon fontSize="large" />}
              onClick={() => setReferenceOpen(true)}
              variant="text"
              color="inherit"
              sx={{ mb: 1 }}
              disabled={isReadonly}
            >
              {t('treatment:protocol.url')}
              {getNumberOfReferences(watch('referenceUrls'))}
            </Button>
          </Stack>
          <ProfessionSelector
            label={t('survey:surveys.professions')}
            name="professions"
            required
            hasError={!!errors.professions}
            helperText={get(errors, 'professions.message', '')}
            disabled={isReadonly}
          />
          <Controller
            control={control}
            name="categories"
            rules={{
              validate: (value) =>
                value?.length < 1
                  ? (t('messages:warnings.required') as string)
                  : true,
            }}
            render={({ field }) => (
              <SurveyCategoryMultiSelector
                label={t('survey:surveys.category')}
                field={field}
                hasError={!!errors.categories}
                helperText={get(errors, 'categories.message', '')}
                disabled={isReadonly}
              />
            )}
          />

          <BoxWithLabel label={t('survey:description.title')}>
            <Stack>
              <TextField
                {...register('description', {
                  required: t('messages:warnings.required') as string,
                })}
                fullWidth
                placeholder={t('survey:description.placeholder')}
                variant="outlined"
                multiline
                error={!!errors.description}
                helperText={errors.description?.message}
                disabled={isReadonly}
                data-cy="EditSurveyForm-TextField-description"
              />
            </Stack>
          </BoxWithLabel>
        </CardContent>
      </Card>

      {isReferenceOpen && (
        <Reference
          name="referenceUrls"
          isOpen={isReferenceOpen}
          onClose={() => setReferenceOpen(false)}
          isStringified={false}
        />
      )}
    </>
  )
}

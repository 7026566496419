import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useSelectedLanguage } from '../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
} from '@latticejs/mui-recharts'

import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek' // dependent on weekOfYear plugin

import { useQuery } from '@apollo/client'
import { GET_DOCTORS_FOR_STATISTICS } from '../../../operations/doctorProfileOperations'
import { getDoctorProfiles } from '../../../models/graphql'
import { BoxWithLabel } from '../../../common/components/BoxWithLabel'
import { Loading } from '../../../common/components/Loading'

dayjs.extend(isoWeek)

type DoctorPatientsCountType = {
  doctorName: string
  patientsCount: number
}

type AppointmentsPerWeekType = {
  week: string
  weekLabel: string
  appointmentsCount: number
}

const Statistics: React.FC = () => {
  const { t } = useTranslation()
  const selectedLanguage = useSelectedLanguage()

  const { loading, data } = useQuery<getDoctorProfiles>(
    GET_DOCTORS_FOR_STATISTICS,
    {
      variables: {
        paginationInput: {},
      },
    }
  )

  const patientsNumber = useMemo(() => {
    const patientsIds = new Set()
    data?.getDoctorProfiles.forEach((doctorProfile) =>
      doctorProfile.ownPatients.forEach((patientProfile) =>
        patientsIds.add(patientProfile.id)
      )
    )
    return patientsIds.size
  }, [data])

  const doctorPatientsCount = useMemo(() => {
    const intlCollator = new Intl.Collator(selectedLanguage)
    const doctorPatientsCount: DoctorPatientsCountType[] = []
    data?.getDoctorProfiles.forEach((doctorProfile) =>
      doctorPatientsCount.push({
        doctorName: t('common:formattedNameFull', doctorProfile),
        patientsCount: doctorProfile.ownPatients.length,
      })
    )
    return doctorPatientsCount.sort((a, b) =>
      intlCollator.compare(a.doctorName, b.doctorName)
    )
  }, [data, t, selectedLanguage])

  const appointmentsCount = useMemo(() => {
    let appointmentsCount = 0
    data?.getDoctorProfiles.forEach((doctorProfile) =>
      doctorProfile.treatments?.forEach(
        (treatment) =>
          (appointmentsCount += treatment.appointments?.length || 0)
      )
    )
    return appointmentsCount
  }, [data])

  const appointmentsPerWeek = useMemo(() => {
    const appointmentsPerWeek = new Map<string, AppointmentsPerWeekType>()

    const getWeekFromDate = (dateString: string): string =>
      dayjs(dateString).startOf('isoWeek').toISOString()

    const getWeekLabelFromDate = (dateString: string): string => {
      const date = dayjs(dateString)
      return t('statistics:formattedWeek', {
        year: date.year(),
        week: date.isoWeek(),
      })
    }

    data?.getDoctorProfiles.forEach((doctorProfile) =>
      doctorProfile.treatments?.forEach((treatment) =>
        treatment.appointments?.forEach((appointment) => {
          const week = getWeekFromDate(appointment.proposedDate)
          const weekAppointments = appointmentsPerWeek.get(week)
          weekAppointments
            ? weekAppointments.appointmentsCount++
            : appointmentsPerWeek.set(week, {
                week: week,
                weekLabel: getWeekLabelFromDate(week),
                appointmentsCount: 1,
              })
        })
      )
    )

    return Array.from(appointmentsPerWeek.values()).sort((a, b) =>
      a.week > b.week ? 1 : -1
    )
  }, [data, t])

  return (
    <Box>
      <Typography>{t('statistics:pageTitle')}</Typography>

      {loading ? (
        <Loading />
      ) : (
        <>
          <BoxWithLabel label={t('statistics:totalNumberOfPatients')}>
            <Typography>{patientsNumber}</Typography>
          </BoxWithLabel>

          <BoxWithLabel label={t('statistics:doctorPatientsCount')}>
            <Box my={1} height={200}>
              <ResponsiveContainer>
                <BarChart data={doctorPatientsCount}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="doctorName" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    type="monotone"
                    dataKey="patientsCount"
                    name={t('statistics:patientsCount')}
                    fill="#8884d8"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </BoxWithLabel>

          <BoxWithLabel label={t('statistics:totalNumberOfAppointments')}>
            <Typography>{appointmentsCount}</Typography>
          </BoxWithLabel>

          <BoxWithLabel label={t('statistics:appointmentsPerWeek')}>
            <Box my={1} height={200}>
              <ResponsiveContainer>
                <AreaChart data={appointmentsPerWeek}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="weekLabel" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Area
                    type="monotone"
                    dataKey="appointmentsCount"
                    name={t('statistics:appointmentsCount')}
                    stroke="#8884d8"
                    fill="#8884d8"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </BoxWithLabel>
        </>
      )}
    </Box>
  )
}

export { Statistics }

import React from 'react'
import dayjs from 'dayjs'
import { Box, Theme } from '@mui/material'
import {
  AppointmentStatus,
  getTreatment_getTreatment_appointments as Appointment,
  getTreatment_getTreatment_surveys as Survey,
} from '../../../../../models/graphql'

interface DayBackground {
  theme: Theme
  marginStyles: string
  actualDate: dayjs.Dayjs
  eventThisDay: (Appointment | Survey)[]
}

const DayBackground: React.FC<DayBackground> = ({
  theme,
  marginStyles,
  actualDate,
  eventThisDay,
}) => {
  const isPast = actualDate.isBefore(dayjs().startOf('day'))

  if (!eventThisDay.length) {
    return <></>
  }

  const getBgColor = (appointmentOrSurvey: Appointment | Survey) => {
    if (appointmentOrSurvey.__typename === 'Appointment') {
      return isPast
        ? theme.palette.grey[100]
        : appointmentOrSurvey.status === AppointmentStatus.BetmenBooked ||
          appointmentOrSurvey.status === AppointmentStatus.EesztBooked
        ? theme.palette.success.light
        : theme.palette.primary.light
    }
    return isPast ? theme.palette.grey[100] : theme.palette.success.dark
  }

  return (
    <Box
      position="absolute"
      display="flex"
      flexDirection="column"
      top={1}
      bottom={1}
      left={1}
      right={1}
      zIndex={0}
    >
      {eventThisDay.map((el, index) => (
        <Box
          key={index}
          className={marginStyles}
          flexGrow={1}
          data-cy={`DayBackground-Box-${el.id}`}
          bgcolor={getBgColor(el)}
        />
      ))}
    </Box>
  )
}

export { DayBackground }

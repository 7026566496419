import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import dayjs from 'dayjs'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Arrow,
  ArrowProps,
  calculateArrowLevels,
  getArrowConfigByDistance,
  getBrokenArrowConfig,
  getMaximumArrowLevel,
  getWidthForLevel,
} from './components/Arrow'
import { usePermissions } from '../../../common/hooks/usePermissions'
import { useSelectedLanguage } from '../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useUserType } from '../../../common/hooks/useUserType'
import {
  getTreatment_getTreatment_appointments as Appointment,
  getTreatment_getTreatment_surveys as Survey,
  getTreatment_getTreatment_appointments_doctor as AppointmentDoctor,
  TreatmentStatus,
  getTreatment_getTreatment,
} from '../../../models/graphql'
import { appointmentDistanceFormatter } from '../../../utils/appointmentDistanceFromatter'
import { AddAppointmentModal } from './components/Appointment/AddAppointmentModal'
import { AddProtocolModal } from './components/AddProtocolModal'
import { AppointmentDependencyModal } from './components/Appointment/AppointmentDependencyModal'
import { AppointmentEmptyState } from './components/Appointment/AppointmentEmptyState'
import { AppointmentModal } from './components/Appointment/AppointmentModal'
import {
  HandleSurveyModal,
  AddSurveyModalParams,
} from './components/Survey/AddSurveyModal'
import { NoFilterResultContentComponent } from './components/NoFilterResultContent'
import {
  TreatmentGroupedAppointmentsComponent,
  TreatmentGroupedAppointmentsComponentViewModel,
} from './components/TreatmentGroupedAppointments'
import { TreatmentLog } from './components/TreatmentLog'
import { TreatmentPanelHeader } from './components/TreatmentPanelHeader'
import { TreatmentPanelSubHeader } from './components/TreatmentPanelSubHeader'
import { TreatmentSurveyPreview } from './components/Survey/TreatmentSurveyPreview'
import {
  getFilteredAppointments,
  getGroupedAppointmentsAndSurveys,
} from './utils/appointment.util'

export interface AddAppointmentModalParams {
  isOpen: boolean
  appointmentType?: string
  doctor?: AppointmentDoctor
  appointment?: Appointment
  proposedDate?: Date
}

type RouteParams = {
  patientId: string
  treatmentId: string
  appointmentId?: string
  action?: string
}

const getParentsIds = (
  appointments: Appointment[],
  appointmentId: string,
  target: string[] = [],
  recursive = true
): string[] => {
  appointments.forEach((appointment) =>
    appointment.dependent.forEach((dep) => {
      if (dep.toId === appointmentId && dep.fromId) {
        target.push(dep.fromId)
        if (recursive) {
          getParentsIds(appointments, dep.fromId, target)
        }
      }
    })
  )

  return target
}

interface Props {
  treatment: getTreatment_getTreatment
  refetchTreatment: () => void
}

export const TreatmentPanel: React.FC<Props> = ({
  treatment,
  refetchTreatment,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const history = useHistory()
  const { patientId, treatmentId, appointmentId, action } =
    useParams<RouteParams>()
  const userType = useUserType()
  const selectedLanguage = useSelectedLanguage()
  const {
    isOwnTreatment: _isOwnTreatment,
    isAssistantOfTreatment: _isAssistantOfTreatment,
  } = usePermissions()

  const scrollRef = useRef<HTMLElement>(null)
  const firstRef = useRef<HTMLElement>(null)

  const [isAddProtocolModal, toggleAddProtocolModal] = useState(false)
  const [addSurveyModal, toggleAddSurveyModal] = useState<AddSurveyModalParams>(
    { isOpen: false }
  )
  const [showArrows, toggleShowArrows] = useState<boolean>(false)
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
  const [addAppointmentModalOpen, toggleAddAppointmentModal] =
    useState<AddAppointmentModalParams>({ isOpen: false })
  const [showPast, setShowPast] = useState(false)
  const [filterValue, setFilterValue] = useState<string>('')
  const [linkingDisabledIds, setLinkingDisabledIds] = useState<string[]>([])
  const [linkingAppointments, setLinkingAppointments] = useState<Appointment[]>(
    []
  )
  const [hoveredAppointmentId, setHoveredAppointmentId] = useState<
    string | null
  >(null)

  const [arrowProps, setArrowProps] = useState<ArrowProps[]>([])

  const isEditRoute: boolean = action === 'edit'
  const linking = linkingAppointments[0]
  const showLinkingModal = linkingAppointments.length === 2
  const todayStart = dayjs().startOf('day').toISOString()
  const isDeleted = treatment?.status === TreatmentStatus.Cancelled
  const isDraft = treatment?.status === TreatmentStatus.Draft
  const isOwnTreatment = _isOwnTreatment(treatment?.doctor.id)
  const isAssistantOfTreatment = _isAssistantOfTreatment(treatment?.doctor.id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const appointments = treatment?.appointments || []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const surveys = treatment?.surveys || []
  const treatmentLog = treatment?.treatmentLogs || []

  const handleLink = useCallback(
    (appointment: Appointment) => {
      const isExists = linkingAppointments.some(
        (item) => item.id === appointment.id
      )
      if (isExists) {
        setLinkingDisabledIds([])
        setLinkingAppointments([])
      } else {
        const allParents = getParentsIds(
          treatment?.appointments || [],
          appointment.id
        )
        const directParents = getParentsIds(
          treatment?.appointments || [],
          appointment.id,
          [],
          false
        )
        const disabledIds = allParents.filter(
          (id) => !directParents.includes(id)
        )
        setLinkingDisabledIds(disabledIds)
        setLinkingAppointments((prevState) => [...prevState, appointment])
      }
    },
    [linkingAppointments, treatment?.appointments]
  )

  useEffect(() => {
    // if treatment is deleted, show past appointments by default
    if (isDeleted) {
      setShowPast(true)
    }

    const futureAppointments = appointments.filter(
      (a) => todayStart < a.proposedDate
    )
    const hasAppointments = appointments.length > 0
    const hasFutureAppointments = futureAppointments.length > 0
    if (hasAppointments && !hasFutureAppointments) {
      setShowPast(true)
    }
  }, [appointments, setShowPast, todayStart, isDeleted])

  // this hook keeps the scroll position if "shop past appointment clicked"
  // TODO: fix this (or trash it)
  useLayoutEffect(() => {
    if (showPast && scrollRef.current && !isDeleted) {
      scrollRef.current.scrollTop +=
        (firstRef.current?.offsetTop || 0) - scrollRef.current?.offsetTop - 200
    }
  }, [showPast, isDeleted])

  const currentAppointment = appointments.find(
    (appointment) => appointment.id === appointmentId
  )

  const {
    groupedAppointmentsAndSurveys,
    visibleAppointments,
    visibleSurveys,
    futureAppointments,
    filteredAppointment,
    isAllOrderedAppointmentsPast,
    futureSurveys,
  } = useMemo(() => {
    const dateSortedOrderedSurveys = [...surveys].sort((a, b) =>
      a.fillableFrom > b.fillableFrom ? 1 : -1
    )

    const filteredAppointment = getFilteredAppointments(
      appointments,
      treatment,
      selectedLanguage,
      filterValue
    )

    const futureAppointments = filteredAppointment.filter(
      (a) => todayStart <= a.proposedDate
    )

    const futureSurveys = dateSortedOrderedSurveys.filter(
      (s) => todayStart <= s.fillableFrom
    )

    const visibleAppointments = showPast
      ? filteredAppointment
      : futureAppointments

    const visibleSurveys = showPast ? dateSortedOrderedSurveys : futureSurveys

    const groupedAppointmentsAndSurveys = getGroupedAppointmentsAndSurveys(
      filteredAppointment,
      dateSortedOrderedSurveys
    )

    const isAllOrderedAppointmentsPast =
      filteredAppointment.length &&
      !filteredAppointment.find(
        (appointment) =>
          dayjs().startOf('day') <= dayjs(appointment.proposedDate)
      )

    return {
      groupedAppointmentsAndSurveys,
      visibleAppointments,
      visibleSurveys,
      futureAppointments,
      filteredAppointment,
      isAllOrderedAppointmentsPast,
      futureSurveys,
    }
  }, [
    appointments,
    filterValue,
    selectedLanguage,
    showPast,
    todayStart,
    treatment,
    surveys,
  ])

  const showAppointmentEmptyState = useMemo(
    () => !isDeleted && !appointments.length && isOwnTreatment,
    [isDeleted, appointments, isOwnTreatment]
  )

  const isNoFilteredResultContent = useMemo(
    () => !!appointments.length && filteredAppointment.length < 1,
    [appointments, filteredAppointment]
  )

  const nextAppointment = futureAppointments[0]
  const firstFutureAppointment = futureAppointments[1]
  const nextSurvey = futureSurveys[0]
  const firstFutureSurvey = futureSurveys[1]

  const clearFilterValue = () => {
    const searchInput = document.getElementById(
      'treatmentSearchInput'
    ) as HTMLInputElement
    if (searchInput) {
      searchInput.value = ''
    }
    setFilterValue('')
  }

  const createArrowProps = (): ArrowProps[] => {
    const newArrowProps = (
      filterValue.length === 0 ? visibleAppointments : []
    ).reduce<ArrowProps[]>((acc, appointment) => {
      const arrows = appointment.dependent
        .filter((dependent) =>
          visibleAppointments.find(
            (appointment) => dependent.toId === appointment.id
          )
        )
        .map((dependent) => ({
          start: `arrow-row-${appointment.id}`,
          end: `arrow-row-${dependent.toId}`,
          labelText: appointmentDistanceFormatter(
            dependent.distance,
            t,
            dependent.type
          ),
          level: 1,
          highlighted:
            hoveredAppointmentId === dependent.toId ||
            hoveredAppointmentId === appointment.id,
          ...(dependent.isBroken
            ? getBrokenArrowConfig()
            : getArrowConfigByDistance(dependent.distance)),
          maxDistance: undefined,
        }))
      return [...acc, ...arrows]
    }, [])
    calculateArrowLevels(
      newArrowProps,
      visibleAppointments.map((appointment) => `arrow-row-${appointment.id}`)
    )

    return newArrowProps
  }
  const scaleArrows = useMediaQuery(theme.breakpoints.up('lg')) ? 1.0 : 0.5
  const widthOfArrows = getWidthForLevel(
    getMaximumArrowLevel(arrowProps),
    scaleArrows
  )

  useEffect(() => {
    setArrowProps(createArrowProps())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredAppointmentId, filterValue.length])

  useEffect(() => {
    setArrowProps([])
    setTimeout(() => setArrowProps(createArrowProps()), 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatment?.appointments])

  const openAppointment = useCallback(
    (appointmentId: string) => {
      history.push(
        `/${userType}/patients/${patientId}/${treatmentId}/${appointmentId}`
      )
    },
    [history, userType, patientId, treatmentId]
  )

  const [survey, setSurvey] = useState<Survey | null>(null)

  const openSurvey = useCallback((survey: Survey) => setSurvey(survey), [])

  const editAppointment = useCallback((appointment: Appointment) => {
    clearFilterValue()
    toggleAddAppointmentModal({
      isOpen: true,
      appointment,
    })
  }, [])

  const editSurvey = useCallback((survey: Survey) => {
    clearFilterValue()
    toggleAddSurveyModal({
      isOpen: true,
      survey,
    })
  }, [])

  const cloneAppointment = useCallback((appointment: Appointment) => {
    clearFilterValue()
    toggleAddAppointmentModal({
      isOpen: true,
      appointmentType: appointment.appointmentInfo?.id,
      doctor: appointment.doctor,
      proposedDate: new Date(appointment.proposedDate),
    })
  }, [])

  const treatmentGroupedAppointmentsViewModel: TreatmentGroupedAppointmentsComponentViewModel =
    useMemo(
      () => ({
        filterValue,
        filteredAppointment,
        groupedAppointmentsAndSurveys,
        showPast,
        widthOfArrows,
        visibleAppointments,
        visibleSurveys,
        linkingDisabledIds,
        isAllOrderedAppointmentsPast,
        onClickAppointment: openAppointment,
        onClickSurvey: openSurvey,
        editSurvey,
        treatmentItemViewModel: {
          firstRef,
          isAssistantOfTreatment,
          isDraft,
          isOwnTreatment,
          linking,
          firstFutureAppointment,
          nextAppointment,
          firstFutureSurvey,
          nextSurvey,
          cloneAppointment,
          editAppointment,
          onHover: setHoveredAppointmentId,
          onLink: handleLink,
          setShowPast,
        },
      }),
      [
        filterValue,
        filteredAppointment,
        groupedAppointmentsAndSurveys,
        showPast,
        widthOfArrows,
        visibleAppointments,
        visibleSurveys,
        linkingDisabledIds,
        isAllOrderedAppointmentsPast,
        isAssistantOfTreatment,
        isDraft,
        isOwnTreatment,
        linking,
        firstFutureAppointment,
        nextAppointment,
        firstFutureSurvey,
        nextSurvey,
        cloneAppointment,
        editAppointment,
        openAppointment,
        openSurvey,
        handleLink,
        editSurvey,
      ]
    )

  const renderAppointmentsWithArrows = () => (
    <Box display="flex" flexDirection="column" p={2} position="relative">
      <TreatmentGroupedAppointmentsComponent
        vm={treatmentGroupedAppointmentsViewModel}
      />
      {showArrows &&
        arrowProps.map((arrowProp, i) => (
          <Arrow key={i} {...{ ...arrowProp, scale: scaleArrows }} />
        ))}
    </Box>
  )

  return (
    <Box {...{ ref: scrollRef }} height="100%" overflow="auto" display="flex">
      <Box width="100%">
        {treatment && (
          <Box height="100%" width="100%" display="flex" flexDirection="column">
            <TreatmentPanelHeader
              treatment={treatment}
              activeTabIndex={activeTabIndex}
              setActiveTabIndex={setActiveTabIndex}
            />

            <Divider />

            {activeTabIndex === 1 && (
              <TreatmentLog treatmentLogs={treatmentLog} />
            )}

            {activeTabIndex === 0 && (
              <>
                {!!appointments.length && (
                  <TreatmentPanelSubHeader
                    treatment={treatment}
                    isOwnTreatment={isOwnTreatment}
                    showArrows={showArrows}
                    toggleArrowsVisibility={() => toggleShowArrows(!showArrows)}
                    toggleAddSurveyModal={toggleAddSurveyModal}
                    setFilterValue={setFilterValue}
                    toggleAddAppointmentModal={toggleAddAppointmentModal}
                    toggleAddProtocolModal={toggleAddProtocolModal}
                  />
                )}

                {isDeleted && (
                  <Box p={2}>
                    <Typography variant="h6" color="error">
                      {t('treatment:doctor.removedTreatment')}
                    </Typography>
                    <Typography variant="body2">
                      {t('treatment:doctor.reasonOfClosing')}:
                    </Typography>
                    <Typography variant="subtitle1">
                      {treatment?.comment}
                    </Typography>
                  </Box>
                )}

                <>
                  {showAppointmentEmptyState && (
                    <AppointmentEmptyState
                      toggleAddAppointment={() =>
                        toggleAddAppointmentModal({ isOpen: true })
                      }
                      toggleAddProtocol={() => toggleAddProtocolModal(true)}
                      toggleAddSurvey={() =>
                        toggleAddSurveyModal({ isOpen: true })
                      }
                    />
                  )}

                  {isNoFilteredResultContent ? (
                    <NoFilterResultContentComponent filterValue={filterValue} />
                  ) : (
                    renderAppointmentsWithArrows()
                  )}
                </>
              </>
            )}
          </Box>
        )}
      </Box>
      {treatment && (
        <>
          {!!appointmentId && (
            <AppointmentModal
              appointment={currentAppointment}
              isOpen={!!appointmentId}
              editAppointment={editAppointment}
              cloneAppointment={cloneAppointment}
              onClose={() =>
                history.push(
                  `/${userType}/patients/${patientId}/${treatmentId}`
                )
              }
              isOwnTreatment={isOwnTreatment}
              isAssistantOfTreatment={isAssistantOfTreatment}
              treatment={treatment}
            />
          )}

          {addAppointmentModalOpen.isOpen && (
            <AddAppointmentModal
              appointment={addAppointmentModalOpen.appointment}
              treatment={treatment}
              isOpen={addAppointmentModalOpen.isOpen}
              doctor={addAppointmentModalOpen.doctor}
              appointmentType={addAppointmentModalOpen.appointmentType}
              initProposedDate={addAppointmentModalOpen.proposedDate}
              onDone={() => {
                clearFilterValue()
                refetchTreatment()
              }}
              onClose={() => toggleAddAppointmentModal({ isOpen: false })}
            />
          )}

          {isEditRoute && (
            <AddAppointmentModal
              appointment={currentAppointment}
              treatment={treatment}
              isOpen={isEditRoute}
              onDone={() => {
                clearFilterValue()
                refetchTreatment()
              }}
              onClose={() => {
                history.push(
                  `/${userType}/patients/${patientId}/${treatmentId}/${appointmentId}`
                )
                toggleAddAppointmentModal({ isOpen: false })
              }}
            />
          )}

          {showLinkingModal && (
            <AppointmentDependencyModal
              isOpen={showLinkingModal}
              onClose={() => {
                setLinkingAppointments([])
                setLinkingDisabledIds([])
              }}
              onDone={() => {
                setLinkingAppointments([])
                setLinkingDisabledIds([])
              }}
              isOwnTreatment={isOwnTreatment}
              treatment={treatment}
              linking={linkingAppointments}
            />
          )}

          <AddProtocolModal
            isOpen={isAddProtocolModal}
            onDone={() => {
              clearFilterValue()
              refetchTreatment()
            }}
            onClose={() => toggleAddProtocolModal(false)}
            treatmentId={treatmentId}
            treatmentBnoCodes={treatment.bnoCodes}
          />

          {addSurveyModal.isOpen && treatment.patient.user && (
            <HandleSurveyModal
              treatmentId={treatmentId}
              patientId={treatment.patient.user.id}
              params={addSurveyModal}
              onClose={() => toggleAddSurveyModal({ isOpen: false })}
              onDone={() => {
                refetchTreatment()
              }}
            />
          )}

          {!!survey?.surveySchema.id && (
            <TreatmentSurveyPreview
              isOpen={!!survey}
              surveySchemaId={survey?.surveySchema.id}
              surveyId={survey.id}
              treatmentId={treatment.id}
              close={() => setSurvey(null)}
            />
          )}
        </>
      )}
    </Box>
  )
}

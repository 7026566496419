import { Box, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { getSurveySchemaById_getSurveySchemaById as SurveySchema } from '../../../../../models/graphql'
import { isValidHttpUrl } from '../../../../../common/components/Reference/referenceUtil'

interface Props {
  surveySchema: SurveySchema
}

export const ViewSurveyDetails: React.FC<Props> = ({ surveySchema }) => {
  const { t } = useTranslation()

  return (
    <>
      {!!surveySchema.bnoCodes?.length && (
        <Typography variant="subtitle1" mt={1}>
          <Box fontWeight="fontWeightMedium" display="inline">
            {t('treatment:protocol.bnoCode')}:{' '}
          </Box>
          {surveySchema.bnoCodes
            .map((item) => `${item.code} - ${item.title}`)
            .join(', ')}
        </Typography>
      )}
      {!!surveySchema.snomedCode && (
        <Typography variant="subtitle1">
          <Box fontWeight="fontWeightMedium" display="inline">
            {t('treatment:protocol.snowmedCode')}
          </Box>
          : {surveySchema.snomedCode}
        </Typography>
      )}
      {!!surveySchema.referenceUrls?.length && (
        <Stack>
          <Typography variant="subtitle2">
            {t('treatment:protocol.url')}:
          </Typography>
          {surveySchema.referenceUrls?.map((reference, i) => (
            <Typography key={i} variant="subtitle1" sx={{ pl: 4 }}>
              {reference.name}:{' '}
              {isValidHttpUrl(reference.url) ? (
                <Link
                  variant="subtitle1"
                  href={reference.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {reference.url}
                </Link>
              ) : (
                reference.url
              )}
            </Typography>
          ))}
        </Stack>
      )}
      {!!surveySchema.description && (
        <Typography variant="subtitle1">
          <Box fontWeight="fontWeightMedium" display="inline">
            {t('survey:description.title')}
          </Box>
          : {surveySchema.description}
        </Typography>
      )}
    </>
  )
}

import { Theme } from '@mui/material/styles/createTheme'
import React from 'react'
import { Box, Typography, useMediaQuery, Stack } from '@mui/material'
import { About } from './About'
import { Law } from './Law'
import { Contact } from './Contact'
import { Logo } from '../../components/Logo'
import { useTranslation } from '../../hooks/helper/useTranslation'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

interface Props {
  setIsFooterOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ClosedFooter: React.FC<Props> = ({ setIsFooterOpen }) => {
  const { t } = useTranslation()
  const smUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))
  const lgUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))

  return (
    <Stack direction="row" py={1.5} px={{ xs: 2, lg: 4 }} height={45}>
      <Stack flex={1} gap={3} flexDirection="row" alignItems="center">
        <Box>
          <Logo width={{ xs: 100 }} />
        </Box>
        {smUp && (
          <Typography variant="caption">{t('footer:rights')}</Typography>
        )}
      </Stack>

      {lgUp && (
        <Stack gap={2} flexDirection="row" alignItems="center">
          <About variant="body2" fontWeight={600} />
          <Box sx={{ borderRight: 'solid', height: 16 }} />
          <Law variant="body2" fontWeight={600} />
          <Box sx={{ borderRight: 'solid', height: 16 }} />
          <Contact variant="body2" fontWeight={600} />
        </Stack>
      )}

      <Stack
        justifyContent="center"
        alignItems="center"
        onClick={() => setIsFooterOpen(true)}
        sx={{ cursor: 'pointer' }}
        ml={2}
      >
        <KeyboardArrowUpIcon />
      </Stack>
    </Stack>
  )
}

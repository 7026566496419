import { DUPLICATE_SURVEY_SCHEMA } from '../operations/surveyOperations'
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  duplicateSurveySchema,
  duplicateSurveySchemaVariables,
} from '../../../../models/graphql'

type Options = MutationHookOptions<
  duplicateSurveySchema,
  duplicateSurveySchemaVariables
>

type Return = MutationTuple<
  duplicateSurveySchema,
  duplicateSurveySchemaVariables
>

export const useSurveySchemaDuplicate = (options: Options = {}): Return => {
  return useMutation<duplicateSurveySchema, duplicateSurveySchemaVariables>(
    DUPLICATE_SURVEY_SCHEMA,
    options
  )
}

import React, { FC, MouseEventHandler } from 'react'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'
import { SaveButton } from '../../../../../common/components/SaveButton'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useTreatmentSchemaStatusUpdate } from '../../hooks/useTreatmentSchemaStatusUpdate'
import { ButtonProps } from '@mui/material'

interface Props extends ButtonProps {
  treatmentSchemaId: string
  isActiveSchema: boolean
  isSubmitting: boolean
  isDirty: boolean
  onSaveAndPublish: () => void
}

export const TreatmentSchemaPublishButton: FC<Props> = ({
  treatmentSchemaId,
  isActiveSchema,
  isSubmitting,
  isDirty,
  onSaveAndPublish,
  ...props
}) => {
  const { t } = useTranslation()

  const text = isActiveSchema ? 'protocol:unPublish' : 'protocol:publish'

  const [updateTreatmentSchemaStatus, { loading }] =
    useTreatmentSchemaStatusUpdate()

  const handleClick: MouseEventHandler = () => {
    if (isDirty) {
      return onSaveAndPublish()
    }
    return updateTreatmentSchemaStatus({
      variables: {
        treatmentSchemaId,
        publish: !isActiveSchema,
      },
    })
  }

  return (
    <SaveButton
      color={!isActiveSchema ? 'primary' : 'secondary'}
      isSaving={isSubmitting || loading}
      text={t(text)}
      startIcon={
        !isActiveSchema ? (
          <VisibilityIcon fontSize="large" />
        ) : (
          <VisibilityOffIcon fontSize="large" />
        )
      }
      onClick={handleClick}
      {...props}
    />
  )
}

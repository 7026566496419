import { IconButton, Tooltip } from '@mui/material'
import React, { PropsWithChildren, ReactElement } from 'react'
import { DataTableRowAction, DataTableRowData } from './interfaces'

type Props<T> = {
  action: DataTableRowAction<T>
  row: T
}

export const DataTableActionButton = <T extends DataTableRowData>(
  props: PropsWithChildren<Props<T>>
): ReactElement | null => {
  const { action, row } = props
  const actionData = typeof action === 'function' ? action(row) : action

  return actionData.hidden ? null : (
    <Tooltip title={actionData.tooltip}>
      <IconButton
        onClick={(event) => actionData.onClick(event, row)}
        disabled={actionData.disabled}
      >
        {actionData.icon}
      </IconButton>
    </Tooltip>
  )
}

import React from 'react'
import { SelectedChip } from '../SelectedChip/SelectedChip'
import { ChipProps } from '@mui/material'

interface Props extends ChipProps {
  filterInput: boolean
  setFilterInput: (newFilter: boolean) => void
}

export const BooleanFilter: React.FC<Props> = ({
  filterInput,
  setFilterInput,
  ...chipProps
}) => {
  return (
    <SelectedChip
      selected={filterInput}
      onClick={() => setFilterInput(!filterInput)}
      {...chipProps}
    />
  )
}

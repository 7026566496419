import { gql } from '@apollo/client'

export const EESZT_STS_LOGIN = gql`
  mutation EesztStsLogin(
    $stsLoginInput: EESZTStsLoginInput!
    $institutionId: String!
    $institutionUnitId: String!
  ) {
    EesztStsLogin(
      stsLoginInput: $stsLoginInput
      institutionId: $institutionId
      institutionUnitId: $institutionUnitId
    ) {
      success
      expires
    }
  }
`

export const EESZT_MOBILE_TOKEN_LOGIN = gql`
  mutation EesztMobileTokenLogin(
    $mobileToken: String!
    $institutionId: String!
    $institutionUnitId: String!
  ) {
    EesztMobileTokenLogin(
      mobileToken: $mobileToken
      institutionId: $institutionId
      institutionUnitId: $institutionUnitId
    ) {
      success
      expires
    }
  }
`

export const GET_ESZIG_ID_NO_SAML = gql`
  query getEszigIdNoSaml {
    getEszigIdNoSaml
  }
`

export const EESZT_ESZIG_LOGIN = gql`
  mutation EesztESZIGLogin($token: String!) {
    EesztESZIGLogin(token: $token) {
      success
      expires
    }
  }
`

export const EESZT_LOGOUT = gql`
  mutation EesztLogout {
    EesztLogout {
      success
    }
  }
`

export const EESZT_FREE_SLOTS_BY_INSTITUTION = gql`
  query getFreeSlotsByInstitution(
    $institutionId: String!
    $institutionUnitId: String!
    $serviceId: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    getFreeSlotsByInstitution(
      institutionId: $institutionId
      institutionUnitId: $institutionUnitId
      serviceId: $serviceId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      startTime
      endTime
      institutionData {
        id
        name
      }
      organizationData {
        id
        name
      }
      consultationData {
        id
        name
        description
        type
        maxPatient
      }
    }
  }
`

export const EESZT_FEED = gql`
  query EesztFeed {
    EesztFeed {
      title
      description
      items {
        title
        description
        link
        guid
        pubDate
      }
    }
  }
`

export const EESZT_TOR_SYNC_STATE = gql`
  query EesztTorSyncState {
    eesztTorSyncState {
      synchronizationInProgress
      lastSyncEndDate
      lastSyncSuccessful
    }
  }
`

export const SYNCHRONIZE_EESZT_TOR_DATA = gql`
  mutation synchronizeEesztTorData {
    synchronizeEesztTorData
  }
`

// export const EESZT_STS_OTP_LOGIN = gql`
//   mutation EesztStsOtpLogin($stsOtpLoginInput: EESZTStsOtpLoginInput!) {
//     EesztStsOtpLogin(stsOtpLoginInput: $stsOtpLoginInput) {
//       success
//       expires
//     }
//   }
// `

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
  Zoom,
} from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { LockIcon } from '../../../common/icons/LockIcon'
import theme from '../../../theme'
import { useLogin } from '../hooks/useLogin'
import { getLoginErrorTranslateKey } from '../utils/login.utils'
import { LoginForm } from './LoginFields'

interface Props {
  email: string
  isOpen: boolean
  close: () => void
}

const VerifyPassword: React.FC<Props> = ({ email, isOpen, close }) => {
  const { t } = useTranslation()
  const [login, { error }] = useLogin()
  const loginError = getLoginErrorTranslateKey(error?.message)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginForm>({
    defaultValues: {
      email,
      password: '',
    },
  })

  const onSubmit = handleSubmit(async (values) => {
    await login({
      variables: {
        email: values.email,
        password: values?.password,
      },
      onCompleted: close,
    })
  })

  return (
    <Dialog sx={{ minWidth: '320px' }} open={isOpen}>
      <form onSubmit={onSubmit} noValidate>
        <DialogTitle>{t('auth:verifyPassword')}</DialogTitle>
        <DialogContent>
          <Controller
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                variant="outlined"
                margin="normal"
                fullWidth
                label={t('auth:password')}
                type="password"
                error={!!errors.password}
                helperText={
                  errors.password?.message
                    ? t(errors.password?.message)
                    : undefined
                }
                required
                autoComplete="current-password"
                data-error={errors.password?.message}
                data-cy="Login-TextField-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <LockIcon
                        width={14}
                        height={18}
                        sx={{ color: `${theme.palette.common.black}66` }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            name="password"
            control={control}
            rules={{
              required: 'messages:warnings.emptyField',
            }}
          />
          <Zoom in={!!error}>
            <Box mt={3}>
              {error && (
                <Typography
                  variant="body2"
                  color="error"
                  data-error={loginError}
                >
                  {t(loginError)}
                </Typography>
              )}
            </Box>
          </Zoom>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {t(`common:close`)}
          </Button>
          <Button
            data-cy="Login-Button-submit"
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            fullWidth
          >
            {t('common:confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export { VerifyPassword }

import { Box, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import {
  GetIndividualSurveyResults_getIndividualSurveyResults_surveys as SurveyResult,
  getSurveySchemaById_getSurveySchemaById as SurveySchema,
} from '../../../../../models/graphql'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { SurveyResultPatientDetails } from './SurveyResultPatientDetails'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { SurveyResultQuestions } from './SurveyResultQuestions'
import { SurveyResultDownloadSelector } from './SurveyResultDownloadSelector'
interface Props {
  surveySchema: SurveySchema
  surveyResults: SurveyResult[]
  totalSurveyResults: number
  fetchMoreResult: () => void
  actualPatientIndexState: [
    number,
    React.Dispatch<React.SetStateAction<number>>
  ]
  hidePatientDetails: boolean
  setHidePatientDetails: React.Dispatch<React.SetStateAction<boolean>>
  csvInBase64?: string
  xlsxInBase64?: string
}

export const SurveyResultByPatient: React.FC<Props> = ({
  surveySchema,
  surveyResults,
  totalSurveyResults,
  fetchMoreResult,
  hidePatientDetails,
  setHidePatientDetails,
  actualPatientIndexState,
  csvInBase64,
  xlsxInBase64,
}) => {
  const { t } = useTranslation()
  const [actualPatientIndex, setActualPatientIndex] = actualPatientIndexState
  const actualPatientResult = surveyResults[actualPatientIndex]

  const showNextResult = () => {
    if (actualPatientIndex === surveyResults.length - 1) {
      fetchMoreResult()
    }
    setActualPatientIndex((prevIndex) => prevIndex + 1)
  }

  return surveyResults.length === 0 ? (
    <Typography variant="h4" textAlign="center">
      {t('survey:result.noResultData')}
    </Typography>
  ) : (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ p: 1, pt: 0 }}>
        <Stack direction="row" gap={1} alignItems="center">
          <IconButton
            disabled={actualPatientIndex === 0}
            onClick={() => setActualPatientIndex((prevIndex) => prevIndex - 1)}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography variant="subtitle2">{actualPatientIndex + 1}</Typography>
          <Typography variant="subtitle1">/</Typography>
          <Typography variant="subtitle1">{totalSurveyResults}</Typography>
          <IconButton
            disabled={actualPatientIndex === totalSurveyResults - 1}
            onClick={showNextResult}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Stack>
      </Stack>
      {actualPatientResult && (
        <>
          <SurveyResultPatientDetails
            surveyResult={actualPatientResult}
            hidePatientDetails={hidePatientDetails}
            setHidePatientDetails={setHidePatientDetails}
          />

          {!!csvInBase64 && !!xlsxInBase64 && (
            <Box display={'flex'} flexDirection={'row-reverse'} mb={2}>
              <Box sx={{ maxWidth: 200 }}>
                <SurveyResultDownloadSelector
                  csvInBase64={csvInBase64}
                  xlsxInBase64={xlsxInBase64}
                />
              </Box>
            </Box>
          )}

          <SurveyResultQuestions
            surveySchema={surveySchema}
            surveyResult={actualPatientResult}
          />
        </>
      )}
    </>
  )
}

import { RemoveRedEye } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import { Button, ButtonProps } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMe } from '../../../../../common/hooks/useMe'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { getTreatmentSchemas_getTreatmentSchemas_treatmentSchemas as TreatmentSchema } from '../../../../../models/graphql'
import { isTreatmentSchemaEditable } from '../../../../../utils/treatmentSchemaHelper'

interface Props extends ButtonProps {
  treatmentSchema: TreatmentSchema
}
export const TreatmentSchemaEditButton: React.FC<Props> = ({
  treatmentSchema,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })
  const userId = me?.id

  const isEditable = isTreatmentSchemaEditable(treatmentSchema, userId)

  return (
    <Button
      variant="text"
      startIcon={isEditable ? <EditIcon /> : <RemoveRedEye />}
      onClick={() =>
        history.push(
          `/${me?.userType.toLowerCase()}/treatment-option/${
            treatmentSchema.id
          }`
        )
      }
      data-cy="BetmenListEditButton"
      {...buttonProps}
    >
      {isEditable ? t('common:edit') : t('common:view')}
    </Button>
  )
}

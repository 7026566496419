import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  deleteSurveysFromTreatment,
  deleteSurveysFromTreatmentVariables,
} from '../../../../models/graphql'
import { DELETE_SURVEYS_FROM_TREATMENT } from '../operations/surveyOperations'

type Options = MutationHookOptions<
  deleteSurveysFromTreatment,
  deleteSurveysFromTreatmentVariables
>

type Return = MutationTuple<
  deleteSurveysFromTreatment,
  deleteSurveysFromTreatmentVariables
>

export const useSurveysDeleteFromTreatment = (
  options: Options = {}
): Return => {
  return useMutation<
    deleteSurveysFromTreatment,
    deleteSurveysFromTreatmentVariables
  >(DELETE_SURVEYS_FROM_TREATMENT, options)
}

import React from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'

import { Box, Collapse } from '@mui/material'
import { Alert } from '@mui/material'

import { SaveButton } from '../SaveButton'
import { useEesztEszigLogin } from '../../hooks/eeszt/useEesztEszigLogin'

export const EesztEszigLogin: React.FC = () => {
  const { t } = useTranslation()

  const { getEszigIdNoSaml, error, loading } = useEesztEszigLogin()

  const errorText = error?.message.replace(/[\W_]/g, '-')

  return (
    <>
      <Box mb={1}>
        <Alert severity="info">{t(`eeszt:auth.notLoggedInMessage`)}</Alert>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <SaveButton
          fullWidth
          disabled={loading}
          isSaving={loading}
          onClick={() => getEszigIdNoSaml()}
          text={t('eeszt:auth.eszigLogin')}
          inProgressText={t('eeszt:auth.inProgress')}
          completedIcon={error ? <></> : undefined}
          completedText={
            error ? t('eeszt:auth.errored') : t('eeszt:auth.complete')
          }
        />
      </Box>
      <Collapse in={!!error} unmountOnExit>
        <Box mt={1}>
          <Alert severity="error">{t(`eeszt:authError.${errorText}`)}</Alert>
        </Box>
      </Collapse>
    </>
  )
}

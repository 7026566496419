import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import {
  Grid,
  GridProps,
  GridSize,
  IconButton,
  SortDirection,
  Typography,
} from '@mui/material'
import { isFunction } from 'lodash'
import React, { FC, useState } from 'react'
import { useColorPalette } from '../../hooks/helper/useColor'
import { useBetmenList } from './BetmenList'

interface Props extends GridProps {
  gridSize?: GridSize
  columnName?: string | number
  justifyContent?: 'flex-start' | 'center' | 'flex-end'
  actions?: boolean
}

export const BetmenListHeaderCell: FC<Props> = ({
  children,
  gridSize = 'auto',
  columnName,
  justifyContent = 'flex-start',
  actions = false,
  ...props
}) => {
  const colorPalette = useColorPalette()
  const { sort, lastSortedColumnName, gridColumnSizes } = useBetmenList()
  const [dir, setDir] = useState<SortDirection>(false)
  const [hovered, setHovered] = useState<boolean>(false)

  // "cellindex" assigned to props in BetmenListHeader
  const indexOfComponent = (props as typeof props & { cellindex: number })
    .cellindex
  const inheritedGridSize = gridColumnSizes?.[indexOfComponent]

  const changeSortDirection = () => {
    if (!isFunction(sort)) {
      return
    }

    if (!dir) {
      setDir('asc')
      sort(columnName, 'asc')
    } else {
      const direction = dir === 'asc' ? 'desc' : 'asc'
      setDir(direction)
      sort(columnName, direction)
    }
  }

  return (
    <Grid
      item
      xs={inheritedGridSize || gridSize}
      display="flex"
      alignItems="center"
      justifyContent={actions ? 'flex-end' : justifyContent}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        cursor: !!columnName ? 'pointer' : 'auto',
        minHeight: '30px',
        pr: actions ? 3 : undefined,
      }}
      {...props}
    >
      {typeof children === 'string' ? (
        <Typography color={colorPalette.grey[400]} variant="subtitle2">
          {children}
        </Typography>
      ) : (
        children
      )}
      {columnName &&
        ((dir !== false && lastSortedColumnName === columnName) || hovered) && (
          <IconButton
            sx={{ color: colorPalette.common.black, ml: 1.25 }}
            onClick={changeSortDirection}
            size="small"
          >
            {dir === 'desc' ? <ArrowDownward /> : <ArrowUpward />}
          </IconButton>
        )}
    </Grid>
  )
}

import dayjs from 'dayjs'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ChainedBackend from 'i18next-chained-backend'
import LocizeBackend from 'i18next-locize-backend'
import resourcesToBackend from 'i18next-resources-to-backend'

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    lng: 'hu',
    fallbackLng: 'en',
    keySeparator: '.',
    debug: false,
    ns: [
      'menu',
      'auth',
      'doctor',
      'treatment',
      'common',
      'home',
      'appointment',
      'patients',
      'messages',
      'settings',
      'table',
      'notification',
      'profile',
      'professions',
      'protocol',
      'eeszt',
      'help',
      'institution',
      'about',
      'footer',
      'statistics',
      'cookie',
      'survey',
    ],
    load: 'languageOnly',
    saveMissing: true,
    whitelist: ['en', 'hu'],
    interpolation: {
      escapeValue: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      format: (value: dayjs.ConfigType, format: any, lng: any) => {
        if (format === 'intlDateFormattedLongMonth') {
          switch (lng) {
            case 'en':
              return dayjs(value).format('YYYY. MMMM DD.')
            case 'hu':
              return dayjs(value).format('YYYY. MMMM DD.')
            default:
              break
          }
        }
        if (format === 'intlDateFormattedWithHours') {
          switch (lng) {
            case 'en':
              return dayjs(value).format('YYYY. MMMM DD. HH:mm')
            case 'hu':
              return dayjs(value).format('YYYY. MMMM DD. HH:mm')
            default:
              break
          }
        }

        if (format === 'datePrintedDayWithHours') {
          switch (lng) {
            case 'en':
              return dayjs(value).format('MMM. DD, dddd, HH:mm')
            case 'hu':
              return dayjs(value).format('MMM. DD, dddd, HH:mm')
            default:
              break
          }
        }
        if (format === 'datePrintedDay') {
          switch (lng) {
            case 'en':
              return dayjs(value).format('MMM. DD, dddd')
            case 'hu':
              return dayjs(value).format('MMM. DD, dddd')
            default:
              break
          }
        }

        if (format === 'patientFormattedDate') {
          switch (lng) {
            case 'en':
              return dayjs(value).format('YYYY. MMMM DD, dddd')
            case 'hu':
              return dayjs(value).format('YYYY. MMMM DD, dddd')
            default:
              break
          }
        }
        if (format === 'patientFormattedTime') {
          switch (lng) {
            case 'en':
              return dayjs(value).format('HH:mm')
            case 'hu':
              return dayjs(value).format('HH:mm')
            default:
              break
          }
        }
        return ''
      },
    },
    backend: {
      backends:
        process.env.REACT_APP_ENABLE_LOCALIZE === 'true'
          ? [
              LocizeBackend,
              resourcesToBackend(
                (lng: string, ns: string) =>
                  import(`./locales/${lng}/${ns}.json`)
              ),
            ]
          : [
              resourcesToBackend(
                (lng: string, ns: string) =>
                  import(`./locales/${lng}/${ns}.json`)
              ),
            ],
      backendOptions:
        process.env.REACT_APP_ENABLE_LOCALIZE === 'true'
          ? [
              {
                projectId: `${process.env.REACT_APP_LOCALIZE_PROJECT_ID}`,
                apiKey: `${process.env.REACT_APP_LOCALIZE_API_KEY}`,
                version: `${process.env.REACT_APP_LOCALIZE_ENV}` || 'latest',
                referenceLng: 'hu',
              },
            ]
          : [],
    },
  })

export default i18n

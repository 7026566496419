import React from 'react'
import {
  Dialog,
  Stack,
  Typography,
  Link,
  Switch,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import { useTranslation } from '../../common/hooks/helper/useTranslation'
import { Link as RouterLink } from 'react-router-dom'
import { CloseIcon } from '../../common/icons/CloseIcon'

enum CookieType {
  Mandatory = 'mandatory',
  Performance = 'performance',
  Functional = 'functional',
}

interface CustomizeCookiesProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const CookiePermissions: React.FC<CustomizeCookiesProps> = ({
  open,
  setOpen,
}) => {
  const { t } = useTranslation()

  const customizeNamespace = 'cookie:customize.'

  const handleClose = () => setOpen(false)

  // TODO actual functionality
  const handleSave = () => setOpen(false)

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {t(`${customizeNamespace}title`)}
        <IconButton
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ maxHeight: '70vh', maxWidth: { lg: '60vw' }, pr: 6 }}
      >
        <Stack mb={3.5} spacing={1}>
          <Typography variant="subtitle1">
            {t(`${customizeNamespace}description`)}
          </Typography>
          <Link component={RouterLink} underline="hover" to={'/cookie-policy'}>
            {t('cookie:policyTitle')}
          </Link>
        </Stack>

        <Typography variant="h6" mb={2.5}>
          {t(`${customizeNamespace}subtitle`)}
        </Typography>
        {Object.values(CookieType).map((type: string) => {
          const isMandatory = type === CookieType.Mandatory
          return (
            <Stack key={type} direction="row" mb={3}>
              <Stack>
                <Typography variant="subtitle2" mb={1}>
                  {t(`cookie:${type}.title`)}
                </Typography>
                <Typography variant="subtitle1">
                  {t(`cookie:${type}.description`)}
                </Typography>
              </Stack>
              <Stack pl={3} minWidth={100} alignItems="center">
                <Switch disabled={isMandatory} defaultChecked />
                {isMandatory && (
                  <Typography variant="caption">
                    {t('cookie:alwaysActive')}
                  </Typography>
                )}
              </Stack>
            </Stack>
          )
        })}
      </DialogContent>

      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button onClick={handleSave}>{t('common:save')}</Button>
      </DialogActions>
    </Dialog>
  )
}

import { Delete } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNotifications } from '../../../../../common/providers/NotificationProvider'
import { ConfirmDialog } from '../../../../../common/components/dialogs/ConfirmDialog'
import { ConfirmDialogValue } from '../../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { getSurveySchemaById_getSurveySchemaById as SurveySchema } from '../../../../../models/graphql'
import { Section } from '../../../SurveyStore/components/EditSurvey/editSurvey.types'
import { FillSurvey } from '../../../SurveyStore/components/FillSurvey/FillSurvey'
import { useSurveySchemaData } from '../../../SurveyStore/hooks/useSurveySchemaData'
import { mapSurveySchemaQuestionsToSections } from '../../../SurveyStore/utils/survey.mapper'
import {
  getNumberOfQuestionsInSurvey,
  getOrderedSections,
} from '../../../SurveyStore/utils/survey.util'
import { useRemoveSurveyFromTreatment } from '../../hooks/useRemoveSurveyFromTreatment'

interface Props {
  surveySchemaId: string
  surveyId: string
  treatmentId: string
  isOpen: boolean
  close: () => void
}

export const TreatmentSurveyPreview: React.FC<Props> = ({
  surveySchemaId,
  surveyId,
  treatmentId,
  isOpen,
  close,
}) => {
  const { t } = useTranslation()
  const { surveySchema } = useSurveySchemaData({
    variables: { surveySchemaId },
  })
  const [showConfirmDialog, setShowConfirmDialog] =
    useState<ConfirmDialogValue>(false)
  const { setSuccessToast, setErrorToast } = useNotifications()
  const [removeSurvey] = useRemoveSurveyFromTreatment()

  const deleteSurvey = () =>
    removeSurvey({
      variables: {
        treatmentId,
        surveyId,
      },
      refetchQueries: ['getTreatment'],
      onCompleted: () => {
        close()
        setSuccessToast(t('notification:successRemoveSurvey'))
      },
      onError: (e) => setErrorToast(e),
    })

  const formMethods = useForm<SurveySchema>({
    defaultValues: {
      title: surveySchema?.title || '',
      description:
        surveySchema?.description || t('survey:description.placeholder'),
    },
  })

  const { setValue } = formMethods

  useEffect(() => {
    if (surveySchema) {
      setValue('title', surveySchema.title)
      setValue('description', surveySchema.description)
    }
  }, [surveySchema, setValue])

  const sections = useMemo<Section[]>(
    () =>
      surveySchema
        ? mapSurveySchemaQuestionsToSections(
            surveySchema?.surveySchemaQuestions
          )
        : [{ order: 1, questions: [], name: '' }],
    [surveySchema]
  )

  const orderedSections = getOrderedSections(sections)
  const numberOfQuestionsInSurvey = getNumberOfQuestionsInSurvey(sections)

  return surveySchema ? (
    <>
      <Dialog
        open={isOpen}
        onClose={(_event, reason) => {
          if (reason !== 'backdropClick') {
            close()
          }
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {surveySchema.title}
          <Button
            size="small"
            color="error"
            variant="contained"
            startIcon={<Delete />}
            onClick={() => setShowConfirmDialog(true)}
          >
            {t('common:delete')}
          </Button>
        </DialogTitle>
        <DialogContent>
          <FormProvider {...formMethods}>
            <FillSurvey
              orderedSections={orderedSections}
              numberOfQuestionsInSurvey={numberOfQuestionsInSurvey}
            />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {t('common:close')}
          </Button>
        </DialogActions>
      </Dialog>
      {showConfirmDialog && (
        <ConfirmDialog
          isAlertingDialog
          valueState={[showConfirmDialog, setShowConfirmDialog]}
          text={t(`survey:confirm.delete`)}
          onAccept={deleteSurvey}
        />
      )}
    </>
  ) : null
}

import { Typography } from '@mui/material'
import React from 'react'
import { useColorPalette } from '../../hooks/helper/useColor'
import { useTranslation } from '../../hooks/helper/useTranslation'

export enum StatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
}

interface Props {
  status: StatusEnum
  title?: string
}

export const Status: React.FC<Props> = ({ status, title }) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  const getColor = () => {
    switch (status) {
      case StatusEnum.Active:
        return colorPalette.success.dark
      case StatusEnum.Inactive:
        return colorPalette.common.black
      default:
        return colorPalette.grey[300]
    }
  }

  return (
    <Typography variant="h6" color={getColor()}>
      {title || t(`common:statusName.${status.toLowerCase()}`)}
    </Typography>
  )
}

import { gql } from '@apollo/client'
import {
  FRAGMENT_APPOINTMENT_CHORE,
  FRAGMENT_ASSISTANT_PROFILE_CHORE,
  FRAGMENT_DEPENDENCY_CHORE,
  FRAGMENT_DOCTOR_PROFILE,
  FRAGMENT_DOCTOR_PROFILE_MINIMAL_DATA,
  FRAGMENT_INSTITUTION_CHORE,
  FRAGMENT_MULTILANGTEXT,
  FRAGMENT_PATIENT_CHORE,
  FRAGMENT_ROOM_CHORE,
  FRAGMENT_SURVEY_CHORE,
  FRAGMENT_TREATMENT_CHORE,
  FRAGMENT_TREATMENT_SCHEMA_CHORE,
} from './fragments'

export const GET_TREATMENT = gql`
  query getTreatment($treatmentId: String!) {
    getTreatment(treatmentId: $treatmentId) {
      ...treatmentChore
      firstAndLastDates {
        firstAppointmentDate
        lastAppointmentDate
      }
      pocId
      doctor {
        ...doctorProfileChore
      }
      treatmentLogs {
        id
        date
        type
        title {
          ...multiLangText
        }
        user {
          title
          firstName
          lastName
        }
        diff
      }
      appointments {
        ...appointmentChore
        createdAt
        updatedAt
        ratingText
        ratingStars
        status
        timeFrom
        timeTo
        katId
        eesztBookingTransaction {
          status
          message
        }
        treatmentSchema {
          ...treatmentSchemaChore
        }
        doctor {
          ...doctorProfileChore
        }
        info {
          eesztServiceId
          goodToKnow {
            ...multiLangText
          }
          patientTitle {
            ...multiLangText
          }
          doctorTitle {
            ...multiLangText
          }
          doctorTodo {
            ...multiLangText
          }
          doctorTitle {
            ...multiLangText
          }
          beforeTreatmentNote
          oeno
        }
        dependent {
          ...dependencyChore
        }
      }
      patient {
        ...patientChore
        user {
          id
          email
        }
      }
      surveys {
        ...surveyChore
        createdByTreatmentSchemaId
      }
    }
  }
  ${FRAGMENT_TREATMENT_CHORE}
  ${FRAGMENT_TREATMENT_SCHEMA_CHORE}
  ${FRAGMENT_APPOINTMENT_CHORE}
  ${FRAGMENT_PATIENT_CHORE}
  ${FRAGMENT_ASSISTANT_PROFILE_CHORE}
  ${FRAGMENT_DOCTOR_PROFILE}
  ${FRAGMENT_MULTILANGTEXT}
  ${FRAGMENT_DEPENDENCY_CHORE}
  ${FRAGMENT_SURVEY_CHORE}
`

export const PATIENT_HOME_DATA = gql`
  query getPatientHomeData(
    $patientId: String!
    $paginationInput: PaginationInput
    $searchTerm: String
    $treatmentId: String
    $isPast: Boolean
  ) {
    getAppointments(
      patientId: $patientId
      paginationInput: $paginationInput
      searchTerm: $searchTerm
      treatmentId: $treatmentId
      isPast: $isPast
    ) {
      appointments {
        id
        proposedDate
        status
        isBetmenBooked
        eesztBookingId
        info {
          patientTitle {
            ...multiLangText
          }
          goodToKnow {
            ...multiLangText
          }
        }
        institution {
          ...institutionChore
        }
        room {
          ...roomChore
        }
        treatment {
          id
          status
          description {
            ...multiLangText
          }
          title {
            ...multiLangText
          }
          doctor {
            id
            title
            firstName
            lastName
          }
        }
        doctor {
          ...doctorProfileMinimalForPatients
        }
        dependent {
          id
          distance
          isBroken
          fromId
          toId: appointmentId
        }
      }
      total
    }
  }
  ${FRAGMENT_MULTILANGTEXT}
  ${FRAGMENT_DOCTOR_PROFILE_MINIMAL_DATA}
  ${FRAGMENT_ROOM_CHORE}
  ${FRAGMENT_INSTITUTION_CHORE}
`

export const GET_PATIENT_TREATMENTS = gql`
  query getTreatmentsToPatientDashboard($patientId: String!) {
    getTreatmentsToPatientDashboard(patientId: $patientId) {
      id
      status
      title {
        ...multiLangText
      }
    }
  }
  ${FRAGMENT_MULTILANGTEXT}
`

export const GET_KAT_EVENTS = gql`
  query getKatEvents($tajNumber: String!) {
    getKatEvents(tajNumber: $tajNumber) {
      katId
      taj
      eventType
      status
      room {
        ...multiLangText
      }
      eventStart
      doctor
    }
  }
  ${FRAGMENT_MULTILANGTEXT}
`

export const GET_PATIENT_APPOINTMENT_COUNT = gql`
  query getPatientAppointmentsCount($patientId: String!) {
    getPatientProfile(patientId: $patientId) {
      treatments {
        id
        appointments {
          id
          proposedDate
          info {
            patientTitle {
              ...multiLangText
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_MULTILANGTEXT}
`

export const CREATE_TREATMENT = gql`
  mutation createTreatment($treatmentData: CreateTreatmentInput!) {
    __typename
    createTreatment(treatmentData: $treatmentData) {
      ...treatmentChore
    }
  }
  ${FRAGMENT_TREATMENT_CHORE}
`

export const ADD_PROTOCOL = gql`
  mutation addProtocol($treatmentData: AddProtocolInput!) {
    __typename
    addProtocol(treatmentData: $treatmentData) {
      ...treatmentChore
    }
  }
  ${FRAGMENT_TREATMENT_CHORE}
`

export const UPDATE_POC = gql`
  mutation updatePoc(
    $pocId: String!
    $katId: String!
    $appointmentId: String!
  ) {
    updatePoc(pocId: $pocId, katId: $katId, appointmentId: $appointmentId) {
      pocId
    }
  }
`

export const DELETE_PROTOCOL = gql`
  mutation deleteProtocol($treatmentData: DeleteProtocolInput!) {
    __typename
    deleteProtocol(treatmentData: $treatmentData) {
      ...treatmentChore
      firstAndLastDates {
        firstAppointmentDate
        lastAppointmentDate
      }
      pocId
      doctor {
        ...doctorProfileChore
      }
      treatmentLogs {
        id
        date
        type
        title {
          ...multiLangText
        }
        user {
          title
          firstName
          lastName
        }
        diff
      }
      appointments {
        ...appointmentChore
        createdAt
        updatedAt
        ratingText
        ratingStars
        status
        timeFrom
        timeTo
        katId
        eesztBookingTransaction {
          status
          message
        }
        treatmentSchema {
          ...treatmentSchemaChore
        }
        doctor {
          ...doctorProfileChore
        }
        info {
          id
          eesztServiceId
          goodToKnow {
            ...multiLangText
          }
          patientTitle {
            ...multiLangText
          }
          doctorTitle {
            ...multiLangText
          }
          doctorTodo {
            ...multiLangText
          }
          doctorTitle {
            ...multiLangText
          }
          beforeTreatmentNote
          oeno
        }
        dependent {
          ...dependencyChore
        }
      }
      patient {
        ...patientChore
        user {
          id
          email
        }
      }
      surveys {
        ...surveyChore
        createdByTreatmentSchemaId
      }
    }
  }
  ${FRAGMENT_TREATMENT_CHORE}
  ${FRAGMENT_TREATMENT_SCHEMA_CHORE}
  ${FRAGMENT_APPOINTMENT_CHORE}
  ${FRAGMENT_PATIENT_CHORE}
  ${FRAGMENT_ASSISTANT_PROFILE_CHORE}
  ${FRAGMENT_DOCTOR_PROFILE}
  ${FRAGMENT_MULTILANGTEXT}
  ${FRAGMENT_DEPENDENCY_CHORE}
  ${FRAGMENT_SURVEY_CHORE}
`

export const SEND_DRAFT_TREATMENT = gql`
  mutation sendDraftTreatment($treatmentId: ID!) {
    sendDraftTreatment(treatmentId: $treatmentId) {
      id
      status
    }
  }
`

export const CANCEL_TREATMENT = gql`
  mutation cancelTreatment($treatmentData: CancelTreatmentInput!) {
    cancelTreatment(treatmentData: $treatmentData) {
      id
      status
    }
  }
`

export const UPDATE_TREATMENT = gql`
  mutation updateTreatment($treatmentData: UpdateTreatmentInput!) {
    updateTreatment(treatmentData: $treatmentData) {
      ...treatmentChore
    }
  }
  ${FRAGMENT_TREATMENT_CHORE}
`

export const DELETE_DRAFT_TREATMENT = gql`
  mutation deleteDraftTreatment($treatmentData: UpdateTreatmentInput!) {
    deleteDraftTreatment(treatmentData: $treatmentData)
  }
`

export const TRIGGER_EXPIRING_TREATMENT_REMAINDER = gql`
  query triggerExpiringTreatmentRemainder {
    triggerExpiringTreatmentRemainder
  }
`

export const ADD_SURVEY_TO_TREATMENT = gql`
  mutation addSurveyToTreatment(
    $treatmentId: String!
    $surveySchemaId: String!
    $patientUserId: String!
    $fillableFrom: DateTime!
    $fillableUntil: DateTime!
  ) {
    addSurveyToTreatment(
      treatmentId: $treatmentId
      surveySchemaId: $surveySchemaId
      patientUserId: $patientUserId
      fillableFrom: $fillableFrom
      fillableUntil: $fillableUntil
    ) {
      id
    }
  }
`

export const REMOVE_SURVEY_TO_TREATMENT = gql`
  mutation removeSurveyFromTreatment(
    $treatmentId: String!
    $surveyId: String!
  ) {
    removeSurveyFromTreatment(treatmentId: $treatmentId, surveyId: $surveyId) {
      id
    }
  }
`

export const UPDATE_SURVEY_FILLABLE_FROM_DATE = gql`
  mutation updateSurveyFillableFromDate(
    $surveyId: String!
    $fillableFromDate: DateTime!
    $fillableUntilDate: DateTime!
  ) {
    updateSurveyFillableFromDate(
      surveyId: $surveyId
      fillableFromDate: $fillableFromDate
      fillableUntilDate: $fillableUntilDate
    ) {
      id
    }
  }
`

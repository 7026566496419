import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const DashboardIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 21 22" width="21" height="22" {...props}>
      <g transform="translate(-30.000000, -112.000000)" fillRule="nonzero">
        <g transform="translate(30.000000, 110.000000)">
          <g transform="translate(0.000000, 2.000000)">
            <path d="M1.509375,12.474 C1.105125,12.473125 0.725375,12.315625 0.440125,12.0295 C0.15575,11.743375 -0.000875,11.363625 -4.44090904e-16,10.959375 L-4.44090904e-16,1.519 C-0.000875,1.1165 0.15575,0.735875 0.441,0.450625 C0.72625,0.1645 1.105125,0.006125 1.509375,0.00525 L8.337,0.016625 C9.169125,0.020125 9.8455,0.7 9.84375,1.53125 L9.84375,10.959375 C9.8455,11.7915 9.169125,12.471375 8.336125,12.474 C8.336125,12.474 8.33525,12.474 8.33525,12.474 L1.509375,12.474 Z M1.51025,1.31775 C1.456875,1.31775 1.40875,1.337875 1.371125,1.376375 C1.332625,1.414875 1.3125,1.463875 1.3125,1.51725 L1.3125,10.96025 C1.3125,11.015375 1.332625,11.06525 1.37025,11.102875 C1.40875,11.141375 1.456875,11.1615 1.51025,11.1615 L8.334375,11.1615 C8.442875,11.1615 8.53125,11.071375 8.53125,10.961125 L8.53125,1.530375 C8.53125,1.41925 8.442,1.33 8.332625,1.329125 L1.51025,1.31775 Z" />
            <path d="M12.662125,7.224 C12.257875,7.224 11.88425,7.07 11.60075,6.79175 C11.314625,6.51 11.155375,6.13375 11.15275,5.732125 L11.15275,1.514625 C11.158,0.68775 11.83175,0.016625 12.655125,0.016625 L19.483625,0.00525 C20.314875,0.00525 20.9895,0.674625 20.9965,1.498 L20.9965,5.726 C20.990375,6.55375 20.31575,7.224 19.492375,7.224 L12.662125,7.224 Z M12.65775,1.329125 C12.551875,1.329125 12.466125,1.414 12.46525,1.518125 L12.46525,5.726 C12.46525,5.77325 12.485375,5.8205 12.522125,5.856375 C12.567625,5.901 12.62275,5.910625 12.66125,5.9115 L19.487125,5.9115 C19.5965,5.9115 19.683125,5.826625 19.684,5.721625 L19.684,1.50325 C19.683125,1.402625 19.5965,1.31775 19.4915,1.31775 C19.4915,1.31775 12.658625,1.329125 12.65775,1.329125 Z" />
            <path d="M1.50675,20.993875 C0.678125,20.993875 0.004375,20.32275 0,19.497625 L0,15.28625 C0.004375,14.4585 0.679,13.7865 1.504125,13.7865 L8.334375,13.7865 C9.163875,13.7865 9.8385,14.457625 9.84375,15.28275 L9.84375,19.504625 C9.841125,19.910625 9.68275,20.28775 9.396625,20.57125 C9.113125,20.85125 8.736875,21.00525 8.33875,21.00525 L1.50675,20.993875 Z M1.49275,15.099 C1.395625,15.099 1.313375,15.1865 1.3125,15.28975 L1.3125,19.494125 C1.313375,19.5965 1.399125,19.681375 1.504125,19.681375 C1.504125,19.681375 8.33525,19.69275 8.33525,19.69275 C8.390375,19.69275 8.427125,19.683125 8.4735,19.63675 C8.511125,19.6 8.53125,19.55275 8.53125,19.501125 L8.53125,15.28625 C8.530375,15.183875 8.444625,15.099 8.33875,15.099 L1.49275,15.099 Z" />
            <path d="M12.662125,21.00525 C12.257875,21.004375 11.878125,20.846 11.592875,20.559875 C11.3085,20.27375 11.151875,19.894 11.15275,19.48975 L11.15275,10.051125 C11.151875,9.6495 11.307625,9.26975 11.592875,8.98275 C11.878125,8.69575 12.257,8.537375 12.660375,8.5365 L19.487125,8.5365 C19.89225,8.537375 20.272,8.69575 20.55725,8.98275 C20.8425,9.268875 20.99825,9.6495 20.9965,10.05375 L20.9965,19.480125 C20.99825,20.3105 20.32275,20.990375 19.4915,20.99475 L12.66475,21.006125 C12.663875,21.00525 12.662125,21.00525 12.662125,21.00525 Z M12.663,9.849 C12.553625,9.849875 12.46525,9.939125 12.46525,10.049375 L12.46525,19.490625 C12.46525,19.546625 12.485375,19.595625 12.523,19.634125 C12.5615,19.672625 12.609625,19.69275 12.663875,19.69275 L19.48625,19.681375 C19.59475,19.6805 19.684,19.590375 19.684,19.480125 L19.684,10.051125 C19.684,9.995125 19.663875,9.946125 19.62625,9.907625 C19.588625,9.869125 19.539625,9.849 19.485375,9.849 L12.663,9.849 Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export { DashboardIcon }

import React from 'react'
import { CircularProgress, Box } from '@mui/material'

interface Loading {
  inline?: boolean
  size?: string | number
  color?: 'primary' | 'secondary' | 'inherit' | undefined
}
const Loading: React.FC<Loading> = ({ inline, size, color }) => {
  return inline ? (
    <CircularProgress size={size} color={color} />
  ) : (
    <Box
      flexGrow={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={size} color={color} />
    </Box>
  )
}

export { Loading }

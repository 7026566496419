import React, { FC } from 'react'
import { Avatar as MUAvatar, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

export type Sizes = 'tiny' | 'small' | 'large'

type PossibleTextVariants = 'h4' | 'h5' | 'h6'

type Props = {
  firstName?: string
  lastName?: string
  size?: Sizes
  className?: string
}

const getAvatarSizes = (size: Sizes): [number, number, string] => {
  switch (size) {
    case 'large':
      return [80, 2, 'h4']
    case 'small':
      return [60, 1, 'h5']
    case 'tiny':
    default:
      return [40, 1, 'h6']
  }
}

type StyleProps = {
  boxSize: number
  boxMargin: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  avatar: {
    width: ({ boxSize }) => boxSize,
    height: ({ boxSize }) => boxSize,
    marginRight: ({ boxMargin }) => theme.spacing(boxMargin),
    transition: 'all 0.2s',
  },
}))

export const Avatar: FC<Props> = ({
  firstName,
  lastName,
  size = 'small',
  className,
}) => {
  const [boxSize, boxMargin, textVariant] = getAvatarSizes(size)

  const classes = useStyles({ boxSize, boxMargin })

  return (
    <MUAvatar className={clsx(className, classes.avatar)}>
      {firstName && lastName && (
        <Typography
          color="textPrimary"
          variant={textVariant as PossibleTextVariants}
        >{`${firstName[0]}${lastName[0]}`}</Typography>
      )}
    </MUAvatar>
  )
}

import { Me_me_profile } from '../../models/graphql'
import { useMe } from './useMe'

export const useUserProfile = (): Me_me_profile | undefined => {
  const { data: { me } = {} } = useMe({
    fetchPolicy: 'cache-only',
  })

  return me?.profile
}

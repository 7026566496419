import { gql } from '@apollo/client'
import { FRAGMENT_ASSISTANT_PROFILE_CHORE } from '../../../../operations/fragments'

export const GET_DOCTOR_OWN_ASSISTANTS = gql`
  query getDoctorOwnAssistants {
    getDoctorOwnAssistants {
      id
      assistant {
        ...assistantProfileChore
      }
      closedAt
    }
  }
  ${FRAGMENT_ASSISTANT_PROFILE_CHORE}
`

export const ADD_ASSISTANT_TO_DOCTOR = gql`
  mutation addAssistantToDoctor($assistantId: String!) {
    addAssistantToDoctor(assistantId: $assistantId) {
      id
      assistant {
        ...assistantProfileChore
      }
      closedAt
    }
  }
  ${FRAGMENT_ASSISTANT_PROFILE_CHORE}
`

export const REMOVE_ASSISTANT_FROM_DOCTOR = gql`
  mutation removeAssistantFromDoctor($assistantId: String!) {
    removeAssistantFromDoctor(assistantId: $assistantId) {
      id
      assistant {
        ...assistantProfileChore
      }
      closedAt
    }
  }
  ${FRAGMENT_ASSISTANT_PROFILE_CHORE}
`
export const INVITE_ASSISTANT = gql`
  mutation inviteAssistant($createAssistantArgs: CreateAssistantProfileData!) {
    inviteAssistant(createAssistantArgs: $createAssistantArgs) {
      ...assistantProfileChore
    }
  }
  ${FRAGMENT_ASSISTANT_PROFILE_CHORE}
`

export const FIND_ASSISTANT_PROFILE = gql`
  query findAssistantProfile($registrationNumber: String!) {
    findAssistantProfile(registrationNumber: $registrationNumber) {
      ... on AssistantProfile {
        ...assistantProfileChore
      }
      ... on AssistantProfileNotFoundError {
        message
      }
    }
  }
  ${FRAGMENT_ASSISTANT_PROFILE_CHORE}
`

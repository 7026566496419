import { Grid, Chip, ChipProps, Tooltip } from '@mui/material'
import React from 'react'

interface Props {
  values: string[]
  limitTags?: number
  chipProps?: ChipProps
}

export const ChipGroup: React.FC<Props> = ({
  values,
  limitTags = values.length,
  chipProps,
}) => {
  return (
    <Grid container gap={1} sx={{ pr: 1 }}>
      {values.slice(0, limitTags).map((value: string, i: number) => (
        <Tooltip key={`${value}_${i}`} title={value} placement="top">
          <Chip label={value} sx={{ maxWidth: '100%' }} {...chipProps} />
        </Tooltip>
      ))}
      {values.length > limitTags && (
        <Tooltip title={values.slice(limitTags).join(', ')}>
          <Chip label={`+ ${values.slice(limitTags).length}`} {...chipProps} />
        </Tooltip>
      )}
    </Grid>
  )
}
